import { DateTime } from "luxon";

const US_TIMEZONE_DISPLAY = [
    { zoneId: "Pacific/Honolulu", display: "US/Hawaii" },
    { zoneId: "America/Anchorage", display: "US/Alaska" },
    { zoneId: "America/Los_Angeles", display: "US/Pacific" },
    { zoneId: "America/Phoenix", display: "US/Arizona" },
    { zoneId: "America/Denver", display: "US/Mountain" },
    { zoneId: "America/Chicago", display: "US/Central" },
    { zoneId: "America/New_York", display: "US/Eastern" }
];

export class TimeZoneInfo {
    constructor(options={}) {
        this.offset = _.parseNumber(options?.offset, 0);
        this.utcOffset = TimeZoneInfo.getUtcOffset(this.offset);
        this.zoneIds = options?.zoneIds || [];
        this.displayValues = options?.displayValues || [];
    }

    get display() { return _.join(this.displayValues, ", "); }
    get aliases() { return _.join(this.zoneIds, ", "); }

    static get clientTimeZoneId() { return Intl.DateTimeFormat().resolvedOptions().timeZone; }

    setOffset(offsetNum) {
        this.offset = _.parseNumber(offsetNum);
        this.utcOffset = TimeZoneInfo.getUtcOffset(this.offset);
    }

    deriveDisplayValue(force=false) {
        if(!force && !_.isEmpty(this.displayValues)) return;
        let initialZoneId = this.offset === 0 ? "UTC" : _.first(_.sortBy(this.zoneIds));
        _.remove(this.zoneIds, initialZoneId);
        this.displayValues.push(initialZoneId);
    }

    static getOffset(zoneId=null) {
        let tzid = zoneId || this.clientTimeZoneId;
        let dtNow = DateTime.now().setZone(tzid);
        let offset = dtNow.offset/60;
        if(dtNow.isInDST && !dtNow.isOffsetFixed) offset--;
        return offset;
    }

    static getUtcOffset(offsetVal) {
        let offsetNum = _.parseNumber(offsetVal, 0);
        return offsetNum === 0
            ? "UTC"
            : offsetNum > 0
                ? `UTC+${offsetNum}`
                : `UTC${offsetNum}`;
    }
}

export function buildTimezoneLookup() {
    const timezones = Intl.supportedValuesOf("timeZone");

    let result = [];
    let currentOffset = -11;
    while(currentOffset < 15) {
        result.push(new TimeZoneInfo({ offset: currentOffset }));
        currentOffset++;
    }

    _.forEach(timezones, zoneId => {
        let offset = TimeZoneInfo.getOffset(zoneId);
        let utcOffset = TimeZoneInfo.getUtcOffset(offset);
        let offsetIndex = _.findIndex(result, o => o.utcOffset === utcOffset);

        if(offsetIndex < 0) return;

        result[offsetIndex].zoneIds.push(zoneId);

        let usZone = _.find(US_TIMEZONE_DISPLAY, { zoneId });
        if(!usZone) return;

        result[offsetIndex].displayValues.push(usZone.display);
    });

    _.forEach(result, tz => tz.deriveDisplayValue());

    return result;
}

