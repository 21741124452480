import { DateTime } from 'luxon';

export default class {
    // XOXO TODO : token,
    constructor ({user, settings}) {
        //this.token = token;
        this.user = user || settings.user;
        this.userId = this.user.RqOneUserId || this.user.usersID;
        this.username = this.user.sub || this.user.login;

        this.started = DateTime.now().toLocaleString(DateTime.DATE_SHORT) + ', ' + DateTime.now().toLocaleString(DateTime.TIME_SIMPLE);
        this.regions = settings.regions; // list of user regions

        // split name into words for description and format automation id
        if (settings.flags) {
            _.each(settings.flags, item => {
                item.description = _.replace(_.replace(_.replace(_.startCase(item.name), 'Allow', ''), 'Can ', ''), 'Is ', '');
                item.automationId = 'chk_'.concat(_.snakeCase(item.name));
            });
        }
        if (settings.dates) {
            _.each(settings.dates, item => {
                item.description = _.startCase(item.name);
                item.automationId = 'txt_'.concat(_.snakeCase(item.name));
            });
        }
        if (settings.values) {
            _.each(settings.values, item => {
                item.description = _.startCase(item.name);
                item.automationId = 'txt_'.concat(_.snakeCase(item.name));
            });
        }
        this.settings = { permissions: settings.flags, dates: settings.dates, values: settings.values };
    }

    hasPermission (key) {return _.find(this.settings.permissions, { name: key, value: true});}
    getDate (key) {return _.find(this.settings.dates, { name: key });}
    getSetting (key) {return _.find(this.settings.values, { name: key });}
}
