
import NavigationModule from './navigation';
import RqSectionsModule from './rq-sections';
import FileInformationDefaultsModule from "./file-information-defaults";

const state = {
    fileTabs: []
};

const getters = {};

const actions = {
    toggleFileHistory({ commit, state }) {
        commit("setFileHistoryActive", !state.fileHistoryActive);
    }
};

const mutations = {
    setFileHistoryActive(state, val) { state.fileHistoryActive = val; }
};

export default {
    state,
    getters,
    actions,
    mutations,
    modules: {
        navigation: NavigationModule,
        rqSections: RqSectionsModule,
        fileInformationDefaults: FileInformationDefaultsModule
    }
};