import { GlobalEventManager } from "@/app.events";
import { RQ_DIALOG_EVENTS } from "vendor/rq-ui-vue.esm";

const createRqDialogInterface = function() {
    const createDialogId = () => _.uniqueId("rq_dialog_");
    const launchPromptDialog = (event, options) => {
        let dialogId = createDialogId();
        GlobalEventManager.emit(event, { ...options, dialogId });
        return dialogId;
    };
    return {
        open(options) {
            let dialogId = createDialogId();
            GlobalEventManager.emit(RQ_DIALOG_EVENTS.OPEN, {
                ...options,
                dialogId
            });
            return dialogId;
        },

        messageBox(title, message, onOk=(e => true), theme=null, dlgOptions={}) {
            let dialogId = createDialogId();
            GlobalEventManager.emit(RQ_DIALOG_EVENTS.PROMPT_MESSAGE, {
                dialogId,
                title,
                message,
                theme,
                onOk,
                dlgOptions
            });
            return dialogId;
        },

        confirm(title, message, onOk, onCancel=(e => true), dlgOptions={}) {
            let dialogId = createDialogId();
            GlobalEventManager.emit(RQ_DIALOG_EVENTS.PROMPT_CONFIRM, {
                dialogId,
                title,
                message,
                onOk,
                onCancel,
                dlgOptions
            });
            return dialogId;
        },

        //see RqDialogManager component for individual options
        promptInput(options) {
            return launchPromptDialog(RQ_DIALOG_EVENTS.PROMPT_INPUT, options);
        },

        promptDateInput(options) {
            return launchPromptDialog(RQ_DIALOG_EVENTS.PROMPT_DATE_INPUT, options);
        },

        promptSelect(options) {
            return launchPromptDialog(RQ_DIALOG_EVENTS.PROMPT_SELECT, options);
        },

        promptMultiSelect(options) {
            return launchPromptDialog(RQ_DIALOG_EVENTS.PROMPT_MULTISELECT, options);
        },
        /****************************************************/

        close(dialogId=null){
            GlobalEventManager.emit(RQ_DIALOG_EVENTS.CLOSE, dialogId);
        },

        reloadComponent({ dialogId=null, props={} }) {
            GlobalEventManager.emit(RQ_DIALOG_EVENTS.RELOAD, { dialogId, props });
        },

        closeAll() {
            GlobalEventManager.emit(RQ_DIALOG_EVENTS.CLOSE_ALL);
        }
    };
}

const rqDialogPlugin = {
    install(app) {
        Object.defineProperty(app.config.globalProperties, "$dialog", {
            get() {
                return createRqDialogInterface();
            }
        });
    }
};

const useRqDialog = function(){
    const methods = createRqDialogInterface();
    return {
        openDialog: methods.open,
        showMessageBox: methods.messageBox,
        showConfirm: methods.confirm,
        promptInput: methods.promptInput,
        promptDateInput: methods.promptDateInput,
        promptSelect: methods.promptSelect,
        promptMultiSelect: methods.promptMultiSelect,
        closeDialog: methods.close,
        reloadDialogComponent: methods.reloadComponent,
        closeAllDialogs: methods.closeAll
    }
}

export {
    createRqDialogInterface,
    rqDialogPlugin,
    useRqDialog
};