<template>
    <rq-pdf-viewer
        ref="pdfViewer"
        :features="features"
        :document="content"
    />
</template>
<script setup>
    import { ref, computed } from "vue";
    import { useLicenseStore } from "@/store/modules/license";
    import { RqPdfViewer } from "@/shared/components/rq/";

    defineProps({
        content: { type: String, default: null }
    });

    const licenseStore = useLicenseStore();

    const pdfViewer = ref(null);

    const features = computed(() => ({
        annotations: licenseStore.features.pdfAnnotations,
        bookmarks: licenseStore.features.pdfBookmarks,
        editing: licenseStore.features.pdfEditing,
        formCreator: licenseStore.features.pdfFormCreator,
        redaction: licenseStore.features.pdfRedaction,
        standard: true,
    }));

    const invokePdfViewerMethod = (method, ...params) => _.invoke(pdfViewer.value, method, ...params);
    const saveContent = () => invokePdfViewerMethod("getContent");

    defineExpose({ saveContent });
</script>