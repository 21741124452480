<template>
    <DxPopover
        v-if="isActive"
        :target="`#${popover.target}`"
        :container="container"
        :position="placement"
        title-template="rq-popover-title"
        :show-title="true"
        :wrapper-attr="{ class: 'rq-popover rq-check-detail-popover' }"
        v-model:visible="isVisible"
        @hidden="onHidden">
        <template #rq-popover-title>
            <div class="rq-popover-header">
                <span class="title">{{popover.title}}</span>
                <b-btn variant="icon" class="close-button" v-rq-tooltip.top.hover title="Close" @click="isVisible = false">
                    <FontAwesomeIcon icon="fas fa-times" />
                </b-btn>
            </div>
        </template>
        <div class="rq-container">
            <rqdx-action-data-grid
                ref="dataGrid"
                automation_id="tbl_grid_lines"
                :config="popover.gridConfig"
                :data-source="popover.items"
                :persist-state="false"
                class="pb-0"
                fixed-header
                hide-toolbar
            />
        </div>
    </DxPopover>
</template>

<script>
    import { computed } from "vue";
    import { usePopoverVisibleBindings } from "./usePopoverVisibleBindings";

    export default {
        name: "GridPopover",
        props: {
            placement: { type: [String,Object], default: "left" },
            container: { type: [String, HTMLElement, Object], default: null },
            popover: { type: Object, default: () => ({target: "", gridConfig: {}, title: "", items: [], visible: false}) },
            visible: { type: Boolean, default: false },
        },
        setup(props, { emit }) {
            const visibleProp = computed(() => props.visible);
            const { isActive, isVisible } = usePopoverVisibleBindings(visibleProp);
            const onHidden = () => emit("update:visible", false);
            return { isVisible, isActive, onHidden };
        },
    }
</script>
