<template>
    <div :class="{ 'rq-list-box-container': true, 'rq-list-box-disabled': disabled }" :style="styleAttr">
        <perfect-scrollbar class="rq-lb-scroll-area" :options="psOptions">
            <ul class="rq-list-box">
                <li ref="listItemElements" v-for="(item,index) in localList" :key="item.itemID"
                    :class="{
                        'list-item no-select':true,
                        'list-item-selected':item.isSelected,
                        'list-item-marked':item.isMarked,
                        'list-item-error': item.isError
                    }"
                    @click="onItemClicked(index, $event)"
                    @dblclick="onItemDblClicked(index, item)">
                    {{item.itemName}}
                </li>
            </ul>
        </perfect-scrollbar>
   </div>
</template>
<script>
    import { ListBoxItemModel } from "@/shared/models/models";

    export default {
        props: {
            dataSource: { type: Array, default: () => [] },
            sortFields: { type: [String,Array], default: () => [] },
            multiSelect: { type: Boolean, default: false },
            height: { type: [String, Number], default: null },
            modelValue: { type: Array, default: () => [] },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                localList: [],
                lastSelectedIndex: 0,
                scrollToId: null
            };
        },
        computed: {
            selectedIds() { return _.map(this.selectedItems, item => item.itemID); },
            selectedItems() { return _.filter(this.localList, item => _.parseBool(item.isSelected)); },
            styleAttr() {
                if(_.isNil(this.height)) return {};
                return {
                    height: _.isString(this.height) && (_.endsWith(this.height, "px") || _.endsWith(this.height,"%") || _.startsWith(this.height,"calc")) ? this.height : `${this.height}px`
                };
            },
            psOptions() {
                return {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: true,
                    wheelPropagation: false,
                    interceptRailY: styles => ({ ...styles, height: 0 })
                }
            }
        },
        watch: {
            dataSource: {
                handler(newValue, oldValue) {
                    if(_.isEmpty(newValue)){
                        this.localList = [];
                        return;
                    }
                    if(this.compareLists(newValue, this.localList)) return;
                    let mapped = _.map(newValue, item => new ListBoxItemModel({ ...item, isSelected: _.includes(this.selectedIds, item.itemID) }));
                    this.localList = _.isEmpty(this.sortFields) ? mapped : _.sortBy(mapped, this.sortFields);
                    if(_.isNil(this.scrollToId)) return;
                    this.scrollItemIntoView(this.scrollToId);
                    this.scrollToId = null;
                },
                immediate: true,
                deep: true
            },
            modelValue: {
                handler(newValue, oldValue) {
                    if(this.compareLists(newValue, this.selectedItems)) return;
                    _.forEach(this.localList, item => {
                        item.isSelected = _.some(newValue, v => v.itemID === item.itemID);
                    });
                },
                immediate: true
            },
            selectedItems(newValue, oldValue) {
                if(this.compareLists(newValue, this.modelValue)) return;
                this.$emit("update:modelValue", newValue);
            }
        },
        methods: {
            onItemClicked(index, e) {
                if(this.disabled) return;
                if(this.multiSelect && e.shiftKey) {
                    this.selectRange(this.lastSelectedIndex, index)
                }
                else if(this.multiSelect && e.ctrlKey) {
                    this.selectItem(index, true);
                }
                else {
                    this.selectItem(index);
                }
                this.lastSelectedIndex = index;
            },
            onItemDblClicked(index, item) {
                if(this.disabled) return;
                this.$emit("item-dblclick", { index, item });
            },
            selectItem(index, multiSelect=false) {
                _.each(this.localList, (o,i) => {
                    o.isSelected = i === index || (_.parseBool(multiSelect) && o.isSelected);
                });
            },
            selectRange(index1, index2) {
                let startIndex = _.min([index1, index2]);
                let endIndex = _.max([index1, index2]);
                _.updateAll(this.localList, "isSelected", (item,index) => startIndex <= index && index <= endIndex);
            },
            isItemSelected(itemID) {
                return _.some(this.localList, item => item.itemID === itemID && item.isSelected);
            },
            compareLists(listA, listB) {
                return listA.length === listB.length && _.isEmpty(_.differenceWith(listA, listB, _.isEqual));
            },
            scrollItemIntoView(itemID) {
                let itemIndex = _.findIndex(this.localList, { itemID });
                if(itemIndex < 0) return;
                this.$nextTick(() => {
                    this.scrollToId = null;
                    _.invoke(this, `$refs.listItemElements[${itemIndex}].scrollIntoView`, true);
                });
            },
            syncDataSource() {
                const self = this;
                if(!_.isArray(self.dataSource)) return;
                self.skipNextInit = true;
                self.$emit("update:dataSource", self.listItems.slice());
            }
        }
    }
</script>