<template>
    <div
        id="custom-data"
        class="custom-data-container row">
        <custom-data-field
            :ref="elementRef(item.customDataDefinition.customDataDefinitionID)"
            v-for="item in customDataDefValues"
            :key="item.customDataDefinition.customDataDefinitionID"
            :custom-data-definition="item.customDataDefinition"
            v-model:custom-data-value="item.customData"
            :read-only="readOnly"
            @parent-picklist-change="onParentChange"
            @picklist-data-ready="onPickListDataReady"
        />
    </div>
</template>
<script>
import CustomDataField from "./CustomDataField.vue";
import { CustomData, CustomDataDefinition, CustomDataType } from "./models";
import { TabAlert } from "@/shared/models/models";
import { AlertSeverity } from "@/shared/models/enums";

export default {
    name: "CustomDataContainer",
    components:  { CustomDataField },
    props: {
        referenceTable: { type: Number, default: 0 },
        referenceTablePkValue: { type: Number, default: 0 },
        customDataTabId: { type: Number, default: 0 },
        modelValue: { type: Array, default: () => []},
        isValid: { type: Boolean, default: true },
        hasChanges: { type: Boolean, default: false },
        readOnly: { type: Boolean, default: false }
    },
    inject: {
        scrollContainerId: { default: null },
        validationCallbacks: { default: () => [] }
    },
    data: function () {
        return {
            currentCustomDataList: [],
            originalCustomDataList: [],
            customDataDefValues: []
        }
    },
    created()  {
        this.initialize();
    },
    mounted() {
        this.checkScrollToHash();
    },
    watch: {
        customDataDefValues: {
            handler(newValue, oldValue) {
                this.currentCustomDataList = this.getCustomDataList();
                this.$emit('update:value', this.currentCustomDataList);
                this.$emit('update:hasChanges', !!this.currentCustomDataList.length)
            },
            deep: true
        },
        referenceTablePkValue: {
            handler(newValue, oldValue) {
                if(newValue !== oldValue) this.initialize();
            },
            immediate: false
        },
        hasChanges: {
            handler(newValue, oldValue) {
                //if the old value was true and changes to false, then initialize again to grab proper ids from db
                if(oldValue && !newValue) this.initialize();
            },
            immediate: false
        }
    },
    methods: {
        onParentChange(e) {
            _.each(this.$refs, (item) => {
                if(item[0].customDataDefinition.parentID === e.customDataDefinitionID) {
                    _.invoke(item[0], "updateChildDropdownItems", { value: e.value, customDataDefinitionID: e.customDataDefinitionID });
                }
            });
        },
        elementRef(id){
            return `customDataField_${id}`;
        },
        initialize() {
            // get all the custom definitions specific and custom data from db
            const self = this;
            self.validationCallbacks.push(self.getValidationTabAlerts);
            self.$api.CustomDataApi.getCustomDataValues(this.referenceTablePkValue, this.referenceTable, this.customDataTabId)
                .then(result => {
                    self.customDataDefValues = _.cloneDeep(self.mapCustomDataValues(result));
                    let customDataList  = self.getCustomDataList();
                    self.originalCustomDataList = self.getOriginalCustomDataList();
                    self.$emit('custom-data-loaded', customDataList);
                    self.$nextTick(() => {
                        self.resetFieldValidations();
                    });
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error Loading Custom Data Values.` });
                    return error;
                });
        },
        mapCustomDataValues(data) {
            // creates an array that combines all the customdata definition with its respective customdata
            let self = this;
            let values = _.map(data.customDataDefinition, x => {
                // when customData is undefined, it means that theres no customData value
                let rawCustomData = _.find(data.customData, y => y.customDataDefinitionID === x.customDataDefinitionID);
                let finalCustomData;
                if(_.isUndefined(rawCustomData)) {
                    finalCustomData = new CustomData({
                                                    customDataType: x.customDataType,
                                                    customDataDefinitionID: x.customDataDefinitionID,
                                                    referenceTablePKValue: self.referenceTablePkValue,
                                                    originalValue: null
                                                });
                }
                else {
                    let val = _.merge(new CustomData(rawCustomData), { customDataType: x.customDataType });
                    finalCustomData = new CustomData(_.merge(val, { originalValue: self.getValue(val) }));
                }

                return {
                    customDataDefinition: new CustomDataDefinition(x),
                    customData: finalCustomData
                };
            });
            return values;
        },
        getOriginalCustomDataList() {
            let t = _.map(_.filter(this.customDataDefValues, x => x.customData.customDataID !== 0), y => y.customData.toEntity());
            return t;
        },
        getCustomDataList() {
            return _.map(_.filter(this.customDataDefValues, x => {
                let customData = x.customData;
                if(customData.hasChange || (customData.isNew && customData.hasValue)) return x;

            }), y => y.customData.toEntity());
        },
        validate() {
            // iterates to all the custom data field and check if they are valid
            // will return true if all are valid, false if any is false
            let fieldIsValidValues = [];
            _.each(this.$refs, (item) => {
                fieldIsValidValues.push((_.invoke(item, "[0].isValid")));
            });
            let isValid = !_.some(fieldIsValidValues, x => x === false);
            this.$emit('update:isValid', isValid);
            return isValid;
        },
        checkHasDirty() {
            let fieldIsDirtyValues = [];
            _.each(this.$refs, (item) => {
                fieldIsDirtyValues.push((_.invoke(item, "[0].isDirty")));
            });
            let isDirty = _.some(fieldIsDirtyValues, x => x === true);
            return isDirty;
        },
        resetFieldValidations() {
            // iterates to all custom data field and reset validation
            _.each(this.$refs, (item) => {
                _.invoke(item, "[0].resetValidation");
            });
            this.$emit('update:isValid', true);
        },
        onPickListDataReady(e) {
            // raises an event for the picklist dropdown that will cause the child pick list to update its items
            let self = this;
            _.each(self.$refs, (item) => {
                let field = _.get(item, "[0]");
                if(field.customDataDefinition.customDataType === CustomDataType.PickList && field.customDataDefinition.customDataDefinitionID === e.parentID) {
                    _.invoke(field, "raisePickListChangeEvent");
                }
            });
        },
        getValue(data) {
            switch(data.customDataType) {
                case CustomDataType.Boolean:
                    return data.boolValue;
                case CustomDataType.Integer:
                    return data.intValue;
                case CustomDataType.Decimal:
                    return data.decimalValue;
                case CustomDataType.Money:
                    return data.moneyValue;
                case CustomDataType.Date:
                case CustomDataType.DateTime:
                case CustomDataType.Time:
                    return data.dateTimeValue;
                case CustomDataType.Memo:
                case CustomDataType.Text:
                case CustomDataType.RTF:
                case CustomDataType.PickList:
                case CustomDataType.ChildPickList:
                    return data.stringValue;
                case CustomDataType.Company:
                    return data.stringValue;
                default:
                    return null;
            }
        },
        getValidationTabAlerts() {
            let self = this;
            let vuelidateObjectList = [];
            let tabAlerts = [];

            if(!self.checkHasDirty()) return tabAlerts;

            self.validate();

            vuelidateObjectList = _.filter(_.map(self.$refs, (item) => {
                let customDataField = _.get(item, "[0]");
                return customDataField.v$;
            }), x => x.$error);

            tabAlerts =_.map(vuelidateObjectList, validationObject => {
                let validationFieldPaths = _.map(validationObject.$flattenParams(), x => _.join(_.get(x,"path"), "."));

                let validationFieldOjbects = _.map(validationFieldPaths, x => {
                    return {
                        key: _.last(_.split(x,'.')),
                        value: _.get(validationObject, x)
                    }
                });

                let errorFields = _.filter(validationFieldOjbects, x => x.value.$error);

                return new TabAlert({
                    alertCount: errorFields.length,
                    alertType: AlertSeverity.Error,
                    customDataTabId: self.customDataTabId 
                });
            });
            return tabAlerts;
        },
        async checkScrollToHash() {
            if(this.$route.hash !== "#custom-data" || !this.scrollContainerId) return;
            await this.$nextTick();
            let scrollToElementEvent = this.$rqui?.GlobalEventNames?.RqScrollContainer?.scrollToElement;
            this.$events.emit(scrollToElementEvent, {
                scrollContainerId: this.scrollContainerId,
                target: "#custom-data"
            });
        }
    }
}
</script>