
export const NAV_IDS = {
	ACCOUNTING: "sb-nav-acct",
	ACCOUNTING_CHECKS_ENTRY: "sb-nav-acct-checks-entry",
	ACCOUNTING_CON_CHECKS: "sb-nav--acct-con-checks",
	ACCOUNTING_ETB: "sb-nav-acct-etb",
	ACCOUNTING_ESCROW_ACTIVITY: "sb-nav-acct-escrow-activity",
	ACCOUNTING_EBANK_RECON: "sb-nav-acct-e-bank-recon",
	ACCOUNTING_EREMIT: "sb-nav-acct-e-remit",
	ACCOUNTING_EREMIT_FRAGMENT: "sb-nav-acct-e-remit-fragment",
	ACCOUNTING_DEPOSITS_ENTRY: "sb-nav-acct-deposits-entry",
	ACCOUNTING_FILING: "sb-nav-acct-filing",
	ACCOUNTING_INVOICE_BATCH_PAYMENTS: "sb-nav-invoice-batch-payments",
	ACCOUNTING_POSITIVE_PAY: "sb-nav-positive-pay",
	ACCOUNTING_PENDING_APPROVALS: "sb-nav-pending-approvals",
	ACCOUNTING_INVESTMENT: "sb-nav-acct-investment",
	ACCOUNTING_RECON: "sb-nav-acct-rec",
	ACCOUNTING_WORKDAY: "sb-nav-workday-epicor",
	ACCOUNTING_VERIFY_DEPOSITS: "sb-nav-acct-verify-deposits",
	// ACCOUNTING_WELCOME: "sb-nav-acct-welcome",
	ACCOUNTING_WIRES: "sb-nav-acct-wires",
	ACCOUNTING_WIRE_OUT: "sb-nav-acct-wire-out",
	AUDIT_LOG: "sb-nav-audit-log",
	CDF_EDITOR: "sb-nav-cdf-editor",
	CONFIG: "sb-nav-config",
	CONFIG_SEARCH: "sb-nav-config-search",
	CONFIG_CHECK_WRITING_ESCROW: "sb-nav-cfg-check-writing-escrow",
	CONFIG_CUSTOM_DATA: "sb-nav-cfg-custom-data",
	CONFIG_DOCUMENTS: "sb-nav-cfg-documents",
	CONFIG_DOCUMENTS_REPORTS: "sb-nav-cfg-documents-reports",
	CONFIG_DOCUMENTS_DOCUMENTS: "sb-nav-cfg-documents-documents",
	CONFIG_DOCUMENTS_FILE_SCAN: "sb-nav-cfg-documents-fileScan",
	CONFIG_MAIN: "sb-nav-cfg-main",
	CONFIG_MAIN_ENTERPRISE: "sb-nav-cfg-main-enterprise",
	CONFIG_MAIN_FILES: "sb-nav-cfg-main-files",
	CONFIG_MAIN_COMPANIES: "sb-nav-cfg-main-companies",
	CONFIG_MAIN_COMMUNICATION: "sb-nav-cfg-main-communication",
	CONFIG_SETTLEMENT_STATEMENTS_CALCULATORS: "sb-nav-cfg-settlement-statements-calculators",
	CONFIG_INTEGRATIONS: "sb-nav-cfg-integrations",
	CONFIG_TITLE_PRODUCTION_POLICY: "sb-nav-cfg-title-production-policy",
	CONFIG_WORKFLOW_VENDOR_SERVICES: "sb-nav-cfg-workflow-vendor-services",
	CHECK_WRITING: "sb-nav-check-writing",
	CLOSING: "sb-nav-closing",
	CLOSING_MARKET: "sb-nav-closing-market",
	CSS: "sb-nav-commerical-setttlment-statement",
	FILE_ALERTS: "sb-nav-file-alerts",
	HOME: "sb-nav-home",
	HOME_INTERNET_ALARMS: "sb-nav-home-internet-alarms",
	HOME_SEARCH: "sb-nav-home-search",
	HOME_RECENT_FILES: "sb-nav-home-recent-files",
	HOME_PORTFOLIO_MANAGER: "sb-nav-home-portfolio-manager",
	HOME_DASHBOARD: "sb-nav-home-report-dashboared",
	HOME_MANAGE_COMPANIES: "sb-nav-home-manage-companies",
	HOME_WORKFLOW: "sb-nav-home-workflow-dashboard",
	HOME_SIGNING_DASHBOARD: "sb-nav-home-signing-dashboard",
	DOCUMENT_MGMT: "sb-nav-document-mgmt",
	GENERATE_DOCUMENTS: "sb-nav-docs",
	GENERATE_EZJACKET: "sb-nav-ezjacket",
	HUD_SETTLEMENT_ADDENDUMS: "sb-nav-settlement-hud-addendums",
	INVOICE: "sb-nav-invoice",
	ITEM_11: "sb-nav-item-11",
	ORDER: "sb-nav-file",
	ORDER_ENTRY: "sb-nav-oe",
	ORDER_ENTRY_MAIN: "sb-nav-oe-main",
	ORDER_ENTRY_NOTES: "sb-nav-oe-notes",
	ORDER_ENTRY_CONTACTS: "sb-nav-oe-oc-contacts",
	ORDER_ENTRY_CUSTOM_PAGE: "sb-nav-oe-oc-custom",
	ORDER_ENTRY_LOANS: "sb-nav-oe-ol-loans",
	ORDER_ENTRY_CPL: "sb-nav-oe-ol-cpl",
	ORDER_ENTRY_PROPERTIES: "sb-nav-oe-properties",
	ORDER_WORKFLOW: "sb-nav-ow",
	POLICY: "sb-nav-policy",
	POLICY_LOAN_SELECTION: "sb-nav-policy-loan-selection",
	POLICY_OWNER: "sb-nav-policy-owner",
	POLICY_VOIDED: "sb-nav-policy-voided",
	PREMIUMS: "sb-nav-premiums",
	RECORDING: "sb-nav-recording",
	REPORTS: "sb-nav-reports",
	REPORTS_1099: "sb-nav-reports-1099",
	REPORT_MANAGEMENT: "sb-nav-report-management",
	REPORT_GENERATION: "sb-nav-report-generation",
	SETTLEMENT_DASHBOARD: "sb-nav-settlement-dashboard",
	SETTLEMENT_ADDENDUMS: "sb-nav-settlement-addendums",
	SETTLEMENT_HUD_ADDENDUMS: "sb-nav-settlement-hud-addendums",
	SETTLEMENT: "sb-nav-settlement",
	SETTLEMENT_ADDITIONAL_TITLE_SERVICES: "sb-nav-settlement-additional-title-services",
	SETTLEMENT_CONTACTS: "sb-nav-settlement-contacts",
	SETTLEMENT_AGGREGATE_ADJUSTMENT: "sb-nav-settlement-aggregate-adjustment",
	SETTLEMENT_CASH_TO_CLOSE: "sb-nav-settlment-cashtoclose-section",
	SETTLEMENT_CDF_PAYOFFS: "sb-nav-settlement-CDF-payoffs",
	SETTLEMENT_FEES: "sb-nav-settlement-fees",
	SETTLEMENT_PAID_BY_OTHERS: "sb-nav-settlement-paid-by-others",
	SETTLEMENT_PAYOFFS: "sb-nav-settlement-payoffs",
	SETTLEMENT_ORDER_SUMMARY: "sb-nav-settlement-order-summary",
	SETTLEMENT_LOANTERMS: "sb-nav-settlement-loanterm-section",
	SETTLEMENT_LOAN_CALCULATION: "sb-nav-settlement-loan-calculation",
	SETTLEMENT_DEBIT_CREDITS: "sb-nav-settlement-debitcredits-section",
	SETTLEMENT_PROJECTED_PAYMENT: "sb-nav-settlement-projectedpayment-section",
	SETTLEMENT_LOAN_DISCLOSURES: "sb-nav-settlement-loan-disclosures-section",
	SETTLEMENT_ORIGINATION_CHARGES_CDF: "sb-nav-settlement-origination-section-cdf",
	SETTLEMENT_ORIGINATION_CHARGES_HUD: "sb-nav-settlement-origination-section-hud",
	SETTLEMENT_ESCROWS_CDF: "sb-nav-settlement-escrow-section-cdf",
	SETTLEMENT_ESCROWS_HUD: "sb-nav-settlement-escrow-section-hud",
	SETTLEMENT_GFE_COMPARISON: "sb-nav-settlement-gfe-comparison",
	SETTLEMENT_NET_DISBURSEMENT_DISB: "sb-nav-settlement-net-disbursement",
	SETTLEMENT_NET_DISBURSEMENT_NET: "sb-nav-settlement-net-disbursement",
	SETTLEMENT_TITLE_CHARGES_CDF: "sb-nav-settlement-title-section-cdf",
	SETTLEMENT_TITLE_CHARGES_HUD: "sb-nav-settlement-title-section-hud",
	SETTLEMENT_OTHER_CHARGES_CDF: "sb-nav-settlement-othercost-section-cdf",
	SETTLEMENT_OTHER_CHARGES_HUD: "sb-nav-settlement-othercost-section-hud",
	SETTLEMENT_PREPAIDS_CDF: "sb-nav-settlement-prepaid-section-cdf",
	SETTLEMENT_PREPAIDS_HUD: "sb-nav-settlement-prepaid-section-hud",
	SETTLEMENT_PRORATIONS: "sb-nav-settlement-prorations",
	SETTLEMENT_REALTOR_COMMISSION: "sb-nav-settlement-realtor-commission",
	SETTLEMENT_RECORDING_FEES_AND_TAXES: "sb-nav-settlement-recording-fees-and-taxes",
	SETTLEMENT_WORKSHEET_801: "sb-nav-settlement-worksheet801",
	TITLE_PRODUCTION: "sb-nav-tp",
	UTILITIES: "sb-nav-util",
	UTILITIES_COMPANIES: "sb-nav-utilities-companies"
};