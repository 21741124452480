<template>
    <DxPopover
        v-if="isActive"
        :target="target"
        :boundary="container"
        position="right"
        title-template="rq-popover-title"
        :show-title="true"
        :wrapper-attr="{
            class: 'rq-popover rq-address-detail-popover'
        }"
        v-model:visible="isVisible"
        @hidden="onHidden">
        <template #rq-popover-title>
            <div class="rq-popover-header">
                <span class="title">{{title}}</span>
            </div>
        </template>
        <dl class="row">
            <template v-if="displayNumber > 0">
                <dt class="col-4">Company ID:</dt> <dd class="col-8">{{displayNumber}}</dd>
            </template>
            <dt class="col-4">Name:</dt> <dd class="col-8">{{name}}</dd>
            <dt class="col-4">Address 1:</dt> <dd class="col-8">{{address1}}</dd>
            <dt class="col-4">Address 2:</dt> <dd class="col-8">{{address2}}</dd>
            <dt class="col-4">City:</dt> <dd class="col-8">{{city}}</dd>
            <dt class="col-4">State:</dt> <dd class="col-8">{{state}}</dd>
            <dt class="col-4">Zip:</dt> <dd class="col-8">{{zip}}</dd>
        </dl>
    </DxPopover>
</template>

<script>
    import { computed } from "vue";
    import { usePopoverVisibleBindings } from "./usePopoverVisibleBindings";
    export default {
        name: "AddressDetailPopover",
        props: {
            title:  { type: String, default: null },
            target: { type: [String, HTMLElement, SVGElement, Function, Object], default: null },
            placement: { type: String, default: "right" },
            container: { type: [String, HTMLElement, Object], default: null },
            displayNumber: { type: Number, default: 0 },
            name: { type: String, default: "" },
            address1: { type: String, default: "" },
            address2: { type: String, default: "" },
            city: { type: String, default: "" },
            state: { type: String, default: "" },
            zip: { type: String, default: "" },
            delayShown: { type: Number, default: 300 },
            delayHidden: { type: Number, default: 0 },
            visible: { type: Boolean, default: false }
        },
        setup(props, { emit }) {
            const delayShownProp = computed(() => props.visible);
            const delayHiddenProp = computed(() => props.visible);
            const visibleProp = computed(() => props.visible);

            const { isActive, isVisible } = usePopoverVisibleBindings(visibleProp, delayShownProp, delayHiddenProp);
            const onHidden = () => emit("update:visible", false);

            return { isVisible, isActive, onHidden };
        }
    }
</script>
