import {
    ADD_FILE_GROUP, APPLY_TEMPLATE, CANCEL, MANAGE_NOTES,
    SAVE, SPACER, VIEW_DOCS, SET_POLICY_OPTIONS
} from "./global";

export const Actions = {
    IMPORT: {
        automation_id: 'btn_upload',
        key: "upload",
        text: "Import Title Production Data",
        tooltip: "Import Title Production Data",
        disableWhenReadOnly: true
    },
    EXPORT: {
        automation_id: 'btn_download',
        key: "download",
        text: "Export Title Production Data",
        tooltip: "Export Title Production Data",
        disableWhenReadOnly: true
    },
    //RQO-24436 disable title production version/audit log
    // VIEW_AUDIT_LOG: {
    //     automation_id: 'btn_view_audit_log',
    //     key: "tp-audit-log",
    //     text: "Audit",
    //     tooltip: "View Audit Log"
    // },
    // CREATE_VERSION: {
    //     automation_id: 'btn_create_version',
    //     key: "tp-create-label",
    //     text: "Create Version",
    //     tooltip: "Create Version",
    //     disableWhenReadOnly: true
    // },
    UNLOCK_DATES: {
        automation_id: 'btn_lock_dates',
        key: "lockdates",
        text: "Unlock",
        tooltip: "Unlock Remit Dates",
        permissionKeys: ["CanUnlockPolicyRemitDates"],
        disableWhenReadOnly: true
    },
    VOID_POLICY: {
        automation_id: 'btn_void_policy',
        key: "voidPolicy",
        text: "Void Policy",
        tooltip: "Void Policy",
        permissionKeys: ["CanVoidPolicies"],
        disableWhenReadOnly: true
    },

};

export const TitleViewActions = {
    LOAN_SELECTION: [
        SPACER, ADD_FILE_GROUP, MANAGE_NOTES("Title Production"),
        SPACER, VIEW_DOCS, APPLY_TEMPLATE
    ],
    LOAN: [
        ADD_FILE_GROUP, VIEW_DOCS, MANAGE_NOTES("Title Production"),
        SAVE, CANCEL, APPLY_TEMPLATE,
        Actions.IMPORT,
        Actions.EXPORT,
        //RQO-24436 disable title production version/audit log
        // _.assign({}, Actions.VIEW_AUDIT_LOG, { props: { categories: [
        //     'CommitmentPolicyHeader',
        //     'CommitmentPolicyDetail',
        //     'AssignedLanguage'
        // ]}}),
        // Actions.CREATE_VERSION,
    ],
    NOTES: [
        SPACER, SPACER, MANAGE_NOTES("Title Production"),
        SPACER, SPACER, VIEW_DOCS
    ]
};

let policyActions = [
    ADD_FILE_GROUP, VIEW_DOCS, MANAGE_NOTES("Policy"),
    SAVE, CANCEL, APPLY_TEMPLATE,
    // Actions.VIEW_AUDIT_LOG,
    // Actions.CREATE_VERSION,
    SET_POLICY_OPTIONS
    //RQO-6444 - Not wired up and page-level action is hidden after policy is voided...need more information for this to be resolved
    //Actions.VOID_POLICY
];
export const PolicyViewActions = {
    LOAN_SELECTION: [
        SPACER, ADD_FILE_GROUP, MANAGE_NOTES("Policy"),
        SPACER, VIEW_DOCS, APPLY_TEMPLATE
    ],
    LOAN: policyActions,
    OWNER: policyActions,
    NOTES: [
        SPACER, SPACER, ADD_FILE_GROUP,
        SPACER, SPACER, VIEW_DOCS
    ]
};