
// Import global application styles
import "../scss/style.scss";

import { createApp, h } from "vue";
import { createPinia } from "pinia";
import { createAppInstaller } from "./AppInstaller";
import { appConfig, APP_ISSUE_CODE } from "./AppConfig";
import { createFaLibrary, createFaLibraryRestricted } from "./FaInstaller";

// Authentication
import { EnvironmentApi } from "./api";

//import { GlobalEventManager } from "@/app.events";
import { UAService, Identity } from "@/shared/services";

// Import root component, router, & store
import App from "./App";
import AppRestricted from "./AppRestricted";
import router from "./router";
import store from "./store";
import { INITIALIZE_SESSION } from "./store/actions";

const initApp = async () => {
    const overrideKeycloakAuthHost = appSettings.overrideKeycloakAuthHost;
    let response = await EnvironmentApi.getConfiguration();
    let hostname = location.hostname;
    let tenantName = _.toLower(hostname.substring(0, hostname.indexOf(".")));
    return await Identity.initialize({
        url: overrideKeycloakAuthHost || response.data.keycloakAuthHost,
        clientId: `${tenantName}-ui`,
        realm: response.data.realm,
        logoutRedirectUri: location.origin,
    });
};

const loadRestrictedApp = (issueCode=APP_ISSUE_CODE.UNHANDLED) => {
    createFaLibraryRestricted();
    const app = createApp({
        render: () => h(AppRestricted, { issueCode })
    });
    app.mount("#app");
};

const loadFullApp = async sessionInfo => {
    createFaLibrary();

    const app = createApp(App);
    const pinia = createPinia();

    app.use(appConfig);
    app.use(pinia);
    app.use(store);

    let isSessionValid = await store.dispatch(INITIALIZE_SESSION, sessionInfo);
    if(!isSessionValid) return false;

    app.use(router);
    router.app = app;

    app.use(createAppInstaller(router));

    await router.isReady()
    app.mount("#app");

    return true;
};

const createHorizonApp = async () => {
    let issueCode = "";

    try {
        if(!UAService.isDeviceSupported()) {
            issueCode = APP_ISSUE_CODE.UNSUPPORTED_DEVICE;
        }
        else {
            //get environment info and authorize user
            let sessionInfo = await initApp();
            if(sessionInfo?.status === APP_ISSUE_CODE.UNAUTHORIZED) return;
            if(sessionInfo?.isValidUser) {
                let success = await loadFullApp(sessionInfo);
                //if success is true, return since there's nothing more to do
                if(success) return;
            }
            else {
                issueCode = sessionInfo?.status;
            }
        }
    }
    catch(err) {
        issueCode = APP_ISSUE_CODE.UNHANDLED;
        console.error(err);
    }

    loadRestrictedApp(issueCode);
};

createHorizonApp();