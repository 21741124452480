export class CustomData {
    constructor(options){
        options = options || {};

        this.customDataID = _.parseNumber(options.customDataID, 0);
        this.customDataDefinitionID = _.parseNumber(options.customDataDefinitionID, null);
        this.referenceTablePKValue = _.parseNumber(options.referenceTablePKValue, null);
        this.boolValue = _.parseBool(options.boolValue, null);
        this.intValue = _.parseNumber(options.intValue, null);
        this.decimalValue = _.parseNumber(options.decimalValue, null);
        this.dateTimeValue = options.dateTimeValue || null;
        this.stringValue = options.stringValue || null;
        //this.moneyValue = options.moneyValue || null;
        this.moneyValue = _.parseNumber(options.moneyValue, null);

        this.customDataType = _.parseNumber(options.customDataType, null);
        this.originalValue = options.originalValue;
    }

    get hasChange() {
        return this.value !== this.originalValue;
    }

    get isNew() {
        return this.customDataID === 0;
    }

    get hasValue() {
        return !_.isNullOrEmpty(this.value);
    }

    toEntity() {
        return _.omit(this, ["customDataType", "value"]);
    }

    get value() {
        switch(this.customDataType) {
            case CustomDataType.Boolean:
                return this.boolValue;
            case CustomDataType.Integer:
                return this.intValue;
            case CustomDataType.Decimal:
                return this.decimalValue;
            case CustomDataType.Money:
                return this.moneyValue;
            case CustomDataType.Date:
            case CustomDataType.DateTime:
            case CustomDataType.Time:
                return this.dateTimeValue;
            case CustomDataType.Memo:
            case CustomDataType.Text:
            case CustomDataType.RTF:
            case CustomDataType.PickList:
            case CustomDataType.ChildPickList:
                return this.stringValue;
            case CustomDataType.Company:
                return this.stringValue;
            default:
                return null;
        }
    }

    set value(value) {
        switch(this.customDataType) {
            case CustomDataType.Boolean:
                this.boolValue = value;
                break;
            case CustomDataType.Integer:
                this.intValue = value;
                break;
            case CustomDataType.Decimal:
                this.decimalValue = value;
                break;
            case CustomDataType.Money:
                this.moneyValue = value;
                break;
            case CustomDataType.Date:
            case CustomDataType.DateTime:
            case CustomDataType.Time:
                this.dateTimeValue = _.isObject(value) ? null : value;
                break;
            case CustomDataType.Memo:
            case CustomDataType.Text:
            case CustomDataType.RTF:
            case CustomDataType.PickList:
            case CustomDataType.ChildPickList:
            case CustomDataType.Company:
                this.stringValue = value;
                break;
        }
    }
}
export class CustomDataDefinition {
    constructor(options){
        options = options || {};

        this.customDataDefinitionID = _.parseNumber(options.customDataDefinitionID, 0);
        this.customDataTabID = _.parseNumber(options.customDataTabID, null);
        this.label = options.label || null;
        this.referenceTable =  _.parseNumber(options.referenceTable, -1);
        this.customDataType =  _.parseNumber(options.customDataType, null);
        this.positionOrdinal =  _.parseNumber(options.positionOrdinal, 0);
        this.maxLength =  _.parseNumber(options.maxLength, null);
        this.module = options.module || null;
        this.displayLabel = options.displayLabel || null;
        this.gridListValueRequired =  _.parseBool(options.gridListValueRequired);
        this.parentID =  _.parseNumber(options.parentID, 0);
        
        this.parentSelectionID = _.parseNumber(options.parentSelectionID, 0);
        this.listValueCount = _.parseNumber(options.listValueCount, 0);
    }

    get isNew() { return this.customDataDefinitionID === 0; }

    toDataObject() { return _.toPlainObject(this); }
}

const customDataReferenceTable = {
    Orders: 0,
    FileMain: 1,
    Buyer: 2,
    Seller: 3,
    OrderContacts: 4,
    SaleLoan: 5,
    Property: 6,
    Invoices: 7
};

export class CustomDataReferenceTable {
    static get Orders () {return customDataReferenceTable.Orders;}
    static get FileMain () {return customDataReferenceTable.FileMain;}
    static get Buyer () {return customDataReferenceTable.Buyer;}
    static get Seller () {return customDataReferenceTable.Seller;}
    static get OrderContacts () {return customDataReferenceTable.OrderContacts;}
    static get SaleLoan () {return customDataReferenceTable.SaleLoan;}
    static get Property () {return customDataReferenceTable.Property;}
    static get Invoices () {return customDataReferenceTable.Invoices;}

    static get lookupItems () { return this.getLookupItems(); }

    static getLookupItems(valueExpr="id", displayExpr="name") {
        return _.map(customDataReferenceTable, a => ({ [valueExpr]: a, [displayExpr]: CustomDataReferenceTable.displayValue(a) }));
    }

    static displayValue (id) {
        switch (id) {
            case customDataReferenceTable.Orders:
                return "Custom Page";
            case customDataReferenceTable.FileMain:
                return "File Main";
            case customDataReferenceTable.Buyer:
                return "Buyer";
            case customDataReferenceTable.Seller:
                return "Seller";
            case customDataReferenceTable.OrderContacts:
                return "Order Contacts";
            case customDataReferenceTable.SaleLoan:
                return "Loans";
            case customDataReferenceTable.Property:
                    return "Property";
            case customDataReferenceTable.Invoices:
                return "Invoices";
            default:
                return "";
        }
    }
}

const customDataType = {
    Boolean: 0,
    Integer: 1,
    Decimal : 2,
    Date: 3,
    Time: 4,
    DateTime: 5,
    Text: 6,
    Memo: 7,
    RTF: 8,
    PickList: 9,
    Company: 10,
    Money: 11,
    ChildPickList: 12
};

export class CustomDataType {
    static get Boolean () {return customDataType.Boolean;}
    static get Integer () {return customDataType.Integer;}
    static get Decimal () {return customDataType.Decimal;}
    static get Date () {return customDataType.Date;}
    static get Time () {return customDataType.Time;}
    static get DateTime () {return customDataType.DateTime;}
    static get Text () {return customDataType.Text;}
    static get Memo () {return customDataType.Memo;}
    static get RTF () {return customDataType.RTF;}
    static get PickList () {return customDataType.PickList;}
    static get Company () {return customDataType.Company;}
    static get Money () {return customDataType.Money;}
    static get ChildPickList () {return customDataType.ChildPickList;}


    static get lookupItems () { return this.getLookupItems(); }

    static getLookupItems(valueExpr="id", displayExpr="name") {
        return _.map(customDataType, a => ({ [valueExpr]: a, [displayExpr]: CustomDataType.displayValue(a) }));
    }

    static displayValue (id) {
        switch (id) {
            case customDataType.Boolean:
                return "Check Box";
            case customDataType.Integer:
                return "Integer";
            case customDataType.Decimal:
                return "Decimal";
            case customDataType.Date:
                return "Date";
            case customDataType.Time:
                return "Time";
            case customDataType.DateTime:
                return "DateTime";
            case customDataType.Text:
                return "Text";
            case customDataType.Memo:
                return "Memo";
            case customDataType.RTF:
                return "Rich Text";
            case customDataType.PickList:
                return "Pick List";
            case customDataType.Company:
                return "Company";
            case customDataType.Money:
                return "Money";
            case customDataType.ChildPickList:
                return "Child Pick List";
            default: ""
        }
    }
}
export class CustomDataTab {
    constructor(options) {
        options = options || {};

        this.customDataTabID = _.parseNumber(options.customDataTabID, 0);
        this.label = options.label || '';
        this.referenceTable = _.parseNumber(options.referenceTable, 0);
        this.display = _.parseBool(options.display);
        this.positionOrdinal = _.parseNumber(options.positionOrdinal, 0);
        this.inFileMain = _.parseBool(options.inFileMain);
        this.regionID = _.parseNumber(options.regionID, 0);
    }

    get isNew() { return this.customDataTabID === 0; }

    toDataObject() { return _.toPlainObject(this); }
}

export class CustomDataListValue {
    constructor(options) {
        options = options || {};
        this.customDataListValueID = _.parseNumber(options.customDataListValueID, 0);
        this.customDataDefinitionID = _.parseNumber(options.customDataDefinitionID, 0);
        this.availableValue = options.availableValue || '';
        this.parentID = _.parseNumber(options.parentID, null);        
        this.positionOrdinal = _.parseNumber(options.positionOrdinal, 0);        
    }

    get isNew() { return this.customDataTabID === 0; }

    toDataObject() { return _.toPlainObject(this); }

}
