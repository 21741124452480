
const RegEx = {
    ABA: new RegExp(/^([0-9]{9})$/),
    SWIFT: new RegExp(/^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/),
    Email: new RegExp(/^$|^(([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})(\s*;\s*|\s*$))*$/),
    SSN: new RegExp(/^([0-9]{3})-([0-9]{2})-([0-9]{4})$/),
    MaskedSSN1: new RegExp(/^\*{3}-\*{2}-([0-9]{4})$/),
    MaskedSSN2: new RegExp(/^\*{3}-\*{2}-\*{4}$/),
    TaxID: new RegExp(/^([0-9]{2})-([0-9]{7})$/),
    MaskedTaxID1: new RegExp(/^\*{2}-\*{3}([0-9]{4})$/),
    MaskedTaxID2: new RegExp(/^\*{2}-\*{7}$/),
    FileName: new RegExp(/^(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^|*?\\:<>/$"]*[^.|*?\\:<>/$"]+$/),
    PropertyTaxID: new RegExp(/[\s\S]/),
    Foreign: new RegExp(/^[a-zA-Z0-9]+$/),
    //Guid: new RegEx(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/)
};

export default RegEx;