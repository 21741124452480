<template>
    <div :class="{
            'rq-select-all-toggle': true,
            'checked': allSelected,
            'partial': someSelected
        }"
        tabindex="-1"
        @click="emitToggle"
        @keypress="onToggleKeyPress"></div>
</template>
<script setup>
    const props = defineProps({
        allSelected: { type: Boolean, default: false },
        someSelected: { type: Boolean, default: false }
    });
    const emit = defineEmits(["toggle"]);
    const emitToggle = () => emit("toggle", !props.allSelected);
    function onToggleKeyPress(e) {
        if(e.key !== "Enter" && !_.isEmpty(_.trim(e.key))) return;
        emitToggle();
    }
</script>