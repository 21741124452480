import CompanyAddressesApi from "@/api/companyaddresses";
import { CompanyAddress } from "@/modules/file/order-entry/contacts/models";

export default function useGridCompanyAddressAutoCompletePicker() {

    async function fetchCompanyAddressAutoCompleteData(loadOptions, cellInfo) {
        if(_.isEmpty(loadOptions.searchValue)) return {
            data: [],
            totalCount: 0
        };
        
        let state = !_.isNil(cellInfo.data?.state) ? cellInfo.data?.state: "";
        let response = await CompanyAddressesApi.addressLookup(loadOptions.searchValue, state);
        return {
            data: response,
            totalCount: response.length
        };
    }

    function getCompanyAddressAutoCompleteGridColumn({
        column,
        disabled=false,
        activeOnly=false
    }) {
        let resultCol = _.cloneDeep(column);

        let getGridData = v => {
            let result = {
                address1: _.get(v, "address1", null) || null,
                address2: _.get(v, "address2", null) || null,
                addressTypeID: _.get(v, "addressTypeID", null) || null,
                addressTypeName: _.get(v, "addressTypeName", null) || null,
                companyAddressID: _.get(v, "companyAddressID", null) || null,
                companyID: _.get(v, "companyID", null) || null,
                city: _.get(v, "city", null) || null,
                state: _.get(v, "state", null) || null,
                zip: _.get(v, "zip", null) || null,
                countyID: _.get(v, "countyID", null) || null,
                countyName: _.get(v, "countyName", null) || null
            };

            return result;
        };

        let getAddressData = v => {
            let result = {
                address1: _.get(v, "address1", null) || null,
                address2: _.get(v, "address2", null) || null,
                addressTypeID: _.get(v, "addressTypeID", null) || null,
                addressTypeName: _.get(v, "addressTypeName", null) || null,
                companyAddressID: _.get(v, "companyAddressID", null) || null,
                companyID: _.get(v, "companyID", null) || null,
                city: _.get(v, "city", null) || null,
                state: _.get(v, "state", null) || null,
                zip: _.get(v, "zip", null) || null,
                countyID: _.get(v, "countyID", null) || null,
                countyName: _.get(v, "countyName", null) || null
            };

            return new CompanyAddress(result);
        };

        resultCol.setCellValue = function(rowData, value) {
            let documentData = getGridData(value);
            _.assign(rowData, documentData);
        };

        const addressPickerTemplate = (cellElement, cellInfo) => {
            let currentData = getAddressData(cellInfo.data);
            let $dxInstance = null;

            const stopEventPropagation = e => {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            };

            //assigned to action button "id" attr to be used as toolitp targets
            let viewEditButtonId = _.uniqueId("rq-editor-action-");

            let $dxElement = $("<div>").dxSelectBox({
                dataSource:{
                    paginate: true,
                    pageSize: 50,
                    filter: null,
                    byKey: key => ({ text: key }),
                    load: _.debounce(function(e) {
                        return fetchCompanyAddressAutoCompleteData(e, cellInfo);
                    }, 300, { leading: true, trailing: true })
                },
                dropDownOptions: {
                    elementAttr: { class: "rq-dx-theme-blue" },
                    minWidth: 350
                },
                displayExpr: "text",
                valueExpr: "text",
                value: currentData.address1,
                searchEnabled: true,
                showDataBeforeSearch: false,
                openOnFieldClick: false,
                opened: false,
                noDataText: "Enter a value to search for an address",
                itemTemplate: itemData => {
                    if (!itemData) return;
                    var reg = new RegExp($dxInstance.option("text"), 'ig');
                    var display = _.replace(itemData.text, reg, '<strong>$&</strong>');
                    return $('<span>').addClass('d-flex justify-content-between')
                            .append($('<span>').append(display));
                },
                onValueChanged(e) {
                    let selectedItem = e.component.option("selectedItem");
                    let addressTypeID = !_.isNil(currentData.addressTypeID) ? currentData.addressTypeID : cellInfo.row.cells[1].value;
                    currentData = new CompanyAddress(
                        {
                            companyAddressID: currentData.companyAddressID,
                            companyID:currentData.companyID,
                            address1: selectedItem.line1,
                            address2: selectedItem.line2,
                            addressTypeID: addressTypeID,
                            addressTypeName: currentData.addressTypeName,
                            city: selectedItem.city,
                            state: selectedItem.state.abbreviation,
                            zip: selectedItem.zipCode
                        }
                    );
                    cellInfo.setValue(currentData);
                    
                },
                onOpened(e) {
                    let $contentElement = e.component._popup.content();
                    if($contentElement.hasClass("rq-dx-theme-default")) return;
                    $contentElement.addClass("rq-dx-theme-default");
                },
                onClosed(e) {
                },
            });
            $dxInstance = $dxElement.dxSelectBox("instance");

            /* Tooltips */
            let commonTooltipConfig = { wrapperAttr: { class: "rq-dx-tooltip" }, position: "top", showEvent: "mouseenter", hideEvent: "mouseleave" };
            cellElement
                .append($("<span/>").text("View/Edit Document Template").dxTooltip({ target: `#${viewEditButtonId}`, ...commonTooltipConfig }));
            /************/

            return $dxElement;
        };

        const disabledEditTemplate = (cellElement, cellInfo) => {
            let currentData = getAddressData(cellInfo.data);
            return $("<div/>").dxTextBox({ value: currentData.address1, readOnly: true });
        };

        resultCol.editCellTemplate = function(cellElement, cellInfo) {

            let isDisabled = _.isFunction(disabled) ? disabled(cellInfo) : _.parseBool(disabled);
            return isDisabled
                ? disabledEditTemplate(cellElement, cellInfo)
                : addressPickerTemplate(cellElement, cellInfo);
        };

        return resultCol;
    }

    return {
        getCompanyAddressAutoCompleteGridColumn
    };
}