import authentication from "./authentication";
import documents from "./documents";
import exagoSession from './exago-session';
import orders from "./orders/";
import prepaids from "./settlement-section";
import properties from "./properties";
import rateEngine from "./rateEngine";
import realtorCommission from "./realtorCommission";
import recording from "./recording";
import settlement from "./settlement";
import system from "./system";
import ui from './ui';
import workflow from "./workflow";
import reports from './reports';

export default {
    authentication,
    documents,
    exagoSession,
    orders,
    prepaids,
    properties,
    rateEngine,
    realtorCommission,
    recording,
    reports,
    settlement,
    system,
    ui,
    workflow
};