import Store from "@/store";
import UsersApi from "@/api/users";
import Keycloak from "keycloak-js";
import { AUTH_ACTIONS } from "@/store/actions";
import { GlobalEventManager } from "@/app.events";
import { Interval } from "@/shared/utilities";
import { APP_ISSUE_CODE } from "@/AppConfig";
import { Duration } from "luxon";
import LogService from "./log.service";

const REFRESH_TOKEN_EVENT = "identity-service--refresh-token";

class IdentityService {
    constructor() {
        this.intervalName = "token-refresh";
        this.source = "identity.service.js";
        this.keycloak = null;
        this.logoutInProgress = false;
    }

    get identityToken() {
        let kcValues = _.pick(this.keycloak, ["token", "tokenParsed"]);
        return _.merge({}, { token:"", tokenParsed: "" }, kcValues);
    }

    get isAuthenticated() {
        return _.getBool(this, "keycloak.authenticated");
    }

    async initialize({ url, clientId, realm, logoutRedirectUri }) {
        const self = this;
        let silentCheckSsoRedirectUri = `${window.location.origin}/silent-check-sso.html`;

        LogService.logInfo("IdentityService.initialize :: initializing keycloak...");
        self.keycloak = new Keycloak({ url, clientId, realm, logoutRedirectUri });
        let auth = await self.keycloak.init({ onLoad: "check-sso", silentCheckSsoRedirectUri })

        LogService.logInfo(`IdentityService.initialize :: keycloak initialized...result=${auth}`);
        let redirectToLogin = !auth;
        if (!redirectToLogin && sessionStorage.dologoff) {
            LogService.logInfo("IdentityService.initialize :: sessionStorage.doLogoff is true. forcing logout...");
            sessionStorage.removeItem("dologoff");
            self.keycloak.logout();
            redirectToLogin = true;
        }

        if(redirectToLogin) {
            LogService.logInfo("IdentityService.initialize :: redirecting to login...");
            let redirctUrl = `${window.location.origin}${window.location.pathname}`;
            window.location.href = self.createLoginUrl(redirctUrl);
            return { status: APP_ISSUE_CODE.UNAUTHORIZED };
        }

        let userSettings = await UsersApi.getUserSettings(self.identityToken.token);
        let user = userSettings?.user;
        let hasUser = user?.usersID > 0;
        let hasUserGroup = user?.groupUsersID > 0;
        return {
            userSettings,
            token: self.identityToken,
            isValidUser: hasUser && hasUserGroup,
            status: hasUser
                ? hasUserGroup
                    ? "authorized"
                    : APP_ISSUE_CODE.INVALID_USER //a user is returned, but has no associated group
                : APP_ISSUE_CODE.MISSING_USER //a null or empty user is returned
        };
    }

    createLoginUrl(redirectUri=null) {
        let urlResult = this.keycloak.createLoginUrl({ redirectUri });
        return urlResult || redirectUri;
    }

    createLogoutUrl(redirectUri=null) {
        let urlResult = this.keycloak.createLogoutUrl({ redirectUri });
        return urlResult || redirectUri;
    }

    logOut(redirectUri=null) {
        //Protect against any race conditions caused by watchers hitting the API
        if(this.logoutInProgress) return;

        this.logoutInProgress = true;
        this.keycloak.logout({ redirectUri, caller:"IdentityService.logOut" });
    }

    async refreshToken(minValiditySecond=-1) {
        const self = this;
        // -1 forces a refresh.
        var minValidity = _.parseNumber(minValiditySecond, -1);

        if (_.isNil(self.keycloak)) {
            throw "Keycloak not initialized";
        }

        LogService.logInfo("IdentityService.refreshToken :: begin token refresh...");

        let retryCount = 1;
        let refreshComplete = false;
        while(retryCount < 6 && !refreshComplete) {
            try {
                let refreshed = await self.keycloak.updateToken(minValidity);
                if(!_.parseBool(refreshed)) {
                    LogService.logInfo("IdentityService.refreshToken :: token was not refreshed. returning.");
                }
                if(_.isEmpty(self.identityToken.token)) {
                    LogService.logInfo("IdentityService.refreshToken :: token refreshed but token is empty. this will result in session invalidation and logout...");
                }
                else {
                    LogService.logInfo("IdentityService.refreshToken :: token refreshed successfully. storing token...");
                }

                await Store.dispatch(AUTH_ACTIONS.SET_IDENTITY_AUTH, self.identityToken);
                LogService.logInfo("IdentityService.refreshToken :: token storage complete.");

                refreshComplete = true;
            }
            catch(err) {
                LogService.logError("IdentityService.refreshToken :: Error while refreshing token", err);
                retryCount++;
                if(retryCount < 6) {
                    LogService.logInfo(`IdentityService.refreshToken :: Retrying token refresh -- Attempt #${retryCount}...`);
                }
            }
        }

        if(refreshComplete) return true;

        LogService.logInfo("IdentityService.refreshToken :: Token refresh attempt limit exceeded...aborting.");
        await Store.dispatch(AUTH_ACTIONS.LOGOUT, { caller: "IdentityService.refreshToken" });
        return false;
    }

    registerRefreshTokenEvent() {
        LogService.logInfo("IdentityService.registerRefreshTokenEvent :: registering refresh token event...");

        const tokenSync = e => {
            if(_.get(e, "key", "") !== this.intervalName) return;
            var minValidity = _.getNumber(e, "minValiditySecond");
            this.refreshToken(minValidity);
        };

        GlobalEventManager.off(REFRESH_TOKEN_EVENT);
        GlobalEventManager.on(REFRESH_TOKEN_EVENT, tokenSync, this.source);
    }

    stopInterval() {
        const self = this;
        Interval.clear(self.intervalName);
        LogService.logInfo("IdentityService.stopInterval :: interval cleared.");
    }

    queueInterval(intervalMs=300000, delayMs=100) {
        const self = this;

        LogService.logInfo("IdentityService.queueInterval :: stopping refresh interval...");
        self.stopInterval();

        let minValiditySecond = 300;    // 5 minutes.
        let intervalMillis = _.parseNumber(intervalMs, 300000);

        LogService.logInfo(`IdentityService.queueInterval :: starting refresh interval...duration=${Duration.fromMillis(intervalMillis).toFormat("m'm' s's'")}, delay=${delayMs}ms`);
        Interval.make(
            self.intervalName,
            () => {
                self.refreshToken(minValiditySecond);
            },
            intervalMillis,
            false,
            delayMs,
        );
    }
}

const identityService = new IdentityService();

export default identityService;
