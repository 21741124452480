<template>
    <div class="input-group contact-picker">
        <dx-select-box
            ref="selectBox"
            :items="contacts"
            display-expr="fullName"
            value-expr="contactID"
            :disabled="isDisabled"
            :no-data-text="noDataText"
            v-model="selectedValue"
            @input="onInput"
            @focusIn="onFocusIn"
            @focusOut="onFocusOut"
            @opened="onOpened"
            @valueChanged="onValueChanged"
        />
        <!--
            TODO: DISBLED TOOLTIP WRAPPER - update css to provide away to have a wrapper around input group buttons/dropdowns to target for tooltips when disabled
            <span v-rq-tooltip.hover.top.html :title="isDisabled ? '' : `Add Contact${disableAddEdit ? ' <br/>(Access Restricted)' : ''}`">
            </span>
        -->
        <b-dropdown v-if="selectedValue" right split :disabled="isDisabled || disableAddEdit" @click="onAddContact">
            <template #button-content><FontAwesomeIcon icon="fa fa-user-plus" aria-hidden="true" /></template>
            <b-dropdown-item @click="onEditContactClick">View/Edit Selected Contact</b-dropdown-item>
        </b-dropdown>
        <b-btn v-else
            variant="theme"
            class="input-group-icon"
            @click="onAddContact"
            :disabled="isDisabled || disableAddEdit">
            <FontAwesomeIcon icon="fas fa-user-plus" aria-hidden="true" />
        </b-btn>
    </div>
</template>

<script>
    import { Contact } from "@order-entry/contacts/models";
    import { SearchRequest } from "@/shared/models/models";
    import ContactEdit from "@utilities/manage-companies/components/ContactEdit";

    export default {
        props: {
            newContactDialogTitle: { default: "Create New Contact" },
            newContactButtonTooltip: { default: "Create New Contact" },
            automation_id: {
                type: String,
                default: ""
            },
            companyId: { type: Number, default: null },
            modelValue: { type: Number, default: null },
            contactName: { default: null },
            placeHolder: { default: "Select ..." },
            disabled: { default: false },
            disableAddEdit:  { default: false }
        },

        data () {
            const self = this;
            return {
                contacts: [],
                selectedValue: null,
                lastInputValue: null,
                focused: false,
                isGettingContacts: false,
                currentDisplayValue: ""
            };
        },

        computed:{
            dxSelectBoxInstance() { return _.get(this, "$refs.selectBox.instance", {}); },
            currentCompanyID() { return _.parseNumber(this.companyId, 0); },
            hasCompany() { return _.gt(this.currentCompanyID, 0); },
            isDisabled() { return this.disabled || !this.hasCompany; },
            addButtonTooltip() {
                return this.disabled ? "" : this.hasCompany
                    ? this.newContactButtonTooltip
                    : "Select a company<br />to enable";
            },
            noDataText() {
                if(_.gt(this.contacts.length, 0)) return "No contacts found matching the value entered.";
                return "No contacts found for the selected company.";
            }
        },

        watch: {
            companyId (newVal, oldVal) {
                if(newVal === oldVal) return;
                this.getContacts()
                    .then (result => {
                        if(_.isNil(this.modelValue)) return;
                        this.selectedValue = this.modelValue;
                    });
            },

            modelValue (newVal, oldVal) {
                let isNewContact = this.checkIfIsNewContact(newVal);
                if (isNewContact && !this.isGettingContacts){
                    this.getContacts()
                        .then(result => {
                            this.selectedValue = newVal;
                        });
                }
                else{
                    if(newVal === oldVal) return;
                    this.selectedValue = newVal;
                }
            },

            selectedValue (newVal, oldVal) {
                if(_.parseNumber(newVal, 0) === _.parseNumber(oldVal, 0) || _.parseNumber(newVal, 0) === _.parseNumber(this.modelValue, 0)) return;
                this.$emit("update:modelValue", newVal);
                this.currentDisplayValue = this.dxSelectBoxInstance.option("displayValue");
                this.$emit("update:contactName", this.currentDisplayValue);
            }
        },

        created () {
            if (!this.companyId) return;
            this.getContacts();
        },

        methods: {

            onInput(e) { this.lastInputValue = e.event.target.value; },

            onValueChanged(e) { this.$emit("change", e); },

            onFocusIn(e) { this.focused = true; },

            onFocusOut(e) {
                let lastInputAsNumber = _.parseNumber(this.lastInputValue, 0);
                if(lastInputAsNumber > 0 && this.selectedValue !== lastInputAsNumber && _.some(this.contacts, { contactID: lastInputAsNumber })) {
                    this.selectedValue = lastInputAsNumber;
                }

                this.lastInputValue = null;
                this.focused = false;
                if(e.component.option("opened"))
                    e.component.close();
            },

            onOpened(e) {
                if(this.focused || !e.component.option("opened")) return;
                e.component.close();
            },

            onEditContactClick () {
                const self = this;

                if (self.isDisabled || !self.selectedValue) return;

                self.$dialog.open({
                    title: "View/Edit Contact",
                    height: "85%",
                    width: "85%",
                    component: ContactEdit,
                    props: {
                        contactId: _.parseNumber(self.selectedValue, 0),
                        modalMode: true
                    },
                    closeOnEsc: true,
                    onOk (e) {
                        return e.component.modalSave()
                            .then(result => {
                                if(!result) return false;
                                let contacts = _.cloneDeep(self.contacts);
                                self.contacts = [];
                                _.remove(contacts, c => c.contactID == result.contactInfo.contactID)
                                contacts.push(new Contact(result.contactInfo));
                                self.selectedValue = result.contactInfo.contactID;
                                self.contacts = contacts;
                                self.resetFocus();
                                return true;
                            });
                    },
                    onCancel (e) {
                        self.resetFocus();
                        return true;
                    }
                });
            },

            onAddContact () {
                const self = this;

                if(self.isDisabled) return;

                self.$dialog.open({
                    title: "Create New Contact",
                    height: "85%",
                    width: "85%",
                    component: ContactEdit,
                    props: {
                        companyId: self.companyId,
                        modalMode: true
                    },
                    closeOnEsc: true,
                    onOk (e) {
                        return e.component.modalSave()
                            .then(result => {
                                if(!result) return false;
                                let contacts = _.cloneDeep(self.contacts);
                                self.contacts = [];
                                contacts.push(new Contact(result.contactInfo));
                                self.selectedValue = result.contactInfo.contactID;
                                self.contacts = contacts;
                                self.resetFocus();
                                return true;
                            });
                    },
                    onCancel (e) {
                        self.resetFocus();
                        return true;
                    }
                });
            },

            checkIfIsNewContact(contactId) {
                return _.isNumber(contactId,0) > 0
                    && !this.contacts
                            .some(item => item.contactID === contactId);
            },

            getContacts () {
                const self = this;

                if(_.lte(self.currentCompanyID, 0)) {
                    self.selectedValue = null;
                    self.contacts = [];
                    return Promise.resolve([]);
                }

                let request = new SearchRequest({
                    filter: ["CompanyID", "=", self.currentCompanyID],
                    parameters: { activeOnly: true },
                    pagingEnabled: false
                });

                this.isGettingContacts = true;
                return self.$api.CompaniesApi.searchContacts(request)
                    .then(response => {
                        self.contacts = _.map(response.results, c => new Contact(c));
                        self.selectedValue = self.modelValue;
                        return response.results;
                    })
                    .finally(()=>{
                        self.isGettingContacts = false;
                    });
            },

            resetFocus(){
                this.dxSelectBoxInstance.focus();
            }
        }
    };
</script>
