<template>
    <DxPopover
        v-if="isActive"
        :target="`#${popover.target}`"
        :container="container"
        :position="placement"
        title-template="rq-popover-title"
        :show-title="true"
        :wrapper-attr="{ class: 'rq-popover rq-notes-detail-popover' }"
        v-model:visible="isVisible"
        @hidden="onHidden">
        <template #rq-popover-title>
            <div class="rq-popover-header">
                <span class="title">{{title}}</span>
            </div>
        </template>
        <div class="rq-container notes-popover">
            {{ popover.notes }}
        </div>
    </DxPopover>
</template>

<script>
    import { computed } from "vue";
    import { usePopoverVisibleBindings } from "./usePopoverVisibleBindings";

    export default {
        name: "NotesPopover",
        props: {
            placement: { type: [String,Object], default: "left" },
            container: { type: [String, HTMLElement, Object], default: null },
            popover: { type: Object, default: () => ({target: "", notes: null, description: null, visible: false}) },
            visible: { type: Boolean, default: false },
        },
        setup(props, { emit }) {
            const visibleProp = computed(() => props.visible);
            const { isActive, isVisible } = usePopoverVisibleBindings(visibleProp);
            const title = computed(() => `Notes for ${props.popover.description}`);
            const onHidden = () => emit("update:visible", false);
            return { isVisible, isActive, title, onHidden };
        },
    }
</script>
<style lang="scss">
    .notes-popover {
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
</style>