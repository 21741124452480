import { computed, ref, watchEffect, unref } from "vue";

export function useDualGridSelection({ items, configs={}, selected=[], itemKey="id", sortField=null }) {

    const availableItemsDataGrid = ref(null);
    const availableItemsGridConfig = ref(null);

    const selectedItemsDataGrid = ref(null);
    const selectedItemsGridConfig = ref(null);

    let initialSelection = unref(selected) || [];
    const selectedIDs = ref(initialSelection.slice());

    const hasSelectedRows = ref({
        available: false,
        selected: false
    });
    const hasAvailableItemsSelected = computed({
        get() { return hasSelectedRows.value.available; },
        set(val) { hasSelectedRows.value.available = val; }
    });
    const hasSelectedItemsSelected = computed({
        get() { return hasSelectedRows.value.selected; },
        set(val) { hasSelectedRows.value.selected = val; }
    });
    const hasAvailableItems = computed(() => {
        let itemsValue = unref(items);
        return itemsValue?.length > selectedIDs.value?.length;
    });
    const hasSelectedItems = computed(() => !_.isEmpty(selectedIDs.value));

    const invokeGridMethod = (grid, method, ...params) => _.invoke(grid, `gridInstance.${method}`, ...params);
    const invokeAvailableItemsGridMethod = (method, ...params) => invokeGridMethod(availableItemsDataGrid.value, method, ...params);
    const invokeSelectedItemsGridMethod = (method, ...params) => invokeGridMethod(selectedItemsDataGrid.value, method, ...params);

    const mergeGridConfig = cfg => {
        let defaultConfig = {
            focusedRowEnabled: true,
            selection: { mode: "multiple", showCheckBoxesMode: "never" },
            scrolling: { showScrollbar: "onHover", useNative: true }
        };
        return _.isEmpty(cfg) ? {} : _.merge({}, cfg, defaultConfig);
    };
    const getGridConfig = (cfgs, type) => mergeGridConfig(_.has(cfgs, "columns") ? cfgs : _.get(cfgs, type, null));

    if(!_.isEmpty(configs)) {
        watchEffect(() => {
            let configsValue = unref(configs);
            availableItemsGridConfig.value = getGridConfig(configsValue, "available");
            selectedItemsGridConfig.value = getGridConfig(configsValue, "selected");
        });
    }

    const getItemData = type => {
        let itemKeyValue = unref(itemKey);
        let itemsValue = unref(items);
        let sortFieldValue = unref(sortField);

        let filteredItems = _.filter(itemsValue, item => {
            let isSelected = _.includes(selectedIDs.value, _.getNumber(item, itemKeyValue, 0));
            return (type === "available" && !isSelected) || (type === "selected" && isSelected);
        });
        return _.isNil(sortFieldValue)
            ? filteredItems
            : _.sortBy(filteredItems, [sortFieldValue]);
    };

    const refreshGrid = type => {
        let gridRef = type === "available"
            ? availableItemsDataGrid.value
            : selectedItemsDataGrid.value;
        hasSelectedRows.value[type] = false;
        invokeGridMethod(gridRef, "option", "focusedRowIndex", -1);
        invokeGridMethod(gridRef, "clearSelection");
        invokeGridMethod(gridRef, "refresh");
    };

    const refreshAvailableGrid = () => refreshGrid("available");
    const refreshSelectedGrid = () => refreshGrid("selected");

    const repaintGrids = () => {
        invokeSelectedItemsGridMethod("repaint");
        invokeAvailableItemsGridMethod("repaint");
    };
    const updateHeight = height => {
        invokeAvailableItemsGridMethod("option", "height", height);
        invokeSelectedItemsGridMethod("option", "height", height);
        repaintGrids();
    };

    const updateDimensions = () => {
        invokeSelectedItemsGridMethod("updateDimensions");
        invokeAvailableItemsGridMethod("updateDimensions");
        repaintGrids();
    };

    const refreshGrids = () => {
        refreshAvailableGrid();
        refreshSelectedGrid();
    };

    let _itemKey = unref(itemKey);
    const availableItemsGridDataSource = {
        loadMode: "raw",
        key: _itemKey,
        load: () => getItemData("available")
    };
    const selectedItemsGridDataSource = {
        loadMode: "raw",
        key: _itemKey,
        load: () => getItemData("selected")
    };

    return {
        availableItemsDataGrid,
        selectedItemsDataGrid,
        availableItemsGridDataSource,
        selectedItemsGridDataSource,
        availableItemsGridConfig,
        selectedItemsGridConfig,
        hasAvailableItems,
        hasSelectedItems,
        hasAvailableItemsSelected,
        hasSelectedItemsSelected,
        selectedIDs,
        getItemData,
        invokeAvailableItemsGridMethod,
        invokeSelectedItemsGridMethod,
        refreshGrid,
        refreshGrids,
        repaintGrids,
        refreshAvailableGrid,
        refreshSelectedGrid,
        updateHeight,
        updateDimensions
    };
}