import { ref, computed, watchEffect } from "vue";
import { defineStore } from "pinia";
import { useStore } from "vuex";
import { useLicenseStore } from "./license";
import { useDeepDiff } from "@/shared/plugins/diff";
import { RecordingInformationApi } from "@/api/";
import { RecordingInformationItem } from "@/modules/file/recording/models";

//WIP - Recording Documents POC
export const useRecordingStore = defineStore("recording", () => {
    const licenseStore = useLicenseStore();
    const vuexStore = useStore();

    const { getChanges } = useDeepDiff();

    const currentOrderId = ref(0);
    const recordingDocuments = ref([]);
    const originalItems = ref([]);
    const hasChanges = ref(false);
    const deletedItems = ref([]);
    let saveItems = [];

    const featureEnabled = computed(() => licenseStore.features?.recordingDocuments || false);
    const contextOrderId = computed(() => vuexStore.state.orders.orderId);
    const contextLoanId = computed(() => vuexStore.state.orders.loans[0].loanID);

    watchEffect(() => {
        let changes = getChanges(originalItems.value, recordingDocuments.value);
        hasChanges.value = changes?.length > 0;
    });

    async function getDocuments(forceRefresh=false) {
        if(!featureEnabled.value || contextOrderId.value <= 0) return [];
        if(contextOrderId.value === currentOrderId.value && !forceRefresh) return recordingDocuments.value;
        currentOrderId.value = contextOrderId.value;
        let result = await RecordingInformationApi.getList(contextLoanId.value);
        //let result = [];
        originalItems.value = _.map(result.items, item => new RecordingInformationItem(item));
        recordingDocuments.value = _.cloneDeep(originalItems.value);
        return result;
    }

    function addDocument(values) {
        recordingDocuments.value.unshift(new RecordingInformationItem(values));
    }

    function removeDocument(clientKey) {
        let index = _.findIndex(recordingDocuments.value, item => item.clientKey === clientKey);
        if(index < 0 || !recordingDocuments.value?.[index]) return;
        let deleted = _.toPlainObject(recordingDocuments.value.splice(index, 1));
        deleted[0].isDeleted = true;
        deletedItems.value.push(deleted[0]);
    }

    async function saveDocuments() {
        await setIDs();
        let saveObject = {
            orderID: contextOrderId.value,
            loanID: contextLoanId.value,
            items: _.concat(recordingDocuments.value, deletedItems.value),
        };
        let result = await RecordingInformationApi.save(saveObject);
        recordingDocuments.value = _.filter(_.map(result.items, item => new RecordingInformationItem(item)), d => d.recordingInformationID > 0);
        originalItems.value = _.cloneDeep(recordingDocuments.value);
        deletedItems.value = [];
        return recordingDocuments.value;
    }

    async function setIDs() {
        for (let i = 0; i < recordingDocuments.value.length; i++) {
            recordingDocuments.value[i].loanID = contextLoanId.value;
            recordingDocuments.value[i].ordersID = contextOrderId.value;
        }
        for (let i = 0; i < deletedItems.value.length; i++) {
            deletedItems.value[i].loanID = contextLoanId.value;
            deletedItems.value[i].ordersID = contextOrderId.value;
        }
    }

    async function reorderDocuments(reorderRequest) {
        let result = await RecordingInformationApi.reorder(reorderRequest);
        return result;
    }

    function cancelChanges() {
        recordingDocuments.value = _.cloneDeep(originalItems.value);
        deletedItems.value = [];
    }

    return {
        featureEnabled,
        currentOrderId,
        recordingDocuments,
        deletedItems,
        hasChanges,
        getDocuments,
        addDocument,
        removeDocument,
        saveDocuments,
        cancelChanges,
        reorderDocuments
    };
});