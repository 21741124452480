<template>    
    <div class="content-wrapper rq-list-manager-item">
        <rq-banner
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showError"
        />
        <rq-action-form-group
            label="Item Name"
            labelFor="txt_prompt"
            :hasError="hasError"
            :show-action="showInactive"
        >
            <template #action>
                <b-form-checkbox
                    automation_id="chk_item_inactive"
                    v-model="item.isInactive">Inactive
                </b-form-checkbox>
            </template>
            <template #default>
                <input 
                    id="txt_prompt"
                    automation_id="txt_item_description"
                    type="text"
                    class="form-control"
                    v-model="item.itemName"
                    @keyup="onTextKeyUp"
                />
            </template>
        </rq-action-form-group>
    </div>
</template>
<script>
    export default {
        name: "EditListItem",
        props: {
            originalValue: { type:Object, default: ()=>({}) },
            showInactive: { type:Boolean, default: false }
        },
        data() {
            return { 
                item: _.clone(this.originalValue), 
                errorMessage: "",
                hasError: false,
                hasChanges: false
            };
        },
        computed: {
            showError() { return !_.isEmpty(this.errorMessage) && this.hasError; }
        },
        watch: {
            "item.itemName"(newValue, oldValue){
                if(newValue === oldValue) return;
                this.checkForChanges();
            },
            "item.isInactive"(newValue, oldValue){
                if(newValue === oldValue) return;
                this.checkForChanges();
            }
        },
        created() {
            this.$emit("disable-ok");
        },
        methods: {
            onTextKeyUp(e) {
                this.hasError = false;
                if(e.key !== "Enter" || !this.hasChanges) return;
                this.$emit("ok");
            },
            checkForChanges() {
                this.hasChanges = this.item.itemName !== this.originalValue.itemName 
                    || (this.showInactive && this.item.isInactive !== this.originalValue.isInactive);
                if(this.hasChanges) {
                    this.$emit("enable-ok");
                }
                else {
                    this.$emit("disable-ok");
                }
            }
        }
    }
</script>