import { computed } from "vue";
import { useStore } from "vuex";
import { UserSecuritySetting, UserSecuritySettings } from '../models/models';

export function useSecuritySettings(localSecurityKeys=[]) {
    const store = useStore();

    const settings = computed(() => store?.getters?.currentSession?.settings);
    const userSecuritySettings = computed(() => new UserSecuritySettings(settings.value));
    const isSysAdmin = computed(() => userSecuritySettings.value.findValue("IsSysAdmin"));
    const allSettings = computed(() => {
        let permissions = _.map(settings.value?.permissions, s => new UserSecuritySetting(s, "bool"));
        let dates = _.map(settings.value?.dates, s => new UserSecuritySetting(s, "date"));
        let values = _.map(settings.value?.values, s => new UserSecuritySetting(s, "int"));
        return [
            ...permissions,
            ...dates,
            ...values
        ];
    });

    const localSecurity = computed(() => _.isEmpty(localSecurityKeys) ? [] : _.mapAsKeys(localSecurityKeys, findSecurityValue));

    function findSecurityValue(key, defaultValue = null) {
        const setting = _.find(allSettings.value, s => s.name === key);
        return _.defaultTo(setting?.value, defaultValue);
    }

    const getUserSecuritySettings = () => new UserSecuritySettings(settings.value);

    return {
        allSettings,
        localSecurity,
        isSysAdmin,
        getUserSecuritySettings,
        findSecurityValue
    };
}