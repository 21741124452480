import { AssignedLanguage } from "@title-policy/models";
import { AppliesToType, AssignedLanguageListType } from "@title-policy/enums";
import { DocumentListFormat } from "@/shared/models/models";
import { TextControlListType, NumberFormat } from "@/shared/models/enums";
import { StandardLanguagePackageDetailDto } from "@/modules/configuration/titleProduction/models";

export const LIST_EVENTS = {
    GLOBAL_ACTION: "clause-list::global-action"
};

export class ClauseListItemModel {
    constructor(options={}, mapChildren=true, id=null, groupId=null) {
        this.clientKey = options.clientKey || _.uniqueId("clause_item_");

        this.standardLanguageID = _.parseNumber(options.standardLanguageID, null) || null;
        this.standardLanguagePackageID = _.parseNumber(options.standardLanguagePackageID, null) || null;

        this.id = _.isNil(id)
            ? _.parseNumber(options.id, 0) || -_.parseNumber(_.uniqueId())
            : id;
        this.groupId = _.isNil(groupId)
            ? _.parseNumber(options.groupId, 0) || this.standardLanguagePackageID || 0
            : groupId;

        this.children = mapChildren
            ? _.map(options.children, item => new ClauseListItemModel(item))
            : [];

        this.code = options.code || null;
        this.description = options.description || null;
        this.level = options.level || null;
        this.parentID = options.parentID || null;
        this.standardLanguageSectionID = _.parseNumber(options.standardLanguageSectionID, null) || null;
        this.standardLanguagePackageDetailID = _.parseNumber(options.standardLanguagePackageDetailID, null) || null;
        this.ordinal = options.ordinal || null;
        this.rtfText = options.rtfText || options.text || null;
        this.htmlText = options.htmlText || options.html || null;

        this.bulletCharacter = options.bulletCharacter || null;
        this.bulletSize = options.bulletSize || 0;
        this.charBeforeNumber = options.charBeforeNumber || null;
        this.charAfterNumber = options.charAfterNumber || null;
        this.firstNumber = _.parseNumber(options.firstNumber, null);
        this.formatCharacter = options.formatCharacter || null;
        this.hangingIndent = _.parseNumber(options.hangingIndent, null);
        this.leftIndent = _.parseNumber(options.leftIndent, null);
        this.linesBetweenClauses = _.parseNumber(options.linesBetweenClauses, 0);
        this.numberFormat = options.numberFormat || null;
        this.notes = options.notes || null;
        this.overrideListFormat = _.parseBool(options.overrideListFormat);
        this.overrideOption = options.overrideOption || null;
        this.overrideLinesBetweenClauses = _.parseBool(options.overrideLinesBetweenClauses);
        this.parentID = _.parseNumber(options.parentID, 0);
        this.sequence= _.parseNumber(options.sequence, 0);
        this.restartNumbering = _.parseBool(options.restartNumbering);
        this.textAfterNumber = options.textAfterNumber || null;
        this.textBeforeNumber = options.textBeforeNumber || null;
        this.textControlListType = options.textControlListType || null;

        this.originalValues = _.toPlainObject(options);
        this.childCount = options.childCount || null;
        this.siblingCount = options.siblingCount || null;
        this.isEditing = _.parseBool(options.isEditing);
        this.isCollapsed = _.parseBool(options.isCollapsed);
        this.isCollapsible = _.parseBool(options.isCollapsible, true);
        this.displayHeight = options.displayHeight || 75;
        this.isSelected = _.parseBool(options.isSelected);
        this.isDeleted = _.parseBool(options.isDeleted);
        this.isMounted = _.parseBool(options.isMounted);
        this.scrollIntoView = _.parseBool(options.scrollIntoView);
        this.canOverrideListFormat = false;

        this.keepCurrentLevel = false;

        this.setIdValues();
    }

    get someSelected() { return this.isSelected || _.some(this.children, child => child.someSelected); }

    get visibleBadges() { return [] }

    get allMounted() { return this.isMounted && _.every(this.children, c => c.allMounted); }

    get allCollapsed() { return this.isCollapsed && _.every(this.children, c => c.allCollapsed); }

    get allExpanded() { return !this.isCollapsed && _.every(this.children, c => c.allExpanded); }

    get hasChildren() { return !_.isEmpty(this.children); }

    //inherited
    setIdValues() {}

    getListFormat(){
        return new DocumentListFormat(this);
    }

    setListFormat(documentListStyle=null) {
        _.assignIn(this, documentListStyle || DocumentListFormat.nullDefaults);
    }

    getTotalChildCount() {
        let childCount = this.children.length;
        if(childCount === 0) return 0;
        let descendentCount = _.sumBy(this.children, c => c.getTotalChildCount());
        return childCount + descendentCount;
    }

    toDataObject(withChildren=false) {
        return withChildren
            ? _.toPlainObject(this)
            : this.entityModel().toDataObject();
    }

    toFlattenedList() {
        let result = [this.entityModel().toDataObject()];
        if(_.isEmpty(this.children)) return result;
        let childList = _.flatMap(this.children, c => c.toFlattenedList());
        result.push(...childList);
        return result;
    }
}

export class StandardLanguageItemModel extends ClauseListItemModel {
    constructor(options={}) {
        super(options, false);
        this.children = _.map(options?.children, c => new StandardLanguageItemModel(c));
    }

    setIdValues() {
        if(_.isNil(this.standardLanguagePackageDetailID))
            this.standardLanguagePackageDetailID = this.id;
        else
            this.id = this.standardLanguagePackageDetailID;
    }

    get listType() { return _.parseNumber(this.textControlListType, 1); }

    get visibleBadges() {
        if(this.listType === TextControlListType.None) return [];

        let badge = { key: "list-format", text: "" };
        let listTypeLabel = TextControlListType.displayValue(this.listType);
        if(this.listType === TextControlListType.Bulleted || this.numberFormat === NumberFormat.None)
            badge.text = `List Format: ${listTypeLabel}`;
        else {
            let numberFormatLabel = NumberFormat.displayValue(this.numberFormat);
            badge.text = `List Format: ${listTypeLabel} - ${numberFormatLabel}`;
        }
        return [badge];
    }

    entityModel() { return new StandardLanguagePackageDetailDto(this); }
}

export class AssignedLanguageItemModel extends ClauseListItemModel {
    constructor(options={}) {
        let listType = _.parseNumber(options.listType, 0);
        let id = _.parseNumber(options.assignedLanguageID, 0) || -_.parseNumber(_.uniqueId());

        super(options, false, id, listType);

        this.assignedLanguageID = id;
        this.listType = listType;

        this.commitmentPolicyHeaderID = _.parseNumber(options.commitmentPolicyHeaderID, 0);
        this.commitmentPolicyDetailID = _.parseNumber(options.commitmentPolicyDetailID, 0);
        this.orderWorkflowTaskID = options.orderWorkflowTaskID || null;

        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.loanID = _.parseNumber(options.loanID, 0);
        this.listPath = options.listPath || "";
        this.promptValues = _.isNil(options.promptValues) || !_.isObject(options.promptValues)
            ? {}
            : _.mapKeys(options.promptValues, (v,k) => _.startsWith(k, "@") ? k : `@${k}:`);
        this.refreshListStructure = _.parseBool(options.refreshListStructure);
        this.applyPolicyOverride = _.parseBool(options.applyPolicyOverride);
        this.intentionallyDeleted = _.parseBool(options.intentionallyDeleted);
        this.appliesToType = _.parseNumber(options.appliesToType, 0);

        this.actionEvent = `tp:${this.clientKey}:assigned_language_action`;
        this.canOverrideListFormat = true;

        this.children = _.map(options?.children, c => new AssignedLanguageItemModel(c));
    }

    get appliesToBadge() {
        if(this.hasInlinePolicyExclusions) return "Inline policy exclusions";
        if(this.appliesToType === AppliesToType.All) return null;
        if(this.appliesToType === AppliesToType.None) return "Excluded from policies";
        let appliesToVal = AppliesToType.displayValue(this.appliesToType);
        return `${appliesToVal} Only`;
    }

    get appliesToBadgeVisible() {
        if(_.isEmpty(this.appliesToBadge)) return false;
        return this.isEligibleForPolicyExclusions;
    }

    get visibleBadges() {
        let badges = [];
        if(this.appliesToBadgeVisible) badges.push({ key: "applies-to", text: this.appliesToBadge });
        if(this.intentionallyDeleted) badges.push({ key: "intentionally-deleted", text: "Intentionally Deleted" });
        return badges;
    }

    get listStyleOverrideDisplay() {
        let listStyleType = _.parseNumber(this.textControlListType, 1);
        if(listStyleType === TextControlListType.None) return "";

        let listTypeLabel = TextControlListType.displayValue(listStyleType);
        let styleText = "";
        if(listStyleType === TextControlListType.Bulleted || this.numberFormat === NumberFormat.None)
            styleText = listTypeLabel;
        else {
            let numberFormatLabel = NumberFormat.displayValue(this.numberFormat);
            styleText = `${listTypeLabel} - ${numberFormatLabel}`;
        }
        return `List Style Override: ${styleText}`;
    }

    get hasParent() { return _.parseNumber(this.parentID, 0) !== 0; }

    get contentLoaded() { return _.isEmpty(this.rtfText) || !_.isEmpty(this.htmlText); }

    get hasInlinePolicyExclusions() {
        if(_.isEmpty(this.htmlText)) return false;
        return _.includes(this.htmlText, "[O]")
            || _.includes(this.htmlText, "[M]")
            || _.includes(this.htmlText, "[N]");
    }

    get hasChildInlinePolicyExclusions() { return _.some(this.children, c => c.hasInlinePolicyExclusions); }

    get anyHasInlinePolicyExclusions() { return this.hasInlinePolicyExclusions || this.hasChildInlinePolicyExclusions; }

    get isEligibleForPolicyExclusions() { return _.includes([AssignedLanguageListType.Exceptions, AssignedLanguageListType.RestrictiveCovenants], this.listType); }

    get appliesToEditable() { return this.isEligibleForPolicyExclusions && !this.hasParent; }

    get isDirty() { return this.originalValues.htmlText !== this.htmlText; }
    set isDirty(val) {
        if(val) return;
        this.originalValues.htmlText = this.htmlText;
    }

    removeListFormatOverride(){
        this.setListFormat();
        this.refreshListStructure = true;
    }

    setListFormat(documentListStyle=null){
        documentListStyle = documentListStyle || DocumentListFormat.defaults;
        _.assignIn(this, documentListStyle);
    }

    entityModel() { return new AssignedLanguage(this); }

    static fromStandardLanguage(standardLanguage){
        return new AssignedLanguageItemModel({
            ...standardLanguage,
            promptValues: standardLanguage.promptValues,
            htmlText: standardLanguage.html,
            rtfText: standardLanguage.text
        });
    }

    static userDefined(html, rtf){
        return new AssignedLanguageItemModel(
            { assignedLanguageID: 0,
            standardLanguageID: 1,
            code: "Manual",
            description: "User Defined",
            htmlText: html || "<p style=\"text-align:left;text-indent:0;margin:0 0 0 0;\"></p>", // This puts in the default styling
            rtfText: rtf || "" });
    }
}