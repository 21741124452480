<template>
    <div
        :id="componentId"
        class="label-info-icon"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave">
        <FontAwesomeIcon :icon="iconName" :spin="isLoading" />
        <DxPopover
            v-if="showPopover"
            :target="`#${componentId}`"
            position="top"
            :wrapper-attr="{
                class: 'rq-popover rq-popover-dark company-info-popover'
            }"
            :visible="true">
            <div v-if="hasPhoneNumber" class="row my-2">
                <div class="col-12">Phone: {{companyInfo.phone}}</div>
            </div>
            <div v-if="hasFaxNumber" class="row my-2">
                <div class="col-12">Fax: {{companyInfo.fax}}</div>
            </div>
            <div v-if="hasEmailAddress" class="row my-2">
                <div class="col-12">Email: {{companyInfo.eMailAddress}}</div>
            </div>
            <div v-if="lacksInformation" class="row my-2">
                <div class="col-12 text-center">No Information Available</div>
            </div>
        </DxPopover>
    </div>
</template>
<script>
    import { DxPopover } from "devextreme-vue";
    export default {
        name: "CompanyInfoIcon",
        props: {
            company: { type: Object, default: () => ({}) },
        },
        components: { DxPopover },
        data() {
            return {
                isActive: false,
                isLoading: false,
                lastPossibleEdit: new Date(),
                companyInfo: {}
            };
        },
        computed: {
            companyId() { return _.getNumber(this, "company.companyID", null) || 0; },
            infoCompanyId() { return _.getNumber(this, "companyInfo.companyID", null) || 0; },
            hasCompany() { return this.companyId > 0; },
            hasInfo() { return this.hasCompany && this.companyId === this.infoCompanyId; },
            hasPhoneNumber() { return !_.isEmpty(this.companyInfo.phone);},
            hasFaxNumber() { return !_.isEmpty(this.companyInfo.fax);},
            hasEmailAddress() { return !_.isEmpty(this.companyInfo.eMailAddress);},
            lacksInformation(){return !this.hasPhoneNumber && !this.hasFaxNumber && !this.hasEmailAddress; },
            showPopover() { return this.isActive && this.hasCompany && this.hasInfo && !this.isLoading; },
            iconName() { return this.isLoading ? "fas fa-spinner" : "fas fa-circle-info"; }
        },
        created() {
            this.componentId = _.uniqueId("company-info-popover-");
        },
        methods: {
            fetchData() {
                const self = this;
                self.isLoading = true;
                self.$api.CompaniesApi.getCompanyDetail(this.companyId)
                    .then(result => {
                        self.companyInfo = result.companyInfo
                        self.isLoading = false;
                        self.lastPossibleEdit = new Date();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error(`Error loading Company Info.`);
                        return error;
                    });
            },
            onMouseEnter() {
                if(!this.hasCompany) return;
                if(!this.hasInfo || this.company.lastPossibleEdit > this.lastPossibleEdit) {
                    this.fetchData();
                }
                this.isActive = true;
            },
            onMouseLeave() {
                this.isActive = false;
            },
        }
    }
</script>
