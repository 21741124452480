<template>
    <draggable
        tag="ul"
        :list="localItems"
        item-key="id"
        :group="{ name: groupName }"
        class="clause-list"
        :scroll="true"
        ghost-class="clause-drag-ghost"
        handle=".clause-drag-handle"
        :swap-threshold="0.5"
        :disabled="!sortEnabled"
        @end="onDragEnd">
        <template #item="{ index, element }">
            <ClauseListItem
                :key="element.clientKey"
                :actions="itemActions"
                :inline-editable="inlineEditable"
                :sort-enabled="sortEnabled"
                :sorting-hides-actions="sortingHidesActions"
                :read-only="readOnly"
                v-model:item="localItems[index]"
                @content-click="handleContentClick"
                @action="handleItemAction"
                @list-changed="emit('list-changed')"
                @change-pending="emit('change-pending', $event)"
                @item-changed="emit('item-changed', $event)">
                <template #header-title>
                    <slot name="header-title" v-bind="{ item: element }"></slot>
                </template>
                <ClauseList
                    :item-actions="itemActions"
                    :sort-enabled="sortEnabled"
                    :sorting-hides-actions="sortingHidesActions"
                    :inline-editable="inlineEditable"
                    :read-only="readOnly"
                    v-model:items="localItems[index].children"
                    @item-action="handleItemAction"
                    @list-changed="emit('list-changed')"
                    @change-pending="emit('change-pending', $event)"
                    @item-changed="emit('item-changed', $event)"
                    is-child-list
                />
            </ClauseListItem>
        </template>
    </draggable>
    <notes-popover
        v-if="!isChildList"
        placement="right"
        container="#app-main"
        :popover="popoverInfo"
        v-model:visible="popoverInfo.visible"
    />
</template>

<script setup>
    import { computed, inject } from "vue";
    import { useVModel } from "@vueuse/core";
    import { GlobalEventManager } from "@/app.events";
    import { LIST_EVENTS } from "@/shared/models/clause-management";
    import { useIconPopover } from "@/shared/composables/useIconPopover";
    import { NotesPopover } from "@/shared/components/rq/";

    import draggable from "vuedraggable";
    import ClauseListItem from "./ClauseListItem";

    const props = defineProps({
        items: { type: Array, default: () => [] },
        itemActions: { type: Array, default: () => [] },
        sortEnabled: { type: Boolean, default: false },
        inlineEditable: { type: Boolean, default: false },
        readOnly: { type: Boolean, default: false },
        sortingHidesActions: { type: Boolean, default: false },
        isChildList: { type: Boolean, default: false }
    });
    const emit = defineEmits([
        "update:items",
        "list-changed",
        "item-action",
        "item-changed",
        "change-pending"
    ]);

    const localItems = useVModel(props, "items", emit);
    const groupId = inject("groupId", 0);
    const groupName = computed(() => `clause-group-${groupId}`);

    const {
        popoverInfo,
        showPopover,
        clearPopover
    } = useIconPopover({ mergeDetail: true });

    /*****************************************************************************************
        TG - An attempt to provide a loading indicator while the DOM is updating with a large
        list of clauses. Currently it only accounts for about 2/3 of the load time while the
        browser takes a bit of time to catch up and actually render the clause items.  Will
        have to revisit later.
    *****************************************************************************************/
    /*
    const timeLabel = lbl => `${groupName.value}_${lbl}`;
    const { startWait, endWait } = useRqBusy();
    if(!props.isChildList) {
        startWait(groupName.value);
        console.time(timeLabel("render"));
        const unwatchItemList = watchEffect(() => {
            if(localItems.value?.some?.(item => !item?.allMounted)) return;
            console.timeEnd(timeLabel("render"));
            endWait(groupName.value);
            unwatchItemList();
        });
    }
    */
    /****************************************************************************************/

    function onDragEnd(e) {
        emit("list-changed");
    }

    function handleItemAction({ actionKey, target=null, item=null }) {
        if(actionKey === "toggle-note" && !props.isChildList) {
            updateNotesPopover(target, item);
            return;
        }
        emit("item-action", { actionKey, target, item });
    }

    function handleContentClick({ itemId }) {
        GlobalEventManager.emit(LIST_EVENTS.GLOBAL_ACTION, {
            groupId,
            itemId,
            action: "set-editing-state"
        });
    }

    function setAllCollapsedValue(val) {
        GlobalEventManager.emit(LIST_EVENTS.GLOBAL_ACTION, {
            groupId,
            action: "set-collapsed-value",
            value: _.parseBool(val)
        });
    }

    function refreshAllEditors() {
        GlobalEventManager.emit(LIST_EVENTS.GLOBAL_ACTION, {
            groupId,
            action: "refresh-editor"
        });
    }

    function updateNotesPopover(target=null, item=null) {
        if(_.isNil(item)) {
            clearPopover();
            return;
        }
        showPopover({
            target,
            title: item?.title,
            description: item?.description,
            detail: {
                notes: item.notes
            }
        });
    }

    defineExpose({
        setAllCollapsedValue,
        refreshAllEditors
    });
</script>
