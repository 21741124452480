<template>
    <dx-select-box
        v-if="loanOptions.length > 1"
        :input-attr="$utils.idAttrs('drp_loans')"
        :element-attr="{ class: classAttr }"
        :items="loanOptions"
        :search-enabled="false"
        value-expr="id"
        display-expr="name"
        item-template="loan-item-template"
        :disabled="disabled"
        v-model="selectedLoanId"
        @value-changed="onValueChanged">
        <template #loan-item-template="{ data }">
            <span>Loan {{data.name}}</span>
        </template>
    </dx-select-box>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { SET_SELECTED_LOAN_ID } from "@/store/mutations";
    import { SETTLEMENT_TYPE, SettlementTypeOption } from "@settlement/models";

    export default {
        name: "RqLoanSelectBox",
        props: {
            size: { type: String, default: "sm" },
            disabled: { type: Boolean, default: false },
            mapSsOnChange: { type: Boolean, default: false },
            mapOverride: { type: Boolean, default: false},
            modelValue: { type: Number, default: 0 }
        },
        data() {
            return {
                selectedLoanId: null,
                lastMappedLoanId: null
            }
        },       
        computed:{
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                readOnly: state => state.isPageReadOnly,
                ssType: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "defaultLoanId"
            ]),
            loanOptions() { return this.lookupHelpers.getLoanOptions(); },
            classAttr() {
                return this.size === "sm" ? "form-control form-control-sm" : "form-control";
            }
        },
        watch: {
            modelValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.selectedLoanId) return;
                    this.selectedLoanId = newValue;
                },
                immediate: true
            }
        },
        methods: {
            onValueChanged(e) {
                if(_.isNil(e.event)) return;
                this.updateSelectedValue(e.value);
            },
            emitValue(value) {
                this.$store.commit(SET_SELECTED_LOAN_ID, value);
                this.$emit("update:modelValue", value);
            },
            updateSelectedValue(value) {
                const self = this;
                if(!self.mapSsOnChange || self.lastMappedLoanId === value) {
                    self.emitValue(value);
                    return;
                }
                self.mapSettlementStatement(value)
                    .finally(() => {
                        self.lastMappedLoanId = value;
                        self.emitValue(value);
                    })
            },
            mapSettlementStatement(loanId) {
                const self = this;
                let ssTypeName = SettlementTypeOption.displayValue(self.ssType);
                let mapPromise = self.mapOverride
                    ? self.$api.SettlementDashboardApi.mapSettlementDataOverride(self.ssType, loanId)
                    : self.$api.SettlementDashboardApi.mapSettlementData(self.ssType, loanId);
                return self.$rqBusy.wait(mapPromise, true)
                    .catch(err => {
                        self.$toast.error(`${ssTypeName} failed to update`);
                        console.error(err);
                    });
            },
        }
    }
</script>
