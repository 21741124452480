import { TabAlert } from "@/shared/models/models";
import { AlertSeverity } from "@/shared/models/enums";
import { isRef, ref, watchEffect } from "vue";

class AlertHelpers {
    static getVuelidateAlertCount(v$, keys=[]) {
        if(!isRef(v$)) return 0;
        return _.isEmpty(keys)
            ? v$?.value?.$errors?.length || 0
            : _.sumBy(v$?.value?.$errors, err => _.includes(keys, err.$property) ? 1 : 0);
    }
    static tabAlertFromVuelidate(v$, keys=[]) {
        return new TabAlert({ alertCount: this.getVuelidateAlertCount(v$, keys) });
    }
    static tabAlertFromList(errList=[]) {
        return new TabAlert({ alertCount: errList?.length || 0 });
    }
    static getAlertCount(v$, mappingInfo) {
        switch(mappingInfo?.type) {
            case "v$": return this.getVuelidateAlertCount(v$, mappingInfo.keys);
            case "list-ref": return mappingInfo.list?.value?.length || 0;
            case "count-ref": return mappingInfo.count?.value || 0;
        }
        return 0;
    }
    static getAlertValue(v$, mappingInfo) {
        let alertCount = _.isArray(mappingInfo)
            ? _.sumBy(mappingInfo, info => this.getAlertCount(v$, info))
            : this.getAlertCount(v$, mappingInfo);
        return new TabAlert({ alertCount }).toDataObject();
    }
    static getTabClass(alertCount=0, alertType=AlertSeverity.Error) {
        let hasAlerts = alertCount > 0;
        return _.evalCssObject({
            "rq-tab": true,
            "tab-alert": hasAlerts,
            "tab-alert-warning": hasAlerts && alertType === AlertSeverity.Warning,
            "tab-alert-error": hasAlerts && alertType === AlertSeverity.Error
        });
    }
}

/*
    This
    Mapping configuration options:
    Type<Object, Array>: {

    }
*/
/**
 * useRqTabAlerts - composable to provide helper functions for resolving alert tab values, or if mapping
 * information is provided, will return reactive values for alert count and tab class.
 *
 * @param {Object}          param
 * @param {Ref<Object>}     param.v$                 - Vuelidate object returned from "useVuelidate()"; required for vuelidate-targeted values/methods
 * @param {Object|Array}  param.alertMapping       - Options to generate reactive alert refs; Object for single validation source type or array if multiple source types are needed
 * @param {string}          param.alertMapping.type  - "v$", "list-ref", or "count-ref"
 * @param {Array<string>}   param.alertMapping.keys  - Only used when type="v$" - list of $property names to limit the derived alert count to; counts all errors found in v$ if empty
 * @param {Ref<Array>}      param.alertMapping.list  - Only used when type="list-ref" - Validation errors array bound to datagrid instance.
 * @param {Ref<Number>}     param.alertMapping.count - Only used when type="count-ref" - ref or computed value deriving what will be assigned to "alertCount".
 *
 * @returns {Object}    Includes "getTabClass", "getVuelidateTabAlert", "getGridTabAlert" helper methods
 *                      and reactive tab title alert values, if alertMapping is provided.
 */
export function useRqTabAlerts({ v$=null, alertMapping={} }={ v$: null, alertMappping: {} }) {

    const getVuelidateTabAlert = (keys=[]) => AlertHelpers.tabAlertFromVuelidate(v$, keys);
    const getGridTabAlert = validationErrors => AlertHelpers.tabAlertFromList(validationErrors);
    const getTabClass = AlertHelpers.getTabClass;

    let tabAlerts = {};

    if(!_.isEmpty(alertMapping)) {
        tabAlerts = _.mapValues(alertMapping, () => ref({ alertCount: 0, tabClass: "rq-tab" }));
        watchEffect(() => {
            _.each(alertMapping, (v,k) => {
                tabAlerts[k].value = AlertHelpers.getAlertValue(v$, v);
            });
        });
    }

    return {
        ...tabAlerts,
        getTabClass,
        getVuelidateTabAlert,
        getGridTabAlert
    };
}