import { ADD_FILE_GROUP, APPLY_TEMPLATE, CANCEL, MANAGE_NOTES, SAVE, SPACER, VIEW_DOCS } from "./global";

export const Actions = {
    CALCULATE: { automation_id: 'btn_calculate', key: "save", text: "Calculate", icon: "fa-calculator", tooltip: "Calculate [entity]", disableWhenReadOnly: true },
    //RQO-24436 disable title production version/audit log
    //VIEW_AUDIT_LOG: { automation_id: 'btn_view_audit_log', key: "tp-audit-log", text: "Audit", "icon": "fa-list-alt", tooltip: "View Audit Log" },
    CLEAR: { automation_id: 'btn_clear', key: "clear", text: "Clear", icon: "fa-remove", tooltip: "Clear", disableWhenReadOnly: true },
    DISCLOSURE_STATEMENT: { automation_id: 'btn_disclosure_statement', key: "disclosureStatement", text: "Disclosure Stmt.", icon: "fa-print", tooltip: "Disclosure Statement" },
    MAP_SS: { automation_id: 'btn_map_settlement', key: "mapSS", text: "Map Settlement", icon: "fa-save", tooltip: "Map Settlement Information", disableWhenReadOnly: true },
    PRINT_REPORT: { automation_id: 'btn_print_report', key: "printReport", text: "Print", icon: "fa-print", tooltip: "Print Report", disableWhenReadOnly: false }
};

const MANAGE_NOTES_ACTION = MANAGE_NOTES("Settlement Statement");

const defaultActionList = [
    ADD_FILE_GROUP, MANAGE_NOTES_ACTION, VIEW_DOCS,
    SAVE, CANCEL
];

export default {
    SETTLEMENT_DASHBOARD: [
        ADD_FILE_GROUP, MANAGE_NOTES_ACTION,_.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Settlement Statement Template" }),
        SPACER, VIEW_DOCS, Actions.MAP_SS
    ],
    CDF_PAYOFF_PAYMENTS: [...defaultActionList],
    PAYOFFS_LIST: [
        SPACER, ADD_FILE_GROUP, MANAGE_NOTES_ACTION,
        SPACER, VIEW_DOCS
    ],
    PAYOFFS_DETAIL: [...defaultActionList],
    PRORATIONS: [
        SPACER, ADD_FILE_GROUP, MANAGE_NOTES_ACTION,
        SPACER, VIEW_DOCS
    ],
    PREMIUMS: [
        ADD_FILE_GROUP, MANAGE_NOTES_ACTION, VIEW_DOCS,
        Actions.CALCULATE, CANCEL
    ],
    RECORDING_FEES_TAXES: [...defaultActionList],
    SETTLEMENT_FEES: [...defaultActionList],
    REALTOR_COMMISSION: [...defaultActionList],
    NOTES: [
        ADD_FILE_GROUP, MANAGE_NOTES_ACTION, VIEW_DOCS
    ],
    ADDENDUMS: [ADD_FILE_GROUP, MANAGE_NOTES_ACTION, VIEW_DOCS],
    HUD_ADDENDUMS: [ADD_FILE_GROUP, MANAGE_NOTES_ACTION, VIEW_DOCS],
    AGGREGATE_ADJUSTMENT: [
        ADD_FILE_GROUP, MANAGE_NOTES_ACTION, Actions.CLEAR,
        SAVE, CANCEL, VIEW_DOCS, Actions.DISCLOSURE_STATEMENT
    ],
    ESCROWS: [...defaultActionList],
    GFE_COMPARISON: [...defaultActionList],
    LOANTERMS: [...defaultActionList],
    CASH_TO_CLOSE: [...defaultActionList],
    LOAN_CALCULATION: [...defaultActionList],
    DEBIT_CREDITS: [...defaultActionList],
    PROJECTED_PAYMENT: [...defaultActionList],
    LOANCOST_CHARGES: [...defaultActionList],
    LOAN_DISCLOSURES: [...defaultActionList],
    PREPAIDS: [...defaultActionList],
    WORKSHEET_801: [
        ADD_FILE_GROUP, MANAGE_NOTES_ACTION, VIEW_DOCS,
        SPACER, SAVE, CANCEL
    ],
    ADDITIONAL_TITLE_SERVICES: [...defaultActionList],
    PAID_BY_OTHERS: [...defaultActionList],
    SETTLEMENT_ORDER_SUMMARY: [...defaultActionList],
    SETTLEMENT_CONTACTS: [
        ADD_FILE_GROUP, MANAGE_NOTES_ACTION, VIEW_DOCS
    ]
}