import { ref, watch, computed } from "vue";

export function useDeepTwoWayBinding(props, emit, propMap) {
    const resultRefs = _.mapValues(propMap, propName => {
        const localRef = ref(props[propName]);
        const propRef = computed(() => props[propName]);
        watch(propRef, newValue => {
            localRef.value = newValue;
        }, { deep: true });
        watch(localRef, newValue => {
            emit(`update:${propName}`, newValue);
        }, { deep: true });
        return localRef;
    });
    return { ...resultRefs };
}