<template>
    <DxPopover
        v-if="isActive"
        :target="`#${popover.target}`"
        :container="container"
        :position="placement"
        title-template="rq-popover-title"
        :show-title="true"
        :wrapper-attr="{ class: 'rq-popover rq-check-approval-popover' }"
        v-model:visible="isVisible"
        @hidden="onHidden">
        <template #rq-popover-title>
            <div class="rq-popover-header">
                <span class="title">{{title}}</span>
                <b-btn variant="icon" class="close-button" v-rq-tooltip.top.hover title="Close" @click="isVisible = false">
                    <FontAwesomeIcon icon="fas fa-times" />
                </b-btn>
            </div>
        </template>
        <div class="rq-container">
            <dl class="row">
                <dt class="col-3 popover-label">Requested By:</dt> <dd class="col-9">{{popover.item.requestorName}}</dd>
                <dt class="col-3 popover-label">Requested On:</dt> <dd class="col-9">{{formatTime(popover.item.requestedOn)}}</dd>
                <dt class="col-3 popover-label" v-if="showRequestorComment">Req. Comments:</dt> <dd class="col-9" v-if="showRequestorComment">{{popover.item.requestorComment}}</dd>
                <dt class="col-3 popover-label">{{popover.item.isApproved ? "Approved" : "Rejected"}} By:</dt> <dd class="col-9">{{popover.item.approverName}}</dd>
                <dt class="col-3 popover-label">{{popover.item.isApproved ? "Approved" : "Rejected"}} On:</dt> <dd class="col-9">{{formatTime(popover.item.actionDate)}}</dd>
                <dt class="col-3 popover-label">Comments:</dt> <dd class="col-9">{{popover.item.approversComment}}</dd>
            </dl>
            <dl class="row mt-n2" v-if="showLossAdavanceInfo">
                <dt class="col-3 popover-label">{{moneyMovementTypeName(popover.item.moneyMovementTypeID)}} Category:</dt> <dd class="col-9">{{popover.item.lossAdvanceReasonCategory}}</dd>
                <dt class="col-3 popover-label">{{moneyMovementTypeName(popover.item.moneyMovementTypeID)}} Reason:</dt> <dd class="col-9">{{popover.item.lossAdvanceReason}}</dd>
                <dt class="col-3 popover-label">Is Recoverable:</dt> <dd class="col-9">{{formatBool(popover.item.isRecoverable)}}</dd>
                <dt class="col-3 popover-label">Is Recovered:</dt> <dd class="col-9">{{formatBool(popover.item.isRecovered)}}</dd>
            </dl>
        </div>
    </DxPopover>
</template>

<script>
    import { computed } from "vue";
    import { DateTime } from "luxon";
    import { usePopoverVisibleBindings } from "./usePopoverVisibleBindings";
    import { MoneyMovementType } from '@config/enums';

    export default {
        name: "CheckWritingApprovalPopover",
        props: {
            placement: { type: [String,Object], default: "left" },
            container: { type: [String, HTMLElement, Object], default: null },
            popover: { type: Object, default: () => ({target: "", item: {}, visible: false}) },
            visible: { type: Boolean, default: false },
        },
        setup(props, { emit }) {
            const visibleProp = computed(() => props.visible);
            const showLossAdavanceInfo = computed(() => _.includes([MoneyMovementType.Loss, MoneyMovementType.Advance], props.popover.item.moneyMovementTypeID));
            const showRequestorComment = computed(() => !_.isNullOrEmpty(props.popover.item.requestorComment) > 0);
            const title = computed(() => `${MoneyMovementType.displayValue(props.popover.item.moneyMovementTypeID)} Approval Information`);
            const { isActive, isVisible } = usePopoverVisibleBindings(visibleProp);
            const onHidden = () => emit("update:visible", false);
            return { isVisible, isActive, onHidden, showLossAdavanceInfo, showRequestorComment, title };
        },
        methods: {
            formatTime(v) { return DateTime.fromISO(v).isValid ? DateTime.fromISO(v).toFormat("MM/dd/yyyy hh:mm a") : ""; },
            formatBool(b) { return b ? "Yes" : "No"; },
            moneyMovementTypeName(id) {return MoneyMovementType.displayValue(id); },
        }
    }
</script>
