import { RQ_SECTION_ACTIONS as ACTIONS } from "../../actions";
import { RQ_SECTION_MUTATIONS as MUTATIONS } from "../../mutations";
const DEFAULT_GROUP = "global";
const getDefaultState = () => {
    return {
        groups: {}
    };
};
const allMatchesValue = (state, groupName, val) => {
    let group = _.get(state.groups, groupName, {});
    return _.every(group, expanded => expanded === val);
};
const state = getDefaultState();

const getters = {
    hasMultipleSections: state => (groupName=DEFAULT_GROUP) => {
        let group = _.get(state.groups, groupName, {});
        return _.keys(group).length > 1;
    },
    allExpanded: state => allMatchesValue(state, DEFAULT_GROUP, true),
    allCollapsed: state => allMatchesValue(state, DEFAULT_GROUP, false),
    getAllExpanded: state => (group=DEFAULT_GROUP) => allMatchesValue(state, group, true),
    getAllCollapsed: state => (group=DEFAULT_GROUP) => allMatchesValue(state, group, false)
};

const actions = {
    [ACTIONS.ADD_SECTION](context, { key, groupName=DEFAULT_GROUP, expanded=true }) {
        context.commit(MUTATIONS.REMOVE_SECTION, { key, groupName, expanded });
        if(!_.has(context.state.groups, groupName))
            context.commit(MUTATIONS.SET_GROUP, groupName);
        context.commit(MUTATIONS.SET_SECTION, { key, groupName, expanded });
    },
    [ACTIONS.UPDATE_SECTION](context, { key, groupName=DEFAULT_GROUP, expanded=true }) {
        if(!_.has(context.state.groups, groupName))
            context.commit(MUTATIONS.SET_GROUP, groupName);
        context.commit(MUTATIONS.SET_SECTION, { key, groupName, expanded });
    },
    [ACTIONS.REMOVE_SECTION](context, key) {
        let groups = context.state.groups;
        _.forEach(groups, (group, groupName) => {
            if(!_.has(group, key)) return;
            context.commit(MUTATIONS.REMOVE_SECTION, { key, groupName });
            if(!_.isEmpty(group)) return;
            context.commit(MUTATIONS.REMOVE_GROUP, groupName);
        });
    },
    [ACTIONS.COLLAPSE_ALL](context, groupName=DEFAULT_GROUP) {
        context.commit(MUTATIONS.SET_GROUP_VALUES, { groupName, expanded:false });
    },
    [ACTIONS.EXPAND_ALL](context, groupName=DEFAULT_GROUP) {
        context.commit(MUTATIONS.SET_GROUP_VALUES, { groupName, expanded:true });
    },
    [ACTIONS.RESET_STATE](context) {
        context.commit(MUTATIONS.RESET_STATE);
    }
};

const mutations = {
    [MUTATIONS.SET_SECTION] (state, section) {
        let group = _.get(state.groups, section.groupName, null);
        _.set(group, section.key, section.expanded);
    },
    [MUTATIONS.SET_GROUP] (state, groupName) {
        _.set(state.groups, groupName, {});
    },
    [MUTATIONS.REMOVE_SECTION] (state, section) {
        let group = _.get(state.groups, section.groupName, null);
        if(_.isNil(group)) return;
        _.unset(state.groups[section.groupName], section.key);
    },
    [MUTATIONS.REMOVE_GROUP] (state, groupName) {
        _.unset(state.groups, groupName);
    },
    [MUTATIONS.SET_GROUP_VALUES] (state, { groupName, expanded }) {
        let group = _.get(state.groups, groupName, {});
        _.forEach(group, (v, key) => {
            _.set(group, key, expanded);
        });
    },
    [MUTATIONS.RESET_STATE] (state) {
        let defaultState = getDefaultState();
        _.assign(state, defaultState);
    }
};

export default { state, getters, actions, mutations };