import PrepaidsApi from "@/api/prepaids";
import { PREPAID_SECTION_DATA_ACTIONS } from "../actions";
import { PREPAID_SECTION_DATA_MUTATIONS } from "../mutations";
import { BASIC_ACTION, ACTION_WITH_COMMIT } from "../helpers";

const state = {
    prepaidSections: [],
};

const getters = {};

const actions = {
    [PREPAID_SECTION_DATA_ACTIONS.GET_PREPAID_SECTION_DATA](context) {
        let apiPromise = PrepaidsApi.getRecordsByOrderId(context.getters.orderId);
        return ACTION_WITH_COMMIT(context, apiPromise, PREPAID_SECTION_DATA_MUTATIONS.SET_PREPAID_SECTION_DATA);
    },

    [PREPAID_SECTION_DATA_ACTIONS.UPDATE_PREPAID_SECTION_DATA] (context, data) {
        let apiPromise = PrepaidsApi.saveDeleteRecords(data.items, data.deletedItems)
            .then(() => context.dispatch(PREPAID_SECTION_DATA_ACTIONS.GET_PREPAID_SECTION_DATA));
        return BASIC_ACTION(context, apiPromise);
    },

    [PREPAID_SECTION_DATA_ACTIONS.DELETE_PREPAID_SECTION_DATA](context, id) {
        let apiPromise = PrepaidsApi.deleteRecord(id);
        return BASIC_ACTION(context, apiPromise, () => { context.commit(PREPAID_SECTION_DATA_MUTATIONS.REMOVE_PREPAID_SECTION_DATA, id); });
    }
};

const mutations = {
    [PREPAID_SECTION_DATA_MUTATIONS.SET_PREPAID_SECTION_DATA] (state, value) { state.prepaidSections = value; },
    [PREPAID_SECTION_DATA_MUTATIONS.UPDATE_PREPAID_SECTION_DATA] (state, value) { state.prepaidSections = value; },
    [PREPAID_SECTION_DATA_MUTATIONS.DELETE_PREPAID_SECTION_DATA](state, value) {
        let itemIndex = _.findIndex(state.prepaidSections, item => item.PrepaidSectionDataID === value);
        if(itemIndex < 0) return;
        state.prepaidSections.splice(itemIndex, 1);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
