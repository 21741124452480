<template>
    <div class="rq-image-viewer-container">
        <div :class="previewClassAttr">
            <img v-if="hasContent"
                :src="dataUrl"
                :title="downloadFileName"
                :style="imageStyle"
            />
        </div>
    </div>
</template>
<script setup>
    import { ref, computed } from "vue";
    const props = defineProps({
        dataUrl: { type: String, default: "" },
        downloadFileName: { type: String, default: "" },
        displayMode: { type: String, default: "fit" }
    });
    const imageScale = ref(1);
    const imageStyle = computed(() => `transform:scale(${imageScale.value});`);
    const previewClassAttr = computed(() => ({
        "img-preview": true,
        "img-preview-fit": props.displayMode === "fit",
        "img-preview-actual-size": props.displayMode === "actual-size"
    }))
    const hasContent = computed(() => !_.isEmpty(props.dataUrl));
</script>