import * as Apis from '@/api';

export default {
    install (app, options) {
        Object.defineProperty(app.config.globalProperties, '$api', {
            get () {
                const self = this;

                // map the API methods to the new mapped API object
                const mapApiMethods = (method, methodName, apiName) => function() {
                    // return a promise wrapping the original API function to capture any errors
                    return new Promise((resolve, reject) => {
                        method.apply(self, arguments)
                            .then((...resolvedArgs) => {resolve.apply(self, resolvedArgs);})
                            .catch((...errors) => {
                                //expect errors to come from the API, do not log them to the console for hackers to pull apart.
                                //console.warn(`An issue occurred while processing the most recent api request. -- Originated from ${apiName}.${methodName}`);
                                //console.error(errors[0]);
                                // self.$rqError.capture(errors[0], 'An issue occurred while processing the most recent api request.', `Originated from ${apiName}.${apiCallName}`);
                                if (options.suppressErrors) {resolve.apply(self, null);} else {reject.apply(self, errors);}
                            });
                    });
                };

                const mapApi = (api, apiName) => _.mapValues(api, (method, methodName) => mapApiMethods(method, methodName, apiName));

                // map the APIs to properties of the returned object
                return _.mapValues(Apis, mapApi);
            }
        });
    }
};
