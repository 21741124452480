<template>
    <DxPopover
        v-if="isActive"
        :target="`#${popover.target}`"
        :container="container"
        :position="placement"
        title-template="rq-popover-title"
        :show-title="true"
        :wrapper-attr="{ class: 'rq-popover rq-check-detail-popover' }"
        v-model:visible="isVisible"
        @hidden="onHidden">
        <template #rq-popover-title>
            <div class="rq-popover-header">
                <span class="title">{{popover.title}}</span>
                <b-btn variant="icon" class="close-button" v-rq-tooltip.top.hover title="Close" @click="isVisible = false">
                    <FontAwesomeIcon icon="fas fa-times" />
                </b-btn>
            </div>
        </template>
        <div class="rq-container">
            <rq-tabs
                :tabs="tabItems"
                v-model="tabIndex"
                >
                <template>
                    <rqdx-action-data-grid
                        :automation_id="getAutomationID"
                        :config="getConfig('premiums')"
                        :data-source="items"
                        :persist-state="false"
                        class="pb-0"
                        fixed-header
                        hide-toolbar
                    />
                </template>
            </rq-tabs>
            <!-- <ul class="nav nav-tabs rq-nav-tabs border-bottom-0">
                <li v-for="tab in tabItems"
                    :key="tab.name"
                    class="nav-item">
                    <rqdx-action-data-grid
                        :automation_id="_.uniqueId('e-remit-detail-')"
                        :config="getConfig(tab.type)"
                        :data-source="tab.items"
                        :persist-state="false"
                        class="pb-0"
                        fixed-header
                        hide-toolbar
                    />
                </li>
            </ul> -->
        </div>
    </DxPopover>
</template>

<script>
    import { computed } from "vue";
    import { usePopoverVisibleBindings } from "./usePopoverVisibleBindings";

    export default {
        name: "CmERemitDetailPopover",
        props: {
            placement: { type: [String,Object], default: "left" },
            container: { type: [String, HTMLElement, Object], default: null },
            popover: { type: Object, default: () => ({target: "", title: "", item: {}, visible: false}) },
            visible: { type: Boolean, default: false },
        },
        data () {
            return {
                tabItems: [],
                tabIndex: 0,
                items: []
            };
        },
        setup(props, { emit }) {
            const visibleProp = computed(() => props.visible);
            const { isActive, isVisible } = usePopoverVisibleBindings(visibleProp);
            const onHidden = () => emit("update:visible", false);
            return { isVisible, isActive, onHidden };
        },
        mounted() {
            this.initTabs();
        },
        watch: {
            visible:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue) && newValue) {
                        this.initTabs();
                    }
                },
            },
        },
        methods: {
            getAutomationID() {
                return _.uniqueId('e-remit-detail-');
            },
            getConfig(type) {
                const self = this;console.log(type);
                if (_.isEqual(type, "premiums")) {
                    return {
                        keyExpr: "sequence",
                        width: "650px",
                        selection: { mode: "none", showCheckBoxesMode: "never" },
                        columns: [
                                {
                                    dataField: "name",
                                    dataType: "string",
                                    minWidth: 400
                                },
                                {
                                    dataField: "grossAmount",
                                    dataType: "number",
                                    format: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    width: 125
                                },
                                {
                                    dataField: "liability",
                                    dataType: "number",
                                    format: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    width: 125
                                },
                        ],
                        summary: {
                            totalItems: [
                                {
                                    name: "TotalLabel",
                                    column: "name",
                                    alignment: "left",
                                    displayFormat: "TOTAL",
                                    cssClass: "rq-summary-label",
                                    summaryType: "sum"
                                },
                                {
                                    name: "ItemNetTotal",
                                    column: "grossAmount",
                                    alignment: "right",
                                    valueFormat: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    displayFormat: "{0}",
                                    summaryType: "sum"
                                },
                                {
                                    name: "ItemTotal",
                                    column: "liability",
                                    alignment: "right",
                                    valueFormat: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    displayFormat: "{0}",
                                    summaryType: "sum"
                                },
                            ]
                        }
                    };
                } else {
                    return {
                        keyExpr: "id",
                        width: "510px",
                        selection: { mode: "none", showCheckBoxesMode: "never" },
                        columns: [
                                {
                                    dataField: "name",
                                    dataType: "string",
                                },
                                {
                                    dataField: "description",
                                    dataType: "string",
                                },
                        ]
                    };
                }
            },
            initTabs() {
                const self = this;
                self.tabItems = [];
                _.each(self.popover.item.policies, policy => {
                    self.tabItems.push({name: policy.policyNumber, items: policy.premiums, type: "premiums", title: policy.policyNumber, automation_id: _.uniqueId('file-')});
                });
                if (_.hasIn(self.popover.item, "documents")) {
                    self.tabItems.push({name: "Documents", items: self.popover.item.documents, type: "documents", title: "Documents", automation_id: _.uniqueId('docs-')});
                }
            }
        }
    }
</script>
