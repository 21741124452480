<template>
    <DxPopover
        v-if="isActive"
        ref="popoverComponent"
        :target="`#${target}`"
        :position="{ my: 'top left', at: 'bottom left', offset: '0 -4' }"
        :wrapper-attr="{ class: 'rq-list-menu' }"
        :visible="isShowing"
        @hidden="onHidden">
        <div :class="{ 'rq-list-menu-content':true, 'rq-list-menu-content-lg': !isBulletMenu }">
            <!-- <div class="rq-list-menu-title">{{title}}</div> -->
            <div :class="{ 'rq-list-menu-items': true, 'rq-list-menu-bullet-items': isBulletMenu }">
                <rq-doc-editor-list-menu-item
                    v-for="item in listOptions"
                    :key="item.name"
                    :item="item"
                    @click="emitEvent('select', item)"
                />
            </div>
        </div>
    </DxPopover>
</template>

<script>
    import { ListType } from "devexpress-richedit";
    import DocumentListHelper from "../DocumentListHelper";
    import RqDocEditorListMenuItem from "./RqDocEditorListMenuItem";

    export default {
        name: "RqDocEditorListMenu",

        props: {
            target: { type: [HTMLElement, SVGElement, Function, Object, String], default: "" },
            title: { type: String, default: "List Options" },
            listType: { type: Number, default: null },
            active: { type: Boolean, default: false }
        },

        components: { RqDocEditorListMenuItem },

        data() {
            return {
                bulletListOptions: [],
                numberedListOptions: [],
                isShowing: true
            };
        },

        computed: {
            isBulletMenu() { return this.listType === ListType.Bullet; },
            listOptions() { return this.isBulletMenu ? this.bulletListOptions : this.numberedListOptions; },
            isActive: {
                get() { return this.active; },
                set(val) {
                    if(!this.visible && val) this.isShowing = true;
                    this.$emit("update:active", _.parseBool(val));
                }
            }
        },

        created() {
            this.bulletListOptions = DocumentListHelper.getBulletMenuOptions();
            this.numberedListOptions = DocumentListHelper.getNumberedMenuOptions();
        },

        methods: {
            onHidden() {
                this.isActive = false;
                this.emitEvent("closed");
            },

            open() {
                this.isShowing = true;
                this.isActive = true;
            },

            close() {
                this.isShowing = false;
            },

            emitEvent(eventName, item=null) {
                this.$emit(eventName, {
                    name: _.isNil(item)
                        ? this.target
                        : item.name,
                    type: this.listType
                });
            }
        }
    };

</script>