import { NAV_ACTIONS } from "../../actions";
import { NAV_MUTATIONS } from "../../mutations";
import { removeFromStateArray } from "../../helpers";
import { NavConfig, NAV_IDS } from "../../../nav";
import { OrderServiceType } from "@/shared/models/enums";
import { SETTLEMENT_TYPE } from "@settlement/models";
import internetAlertsApi from '@/api/internetAlerts';
import { useLicenseStore } from "../license";

const getDefaultState = () => {
    return {
        navItems: _.map(NavConfig.items.slice(), item => ({ ...item, disabled: false })),
        inactiveItemIds: [],
        disabledItemIds: [],
        megaMenuActive: false,
        unreadAlertCount: 0
    };
};

const state = getDefaultState();

const getters = {};

const actions = {
    [NAV_ACTIONS.SET_NAV_ITEM_INACTIVE]({state, commit, dispatch}, id) {
        if(_.includes(state.inactiveItemIds, id)) return Promise.resolve(true);
        commit(NAV_MUTATIONS.ADD_INACTIVE_ITEM_ID, id);
        return dispatch(NAV_ACTIONS.REFRESH_NAV);
    },

    [NAV_ACTIONS.SET_NAV_ITEM_ACTIVE]({state, commit, dispatch}, id) {
        if(!_.includes(state.inactiveItemIds, id)) return Promise.resolve(true);
        commit(NAV_MUTATIONS.REMOVE_INACTIVE_ITEM_ID, id);
        return dispatch(NAV_ACTIONS.REFRESH_NAV);
    },

    [NAV_ACTIONS.SET_NAV_ITEM_DISABLED]({state, commit, dispatch}, id) {
        if(_.includes(state.disabledItemIds, id)) return Promise.resolve(true);
        commit(NAV_MUTATIONS.ADD_DISABLED_ITEM_ID, id);
        return dispatch(NAV_ACTIONS.REFRESH_NAV);
    },

    [NAV_ACTIONS.SET_NAV_ITEM_ENABLED]({state, commit, dispatch}, id) {
        if(!_.includes(state.disabledItemIds, id)) return Promise.resolve(true);
        commit(NAV_MUTATIONS.REMOVE_DISABLED_ITEM_ID, id);
        return dispatch(NAV_ACTIONS.REFRESH_NAV);
    },

    [NAV_ACTIONS.UPDATE_INACTIVE_NAV_ITEMS](context, idList=[]) {
        context.commit(NAV_MUTATIONS.SET_INACTIVE_ITEM_IDS, idList);
        return context.dispatch(NAV_ACTIONS.REFRESH_NAV);
    },

    [NAV_ACTIONS.UPDATE_DISABLED_NAV_ITEMS](context, idList=[]) {
        context.commit(NAV_MUTATIONS.SET_DISABLED_ITEM_IDS, idList);
        return context.dispatch(NAV_ACTIONS.REFRESH_NAV);
    },

    [NAV_ACTIONS.UPDATE_ORDER_NAV](context) {
        let orderServiceId = _.getNumber(context, "rootState.orders.order.service", -1);

        let disabledNavIds = _.includes(OrderServiceType.ownerSpecificTypes, orderServiceId) && orderServiceId !== OrderServiceType.ALTATitleGuard
            ? [NAV_IDS.POLICY_LOAN_SELECTION]
            : _.includes(OrderServiceType.loanSpecificTypes, orderServiceId) && orderServiceId !== OrderServiceType.ALTAConstructionLoan
                ? [NAV_IDS.POLICY_OWNER]
                : [];

        context.commit(NAV_MUTATIONS.SET_DISABLED_ITEM_IDS, disabledNavIds);

        let settlementType = _.getNumber(context, "rootState.orders.orderSummary.settlementStatementType", 0);
        let isWithOutSeller = _.getBool(context, "rootState.orders.orderSummary.isWithOutSeller", false);
        let inactivateNavIds = [];

        if (isWithOutSeller) {
            inactivateNavIds.push(...[
                NAV_IDS.SETTLEMENT_DEBIT_CREDITS,
                NAV_IDS.SETTLEMENT_PRORATIONS,
                NAV_IDS.SETTLEMENT_REALTOR_COMMISSION
            ]);
        }

        if (settlementType === SETTLEMENT_TYPE.CDF) {

            inactivateNavIds.push(...[
                NAV_IDS.SETTLEMENT_HUD_ADDENDUMS,
                NAV_IDS.SETTLEMENT_WORKSHEET_801,
                NAV_IDS.SETTLEMENT_ADDITIONAL_TITLE_SERVICES,
                NAV_IDS.SETTLEMENT_GFE_COMPARISON,
                NAV_IDS.SETTLEMENT_NET_DISBURSEMENT_DISB,
                NAV_IDS.SETTLEMENT_NET_DISBURSEMENT_NET,
                NAV_IDS.SETTLEMENT_ORIGINATION_CHARGES_HUD,
                NAV_IDS.SETTLEMENT_TITLE_CHARGES_HUD,
                NAV_IDS.SETTLEMENT_OTHER_CHARGES_HUD,
                NAV_IDS.SETTLEMENT_PREPAIDS_HUD,
                NAV_IDS.SETTLEMENT_ESCROWS_HUD
            ]);

            if (!isWithOutSeller) {
                inactivateNavIds.push(NAV_IDS.SETTLEMENT_CDF_PAYOFFS);
            }
        }

        if (settlementType === SETTLEMENT_TYPE.HUD_1974) {
            inactivateNavIds.push(...[
                NAV_IDS.SETTLEMENT_ADDENDUMS,
                NAV_IDS.SETTLEMENT_WORKSHEET_801,
                NAV_IDS.SETTLEMENT_ADDITIONAL_TITLE_SERVICES,
                NAV_IDS.SETTLEMENT_LOAN_CALCULATION,
                NAV_IDS.SETTLEMENT_LOAN_DISCLOSURES,
                NAV_IDS.SETTLEMENT_LOANTERMS,
                NAV_IDS.SETTLEMENT_PROJECTED_PAYMENT,
                NAV_IDS.SETTLEMENT_CONTACTS,
                NAV_IDS.SETTLEMENT_CASH_TO_CLOSE,
                NAV_IDS.SETTLEMENT_GFE_COMPARISON,
                NAV_IDS.SETTLEMENT_CDF_PAYOFFS,
                NAV_IDS.CDF_EDITOR,
            ]);

            if (!isWithOutSeller) {
                inactivateNavIds.push(NAV_IDS.SETTLEMENT_NET_DISBURSEMENT_DISB);
                inactivateNavIds.push(NAV_IDS.SETTLEMENT_NET_DISBURSEMENT_NET);
            }
        }

        if (settlementType === SETTLEMENT_TYPE.HUD_2010) {
            inactivateNavIds.push(...[
                NAV_IDS.SETTLEMENT_ADDENDUMS,
                NAV_IDS.SETTLEMENT_LOAN_CALCULATION,
                NAV_IDS.SETTLEMENT_LOAN_DISCLOSURES,
                NAV_IDS.SETTLEMENT_PROJECTED_PAYMENT,
                NAV_IDS.SETTLEMENT_CONTACTS,
                NAV_IDS.SETTLEMENT_CASH_TO_CLOSE,
                NAV_IDS.SETTLEMENT_CDF_PAYOFFS,
                NAV_IDS.CDF_EDITOR,
            ]);

            if (!isWithOutSeller) {
                inactivateNavIds.push(NAV_IDS.SETTLEMENT_NET_DISBURSEMENT_DISB);
                inactivateNavIds.push(NAV_IDS.SETTLEMENT_NET_DISBURSEMENT_NET);
            }
        }

        // Note: This needs to be at the end of updating
        // default deactivation of elements to respect changing of labels
        if (!_.includes(inactivateNavIds, NAV_IDS.SETTLEMENT_ORIGINATION_CHARGES_HUD)) {
            inactivateNavIds.push(NAV_IDS.SETTLEMENT_ORIGINATION_CHARGES_CDF);
        }

        if (!_.includes(inactivateNavIds, NAV_IDS.SETTLEMENT_PREPAIDS_HUD)) {
            inactivateNavIds.push(NAV_IDS.SETTLEMENT_PREPAIDS_CDF);
        }

        if (!_.includes(inactivateNavIds, NAV_IDS.SETTLEMENT_TITLE_CHARGES_HUD)) {
            inactivateNavIds.push(NAV_IDS.SETTLEMENT_TITLE_CHARGES_CDF);
        }

        if (!_.includes(inactivateNavIds, NAV_IDS.SETTLEMENT_OTHER_CHARGES_HUD)) {
            inactivateNavIds.push(NAV_IDS.SETTLEMENT_OTHER_CHARGES_CDF);
        }

        if (!_.includes(inactivateNavIds, NAV_IDS.SETTLEMENT_ESCROWS_HUD)) {
            inactivateNavIds.push(NAV_IDS.SETTLEMENT_ESCROWS_CDF);
        }

        const { cplUnderwriterCompanyID } = context.rootState.system.systemDefaults;

        if (cplUnderwriterCompanyID == context.rootState?.orders?.order?.underwriterCompanyID) {
            inactivateNavIds = inactivateNavIds.filter(id => id != NAV_IDS.ORDER_ENTRY_CPL)
        }
        else {
            inactivateNavIds.push(NAV_IDS.ORDER_ENTRY_CPL);
        }
        
        if (inactivateNavIds.length > 0)
            context.commit(NAV_MUTATIONS.SET_INACTIVE_ITEM_IDS, inactivateNavIds);

        return context.dispatch(NAV_ACTIONS.REFRESH_NAV);
    },

    async [NAV_ACTIONS.REFRESH_NAV]({ state, getters, commit }){
        const licenseStore = useLicenseStore();
        let disabledItemIds = state.disabledItemIds;
        let inactiveItemIds = state.inactiveItemIds;
        let securitySettings = getters.userSecuritySettings;
        let isAdmin = !_.isNil(securitySettings) && securitySettings.findValue("IsAdmin");

        const isAccessDenied = (item, allowAny=false) => {
            let accessKeyValue = _.get(item, "accessKey", null);
            if(_.isEmpty(accessKeyValue)) return false;
            if(_.isNil(securitySettings)) return true;
            return allowAny
                ? !securitySettings.hasAnyAccess(accessKeyValue)
                : !securitySettings.hasAccess(accessKeyValue);
        };
        const isPermissionDenied = (item, allowAny=false) => {
            let permissionKeyValue = _.get(item, "permissionKeys", []);
            if(_.isEmpty(permissionKeyValue)) return false;
            if(_.isNil(securitySettings)) return true;
            return allowAny
                ? !securitySettings.hasAnyPermission(permissionKeyValue)
                : !securitySettings.hasPermission(permissionKeyValue);
        };
        const updateNavItemsRecursive = items => {
            _.forEach(items, item => {
                item.disabled = _.isEmpty(disabledItemIds) ? false : _.includes(disabledItemIds, item.id);
                item.inactive = item.id === NAV_IDS.CONFIG
                    ? isAccessDenied(item, true) && isPermissionDenied(item, true)
                    : (item.adminOnly && !isAdmin)
                        || isAccessDenied(item)
                        || isPermissionDenied(item)
                        || (!_.isEmpty(inactiveItemIds) && _.includes(inactiveItemIds, item.id))
                        || (!_.isEmpty(item.featureFlag) && !licenseStore.checkFeature(item.featureFlag));
                if(!item.children) return;
                updateNavItemsRecursive(item.children);
            });
        };

        let navItems = getDefaultState().navItems;
        updateNavItemsRecursive(navItems);

        commit(NAV_MUTATIONS.SET_NAV_ITEMS, navItems);
        return true;
    },
    [NAV_ACTIONS.GET_UNREAD_ALERT_COUNT](context){
        let promise = internetAlertsApi.getAlertTotals();
        promise.then((results) => {
            let total = _.sumBy(results, (r) => { return r.unreadCount; })
            context.commit(NAV_MUTATIONS.SET_UNREAD_ALERT_COUNT, total);
        });
    }
};

const mutations = {
    [NAV_MUTATIONS.ADD_DISABLED_ITEM_ID](state, value) { state.disabledItemIds.push(value); },
    [NAV_MUTATIONS.REMOVE_DISABLED_ITEM_ID](state, value) { removeFromStateArray(state.disabledItemIds, value); },
    [NAV_MUTATIONS.SET_DISABLED_ITEM_IDS](state, value) { _.set(state, "disabledItemIds", value); },

    [NAV_MUTATIONS.ADD_INACTIVE_ITEM_ID](state, value) { state.inactiveItemIds.push(value); },
    [NAV_MUTATIONS.REMOVE_INACTIVE_ITEM_ID](state, value) { removeFromStateArray(state.inactiveItemIds, value); },
    [NAV_MUTATIONS.SET_INACTIVE_ITEM_IDS](state, value) { _.set(state, "inactiveItemIds", value); },

    [NAV_MUTATIONS.SET_NAV_ITEMS](state, value) { _.set(state, "navItems", value); },
    [NAV_MUTATIONS.SET_MEGA_MENU_STATE](state, value) { _.set(state, "megaMenuActive", value); },
    [NAV_MUTATIONS.SET_UNREAD_ALERT_COUNT](state, value) { _.set(state, "unreadAlertCount", value);}
};

export default { state, getters, actions, mutations };
