<template>
    <div class="rq-container content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div class="form-group mt-3">
            <file-number-input
                automation_id="txt_gfno"
                label="File #"
                v-model:order-id="ordersID"
                v-model:has-error="fileNumberInvalid"
                :allow-locked-files="allowLockedFiles"
                :required="required"
                v-model="gfNo"
                show-clear-button
                show-search-button
            />
        </div>
    </div>
</template>

<script>
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    const DEFAULT_ERROR_MESSAGE = "Please correct the highlighted errors on screen to continue.";

    export default {
        name: 'FileNumberDialog',
        components: { FileNumberInput },
        props: {
            allowLockedFiles: { type: Boolean, default: true },
            required: { type: Boolean, default: false },
        },
        data() {
            return {
                gfNo: "",
                fileNumberInvalid: false,
                ordersID: 0,
                errorMessage: ""
            }
        },
        created() {
            this.$emit("disable-ok");
        },
        watch: {
            fileNumberInvalid(newValue, oldValue) {
                if(newValue){
                 this.$emit("disable-ok");
                 return;
                }

                if(newValue || newValue === oldValue || this.errorMessage !== DEFAULT_ERROR_MESSAGE) //return;
                {
                    this.$emit("enable-ok");
                    return;
                }

                this.errorMessage = "";
                // this.$emit("enable-ok");
            },
            ordersID(newValue) {
                if(_.parseNumber(newValue, 0) > 0 && !this.fileNumberInvalid) {
                    this.$emit("enable-ok");
                } else {
                    this.$emit("disable-ok");
                }
            }
        },
        methods: {
            dialogResult(){
                return {gfNo: this.gfNo, ordersID: this.ordersID};
            },
        },
    }
</script>