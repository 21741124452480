import OrderWorkflowApi from "@/api/orderWorkflow";
import { WORKFLOW_ACTIONS } from "../actions";
import { WORKFLOW_MUTATIONS } from "../mutations";
import { BASIC_ACTION, ACTION_WITH_COMMIT } from "../helpers";

const NOTIFY_FREQUENCY = 300000; //5min.

const state = {
    tasks: [],
    notificationInfo: {
        deptTaskCount: 0,
        userTaskCount: 0
    },
    lastUpdate: null
};

const getters = {
    notifyTimeSpan: state => {
        return _.isDate(state.lastUpdate)
            ? Date.now() - state.lastUpdate
            : -1;
    },
    refreshNotifications: (state, getters) => {
        return getters.notifyTimeSpan < 0 || getters.notifyTimeSpan > NOTIFY_FREQUENCY;
    }
};

const actions = {
    [WORKFLOW_ACTIONS.GET_NOTIFICATION_INFO](context, { countsOnly=true, forceRefresh=false } = {}) {

        if(!forceRefresh && !context.getters.refreshNotifications) return Promise.resolve(context.state.notificationInfo);

        let apiPromise = OrderWorkflowApi.getTaskNotificationInfo(countsOnly);
        context.commit(WORKFLOW_MUTATIONS.SET_LAST_UPDATE, new Date());
        return ACTION_WITH_COMMIT(context, apiPromise, WORKFLOW_MUTATIONS.SET_NOTIFICATION_INFO);
    },

    [WORKFLOW_ACTIONS.UPDATE_DASHBOARD_VIEW_DATE](context) {
        let apiPromise = OrderWorkflowApi.updateDashboardLastViewDate();
        return BASIC_ACTION(context, apiPromise, result => {
            context.dispatch(WORKFLOW_ACTIONS.GET_NOTIFICATION_INFO, { forceRefresh: true });
        });
    }
};

const mutations = {
    [WORKFLOW_MUTATIONS.SET_NOTIFICATION_INFO] (state, value) { _.set(state, "notificationInfo", value); },
    [WORKFLOW_MUTATIONS.SET_LAST_UPDATE] (state, value) { _.set(state, "lastUpdate", value); }
};

export default {
    state,
    getters,
    actions,
    mutations
};