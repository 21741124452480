/*
    -----------------------------------------------------------------------------
    DEPRECATED
    -----------------------------------------------------------------------------
    Moving forward use and/or updatate where necessary to leverage
    the composable, useGridCompanyPicker.js, found here:

        src\shared\composables\useGridCompanyPicker.js
    -----------------------------------------------------------------------------
*/
import CompanyContactLookup from "@order-entry/contacts/components/CompanyContactLookup";
import CompanyEdit from "@utilities/manage-companies/components/CompanyEdit";
import { CompanyPickerModel, SearchRequest, SortOption } from "@/shared/models/models";

export default {
    computed: {
        gcp_dxListClass() {
            let theme = _.get(this, "$route.meta.theme", "default") || "default";
            return `rq-dx-theme-${theme}`;
        },
        gcp_defaultRegionId() {
            if(!_.startsWith(this.$route.path, "/order")) return null;
            return _.getNumber(this, "$store.state.orders.order.regionID", null);
        }
    },
    methods: {
        getCompanyContactGridColumn({
            column,
            dialogTitle,
            showContactPicker=true,
            companyIDExpr="companyID",
            companyNameExpr="companyName",
            contactIDExpr="contactID",
            contactNameExpr="contactName",
            roleId=0,
            disabled=false,
            activeOnly=false
        }) {
            const self = this;
            let resultCol = _.cloneDeep(column);

            let getGridData = v => {
                let result = { };
                result[companyIDExpr] = _.get(v, "companyID", null) || null;
                result[companyNameExpr] = _.get(v, "companyName", "");
                if(showContactPicker) {
                    result[contactIDExpr] = _.get(v, "contactID", null) || null;
                    result[contactNameExpr] = _.get(v, "contactName", "");
                }
                return result;
            };

            let getCompanyData = v => {
                let result = {
                    companyID: _.get(v, companyIDExpr, null) || null,
                    companyName: _.get(v, companyNameExpr, ""),
                };

                if(showContactPicker) {
                    result.contactID = _.get(v, contactIDExpr, null) || null;
                    result.contactName = _.get(v, contactNameExpr, "");
                }

                return new CompanyPickerModel(result);
            };

            resultCol.calculateDisplayValue = rowData => getCompanyData(rowData).displayName;
            resultCol.setCellValue = function(rowData, value) {
                let companyData = getGridData(value);
                _.assign(rowData, companyData);
            };

            const companyPickerTemplate = (cellElement, cellInfo) => {
                let currentData = getCompanyData(cellInfo.data);
                let filter = roleId > 0 ? ["RoleTypeID", "=", roleId] : [];
                let $dxInstance = null;

                const stopEventPropagation = e => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                };
                const updateButtonVisibility = (visible=null) => {
                    if(_.isNil($dxInstance)) return;
                    let buttonInstance = $dxInstance.getButton("viewCompany");
                    buttonInstance.option("visible", _.isNil(visible) ? currentData.companyID > 0 : visible);
                };

                //assigned to action button "id" attr to be used as toolitp targets
                let viewEditButtonId = _.uniqueId("rq-editor-action-");
                let searchButtonId = _.uniqueId("rq-editor-action-");

                let $dxElement = $("<div>").dxSelectBox({
                    dataSource:{
                        paginate: true,
                        pageSize: 50,
                        filter,
                        byKey: key => ({ companyID: key, name: key === currentData.companyID ? currentData.displayName : "" }),
                        load: _.debounce(function(e) {
                            return self.gcp_fetchCompanyData(e, cellInfo, activeOnly);
                        }, 300, { leading: true, trailing: true })
                    },
                    dropDownOptions: {
                        elementAttr: { class: "rq-dx-theme-blue" },
                        minWidth: 350
                    },
                    displayExpr: "name",
                    valueExpr: "companyID",
                    value: currentData.companyID,
                    searchEnabled: true,
                    showDataBeforeSearch: false,
                    openOnFieldClick: false,
                    opened: false,
                    noDataText: "Enter a value to search for a company",
                    buttons: [
                        {
                            name: "viewCompany",
                            location: "after",
                            options: {
                                elementAttr: {
                                    id: viewEditButtonId,
                                    class: "rq-grid-editor-action"
                                },
                                visible: _.parseNumber(currentData.companyID, 0) > 0,
                                icon: "<svg class='rq-icon-symbol'><use href='#rq-far-building'></use></svg>",
                                tabIndex: -1,
                                activeStateEnabled:false,
                                focusStateEnabled:false,
                                onClick() {
                                    self.gcp_showEditCompany(currentData, $dxInstance, cellInfo);
                                }
                            }
                        },
                        {
                            name: "openPicker",
                            location: "after",
                            options: {
                                elementAttr: {
                                    id: searchButtonId,
                                    class: "rq-grid-editor-action"
                                },
                                icon: "<svg class='rq-icon-symbol'><use href='#rq-fas-search'></use></svg>",
                                tabIndex: -1,
                                activeStateEnabled:false,
                                focusStateEnabled:false,
                                onClick() {
                                    self.gcp_showPicker(dialogTitle, currentData, $dxInstance, cellInfo, showContactPicker);
                                }
                            }
                        }
                    ],
                    itemTemplate: itemData => {
                        var reg = new RegExp($dxInstance.option("text"), 'ig');
                        var display = _.replace(CompanyPickerModel.formatDisplayName(itemData.companyID, itemData.name), reg, '<strong>$&</strong>');
                        return $('<span>').addClass('d-flex justify-content-between')
                                .append($('<span>').append(display))
                                .append($('<span>').addClass('rq-pkr-role-name').append(itemData.role));
                    },
                    onValueChanged(e) {
                        let selectedItem = e.component.option("selectedItem");
                        currentData = new CompanyPickerModel(selectedItem);
                        cellInfo.setValue(currentData);
                        updateButtonVisibility();
                    },
                    onOpened(e) {
                        let $contentElement = e.component._popup.content();
                        if($contentElement.hasClass(self.gcp_dxListClass)) return;
                        $contentElement.addClass(self.gcp_dxListClass);
                        updateButtonVisibility(false);
                    },
                    onClosed(e) {
                        updateButtonVisibility();
                    },
                    onKeyDown(e) {
                        let isLookupShortcut = e.event.ctrlKey && e.event.key === "l";
                        if(isLookupShortcut) {
                            self.gcp_showPicker(dialogTitle, currentData, $dxInstance, cellInfo, showContactPicker);
                            stopEventPropagation(e.event);
                        }
                    },
                    onKeyUp(e) {
                        let isLookupShortcut = e.event.ctrlKey && e.event.key === "l";
                        if(isLookupShortcut)
                            stopEventPropagation(e.event);
                    }
                });
                $dxInstance = $dxElement.dxSelectBox("instance");

                /* Tooltips */
                let commonTooltipConfig = { wrapperAttr: { class: "rq-dx-tooltip" }, position: "top", showEvent: "mouseenter", hideEvent: "mouseleave" };
                cellElement
                    .append($("<span/>").text("View/Edit Company").dxTooltip({ target: `#${viewEditButtonId}`, ...commonTooltipConfig }))
                    .append($("<span/>").text("Search/Select Company").dxTooltip({ target: `#${searchButtonId}`, ...commonTooltipConfig }));
                /************/

                return $dxElement;
            };

            const disabledEditTemplate = (cellElement, cellInfo) => {
                let currentData = getCompanyData(cellInfo.data);
                return $("<div/>").dxTextBox({ value: currentData.displayName, readOnly: true });
            };

            resultCol.editCellTemplate = function(cellElement, cellInfo) {
                let isDisabled = _.isFunction(disabled) ? disabled(cellInfo) : _.parseBool(disabled);
                return isDisabled
                    ? disabledEditTemplate(cellElement, cellInfo)
                    : companyPickerTemplate(cellElement, cellInfo);
            };

            return resultCol;
        },

        gcp_fetchCompanyData(loadOptions, cellInfo, activeOnly) {
            const self = this;
            if(_.isEmpty(loadOptions.searchValue)) return;
            let searchRequest = SearchRequest.fromLoadOptions(loadOptions);
            let searchParts = searchRequest.searchTerm.split(" - ");
            let searchId = _.parseNumber(searchRequest.searchTerm, 0);
            if(searchParts.length > 1) {
                searchParts.shift();
                searchRequest.searchTerm = _.join(searchParts, " - ");
            }
            else if(_.gt(searchId, 0)) {
                searchRequest.searchTerm = null;
                searchRequest.addFilter("CompanyID", "=", searchId);
            }
            if(!_.isEmpty(searchRequest.searchTerm)) {
                searchRequest.searchFields = ["Name", "FriendlyName"];
            }
            let rowRegionId = _.get(cellInfo, "data.regionID", null);
            let regionId = _.isNil(rowRegionId)
                ? self.gcp_defaultRegionId
                : rowRegionId;
            if(!_.isNil(regionId)){
                searchRequest.parameters = {
                    regionId: regionId,
                    activeOnly: activeOnly,
                    specificRegionOnly: true
                };
            } else {
                searchRequest.parameters = {
                    activeOnly: activeOnly
                };
            }
            return self.$api.CompaniesApi.search(searchRequest)
                .then(response => {
                    return { data: response.results, totalCount:response.totalRecords };
                });
        },

        gcp_showEditCompany(data, dxComponent, cellInfo) {
            const self = this;
            if(data.companyID <= 0) return;
            dxComponent.close();
            self.$dialog.open({
                title: "Edit Company",
                width: "85%",
                adaptive: true,
                component: CompanyEdit,
                props: { companyId: data.companyID, modalMode: true },
                onOk (e) {
                    return e.component.modalSave()
                        .then(result => {
                            let resultEvent = _.get(result, "originalEvent", e);
                            if (!resultEvent) return false;
                            data.companyName = resultEvent.component.companyInfo.name;
                            cellInfo.setValue(data);
                            _.invoke(dxComponent, "focus");
                            return true;
                        });
                },
                onCancel() {
                    _.invoke(dxComponent, "focus");
                    return true;
                }
            });
        },

        gcp_showPicker (title, data, dxComponent, cellInfo, showContactTab) {
            const self = this;
            dxComponent.close();
            let rowRegionId = _.get(cellInfo, "data.regionID", null);
            let regionId = _.isNil(rowRegionId)
                ? self.gcp_defaultRegionId
                : rowRegionId;
            self.$dialog.open({
                title,
                height: "90%",
                width: "85%",
                component: CompanyContactLookup,
                props: {
                    pickerSearchTerm: data.companyID > 0 ? "" : _.invoke(dxComponent, "option", "text"),
                    roleTypeId: data.roleTypeID,
                    selectedCompanyId: data.companyID || 0,
                    selectedContactId: data.contactID || 0,
                    hideContactTab: !showContactTab,
                    regionId,
                    specificRegionOnly: !_.isNil(regionId)
                },
                closeOnEsc: true,
                onOk(e) {
                    let result = _.get(e, "originalEvent.data", null);
                    if (!result) return false;
                    cellInfo.setValue(result);
                    _.invoke(dxComponent, "repaint");
                    _.invoke(dxComponent, "focus");
                    return true;
                },
                onCancel() {
                    _.invoke(dxComponent, "focus");
                    return true;
                }
            });
        }
    }
}