/*
    For new implementations, use the composable that's been created to provide this functionality:
    e.g. import { useGridInvokerMethods } from "@/shared/composables/grid-invoker";
*/
export default gridRefs => {
    const dataResult = {};
    const methods = {};
    const isValidRefValue = (component, prop) => {
        if(_.isEmpty(component[prop])) {
            console.warn(`GridInvokerMixin -- Missing component ref, "${prop}"`);
            return false;
        }
        return true;
    }
    _.each(gridRefs, (val,key) => {
        const gridRefProperty = `${_.lowerFirst(key)}Ref`
        dataResult[gridRefProperty] = val;
        methods[`invoke${_.upperFirst(key)}Method`] = function(method, ...params) {
            if(!isValidRefValue(this, gridRefProperty)) return;
            return _.invoke(this, `$refs.${this[gridRefProperty]}.gridInstance.${method}`, ...params) || null;
        }
        methods[`invoke${_.upperFirst(key)}ComponentMethod`] = function(method, ...params) {
            if(!isValidRefValue(this, gridRefProperty)) return;
            return _.invoke(this, `$refs.${this[gridRefProperty]}.${method}`, ...params) || null;
        }
    });
    return { data() { return dataResult; }, methods };
};