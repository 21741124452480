<template>
    <div class="menu-item"
        :title="item.title"
        @click="$emit('click')">
        <div v-if="isBulletItem"
            :style="`font-family: ${item.fontName};`"
            class="menu-item-char">{{itemDisplay}}</div>
        <div v-else
            v-for="(val,idx) in itemDisplay"
            :key="idx"
            class="menu-item-row">
            <div class="menu-item-num">{{val}}</div>
            <div class="menu-item-line">&nbsp;</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RqDocEditorListMenuItem",

        props: {
            item: { type: Object, default: () => ({}) }
        },

        computed: {
            itemDisplay() { return this.item.menuItemDisplay; },
            isBulletItem() { return _.isString(this.itemDisplay); }
        }
    };
</script>