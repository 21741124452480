export default {
    ADD: {
        automation_id: 'btn_add_task',
        key: "add-task",
        text: "Add",
        tooltip: "Create new Task(s)",
        permissionKeys: ["AllowAddWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true
    },
    ADD_TASK_PACKAGE: {
        automation_id: 'btn_add_task_package',
        key: "add-task-package",
        text: "Add Task Package",
        tooltip: "Add Task Package",
        permissionKeys: ["AllowAddWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true
    },
    ADD_NOTE: {
        automation_id: 'btn_add_note_to_task',
        key: "add-note-to-tasks",
        text: "Add Note",
        tooltip: "Add Note to selected task",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true
    },
    ASSIGN_ME: {
        automation_id: 'btn_assign_tasks_me',
        key: "assign-tasks-me",
        text: "Assign to Me",
        tooltip: "Assign selected Task(s) to Department and/or Department User",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true,
        allowMultiSelection: true
    },
    ASSIGN: {
        automation_id: 'btn_assign_tasks',
        key: "assign-tasks",
        text: "Assign",
        tooltip: "Assign selected Task(s) to Department and/or Department User",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true,
        allowMultiSelection: true
    },
    ASSIGN_TASKS_BASED_ON_FILE_MAIN: {
        automation_id: 'btn_reassign_tasks',
        key: "reassign-tasks",
        text: "Reassign",
        tooltip: "Reassign ALL Task(s) based on Staff Assignments",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true
    },
    EDIT: {
        automation_id: 'btn_edit_task',
        key: "edit-task",
        text: "Edit",
        tooltip: "Edit selected Task",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true
    },
    COPY: {
        automation_id: 'btn_copy_tasks',
        key: "copy-tasks",
        text: "Copy",
        tooltip: "Copy selected Task(s)",
        permissionKeys: ["AllowAddWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true,
        allowMultiSelection: true
    },
    DELETE: {
        automation_id: 'btn_delete_task',
        key: "delete-task",
        text: "Delete",
        tooltip: "Delete selected Task",
        permissionKeys: ["AllowDeleteWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true,
        allowMultiSelection: true
    },
    EMAIL: {
        automation_id: 'btn_email_task',
        key: "email-task",
        text: "Email",
        tooltip: "Email Task",
        permissionKeys: [],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true
    },
    UPDATE_DUEDATE: {
        automation_id: "btn_update_due_date",
        key: "update-due-date",
        text: "Due Date",
        tooltip: "Update Due Date on selected Tasks",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    UPDATE_STARTDATE: {
        automation_id: "btn_update_start_date",
        key: "update-start-date",
        text: "Start Date",
        tooltip: "Update Start Date on selected Tasks",
        permissionKeys: ["EditTaskStartDate"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    MARK_COMPLETE: {
        automation_id: 'btn_mark_task_complete',
        key: "mark-task-complete",
        text: "Mark Complete",
        tooltip: "Mark selected Task Complete",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: ["ctrl+m"],
        disableWhenReadOnly: true,
        requireSelection: true,
        allowMultiSelection: true
    },
    REOPEN: {
        automation_id: 'btn_reopen_tasks',
        key: "reopen-tasks",
        text: "Reopen",
        tooltip: "Reopen selected task(s)",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: ["ctrl+u"],
        disableWhenReadOnly: true,
        requireSelection: true,
        allowMultiSelection: true
    },

    /* 
        The corresponding column the permission key maps to in the DB/generated entity is misspelled; since the keys are created by pivoting the table 
        and utilizing the column names, the permission key here must be intentionally misspelled (as "AllowMaskTaskNA" instead of "AllowMarkTaskNA") 
    */
    TOGGLE_NA: {
        automation_id: 'btn_toggle_task_na',
        key: "toggle-task-na",
        text: "Toggle N/A",
        tooltip: "Toggle selected Task(s) N/A",
        permissionKeys: ["AllowMaskTaskNA"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true,
        allowMultiSelection: true
    },

    VIEW_DOCS: {
        automation_id: 'btn_print',
        key: "print-documents",
        text: "View Docs",
        tooltip: "View Docs",
        permissionKeys: [],
        accessKey: "Documents_ScreenAccess",
        shortcutKeys: []
    }
};
