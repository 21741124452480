<template>
    <div class="content-wrapper">
        <logi-iframe 
            ref="report"
            :report-path="reportPath"
            :report-options="reportOptions"
            v-bind="$attrs"
            require-report-path
        />
    </div>
</template>
<script>
    import ExagoIframe from "@/shared/components/exago/ExagoIframe";
    import LogiIframe from "@/shared/components/exago/LogiIframe";

    export default {
        name: "ReportViewer",
        components: { LogiIframe },
        props: {
            reportPath: { type: String, default: null },
            reportOptions: { type: Object, default: () => ({}) },
        },

        methods: {
            /*
                TODO: Implement necessary report methods as needed
            */
            refresh() {
                const self = this;

                let holdValue = self.reportPath;

                self.reportPath = null;

                self.$nextTick()
                    .then(() => {
                        self.reportPath = holdValue;
                    });
           },
        }
    }
</script>
