<template>
    <div :id="containerId" class="rq-doc-editor-container">
        <div ref="editorEl"></div>
        <rq-doc-editor-list-menu
            ref="listMenuPopover"
            :target="targetListMenu.id"
            :title="targetListMenu.title"
            :list-type="targetListMenu.type"
            v-model:active="listMenuActive"
            @select="onListMenuSelect"
            @closed="onListMenuClosed"
        />
    </div>
</template>
<script>
    import { DocumentFileType } from "@documents/enums";
    import DocumentListHelper from "./DocumentListHelper";
    import { SpecialCharSelection, DocHyperlinkForm } from "@documents/components/editor-dialogs";
    import { APPEND_USER_DICTIONARY } from "@/store/actions";
    import { mapState } from "vuex";
    import {
        create,
        createOptions,
        RichEdit,
        Interval,
        ViewType,
        Size,
        RichEditUnit,
        DocumentFormat,
        FileTabItemId,
        HomeTabItemId,
        InsertTabItemId,
        HyperlinkInfo,
        RibbonTab,
        RibbonTabType,
        RibbonButtonItem,
        RibbonMenuItem,
        RibbonSubMenuItem,
        ListType
    } from "devexpress-richedit";

    // import CheckBoxImages from "./checkbox-images";

    const DEFAULT_FILE_TAB_OPTIONS = { new: false, open: false, export: false, download: false, print: false };
    const CheckboxRtf = checked => `${checked ? "\\u9746" : "\\u9744"}'\\3f`;

    class CheckboxState {
        static get checked() { return { key: "dx://C", text: String.fromCharCode(9746) }; }
        static get unchecked() { return { key: "dx://U", text: String.fromCharCode(9744) }; }
        static toggle(key) { return key === CheckboxState.checked.key ? this.unchecked : this.checked; }
        static isValid(key) { return key === CheckboxState.checked.key || key === CheckboxState.unchecked.key }
    }

    import RqDocEditorListMenu from "./custom-menus/RqDocEditorListMenu";

    var spellCheckerWorker = null;
    var spellCheckerCallbacks = null;
    var spellCheckerWorkerCommandId = 0;

    export default {
        name: "RqDocumentEditor",
        props: {
            modelValue: { type: String, default: "" },
            documentName: { type: String, default: "Untitled" },
            fileType: { type: Number, default: DocumentFileType.RTF },
            customToolbarItems: { type: Array, default: () => [] },
            showOpenRibbonItem: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            confirmOnLosingChanges: { type: Boolean, default: false },
            fileTabOptions: { type: Object, default: () => DEFAULT_FILE_TAB_OPTIONS },
            showInsertDocLink: { type: Boolean, default: false },
        },
        emits: ["update:documentName", "update:fileType", "update:modelValue", "created", "got-focus", "toolbar-command", "document-loaded"],
        components: { RqDocEditorListMenu },

        editorInstance: RichEdit,

        data () {
            return {
                containerId: _.uniqueId("rq-doc-editor-"),
                editorContent: null,
                initialValue: null,
                contentLoaded: false,
                saveFormat: null,
                saveCallback: null,
                internalCommandIds: {
                    insertCheckBox: "insertCheckBox",
                    insertSymbol: "insertSymbol",
                    insertHyperlink: "insertHyperlink"
                },
                internalToolbarActions: [],
                bulletListMenu: {
                    id: "bullet-list-menu",
                    title: "Bullet Options",
                    type: ListType.Bullet
                },
                numberedListMenu: {
                    id: "numbered-list-menu",
                    title: "Number Formats",
                    type: ListType.Number
                },
                externalLoad: false,
                newDocument: true,
                bulletListOptions: [],
                numberedListOptions: [],
                targetListMenu: {},
                listMenuActive: false
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            documentNameValue:{
                get() { return this.documentName; },
                set(val) { this.$emit("update:documentName", val); }
            },
            fileTypeValue:{
                get() { return this.fileType; },
                set(val) { this.$emit("update:fileType", val); }
            },
            documentFormat() { return this.getFormat(this.fileType); },
            dxFileTabOptions() {
                return { ...this.fileTabOptions, none: !_.some(this.fileTabOptions) };
            }
        },

        watch: {
            modelValue(newVal, oldVal) {
                if(newVal === oldVal || newVal === this.editorContent) return;
                this.editorContent = newVal;
                this.loadContent();
            },
            editorContent(newVal, oldVal) {
                if(newVal === oldVal || newVal === this.modelValue) return;
                this.$emit("update:modelValue", newVal);
            },
            readOnly(newVal, oldVal){
                if(newVal === oldVal) return;
                if(!this.editorInstance) return;
                this.editorInstance.readOnly = newVal;
            }
        },

        created() {
            if(_.isEmpty(this.modelValue)) return;
            this.initialValue = this.modelValue;
            this.editorContent = this.modelValue;
        },

        mounted() {
            this.initializeEditor();
        },

        beforeUnmount() {
            this.dispose();
        },

        methods: {
            initializeEditor() {
                const self = this;
                // the createOptions() method creates an object that contains RichEdit options initialized with default values
                const options = createOptions();
                options.bookmarks.visibility = true;
                options.bookmarks.color = "#ff0000";
                options.authentication.group = 'everyone';
                options.rangePermissions.showBrackets = false;
                options.rangePermissions.highlightColor = false;
                options.confirmOnLosingChanges.enabled = self.confirmOnLosingChanges;
                options.confirmOnLosingChanges.message = "Are you sure you want to perform the action? All unsaved document data will be lost.";
                options.fields.updateFieldsBeforePrint = true;
                options.fields.updateFieldsOnPaste = true;

                // events
                self.initializeEditorEvents(options);

                options.unit = RichEditUnit.Inch;
                options.view.viewType = ViewType.PrintLayout;
                options.view.simpleViewSettings.paddings = { left: 15, top: 15, right: 15, bottom: 15, };

                self.initializeSpellCheck(options);
                self.initializeAutoCorrect(options);

                //options.exportUrl = "https://siteurl.com/api/";
                options.readOnly = this.readOnly;
                options.width = "100%";
                options.height = "100%";
                self.updateRibbon(options);
                self.editorInstance = create(this.$refs.editorEl, options);
                self.$nextTick(() => {
                    if(!self.readOnly) {
                        self.swapMenuButtonCaret(self.bulletListMenu.title);
                        self.swapMenuButtonCaret(self.numberedListMenu.title);
                    }
                    self.$emit("created");
                    self.loadContent();
                });
            },

            initializeEditorEvents(options) {
                const self = this;
                // options.events.activeSubDocumentChanged = () => { console.log("event: activeSubDocumentChanged"); };
                // options.events.autoCorrect = () => { };
                // options.events.calculateDocumentVariable = () => { };
                // options.events.characterPropertiesChanged = () => { };
                // options.events.contentInserted = () => { };
                // options.events.contentRemoved = () => { };
                options.events.documentChanged = (component, event) => { self.$emit("document-changed", { component, event }); };
                // options.events.documentFormatted = () => { console.log("event: documentFormatted"); };
                options.events.documentLoaded = self.onDocumentLoaded;
                options.events.gotFocus = (component, event) => { self.$emit("got-focus", { component, event }); }; //got focus? (TM)
                if(!self.readOnly) {
                    options.events.hyperlinkClick = (s,e) => {
                        self.handleCheckboxHyperlinkClick(s, e);
                        e.handled = true;
                    };
                }
                // options.events.keyDown = () => { };
                // options.events.keyUp = () => { };
                // options.events.paragraphPropertiesChanged = () => { };
                // options.events.lostFocus = () => { };
                // options.events.pointerDown = () => { };
                // options.events.pointerUp = () => { };
                options.events.saving = self.onEditorSaving;
                // options.events.saved = () => { console.log("event: saved"); };
                // options.events.selectionChanged = () => { };

                if(self.readOnly) return;

                options.events.customCommandExecuted = (component, event) => {
                    switch(event.commandName) {
                        case self.internalCommandIds.insertCheckBox:
                            self.handleInsertCheckboxCommand(component, event);
                            return;
                        case self.internalCommandIds.insertSymbol:
                            self.handleInsertSymbolCommand(component, event);
                            return;
                        case self.internalCommandIds.insertHyperlink:
                            self.handleInsertHyperlinkCommand(component, event);
                            return;
                        case self.bulletListMenu.id:
                        case self.numberedListMenu.id:
                            self.handleListButtonCommand(event);
                            return;
                    }

                    self.$emit("toolbar-command", { component, event });
                };
            },

            initializeSpellCheck(options) {
                const self = this;
                spellCheckerCallbacks = Object.create(null);
                options.spellCheck.enabled = true;
                options.spellCheck.suggestionCount = 5;
                options.spellCheck.checkWordSpelling = function (word, callback) {
                    if (!spellCheckerWorker) {
                        var userDictionary = _.cloneDeep(self.user.dictionaryWords) || [];
                        spellCheckerWorker = new Worker(new URL("./spellcheck.worker.js", import.meta.url));
                        if (userDictionary.length > 0) {
                            spellCheckerWorker.postMessage({
                                command: 'addPersonalDictionary',
                                personal: userDictionary,
                            });
                        }

                        spellCheckerWorker.onmessage = function (e) {
                            var savedCallback = spellCheckerCallbacks[e.data.id];
                            delete spellCheckerCallbacks[e.data.id];
                            savedCallback(e.data.isCorrect, e.data.suggestions);
                        };
                    }

                    var currId = spellCheckerWorkerCommandId++;
                    spellCheckerCallbacks[currId] = callback;
                    spellCheckerWorker.postMessage({
                        command: 'checkWord',
                        word: word,
                        id: currId,
                    });
                };

                options.spellCheck.addWordToDictionary = function(word) {
                    self.$store.dispatch(APPEND_USER_DICTIONARY, [word])
                        .then(result => {
                            spellCheckerWorker.postMessage({
                                command: 'addWord',
                                word: word,
                            });
                        })
                        .catch(error => {
                            console.error(error);
                            self.$toast.error("Error adding word to dictionary.");
                        });
                };
            },

            initializeAutoCorrect(options) {
                options.autoCorrect = {
                    correctTwoInitialCapitals: true,
                    detectUrls: true,
                    enableAutomaticNumbering: true,
                    replaceTextAsYouType: true,
                    caseSensitiveReplacement: false,
                    replaceInfoCollection: [
                        { replace: "wnwd", with: "well-nourished, well-developed" },
                        { replace: "(c)", with: "©" }
                    ],
                };
                // capitalize the first letter at the beginning of a new sentence/line
                options.events.autoCorrect = function (s, e) {
                    if (e.text.length == 1 && /\w/.test(e.text)) {
                        var prevText = s.document.getText(new Interval(e.interval.start - 2, 2));
                        if (prevText.length == 0 || /^([.]|[?]|[!] )$/.test(prevText) || prevText.charCodeAt(1) == 13) {
                            var newText = e.text.toUpperCase();
                            if (newText != e.text) {
                                s.beginUpdate();
                                s.history.beginTransaction();
                                s.document.deleteText(e.interval);
                                s.document.insertText(e.interval.start, newText);
                                s.history.endTransaction();
                                s.endUpdate();
                                e.handled = true;
                            }
                        }
                    }
                };
            },

            initializeInternalActions() {
                const self = this;
                self.internalToolbarActions = [
                    {
                        id: self.internalCommandIds.insertCheckBox,
                        label: "Check Box",
                        options: { icon: _.getSvgSymbolIcon("fas fa-check-square"), showText: true },
                        tab: RibbonTabType.Insert,
                        insertAfter: InsertTabItemId.InsertPictureLocally
                    },{
                        id: self.internalCommandIds.insertSymbol,
                        label: "Symbol",
                        options: { icon: _.getSvgSymbolIcon("fas fa-omega"), showText: true },
                        tab: RibbonTabType.Insert,
                        insertAfter: self.internalCommandIds.insertCheckBox
                    }
                ];
                if(!self.showInsertDocLink) return;
                self.internalToolbarActions.push({
                    id: self.internalCommandIds.insertHyperlink,
                    label: "Doc Link",
                    options: { icon: "fad fa-file-export", showText: true },
                    tab: RibbonTabType.Insert,
                    insertAfter: self.internalCommandIds.insertSymbol
                });
            },

            onDocumentLoaded(component, event) {
                const self = this;
                let documentName = self.documentNameValue;
                let documentFileType = self.fileTypeValue;
                if(self.externalLoad) {
                    self.externalLoad = false;
                }
                else if(self.newDocument) {
                    documentName = "Untitled Document";
                    self.newDocument = false;
                }
                else {
                    documentName = component.documentName;
                    documentFileType = self.getDocumentFileType(component.documentFormat);

                    if(documentName !== self.documentNameValue)
                        self.documentNameValue = documentName;

                    if(documentFileType !== self.fileTypeValue)
                        self.fileTypeValue = documentFileType;
                }
                self.$emit("document-loaded", { component, event, documentName, documentFileType });
            },

            onEditorSaving(s, e) {
                const self = this;
                if(self.saveFormat === self.documentFormat) self.editorContent = e.base64;
                if(_.isFunction(self.saveCallback)) self.saveCallback(e.base64);
                self.saveFormat = null;
                e.handled = true;
            },

            updateRibbon(options) {
                this.removeUnusedRibbonFeatures(options);
                if(this.readOnly) return;
                this.addInternalRibbonItems(options);
            },

            addToolsRibbonTab(options) {
                let toolTabItems = this.getToolbarItems();

                if(_.isEmpty(toolTabItems)) return;

                let toolsTab = new RibbonTab;
                toolsTab.id = "rqToolsTab";
                toolsTab.title = "Tools";
                toolsTab.items = this.getToolbarItems();
                options.ribbon.insertTabAfter(toolsTab, RibbonTabType.View);
            },

            addListsRibbonTab(options) {
                let listsTab = new RibbonTab;
                listsTab.id = "rqListsTab";
                listsTab.title = "Lists";

                let toggleListIDs = [
                    HomeTabItemId.ToggleBulletedList,
                    HomeTabItemId.ToggleNumberedList,
                    HomeTabItemId.ToggleMultilevelList
                ];

                let homeTab = options.ribbon.getTab(RibbonTabType.Home);
                let toggleListItems = _.map(toggleListIDs, id => {
                    let ribbonItem = homeTab.getItem(id);
                    homeTab.removeItem(id);
                    return ribbonItem;
                });

                listsTab.items = [
                    new RibbonButtonItem(
                        this.bulletListMenu.id,
                        this.bulletListMenu.title,
                        { icon: _.getSvgSymbolIcon("fas fa-caret-down"), showText: true, beginGroup: true }
                    ),
                    new RibbonButtonItem(
                        this.numberedListMenu.id,
                        this.numberedListMenu.title,
                        { icon: _.getSvgSymbolIcon("fas fa-caret-down"), showText: true }
                    ),
                    ...toggleListItems,
                ];
                options.ribbon.insertTabAfter(listsTab, RibbonTabType.Insert);

                this.bulletListOptions = DocumentListHelper.getBulletMenuOptions();
                this.numberedListOptions = DocumentListHelper.getNumberedMenuOptions();
            },

            removeUnusedRibbonFeatures(options) {
                if(this.dxFileTabOptions.none) {
                    options.ribbon.removeTab(RibbonTabType.File);
                }
                else {
                    let fileTab = options.ribbon.getTab(RibbonTabType.File);
                    if(!this.dxFileTabOptions.new)
                        fileTab.removeItem(FileTabItemId.CreateNewDocument);
                    if(!this.dxFileTabOptions.open)
                        fileTab.removeItem(FileTabItemId.OpenDocument);
                    if(!this.dxFileTabOptions.export)
                        fileTab.removeItem(FileTabItemId.ExportDocument);
                    if(!this.dxFileTabOptions.download)
                        fileTab.removeItem(FileTabItemId.Download);
                    if(!this.dxFileTabOptions.print)
                        fileTab.removeItem(FileTabItemId.PrintDocument);
                }
                options.ribbon.removeTab(RibbonTabType.MailMerge);
                options.ribbon.removeTab(RibbonTabType.References);
            },

            addInternalRibbonItems(options) {
                options.fields.openHyperlinkOnClick = true;

                this.initializeInternalActions();

                //remove built-in hyperlink tab item in favor of custom hyperlink tab item (added below via internal toolbar actions)
                // let insertTab = options.ribbon.getTab(RibbonTabType.Insert);
                // insertTab.removeItem(InsertTabItemId.ShowHyperlinkDialog);

                _.forEach(this.internalToolbarActions, item => {
                    options.ribbon
                        .getTab(item.tab)
                        .insertItemAfter(
                            new RibbonButtonItem(item.id, item.label, item.options),
                            item.insertAfter
                        );
                });

                this.addListsRibbonTab(options);
                this.addToolsRibbonTab(options);
            },

            getToolbarItems() {
                const self = this;
                if(_.isEmpty(this.customToolbarItems)) return [];
                let items = [];
                _.forEach(this.customToolbarItems, item => {
                    if(_.isEmpty(item.items)) {
                        items.push(new RibbonButtonItem(
                            item.id,
                            item.text,
                            {
                                icon: item.prefixIcon,
                                showText: true,
                                beginGroup: _.parseBool(item.beginGroup)
                            }));
                    }
                    else {
                        let menuItem = new RibbonMenuItem(item.id, item.text);
                        menuItem.items = _.map(item.items, mi => new RibbonSubMenuItem(`${item.id}.${mi.key}`, mi.text));
                        items.push(menuItem);
                    }
                });
                return items;
            },

            getImageSize(unitConverter, subDocument, position) {
                var fontSize = subDocument.getCharacterProperties(new Interval(position, 0)).size;
                var picSideSize = unitConverter.pointsToTwips(fontSize);
                return new Size(picSideSize, picSideSize);
            },

            /* These methods are loosely based on the instruction provided within the DevExpress documentation found here:
               https://docs.devexpress.com/AspNetCore/402502/rich-edit/examples/how-to-implement-check-boxes-in-a-document */
            handleInsertCheckboxCommand(s, e) {
                const self = this;
                if(self.readOnly) return;
                s.beginUpdate();
                s.history.beginTransaction();
                self.setCheckHyperlinkText(s.selection);
                s.history.endTransaction();
                s.endUpdate();
            },

            handleCheckboxHyperlinkClick(s, e) {
                const self = this;
                if(self.readOnly || !CheckboxState.isValid(e.targetUri)) return;
                s.beginUpdate();
                s.history.beginTransaction();
                self.setCheckHyperlinkText(s.selection, e.hyperlink, e.targetUri);
                s.history.endTransaction();
                s.endUpdate();
            },

            setCheckHyperlinkText(selection, hyperlinkObj, chkKey = "dx://C") {
                const self = this;
                let hyperlink = hyperlinkObj;
                let subDocument = selection.activeSubDocument;
                let chkState = CheckboxState.toggle(chkKey);
                let chkCharProps = null;
                let trailingSpace = false;

                if(_.isNil(hyperlinkObj)) {
                    chkCharProps = subDocument.getCharacterProperties(selection.intervals[0]);
                    hyperlink = subDocument.hyperlinks.create(selection.active,  new HyperlinkInfo("", chkState.key, undefined, "Click to toggle checkbox"));
                    trailingSpace = true;
                }
                else {
                    chkCharProps = subDocument.getCharacterProperties(hyperlink.interval);
                    //this seems odd, but it's the only way to get the values to properly toggle
                    let hyperlinkInfo = hyperlink.hyperlinkInfo;
                    hyperlinkInfo.url = chkState.key;
                    hyperlink.hyperlinkInfo = hyperlinkInfo;
                }
                subDocument.deleteText(hyperlink.resultInterval);
                let checkInterval = subDocument.insertText(hyperlink.resultInterval.start, chkState.text);

                let chkCharSize = _.get(chkCharProps, "size", null) || 14;
                subDocument.setCharacterProperties(checkInterval, { size: chkCharSize, fontName: "MS Gothic" });

                let endInterval = hyperlink.interval.end;
                if(trailingSpace) {
                    let trailingSpaceInterval = subDocument.insertText(endInterval, " ");
                    endInterval = trailingSpaceInterval.end;
                }

                selection.setSelection(endInterval);
            },
            /********************************************************************************************************************/

            handleInsertSymbolCommand(editor, eventArgs) {
                const self = this;
                if(self.readOnly) return;
                let editorFontNames = editor.document.fonts.getAllFontNames();
                if(!_.includes(editorFontNames, "Symbol")) {
                    editor.document.fonts.create("Symbol", "Symbol");
                }
                self.$dialog.open({
                    title: "Insert Symbol",
                    width: 605,
                    resizable: false,
                    adaptive: true,
                    component: SpecialCharSelection,
                    props: {
                        customChars: [
                        {code:("§").charCodeAt(0), font:""},
                    ]
                    },
                    onOk (e) {
                        let symbol = e.component.selectedCharValue;
                        let fontName = e.component.selectedCharFont;
                        let selection = editor.selection;
                        let subDocument = selection.activeSubDocument;
                        let position = selection.active;
                        let characterProperties = _.isEmpty(fontName) ? {} : { fontName };
                        let initialCharacterProperties = subDocument.getCharacterProperties(selection.intervals[0]);

                        editor.beginUpdate();
                        editor.history.beginTransaction();

                        var symbolInterval = subDocument.insertText(position, symbol);
                        subDocument.setCharacterProperties(symbolInterval, characterProperties);

                        let trailingSpaceInterval = subDocument.insertText(symbolInterval.end, " ");
                        subDocument.setCharacterProperties(trailingSpaceInterval, initialCharacterProperties);
                        let endInterval = trailingSpaceInterval.end;

                        editor.history.endTransaction();
                        editor.endUpdate();

                        selection.setSelection(endInterval);

                        self.$nextTick(() => { editor.focus(); });

                        return true;
                    }
                });
            },

            handleInsertHyperlinkCommand(editor, eventArgs) {
                const self = this;
                if(self.readOnly) return;

                let selection = editor.selection;
                let subDocument = selection.activeSubDocument;
                let hyperlinks = subDocument.hyperlinks.find(selection.intervals[0]);
                let hyperlink = hyperlinks?.[0];
                let hyperlinkInfo = { text: "", url: "", tooltip: ""};
                let isHyperlink = false;
                if(!_.isEmpty(hyperlink?.hyperlinkInfo)) {
                    hyperlinkInfo = hyperlink.hyperlinkInfo;
                    isHyperlink = true;
                }
                else {
                    hyperlinkInfo.text = subDocument.getText(selection.intervals[0]);
                }

                self.$dialog.open({
                    title: "Insert Doc Hyperlink",
                    width: 600,
                    height: 350,
                    resizable: false,
                    component: DocHyperlinkForm,
                    props: {
                        text: hyperlinkInfo.text,
                        url: hyperlinkInfo.url,
                        tooltip: hyperlinkInfo.tooltip
                    },
                    onOk (e) {
                        if(e.component.textIsEmpty || e.component.urlIsEmpty) return true;
                        let urlInfo = e.component.urlInfo;
                        if(!urlInfo.isValid) return true;
                        let endInterval = self.createUpdateHyperlink(editor, urlInfo);
                        selection.setSelection(endInterval);

                        return true;
                    }
                });
            },

            createUpdateHyperlink(editor, linkInfo) {
                let selection = editor.selection;
                let subDocument = selection.activeSubDocument;
                let hyperlinks = subDocument.hyperlinks.find(selection.intervals[0]);
                let hyperlink = hyperlinks?.[0];
                let charProps = subDocument.getCharacterProperties(selection.intervals[0]);

                editor.beginUpdate();
                editor.history.beginTransaction();
                if(_.isNil(hyperlink)) {
                    hyperlink = subDocument.hyperlinks.create(selection.intervals[0],  new HyperlinkInfo(linkInfo.text, linkInfo.url, undefined, linkInfo.tooltip));
                    subDocument.setCharacterProperties(hyperlink.interval, _.pick(charProps, ["allCaps", "bold", "fontName", "italic", "size", "smallCaps"]));
                }
                else {
                    //this seems odd, but it's the only way to get the values to properly toggle
                    let hyperlinkInfo = hyperlink.hyperlinkInfo;
                    hyperlinkInfo.url = linkInfo.url;
                    hyperlinkInfo.text = linkInfo.text;
                    hyperlinkInfo.tooltip = linkInfo.tooltip;
                    hyperlink.hyperlinkInfo = hyperlinkInfo;
                }
                editor.history.endTransaction();
                editor.endUpdate();
                return hyperlink.interval.end;
            },

            /****************************************************************************
                RegionStart - List Menu Methods
            *****************************************************************************/
            handleListButtonCommand({ commandName }) {
                if(this.listMenuActive) {
                    this.closeListMenu()
                        .then(closedId => {
                            if(closedId === commandName) return;
                            this.handleListButtonCommand({ commandName });
                        });
                    return;
                }
                let targetMenu = commandName === this.bulletListMenu.id
                    ? this.bulletListMenu
                    : this.numberedListMenu;
                let targetElement = document.querySelector(`#${commandName}`);
                if(_.isNil(targetElement)) {
                    targetElement = document.querySelector(`div[title='${targetMenu.title}']`);
                    if(_.isNil(targetElement)) {
                        console.warn("Unable to resolve editor ribbon button element to target for popover.");
                        return;
                    }
                    targetElement.id = targetMenu.id;
                }
                this.openListMenu(targetMenu);
            },

            onListMenuSelect(e) {
                const self = this;

                self.closeListMenu();

                let listName = _.get(e, "name", null);
                if(_.isEmpty(listName)) return;

                let editor = self.editorInstance;
                let list = self.getListRef(e, true);
                let paragraphs = editor.document.paragraphs.find(editor.selection.intervals[0]);
                _.forEach(paragraphs, p => {

                    // ************************************************************************************************************************************
                    // TG - Resolving which level each paragraph goes in, while at first seemed relatively straight forward, turned out to be anything but.
                    //  DevEx/Word/etc. as far as I can tell don't use any consistent combination of leftIndent and firstLineIndent (hanging indent), so it
                    //  makes it a guessing game as to how it'll end up.  Probably why DevEx doesn't even attempt to resolve the paragraph level themselves
                    //  with their built-in bullet toolber options.  If it ends up being requested as a "must-have" for some reason, this at least can provide
                    //  a starting point.
                    // ------------------------------------------------------------------------------------------------------------------------------------
                    //
                    // let indent = _.parseNumber(p.properties.leftIndent, 0) + _.parseNumber(p.properties.firstLineIndent, 0);
                    // let levelIndex = indent ? Math.round(indent / 720) : 0;
                    // p.addToList(list, levelIndex);
                    //
                    // ************************************************************************************************************************************

                    let levelIndex = _.parseNumber(p.listLevel, -1);
                    if(levelIndex < 0)
                        p.addToList(list);
                    else
                        p.addToList(list, levelIndex);
                });

                self.$nextTick(() => { editor.focus(); });
            },

            onListMenuClosed() {
                this.closeListMenu();
            },

            toggleRibbonButtonActive(id, val) {
                $(`#${id}`).toggleClass("rq-editor-button-active");
            },

            openListMenu(targetMenu) {
                this.targetListMenu = targetMenu;
                this.listMenuActive = true;
                this.toggleRibbonButtonActive(targetMenu.id, true);
            },

            closeListMenu() {
                this.listMenuActive = false;
                return this.$nextTick()
                    .then(() => {
                        let closedId = this.targetListMenu.id;
                        this.targetListMenu = {};
                        this.toggleRibbonButtonActive(closedId, false);
                        return closedId;
                    });
            },

            getListOption({ name, type }) {
                if(_.isEmpty(name)) return null;
                switch(type) {
                    case ListType.Bullet:
                        return _.find(this.bulletListOptions, { name });
                    case ListType.Number:
                        return _.find(this.numberedListOptions, { name });
                }
                return null;
            },

            getListRef(e, createNew=false) {
                const self = this;
                let listOption = self.getListOption(e);
                let list = listOption?.listRef;
                if(createNew || _.isNil(list)) {
                    list = DocumentListHelper.createDxList(self.editorInstance, e.name);
                    self.setListRef(e, createNew ? null : list);
                }
                return list;
            },

            setListRef(e, list) {
                let listOption = this.getListOption(e);
                if(_.isNil(listOption)) return;
                listOption.listRef = list;
            },

            swapMenuButtonCaret(title) {
                let buttonInstance = $(`div[title='${title}']`).dxButton("instance");
                _.invoke(buttonInstance, "option", "iconPosition", "right");
            },
            /****************************************************************************
                RegionEnd - List Menu Methods
            *****************************************************************************/

            loadContent(content=null, name=null, fileType=null) {
                if(!this.editorInstance) return;
                this.editorInstance.adjust();
                if(!_.isEmpty(content)) this.editorContent = content;
                if(_.isEmpty(this.editorContent)) {
                    this.editorInstance.newDocument();
                    return;
                }
                this.newDocument = false;
                if(!_.isNil(name)) this.documentNameValue = name;
                if(!_.isNil(fileType)) this.fileTypeValue = fileType;
                this.editorInstance.openDocument(this.editorContent, this.documentNameValue, this.getFormat(this.fileTypeValue));
                this.contentLoaded = true;
            },

            load(name="Untitled", type=DocumentFileType.DOCX, content) {
                this.externalLoad = true;
                this.loadContent(content, name, type);
            },

            insert(content, type) {
                const self = this;
                if(_.isEmpty(content)) return;
                let subDocument = self.getActiveSubDocument();
                if(_.isNil(subDocument)) return;
                let position = self.editorInstance.selection.active;
                let docMethod = `insert${_.startCase(type)}`;
                subDocument[docMethod](position, content);
            },

            insertText(content) { this.insert(content, "text"); },
            insertRtf(content) { this.insert(content, "rtf"); },

            getActiveSubDocument() {
                let subDocument = _.get(this, "editorInstance.selection.activeSubDocument", null);
                if(_.isNil(subDocument)) {
                    console.warn("activeSubDocument not found!");
                    return null;
                }
                return subDocument;
            },

            tagSelection(startTag, endTag) {
                const self = this;

                if(_.isEmpty(startTag)) return;

                let startPosition = _.getNumber(self, "editorInstance.selection.start", 0);
                let endPosition = _.getNumber(self, "editorInstance.selection.end", 0) + startTag.length;
                let subDocument = _.get(self, "editorInstance.selection.activeSubDocument", null);
                let docHistory = _.get(self, "editorInstance.history", null);

                if(_.isNil(subDocument) || _.isNil(docHistory)) return;

                docHistory.beginTransaction();
                self.editorInstance.beginUpdate();
                subDocument.insertText(startPosition, startTag);
                subDocument.insertText(endPosition, _.isEmpty(endTag) ? startTag : endTag);
                self.editorInstance.endUpdate();
                docHistory.endTransaction();
            },

            getEditorModule() {
                return this.editorInstance;
            },

            isDirty() { return _.getBool(this, "editorInstance.hasUnsavedChanges"); },

            getContent(fileType=null, forceSave=false) {
                const self = this;
                self.saveFormat = _.isNil(fileType) ? self.documentFormat : self.getFormat(fileType);
                self.saveCallback = null;
                return new Promise(resolve => {
                    if(forceSave || self.isDirty() || fileType !== self.fileType) {
                        self.editorInstance.hasUnsavedChanges = true;
                        self.saveCallback = result => resolve(result);
                        self.editorInstance.saveDocument(self.saveFormat);
                    }
                    else {
                        resolve(self.editorContent);
                    }
                });
            },

            getFormat(type) {
                switch(type) {
                    case DocumentFileType.DOCX: return DocumentFormat.OpenXml;
                    case DocumentFileType.RTF: return DocumentFormat.Rtf;
                }
                return DocumentFormat.OpenXml;
            },

            getDocumentFileType(format) {
                switch(format) {
                    case DocumentFormat.OpenXml: return DocumentFileType.DOCX;
                    case DocumentFormat.Rtf: return DocumentFileType.RTF;
                }
                return DocumentFileType.DOCX;
            },

            print() {
                let printMode = 1; //1 = Html, 2 = Pdf
                _.invoke(this, "editorInstance.printDocument", printMode);
            },

            dispose() {
                this.editorInstance?.dispose();
                spellCheckerWorker?.terminate();

                this.editorInstance = null;
                spellCheckerWorker = null;
                spellCheckerCallbacks = null;
            }
        }
    }
</script>