/*
    Vue directive to auto-resize a textarea element
    use by adding "v-auto-resize" to textarea element
*/
import { nextTick } from "vue";

const setTextAreaHeight = async el => {
    await nextTick();
    el.style.height = "auto";
    el.style.height = `${el.scrollHeight + 2}px`;
};

const resizeTextarea = _.debounce(function(e) {
    setTextAreaHeight(e.target);
}, 300, { "leading":true, "trailing":true });

const setStyleParam = (el, binding, param) => {
    let paramValue = _.get(binding, `value.${param}`, null);
    if(_.isNil(paramValue)) return;
    el.style[param] = isNaN(_.parseNumber(paramValue)) ? paramValue : `${paramValue}px`;
}

export default {
    beforeMount(el, binding) {
        if(el.tagName.toLowerCase() !== "textarea") {
            console.warn("The \"v-auto-resize\" directive can only be used on \"textarea\" elements.");
            return;
        }
        setStyleParam(el, binding, "minHeight");
        setStyleParam(el, binding, "maxHeight");
        el.addEventListener("input", resizeTextarea);
    },
    mounted(el) {
        setTextAreaHeight(el);
    },
    updated(el) {
        setTextAreaHeight(el);
    },
    unmounted(el) {
        if(el.tagName.toLowerCase() !== "textarea") return;
        el.removeEventListener("input", resizeTextarea);
    }
};