<template>
    <div class="content-wrapper email-dialog" @dragenter.prevent="onDragEnter" @dragover.prevent>
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="v$.$error"
            sticky
        />
        <rq-page-section>
            <template #header-actions>
                <ul class="nav email-field-toggles">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_show_cc"
                            variant="theme"
                            :disabled="showCc"
                            @click="showCc=true">Add CC
                        </b-btn>
                    </li>
                     <li class="nav-item">
                        <b-btn
                            automation_id="btn_show_bcc"
                            variant="theme"
                            :disabled="showBcc"
                            @click="showBcc=true">Add BCC
                        </b-btn>
                    </li>
                </ul>
            </template>
            <fieldset>
                <div class="row">
                    <div class="col col-email-label">To:</div>
                    <div :class="{ 'col form-group': true, 'has-error':v$.toList.$error }">
                        <email-field
                            ref="toEmailField"
                            label="To"
                            :data-source="emailData"
                            v-model="toList"
                        />
                    </div>
                </div>
                <div class="row" v-if="showCc">
                    <div class="col col-email-label">Cc:</div>
                    <div :class="{ 'col form-group':true, 'has-error':v$.ccList.$error }">
                        <email-field
                            ref="ccEmailField"
                            label="Cc"
                            :data-source="emailData"
                            v-model="ccList"
                            @hide="showCc=false"
                            show-hide-icon
                        />
                    </div>
                </div>
                <div class="row" v-if="showBcc">
                    <div class="col col-email-label">Bcc:</div>
                    <div :class="{ 'col form-group': true, 'has-error':v$.ccList.$error }">
                        <email-field
                            ref="bccEmailField"
                            label="Bcc"
                            :data-source="emailData"
                            v-model="bccList"
                            @hide="showBcc=false"
                            show-hide-icon
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <div class="rq-container content-wrapper">
            <div class="row">
                <div class="col col-email-label">Subject:</div>
                <div :class="{ 'col form-group':true, 'has-error':v$.subjectValue.$error }">
                    <input automation_id="txt_email_subject"
                        name="subject"
                        v-model="subjectValue"
                        v-on:blur="v$.subjectValue.$touch"
                        class="form-control"
                        autocomplete="email-subject"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-email-label">&nbsp;</div>
                <div class="col">
                    <!--UPLOAD-->
                    <div class="attachments-container">
                        <div class="attachments-form">
                            <RqFileInputButton
                                input-automation-id="file_attachments"
                                name="attachments"
                                label="Add Attachments"
                                @change="onAttachmentsChange"
                                clear-after-change
                                multiple
                            />
                        </div>
                        <div class="attachments-list">
                            <div v-for="(item,index) in attachmentsValue"
                                :key="index"
                                class="badge badge-pill attachment-item"
                                :automation_id="`div_attachment_${index}`">
                                {{item.name}}
                                <rq-icon-button
                                    :automation_id="`btn_remove_attachment_${index}`"
                                    icon="fa fa-times"
                                    @click="removeAttachment(index);"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row message-row">
                <div class="col col-email-label">Message:</div>
                <div class="col" :class="{ 'has-error':v$.body.$error }">
                    <rq-html-editor
                        id="rich_text_editor_dialog"
                        placeholder="Customize your content here..."
                        v-model="body"
                    />
                </div>
            </div>
        </div>
        <div v-show="showDropZone" @dragleave.prevent="onDragEnd" @drop.prevent="onFileDrop" class="attachment-drop-zone">
            <span>Drop to Add Attachment(s)</span>
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from "@vuelidate/core";
    import { required, email } from "@vuelidate/validators";
    import EmailField from "./EmailField";
    import RqHtmlEditor from '@/shared/components/rq/editors/RqHtmlEditor';

    export default {

        name: "EmailForm",

        props: {
            subject: { type: String, default: "" },
            to: { type: String, default: "" },
            cc: { type: String, default: "" },
            bcc: { type: String, default: "" },
            attachments: { type: Array, default: () => [] },
            includeOrderRecipients: { type: Boolean, default: true }
        },

        components: { RqHtmlEditor, EmailField },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            const self = this;
            return {
                toList: [],
                ccList: [],
                bccList: [],
                subjectValue: self.subject,
                body: "",
                attachmentsValue: self.attachments.slice(),
                selectedAttachment: [],
                emailData: [],
                showCc: false,
                showBcc: false,
                emailValidator: email,
                componentDefaultOptions: {},
                showDropZone: false
            };
        },

        computed: {
            orderId() { return _.get(this, "$store.state.orders.orderId", 0); }
        },

        watch: {
            showCc(newValue, oldValue) {
                this.updateDimensions();
                this.ccList = [];
            },
            showBcc(newValue, oldValue) {
                this.updateDimensions();
                this.bccList = [];
            }
        },

        validations() {
            return {
                toList: {
                    requireRecipient (value, vm) {
                        let allEmails = _.concat(vm.toList || [], vm.ccList || [], vm.bccList || []);
                        return !_.isEmpty(allEmails);
                    },
                    $each: { email }
                },
                ccList: { $each: { email } },
                bccList: { $each: { email } },
                subjectValue: { required },
                body: { required }
            };
        },

        created () {
            this.init();
        },

        methods: {
            init() {
                const self = this;
                self.fetchEmailData()
                    .then(() => {
                        self.initializeEmailFields();
                    });
            },

            fetchEmailData() {
                const self = this;
                if(!self.includeOrderRecipients || self.orderId === 0 || _.isNil(self.orderId)) return Promise.resolve([]);
                let apiPromise = self.$api.OrdersApi.getUserEmailList(self.orderId);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.parseData(result);
                        return result;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Failed to retrieve user email list.");
                    });
            },

            parseData(contacts) {
                const self = this;
                self.emailData = [];
                _.each(contacts, contact => {
                    let existing = _.find(self.emailData, { emailAddress: contact.emailAddress });
                    if(existing) {
                        if(existing.contactType !== contact.contactType)
                            existing.contactType += `, ${contact.contactType}`;
                        return;
                    }
                    self.emailData.push(contact);
                });
            },

            initializeEmailFields() {
                const self = this;
                let parseList = val => {
                    if(_.isEmpty(val)) return [];
                    let list = _.isArray(val) ? val : _.isString(val) ? _.split(val, ";") : [];
                    return _.map(list, _.trim);
                };
                self.toList = parseList(self.to);
                self.ccList = parseList(self.cc);
                self.bccList = parseList(self.bcc);
            },

            addAttachments(files) {
                const self = this;
                if(_.isEmpty(files)) return;
                _.each(files, f => { self.attachmentsValue.push({ name: f.name, type: "FILE", content: f }); });
            },

            removeAttachment (index) {
                this.attachmentsValue.splice(index, 1);
            },

            onAttachmentsChange(e) {
                const self = this;
                self.addAttachments(e.target.files);
                self.fileCount = e.target.files.length;
                _.invoke(self, "$refs.emailForm.reset");
            },

            onFileDrop(e) {
                const self = this;
                e.preventDefault();
                self.addAttachments(e.dataTransfer.files);
                self.showDropZone = false;
            },

            onDragEnter(e) {
                let types = _.get(e, "dataTransfer.types", null);
                if(!_.some(types, t => t === "Files")) return;
                this.showDropZone = true;
            },

            onDragEnd(e) {
                if(_.get(e, "relatedTarget.tagName", "") === "IFRAME") return;
                this.showDropZone=false;
            },

            onOk (args) {
                const self = this;
                self.v$.$touch();
                if (!self.v$.$error) {
                    let formData = new FormData();
                    let attachments = [];

                    // Process attachments
                    _.forEach(self.attachmentsValue, a => {
                        if (a.type === "FILE") {
                            formData.append("FILE", a.content, a.name);
                        } else {
                            attachments.push(a);
                        }
                    });

                    let formObj = {
                        to: _.join(self.toList, ";"),
                        cc: _.join(self.ccList, ";"),
                        bcc: _.join(self.bccList, ";"),
                        subject: self.subjectValue,
                        body: self.body,
                        attachments
                    };
                    formData.append("email", JSON.stringify(formObj));

                    let apiPromise = self.$api.UtilitiesApi.sendEmail(formData);
                    return self.$rqBusy.wait(apiPromise)
                        .then(() => {
                            self.$toast.success("Email sent");
                            return true;
                        })
                        .catch(err => {
                            self.$toast.error(`Failed to send email: ${err.errorMessage}`);
                            return false;
                        });
                }

                return false;
            },

            updateDimensions: _.debounce(function() {
                _.invoke(this, "$refs.toEmailField.closeDropDown");
                _.invoke(this, "$refs.ccEmailField.closeDropDown");
                _.invoke(this, "$refs.bccEmailField.closeDropDown");
            }, 300, { leading: true, trailing: true })
        }
    };
</script>
