/***********************************************************
 *  Plugin:         $rq
 *  Description:    Contains globally accessible functions for curated app function and route/navigation scenarios.
************************************************************/
import { UserSecuritySettings } from "@/shared/models/models";
import { UserScreenAccessLevel } from "@/shared/models/enums";
import ReportExecutor from "../components/exago/ReportExecutor";
import { saveHorizonRoute } from "../utilities/Helpers";

const getFileRoute = function(securitySettings, options=null) {

    let orderId = 0;
    let name = null;
    let noAccessDefault = null;

    if(_.isObject(options)) {
        orderId = _.getNumber(options, "orderId", 0);
        name = _.get(options, "name", null);
        noAccessDefault = _.get(options, "noAccessDefault", null);
    }
    else if(isNaN(options) && _.isString(options)) {
        name = options;
    }
    else {
        orderId = _.parseNumber(options, 0);
    }

    let defaultRoute = "oe:main";
    let routes = [
        { name: "oe:main", accessKey:"OrderEntry_ScreenAccess", label:"File Main"},
        { name: "tp:loan:list", accessKey:"Commitment_ScreenAccess", label:"Loans" },
        { name: "policy:loan:list", accessKey:"Policy_ScreenAccess", label:"Policy" },
        { name: "premiums", accessKey:"Premiums_ScreenAccess", label:"Permium Rates" },
        { name: "settlement:payoffs:list", accessKey:"Payoff_ScreenAccess", label:"Payoffs" },
        { name: "settlement:prorations", accessKey:"GenericSettlementStatement_ScreenAccess", label:"Prorations" },
        { name: "o-inv:invoices", accessKey:"Invoices_ScreenAccess",label:"Invoices" },
        { name: "ow:order-workflow", accessKey:"UpdateWorkflow_ScreenAccess",label:"File Workflow" },
        { name: "document-mgmt", accessKey:"Documents_ScreenAccess",label:"Document Management" },
        { name: "check-writing", accessKey:"CheckWriting_ScreenAccess",label:"Check Writing" },
        { name: "ea:consolidated-checks", accessKey:"Consolidated_ScreenAccess",label:"Consolidated Checks"  }
    ];

    if(!_.isEmpty(name) && _.some(routes, { name })) {
        if(_.isNil(noAccessDefault))
            routes = routes.slice(_.findIndex(routes, { name }));
        else {
            let targetRoute = _.remove(routes, { name });
            routes = routes.slice(_.findIndex(routes, { name: noAccessDefault }));
            routes.unshift(...targetRoute);
        }
    }

    let screenAccess = securitySettings.findValues(_.map(routes, "accessKey"));
    let inaccessibleRouteLabel = null;
    let accessibleRouteLabel = null;
    let fileRoute = {};
    let accessWarning = "";
    _.forEach(routes, r => {
        if (!_.isNil(fileRoute.name)) return false;
        if (screenAccess[r.accessKey] === UserScreenAccessLevel.None) {
            if(_.isNil(inaccessibleRouteLabel)) inaccessibleRouteLabel = r.label;
            return;
        }
        fileRoute.name = r.name;
        accessibleRouteLabel = r.label;
    });
    if(_.isNil(fileRoute.name))
        fileRoute.name = _.isEmpty(name) ? defaultRoute : name;
    else if(orderId > 0)
        fileRoute.params = { orderId };
    if(!_.isNil(inaccessibleRouteLabel)) {

        accessWarning = `Access Restricted from ${inaccessibleRouteLabel}, you will be directed to ${accessibleRouteLabel}.`;
    }
    return {
        fileRoute,
        accessWarning
    };
};


const rqAppPlugin = {
    install(app) {

        app.mixin({
            computed: {
                $rq() {
                    const self = this;
                    const saveCurrentRoute = () => saveHorizonRoute(self.$route, self.$store);

                    /***********************************************************
                     *  Examples: *
                     *  goToFile - navigates to the first accessible location in the order presented on the left-nav
                     *
                     *      this.$rq.goToFile(); //goes to the first accessible location of the current file, starting with file-main
                     *      this.$rq.goToFile(333); //goes to the first accessible location for orderId=333, starting with file-main
                     *      this.$rq.goToFile("premiums"); //goes to the first accessible location of the current file, starting with "premiums"
                     *      this.$rq.goToFile({ orderId: 333, name: "premiums" }); //goes to the first accessible location for orderId=333, starting with "premiums"
                     *      this.$rq.goToFile({ orderId: 333, name: "premiums", noAccessDefault: "oe:main" }); //goes to the first accessible location for orderId=333, starting with "premiums" then moving to "oe:main" and proceding down the list
                     *
                    ************************************************************/
                    const goToFile = (options=null) => {
                        let routeInfo = getFileRoute(self.securitySettings, options);
                        if(!_.isEmpty(routeInfo.accessWarning)) {
                            self.$toast.warn(routeInfo.accessWarning);
                        }
                        self.$router.push(routeInfo.fileRoute);
                    };

                    const showReport = (reportPath, reportOptions={}, dialogOptions={}) => {
                        /*
                            reportOptions is a placeholder for whatever additional options or parameters
                            will be established as necessary to pass to different report implementations
                        */
                        let defaultDialogOptions = {
                            height: "85%",
                            width: "85%",
                            okOnly: true,
                            okTitle: "Close",
                        };
                        let dialogOpts = _.defaults({}, dialogOptions, defaultDialogOptions);

                        let executeReport = (path, reportOpts) => {

                            path = _.replaceAll(path, '\\', '/');
                            reportOpts = reportOpts || {};
                            reportOpts.reportPath = _.replaceAll(reportOpts.reportPath, '\\', '/');

                            let reportDisplay = _.trim(_.last(_.split(path, '/')));

                            let reportTitle = !_.isNullOrEmpty(reportOpts.title)
                                ? reportOpts.title
                                : !_.isNullOrEmpty(reportOpts.reportName)
                                    ? reportOpts.reportName
                                    : `Report: ${reportDisplay}`;

                            dialogOpts.title = reportTitle;

                            self.$dialog.open({
                                ...dialogOpts,
                                component: ReportExecutor,
                                props: {
                                    reportPath: path,
                                    reportOptions: reportOpts,
                                },
                            });
                        }

                        // Pre-hooks for augmenting reporting options.
                        let preExecuteHook = _.get(reportOptions, 'preExecuteHook');

                        if (_.isFunction(preExecuteHook)) {
                            preExecuteHook({ reportPath, reportOptions})
                                .then((report) => {
                                    executeReport(reportPath, reportOptions);
                                })
                                .catch(() => {})
                        }
                        else {
                            executeReport(reportPath, reportOptions);
                        }
                    };

                    return { saveCurrentRoute, goToFile, showReport };
                }
            }
        });
    }
};

import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useRqToast } from "./rqToast";
const useRqAppUtils = function() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { toastWarning } = useRqToast();
    const saveCurrentRoute = () => saveHorizonRoute(route, store);
    const goToFile = function(options) {
        let settings = store?.getters?.currentSession?.settings;
        if(_.isNil(settings)) {
            console.warn("Security settings cannot be resolved.");
            return null;
        }
        const securitySettings = new UserSecuritySettings(settings);
        const routeInfo = getFileRoute(securitySettings, options);
        if(!_.isEmpty(routeInfo.accessWarning)) {
            toastWarning(routeInfo.accessWarning);
        }
        router.push(routeInfo.fileRoute);
    }
    return {
        saveCurrentRoute,
        goToFile
    };
}

export {
    rqAppPlugin,
    useRqAppUtils
};
