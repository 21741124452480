import { h, defineComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// More information on the "vue-toastification" package: https://github.com/Maronato/vue-toastification
import { createToastInterface } from "vue-toastification";

const iconComponent = icon => defineComponent(() => {
    return () => h(FontAwesomeIcon, { icon });
});
const CheckCircleComponent = iconComponent("fas fa-check-circle");
const InfoCircleComponent = iconComponent("fas fa-info-circle");
const ExclamationCircleComponent = iconComponent("fas fa-exclamation-circle");

const toastOptions = {
    position: "bottom-right",
    timeout: 3000,
    pauseOnHover: true,
    pauseOnFocusLoss: true,
    hideProgressBar: true,
    transition: "rq-toast-transition",
    toastDefaults: {
        success: { icon: CheckCircleComponent, timeout: 2000 },
        info: { icon: InfoCircleComponent },
        warning: { icon: ExclamationCircleComponent },
        error: { icon: ExclamationCircleComponent },
    }
};

const toast = createToastInterface(toastOptions);

class ToastType {
    static get SUCCESS() { return "success"; }
    static get INFO() { return "info"; }
    static get WARNING() { return "warning"; }
    static get ERROR() { return "error"; }
    static get values() { return ["success", "info", "warning", "error"]; }
}

const normalizeToastType = t => {
    let lowered = _.toLower(t);
    return lowered === "warn"
        ? ToastType.WARNING
        : lowered === "danger"
            ? ToastType.ERROR
            : lowered;
};

const createRqToastInterface = function() {
    const getToastOpts = (obj) => {
        if(_.isString(obj)) {
            return { message: obj, options: {} };
        }
        if(_.isObject(obj)) {
            let message = _.get(obj, "message", null) || "Invalid toast arguments";
            let options = _.pickBy(obj, (v,k) => k !== "message");
            if(_.isBoolean(options.autoHideEnabled) && !options.autoHideEnabled) options.timeout = false;
            return { message, options }
        }
        return { message: "Invalid toast arguments", options: null };
    }

    let showToast = (type, obj) => {
        let normalizedType = normalizeToastType(type);
        if(!_.includes(ToastType.values, normalizedType)) {
            console.error("RqToast :: Invalid toast type: \"%s\"", type);
            return;
        }
        let args = getToastOpts(obj);
        args.options.type = normalizedType;
        return toast(args.message, args.options);
    }

    return {
        showToast,
        success: obj => showToast(ToastType.SUCCESS, obj),
        info: obj => showToast(ToastType.INFO, obj),
        warn: obj => showToast(ToastType.WARNING, obj),
        error: obj => showToast(ToastType.ERROR, obj),
        clear: obj => toast.clear(),
        ToastType
    };
}

const rqToastPlugin = {
    install(app) {
        app.config.globalProperties.$toast = createRqToastInterface();
    }
};

const useRqToast = function() {
    const methods = createRqToastInterface();
    return {
        showToast: methods.showToast,
        toastSuccess: methods.success,
        toastInfo: methods.info,
        toastWarning: methods.warn,
        toastError: methods.error,
        clearToasts: methods.clear,
        ToastType
    }
};

export {
    createRqToastInterface,
    rqToastPlugin,
    useRqToast
};
