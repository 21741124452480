<template>
    <div class="rq-slb-action">
        <span :id="componentId">
            <div v-if="isDropdown" class="dropdown">
                <button
                    :automation_id="action.automation_id"
                    class="btn btn-theme btn-action dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    :disabled="isDisabled()">
                    <span v-html="action.text"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li
                        v-for="childAction in action.children"
                        :key="childAction.key">
                        <button
                            :automation_id="childAction.automation_id"
                            type="button"
                            class="dropdown-item"
                            :disabled="getActionDisabled(childAction)"
                            @click="onActionClick(childAction)">
                            <span>{{childAction.text}}</span>
                        </button>
                    </li>
                </ul>
            </div>
            <b-btn v-else
                :automation_id="action.automation_id"
                variant="theme"
                class="btn-action"
                :disabled="isDisabled()"
                @click="onActionClick()">
                <span class="action-text">{{action.text}}</span>
            </b-btn>
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            action: { type: Object, default: ()=>({}) },
            selectedItems: { type: Array, default: () => [] }
        },

        computed: {
            componentId() { return _.uniqueId("rq-grid-nav-action-"); },
            isDropdown() { return !_.isEmpty(this.action.children); },
            canExecute() { return this.canExecuteAction(); },
            numSelected() { return _.parseNumber(this.selectedItems.length, 0); }
        },

        created() {
            this.bindMousetrap();
        },

        beforeUnmount() {
            this.unbindMousetrap();
        },

        methods: {
            onActionClick(action) {
                let targetAction = action || this.action;
                this.$emit("action-click", targetAction);
            },
            isDisabled() {
                if(_.isBoolean(this.action.disabled)) return this.action.disabled;
                if(!_.isFunction(this.action.disabled)) return false;
                return this.action.disabled({ selectedItems: this.selectedItems.slice() });
            },
            bindMousetrap() {
                const self = this;
                let validActions = _.isEmpty(self.action.shortcutKeys) ? [] : [self.action];
                if(self.isDropdown) {
                    validActions.push(..._.filter(self.action.children, a => !_.isEmpty(a.shortcutKeys)));
                }
                if(_.isEmpty(validActions)) return;
                _.forEach(validActions, a => Mousetrap.bind(a.shortcutKeys, function(e) {
                    self.onActionClick(a);
                    e.preventDefault();
                }));
                self.boundShortcuts = _.map(validActions, a => a.shortcutKeys);
            },
            unbindMousetrap() {
                if(_.isEmpty(this.boundShortcuts)) return;
                _.forEach(this.boundShortcuts, keys => Mousetrap.unbind(keys));
            }
        }
    }

</script>
