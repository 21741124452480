<template>
    <iframe
        v-if="printSrcReady"
        ref="printIframe"
        :src="pdfBlobUrl"
        class="d-none"
        @load="onPrintIframeLoad">
    </iframe>
</template>
<script setup>
    import { ref, computed, nextTick, onBeforeUnmount } from "vue";

    const props = defineProps({
        /* modes: "default" and "immediate" */
        mode: { type: String, default: "default" }
    });

    const emit = defineEmits([
        "print-ready",
        "printed"
    ]);

    const printIframe = ref(null);
    const pdfContent = ref(null);
    const pdfBlobUrl = ref(null);
    const printSrcReady = ref(false);
    const printMode = ref(props.mode);

    const printOnLoad = computed({
        get() { return printMode.value === "immediate"; },
        set(val) { printMode.value = val ? "immediate" : "default"; }
    });

    const hasContent = computed(() => !_.isEmpty(pdfContent.value));

    async function load(options) {
        reset();
        let content = _.isString(options) ? options : options?.content || null;
        if(!_.isEmpty(content)) {
            setContent(content);

            if(_.isBoolean(options?.printOnLoad))
                printOnLoad.value = options.printOnLoad ? "immediate" : "";

            await nextTick();
            createBlobUrl();
        }
        else {
            setContent();
        }
    }

    function setContent(content=null) {
        if(_.isNil(content)) {
            pdfContent.value = null;
            return;
        }
        let base64Content = _.startsWith(content, "data:")
            ? _.split(content, ",").pop()
            : content;

        if(pdfContent.value === base64Content)
            return base64Content;

        pdfContent.value = base64Content;

        return base64Content;
    }

    function createBlobUrl() {
        try {
            if(!_.isNil(pdfBlobUrl.value)) URL.revokeObjectURL(pdfBlobUrl.value);
            pdfBlobUrl.value = _.base64ToObjectUrl(pdfContent.value, "application/pdf");
            printSrcReady.value = true;
        }
        catch(err) {
            console.error(err);
        }
    }

    function print(options=null) {
        let content = _.isString(options)
            ? options
            : options?.content || null;

        if(!_.isEmpty(content)) {
            printOnLoad.value = true;
            load(content);
            return;
        }

        if(!hasContent.value) return;

        if(printSrcReady.value) {
            _.invoke(printIframe, "value.contentWindow.print");
            emit("printed", true);
            return;
        }

        createBlobUrl();
    }

    function reset() {
        printSrcReady.value = false;
        printMode.value = props.mode;

        if(_.isNil(pdfBlobUrl.value)) return;

        URL.revokeObjectURL(pdfBlobUrl.value);
        pdfBlobUrl.value = null;
    }

    function onPrintIframeLoad(e) {
        emit("print-ready");
        if(!printOnLoad.value) return;
        print();
    }

    defineExpose({
        load,
        print
    });

    onBeforeUnmount(() => {
        reset();
    });
</script>