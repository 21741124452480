// root
export const SET_ERROR = "setError";
export const SET_FILE_IN_USE_PROMPT = "setFileInUsePrompt";
export const SET_BUSY = "setBusy";
export const SET_PAGE = "setPage";
export const SET_PAGE_ACCESS_LEVEL = "setPageAccessLevel";
export const SET_PAGE_READ_ONLY = "setPageReadOnly";
export const SET_SAVED_ROUTE = "setSavedRoute";
export const SET_ROUTE_LOOKUPS_REFRESHED = "setRouteLookupsRefreshed";
export const SET_ROUTE_SS_MAPPED = "setRouteSSMapped";

export const SET_BUSY_QUEUE = "rqBusy_SetQueue";
export const SET_BUSY_MESSAGE = "rqBusy_SetMessage";

export const ROOT_MUTATIONS = {
    SET_ERROR,
    SET_FILE_IN_USE_PROMPT,
    SET_BUSY,
    SET_PAGE,
    SET_PAGE_ACCESS_LEVEL,
    SET_PAGE_READ_ONLY,
    SET_SAVED_ROUTE,
    SET_ROUTE_LOOKUPS_REFRESHED,
    SET_ROUTE_SS_MAPPED,
    SET_BUSY_QUEUE,
    SET_BUSY_MESSAGE
};

//shared
export const SET_LOOKUPS = "setLookups";

// authorization
export const SET_IDENTITY_AUTH = "setIdentityAuth";
export const PURGE_AUTH = "purgeAuth";
export const SET_AUTH = "setAuth";
export const SET_SESSION = "setSession";
export const SET_SYSTEM = "setSystem";
export const SET_SESSION_USER = "setUserDefaultCategory";
export const SET_USER_REGIONS = "setUserRegions";
export const SET_USER_DICTIONARY = "setUserDictionary";
export const SET_WALK_ME_VAR_REFERENCE = "setWalkMeVarReference";
export const AUTH_MUTATIONS = {
    SET_IDENTITY_AUTH,
    PURGE_AUTH,
    SET_AUTH,
    SET_SESSION,
    SET_SYSTEM,
    SET_SESSION_USER,
    SET_USER_REGIONS,
    SET_USER_DICTIONARY,
    SET_WALK_ME_VAR_REFERENCE
};

// navigation
export const ADD_DISABLED_ITEM_ID = "addDisabledItemId";
export const REMOVE_DISABLED_ITEM_ID = "removeDisabledItemId";
export const SET_DISABLED_ITEM_IDS = "setDisabledItemIds";
export const ADD_INACTIVE_ITEM_ID = "addInactiveItemId";
export const REMOVE_INACTIVE_ITEM_ID = "removeInactiveItemId";
export const SET_INACTIVE_ITEM_IDS = "setInactiveItemIds";
export const SET_NAV_ITEMS = "setNavItems";
export const UPDATE_NAV_ITEMS = "updateNavItems";
export const SET_MEGA_MENU_STATE = "setMegaMenuState";
export const SET_UNREAD_ALERT_COUNT = "setUndreadAlertCount";
export const NAV_MUTATIONS = {
    ADD_DISABLED_ITEM_ID,
    REMOVE_DISABLED_ITEM_ID,
    ADD_INACTIVE_ITEM_ID,
    REMOVE_INACTIVE_ITEM_ID,
    SET_DISABLED_ITEM_IDS,
    SET_INACTIVE_ITEM_IDS,
    SET_NAV_ITEMS,
    UPDATE_NAV_ITEMS,
    SET_MEGA_MENU_STATE,
    SET_UNREAD_ALERT_COUNT
};

// system
export const SET_CONFIG_SEARCH = "setConfigSearch";
export const SET_ORDER_FACET_SEARCH = "setOrderFacetSearch";
export const SET_EBANK_RECON_SEARCH = "setEbankReconSearch";
export const SET_RECONCILIATION_SEARCH = "setReconciliationSearch";
export const SET_CONSOLIDATED_CHECKS_SEARCH = "setConsolidatedChecksSearch";
export const SET_SYSTEM_DATA = "setSystemData";
export const SET_SYSTEM_DEFAULTS = "setSystemDefaults";
export const SET_SYSTEM_DEFAULT_DATA = "setSystemDefaultData";
export const SET_SYSTEM_LOOKUP_COLLECTION = "setSystemLookupCollection";
export const SET_GLOBAL_REGION_ID = "setGlobalRegionID";
export const SET_SYSTEM_LOOKUP_DOCUMENT_TAGS = "setSystemLookupDocumentTags";
export const SET_SYSTEM_LOOKUP_STANDARD_LANGUAGE_CATEGORIES = "setSystemLookupClauseCategories";
// export const SET_SYSTEM_LOOKUP_SUBDIVISIONS = "setSystemLookupSubdivisions";
// export const SET_SYSTEM_LOOKUP_SURVEYS = "setSystemLookupSurveys";
export const SET_SHOW_DOC_MGMT_PREVIEW = "setShowDocMgmtPreview";
export const SET_DOC_MGMT_GRP_BY_CATEGORY = "setDocMgmtGroupByCategory"
export const SET_SYSTEM_LOOKUPS = "setSystemLookups";

export const SYSTEM_MUTATIONS = {
    SET_SYSTEM_DATA,
    SET_SYSTEM_DEFAULTS,
    SET_SYSTEM_DEFAULT_DATA,
    SET_SYSTEM_LOOKUP_COLLECTION,
    SET_GLOBAL_REGION_ID,
    SET_SYSTEM_LOOKUP_DOCUMENT_TAGS,
    SET_SYSTEM_LOOKUP_STANDARD_LANGUAGE_CATEGORIES,
    // SET_SYSTEM_LOOKUP_SUBDIVISIONS,
    // SET_SYSTEM_LOOKUP_SURVEYS,
    SET_CONFIG_SEARCH,
    SET_SHOW_DOC_MGMT_PREVIEW,
    SET_DOC_MGMT_GRP_BY_CATEGORY,
    SET_ORDER_FACET_SEARCH,
    SET_EBANK_RECON_SEARCH,
    SET_RECONCILIATION_SEARCH,
    SET_CONSOLIDATED_CHECKS_SEARCH,
    SET_SYSTEM_LOOKUPS
};

//loans
export const ADD_ORDER_LOAN = "addOrderLoan";
export const REMOVE_ORDER_LOAN = "removeOrderLoan";
export const SET_ORDER_LOANS = "setOrderLoans";
export const UPDATE_ORDER_LOAN = "updateOrderLoan";
export const LOAN_MUTATIONS = {
    ADD_ORDER_LOAN,
    REMOVE_ORDER_LOAN,
    SET_ORDER_LOANS,
    UPDATE_ORDER_LOAN
};

//invoices
export const SET_ORDER_INVOICES = "setOrderInvoices";
export const INVOICE_MUTATIONS = {
    SET_ORDER_INVOICES
};

//commitment
export const ADD_ASSIGNED_LANGUAGE = "addAssignedLanguage";
export const REMOVE_ASSIGNED_LANGUAGE = "removeAssignedLanguage";
export const SET_ASSIGNED_LANGUAGES = "setAssignedLanguages";
export const SET_CONTENT_CHANGE_PENDING = "setContentChangePending";
export const SET_COMMITMENT_DATA = "setCommitmentData";
export const SET_COMMITMENT_DEFAULTS = "setCommitmentDefaults";
export const SET_CUSTOM_TABS = "setCustomTabs";
export const SET_SCHEDULE_D = "setScheduleD";
export const SET_STANDARD_LANGUAGE_SECTIONS = "setStandardLanguageSections";
export const SET_USERS_WITH_SIGNATURES = "setUsersWithSigantures";
export const UPDATE_ASSIGNED_LANGUAGE = "updateAssignedLanguage";
export const UPDATE_ASSIGNED_LANGUAGE_RANGE = "updateAssignedLanguageRange";
export const COMMITMENT_MUTATIONS = {
    ADD_ASSIGNED_LANGUAGE,
    REMOVE_ASSIGNED_LANGUAGE,
    SET_ASSIGNED_LANGUAGES,
    SET_CONTENT_CHANGE_PENDING,
    SET_COMMITMENT_DATA,
    SET_COMMITMENT_DEFAULTS,
    SET_CUSTOM_TABS,
    SET_SCHEDULE_D,
    SET_STANDARD_LANGUAGE_SECTIONS,
    SET_USERS_WITH_SIGNATURES,
    UPDATE_ASSIGNED_LANGUAGE,
    UPDATE_ASSIGNED_LANGUAGE_RANGE
};

//properties
export const ADD_ORDER_PROPERTY = "addOrderProperty";
export const REMOVE_ORDER_PROPERTY = "removeOrderProperty";
export const SET_BRIEF_LEGAL_DESCRIPTION = "setBriefLegalDescription";
export const SET_PROPERTIES = "setProperties";
export const SET_PROPERTY_LAYOUTS = "setPropertyLayouts";
export const SET_PROPERTY_LOOKUPS = "setPropertyLookups";
export const UPDATE_ORDER_PROPERTY = "updateOrderProperty";
export const PROPERTY_MUTATIONS = {
    ADD_ORDER_PROPERTY,
    REMOVE_ORDER_PROPERTY,
    SET_BRIEF_LEGAL_DESCRIPTION,
    SET_PROPERTIES,
    UPDATE_ORDER_PROPERTY
};

// orders
export const SET_DUPLICATE_SEARCH_SETUP = "setDuplicateSearchSetup";
export const SET_NEXT_FILE_NUMBER = "setNextFileNumber";
export const SET_CHECK_WRITING_IN_USE_PROMPT = "setCheckWritingInUsePrompt";
export const SET_ORDER = "setOrder";
export const SET_ORDER_ID = "setOrderId";
export const SET_ORDER_LOOKUPS = "setOrderLookups";
export const SET_ORDER_PROPERTIES = "setOrderProperties";
export const SET_ORDER_REGION = "setOrderRegion";
export const SET_ORDER_BRANCH = "setOrderBranch";
export const SET_ORDER_STATUS = "setOrderStatus";
export const SET_ORDER_SUMMARY = "setOrderSummary";
export const SET_ORDER_SUMMARY_ALL_PROPS_ON_SS = "setOrderSummaryAllPropsOnSS";
export const INSERT_ORDER_CRITICAL_NOTE = "insertOrderCriticalNotes";
export const SET_ORDER_CRITICAL_NOTES = "setOrderCriticalNotes";
export const SET_ORDER_DNDBW_WARNINGS = "setOrderDndbwWarnings";
export const SET_ORDER_SECONDARY_FILE_LIST = "setSecondaryFileList";
export const SET_ORDER_CRITICAL_NOTE_MODAL_OPEN = "setOrderCriticalNoteModalOpen";
export const UPDATE_ORDER_SETTING_CHANGE_COUNT = "updateOrderSettingChangeCount";
export const INSERT_ORDER_TIME_TRACKER = "insertOrderTimeTracker";
export const DELETE_ORDER_TIME_TRACKER = "deleteOrderTimeTracker";
export const ORDER_MUTATIONS = {
    ADD_ORDER_LOAN,
    ADD_ORDER_PROPERTY,
    REMOVE_ORDER_LOAN,
    REMOVE_ORDER_PROPERTY,
    SET_CHECK_WRITING_IN_USE_PROMPT,
    SET_DUPLICATE_SEARCH_SETUP,
    SET_NEXT_FILE_NUMBER,
    SET_ORDER,
    SET_ORDER_ID,
    SET_ORDER_LOANS,
    SET_ORDER_LOOKUPS,
    SET_ORDER_PROPERTIES,
    SET_ORDER_REGION,
    SET_ORDER_BRANCH,
    SET_ORDER_STATUS,
    SET_ORDER_SUMMARY,
    SET_ORDER_SUMMARY_ALL_PROPS_ON_SS,
    INSERT_ORDER_CRITICAL_NOTE,
    SET_ORDER_CRITICAL_NOTES,
    SET_ORDER_DNDBW_WARNINGS,
    SET_ORDER_SECONDARY_FILE_LIST,
    SET_ORDER_CRITICAL_NOTE_MODAL_OPEN,
    UPDATE_ORDER_LOAN,
    UPDATE_ORDER_SETTING_CHANGE_COUNT,
    INSERT_ORDER_TIME_TRACKER,
    DELETE_ORDER_TIME_TRACKER,
    ...LOAN_MUTATIONS,
    ...COMMITMENT_MUTATIONS
};


// PrepaidSectionData
export const SET_PREPAID_SECTION_DATA = "setPrepaidSectionData";
export const ADD_PREPAID_SECTION_DATA = "addPrepaidSectionData";
export const UPDATE_PREPAID_SECTION_DATA = "updatePrepaidSectionData";
export const REMOVE_PREPAID_SECTION_DATA = "removePrepaidSectionData";
export const PREPAID_SECTION_DATA_MUTATIONS = {
    SET_PREPAID_SECTION_DATA,
    UPDATE_PREPAID_SECTION_DATA,
    REMOVE_PREPAID_SECTION_DATA
};

// settlement- RQO-5366 moved to system lookup store
export const SET_PAYOFF_DATA = "setPayoffData";
export const ADD_PAYOFF = "addPayoff";
export const UPDATE_PAYOFF = "updatePayoff";
export const REMOVE_PAYOFF = "removePayoff";
export const SET_SELECTED_LOAN_ID = "setSelectedLoanId";
export const SETTLEMENT_MUTATIONS = {
    SET_PAYOFF_DATA,
    ADD_PAYOFF,
    UPDATE_PAYOFF,
    REMOVE_PAYOFF,
    SET_SELECTED_LOAN_ID
};

// recording fees and taxes; recording information
export const SET_LOANS_AND_PROPERTIES = "setLoansAndProperties";
export const SET_TOTAL_AMOUNT_VALUE = "setTotalAmountValue";
export const SET_RECORDING_INFORMATION = "setRecordingInformation";
export const ADD_RECORDING_INFORMATION = "addRecordingInformation";
export const UPDATE_RECORDING_INFORMATION = "updateRecordingInformation";
export const REMOVE_RECORDING_INFORMATION = "removeRecordingInformation";
export const SET_RECORDING_FEE_CALCULATIONS = "setRecordingFeeCalculations";
export const ADD_RECORDING_FEE_CALCULATION = "addRecordingFeeCalculation";
export const UPDATE_RECORDING_FEE_CALCULATION = "updateRecordingFeeCalculation";
export const REMOVE_RECORDING_FEE_CALCULATION = "removeRecordingFeeCalculation";
export const SET_TRANSFER_TAX_CALCULATIONS = "setTransferTaxCalculations";
export const ADD_TRANSFER_TAX_CALCULATION = "addTransferTaxCalculation";
export const UPDATE_TRANSFER_TAX_CALCULATION = "updateTransferTaxCalculation";
export const REMOVE_TRANSFER_TAX_CALCULATION = "removeTransferTaxCalculation";
export const RECORDING_MUTATIONS = {
    SET_LOOKUPS,
    SET_LOANS_AND_PROPERTIES,
    SET_TOTAL_AMOUNT_VALUE,
    SET_RECORDING_INFORMATION,
    ADD_RECORDING_INFORMATION,
    UPDATE_RECORDING_INFORMATION,
    REMOVE_RECORDING_INFORMATION,
    SET_RECORDING_FEE_CALCULATIONS,
    ADD_RECORDING_FEE_CALCULATION,
    UPDATE_RECORDING_FEE_CALCULATION,
    REMOVE_RECORDING_FEE_CALCULATION,
    SET_TRANSFER_TAX_CALCULATIONS,
    ADD_TRANSFER_TAX_CALCULATION,
    UPDATE_TRANSFER_TAX_CALCULATION,
    REMOVE_TRANSFER_TAX_CALCULATION
};

// rates/premiums
export const SET_RATE_ORDER_INFO = "setrateOrderInfo";
export const SET_RATES = "setRates";
export const SET_RATES_BY_DATE = "setRatesByDate";
export const ADD_OUTSIDE_PARTY = "addOutsideParty";
export const REMOVE_OUTSIDE_PARTY = "removeOutsideParty";
export const SET_OUTSIDE_PARTIES = "setOutsideParties";
export const SET_PREMIUMS = "setPremiums";
export const SET_CDF_PREMIUMS = "setCdfPremiums";
export const SET_RATE_DATA = "setRateData";
export const SET_ALERTS = "setAlerts";
export const ADD_ALERT = "addAlerts";
export const REMOVE_ALERT = "removeAlerts";
export const REMOVE_ALERTS_BY_TARGET = "removeAlertsByTarget";
export const CLEAR_GENERIC_ALERTS = "clearGenericAlerts";
export const SET_ORDER_ENDORSEMENTS = "setOrderEndorsements";
export const UPDATE_ORDER_ENDORSEMENT = "updateOrderEndorsement";
export const ADD_ORDER_ENDORSEMENTS = "addOrderEndorsements";
export const REMOVE_ORDER_ENDORSEMENT = "removeOrderEndorsement";

export const RATE_MUTATIONS = {
    SET_RATE_ORDER_INFO,
    SET_RATES,
    SET_RATES_BY_DATE,
    ADD_OUTSIDE_PARTY,
    REMOVE_OUTSIDE_PARTY,
    SET_OUTSIDE_PARTIES,
    SET_PREMIUMS,
    SET_CDF_PREMIUMS,
    SET_RATE_DATA,
    SET_ALERTS,
    ADD_ALERT,
    REMOVE_ALERT,
    REMOVE_ALERTS_BY_TARGET,
    CLEAR_GENERIC_ALERTS,
    SET_ORDER_ENDORSEMENTS,
    ADD_ORDER_ENDORSEMENTS,
    UPDATE_ORDER_ENDORSEMENT,
    REMOVE_ORDER_ENDORSEMENT
};

// realtor commission
export const SET_REALTOR_COMMISSION = "setRealtorCommission";
export const REMOVE_REALTOR_COMMISSION_PAYEE = "removeRealtorCommissionPayee";

export const REALTOR_COMMISSION_MUTATIONS = {
    SET_REALTOR_COMMISSION,
    REMOVE_REALTOR_COMMISSION_PAYEE
};

// documents
export const DOCUMENT_MUTATIONS = {
    SET_LOAN_ID: "setLoanId",
    SET_CSS_MAIN_ID: "setCssMainId",
    SET_INVOICE_ID: "setInvoiceId",
    SET_QUEUED_DOCUMENTS: "setQueuedDocuments",
    SET_ACTIVE_PROMPT_DOCUMENTS: "setActivePromptDocuments",
    SET_SAVED_ACTIVE_PROMPTS: "setSavedActivePrompts",
    ADD_DOC_TO_QUEUE: "addDocToQueue",
    ADD_LIST_TO_QUEUE: "addListToQueue",
    REMOVE_DOC_FROM_QUEUE: "removeDocFromQueue",
    REMOVE_DOC_FROM_QUEUE_AT_INDEX: "removeDocFromQueueAtIndex",
    REMOVE_PKG_FROM_QUEUE: "removePkgFromQueue",
    ADD_SAVED_ACTIVE_PROMPT: "addSavedActivePrompt",
    SET_STANDARD_LANGUAGE_CATEGORIES: "setStandardLanguageCategories"
};

export const SET_ORDER_CONTACTS = "setOrderContacts";
export const REMOVE_ORDER_CONTACT = "removeOrderContact";
export const CONTACT_MUTATIONS = {
    SET_ORDER_CONTACTS,
    REMOVE_ORDER_CONTACT
};

export const SET_NOTIFICATION_INFO = "SetTaskNotificationInfo";
export const SET_LAST_UPDATE = "SetLastNotificationUpdate";
export const WORKFLOW_MUTATIONS = {
    SET_NOTIFICATION_INFO,
    SET_LAST_UPDATE
};

export const RQ_SECTION_MUTATIONS = {
    SET_SECTION: "setSectionState",
    SET_GROUP: "setSectionGroup",
    REMOVE_SECTION: "removeSectionState",
    REMOVE_GROUP: "removeSectionGroup",
    SET_GROUP_VALUES: "setSectionGroupValues",
    RESET_STATE: "resetRqSectionsState"
};

export const SET_EXAGO_SESSION = "setExagoSession";
export const SET_EXAGO_REPORTS = "setExagoReports";
export const SET_EXAGO_RENDERED = "setExagoRendered";
export const SET_USE_LOGI_REPORTS = "setUseLogiReports";
export const SET_REPORT_API_BASE_URL = "setReportApiBaseUrl";
export const SET_REPORT_APP_BASE_URL = "setReportAppBaseUrl";
export const SET_REPORT_ACCESS = "setReportAccess";
export const EXAGO_SESSION_MUTATIONS = {
    SET_SESSION: SET_EXAGO_SESSION,
    SET_REPORTS: SET_EXAGO_REPORTS,
    SET_RENDERED: SET_EXAGO_RENDERED,
    SET_USE_LOGI_REPORTS: SET_USE_LOGI_REPORTS,
    SET_REPORT_API_BASE_URL: SET_REPORT_API_BASE_URL,
    SET_REPORT_APP_BASE_URL: SET_REPORT_APP_BASE_URL,
    SET_REPORT_ACCESS: SET_REPORT_ACCESS
};

// Reports
export const REPORTS_SET_PARAMETER_VALUE = "setParameterValue";
export const REPORTS_CLEAR_PARAMETER_VALUES = "clearParameterValues";
export const REPORTS_SET_DEPARTMENT_USERS_AVAILABLE = "setDepartmentUsersAvailable";
export const REPORTS_SET_PAYEE_COMPANY_CONTACTS = "setPayeeCompanyContacts";
export const REPORTS_SET_PERSONS = "setPersons";
export const REPORTS_SET_SECURITY_ROLES = "setSecurityRoles";
export const REPORTS_SET_ALL_COMPANY_ROLES = "setAllCompanyRoles";
export const REPORTS_SET_CUSTOMERS = "setCustomers";
export const REPORTS_SET_BUYERS_SELLERS = "setBuyersSellers";
export const REPORTS_SET_COMPANY_CONTACTS = "setCompanyContacts";
export const REPORTS_SET_BEGINNING_BALANCE = "setBeginningBalance";
export const REPORTS_SET_PARAMETERS_KEYS = "setParametersKeys";
export const REPORTS_SET_REPORT_INFO = "setReportInfo";
export const REPORT_MUTATIONS= {
    CLEAR_PARAMETER_VALUES: REPORTS_CLEAR_PARAMETER_VALUES,
    SET_PARAMETER_VALUE: REPORTS_SET_PARAMETER_VALUE,
    SET_DEPARTMENT_USERS_AVAILABLE: REPORTS_SET_DEPARTMENT_USERS_AVAILABLE,
    SET_PAYEE_COMPANY_CONTACTS: REPORTS_SET_PAYEE_COMPANY_CONTACTS,
    SET_PERSONS: REPORTS_SET_PERSONS,
    SET_SECURITY_ROLES: REPORTS_SET_SECURITY_ROLES,
    SET_ALL_COMPANY_ROLES: REPORTS_SET_ALL_COMPANY_ROLES,
    SET_CUSTOMERS: REPORTS_SET_CUSTOMERS,
    SET_BUYERS_SELLERS: REPORTS_SET_BUYERS_SELLERS,
    SET_COMPANY_CONTACTS: REPORTS_SET_COMPANY_CONTACTS,
    SET_BEGINNING_BALANCE: REPORTS_SET_BEGINNING_BALANCE,
    SET_PARAMETERS_KEYS: REPORTS_SET_PARAMETERS_KEYS,
    SET_REPORT_INFO: REPORTS_SET_REPORT_INFO,
};

export const CLAUSE_MANAGEMENT_MUTATIONS = {
    SET_CONTENT_CHANGE_PENDING: "setClauseContentChangePending",
    SET_CLAUSE_GROUP: "setClauseGroup",
    CLEAR_GROUPS: "clearClauseGroups"
};