<template>
    <li ref="componentElement" :class="itemClassAttr">

        <div class="clause-item">
            <div
                v-if="sortEnabled"
                class="clause-drag-handle">
                <div class="handle-icon">
                    <FontAwesomeIcon icon="far fa-grip-dots"/>
                </div>
            </div>
            <div class="clause-info">
                <div class="header">
                    <div class="header-title">
                        <span class="clause-code">{{localItem.code}}</span>
                        <span class="clause-title-divider">-</span>
                        <span class="clause-description">{{localItem.description}}</span>
                        <span v-if="localItem.notes"
                            :id="notesIconId"
                            class="clause-description-notes ms-2"
                            @mouseenter="showNote"
                            @mouseleave="hideNote">
                            <FontAwesomeIcon icon="fas fa-info-circle" />
                        </span>
                        <span v-for="badge in item.visibleBadges" :key="badge.key" class="badge badge-pill badge-theme mx-2">{{badge.text}}</span>
                        <span
                            v-if="item.canOverrideListFormat"
                            v-show="item.overrideListFormat"
                            class="badge badge-pill badge-theme mx-2">
                            {{item.listStyleOverrideDisplay}}
                            <span
                                v-if="!readOnly"
                                class="remove-badge"
                                title="Remove List Style Override"
                                @click="onRemoveListFormatOverride"
                                v-rq-tooltip.hover.top>
                                <FontAwesomeIcon icon="fas fa-times" />
                            </span>
                        </span>
                        <!-- <b-btn
                            v-if="item.canOverrideListFormat"
                            v-show="item.overrideListFormat"
                            variant="link"
                            class="mr-auto"
                            :disabled="readOnly"
                            @click="onRemoveListFormatOverride">Remove List Style Override
                        </b-btn> -->
                    </div>
                    <div class="icon-actions">
                        <clause-toolbar
                            v-if="!sortEnabled || !sortingHidesActions"
                            :actions="actions"
                            @action-click="onToolbarActionClick"
                        />
                        <rq-icon-button
                            id="btn_toggle_collapse"
                            icon="fas fa-caret-down"
                            :tooltip-text="isCollapsed ? 'Expand Item' : 'Collapse Item'"
                            :rotate="isCollapsed ? 90 : 0"
                            @click="isCollapsed = !isCollapsed"
                        />
                    </div>
                </div>

                <b-collapse
                    ref="collapseComponent"
                    :id="`${item.clientKey}_collapse`"
                    class="clause-collapse mt-2 pe-4"
                    :visible="isExpanded">
                    <rq-html-editor
                        v-if="inlineEditable && editorEnabled"
                        ref="htmlEditor"
                        :placeholder="contentPlaceholder"
                        :strike-through="strikeThrough"
                        v-model="itemContent"
                        @focus="onHtmlEditorFocus"
                        @blur="onHtmlEditorBlur"
                        @change="onHtmlEditorChange"
                        show-insert-doc-link
                        inline
                    />
                    <rq-shadow-dom-element
                        v-else-if="hasContent"
                        class="mb-2"
                        mode="open"
                        :min-height="20"
                        :html-content="itemContent"
                        :custom-css="itemCustomCss"
                        @content-click="onContentClick"
                    />
                    <div v-else
                        class="clause-content-empty"
                        @click="onContentClick">
                        {{contentPlaceholder}}
                    </div>
                </b-collapse>
            </div>
            <div class="clause-item-hover-overlay"></div>
        </div>
        <div class="clause-hover-overlay"></div>
        <slot></slot>
    </li>
</template>

<script setup>
    import { ref, computed, inject, onMounted, onBeforeUnmount, nextTick, watch } from "vue";
    import { useVModel } from "@vueuse/core";
    import ClauseToolbar from "./ClauseToolbar";
    import { ClauseListItemModel, LIST_EVENTS } from "@/shared/models/clause-management";
    import { GlobalEventManager } from "@/app.events";

    const props = defineProps({
        item: { type: ClauseListItemModel, default: () => new ClauseListItemModel() },
        actions: { type: Array, default: () => [] },
        sortEnabled: { type: Boolean, default: false },
        inlineEditable: { type: Boolean, default: false },
        readOnly: { type: Boolean, default: false },
        sortingHidesActions: { type: Boolean, default: false }
    });

    const emit = defineEmits([
        "update:item",
        "action",
        "item-changed",
        "change-pending",
        "content-click"
    ]);

    const groupId = inject("groupId", 0);

    const contentPlaceholder = "Customize clause content here or click the edit icon for advanced editing features...";

    const componentElement = ref(null);
    const htmlEditor = ref(null);
    const localChangePending = ref(false);
    const editActive = ref(false);

    const localItem = useVModel(props, "item", emit);

    const itemContent = ref(null);
    const hasContent = computed(() => {
        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(itemContent.value, "text/html");
        return !_.isEmpty(itemContent.value) && !_.isEmpty(htmlDoc?.documentElement?.innerText?.trim());
    });
    const notesIconId = computed(() => `clause-note-${localItem.value.clientKey}`);
    const editorEnabled = computed(() => props.inlineEditable && !props.sortEnabled && editActive.value);
    const itemClassAttr = computed(() => ({
        "clause-node": true,
        "clause-node-clickable": props.inlineEditable && !localItem.value.isEditing,
        "clause-node-editing": localItem.value.isEditing,
        "clause-node-collapsed": isCollapsed.value
    }));

    watch(() => localItem.value?.htmlText, newVal => {
        if(newVal === itemContent.value) return;
        itemContent.value = newVal;
    }, { immediate: true });

    const isCollapsed = computed({
        get() { return localItem.value.isCollapsed; },
        set(val) { localItem.value.isCollapsed = val; }
    });

    const isExpanded = computed({
        get() { return !isCollapsed.value; },
        set(val) { isCollapsed.value = !val; }
    });

    const strikeThrough = computed(() => !localItem.value.isEditing && localItem.value.intentionallyDeleted);
    const itemCustomCss = computed(() => strikeThrough.value ? " :host { text-decoration: line-through !important; }" : "")
    function onGlobalAction(e) {
        if(e?.action !== "set-editing-state"
            && e?.groupId !== groupId) return;

        switch(e.action) {
            case "set-editing-state":
                setEditingState(e?.groupId, e?.itemId);
                break;
            case "set-collapsed-value":
                isCollapsed.value = e.value;
                break;
            case "refresh-editor":
                refreshEditor();
                break;
        }
    }

    function onToolbarActionClick(e) {
        emit("action", { actionKey: e.actionKey, item: localItem.value });
    }

    function onRemoveListFormatOverride(e) {
        localItem.value.removeListFormatOverride();
        emit("item-changed", { item: localItem.value });
    }

    function onHtmlEditorFocus(e) {
        localItem.value.isEditing = true;
        localItem.value.isSelected = true;
    }

    function onHtmlEditorBlur(e) {
        editActive.value = false;
        localItem.value.isEditing = false;
        localItem.value.isSelected = false;
        if(!localChangePending.value) return;
        localChangePending.value = false;
        emit("item-changed", { item: localItem.value, html: e.content, isContentChange: true });
    }

    function onHtmlEditorChange(e) {
        if(!localItem.value.isEditing) return;
        localChangePending.value = true;
        emit("change-pending", { value: true });
    }

    async function setEditingState(targetGroup=null, targetItemId=null) {
        editActive.value = !_.isNil(targetGroup)
            && !_.isNil(targetItemId)
            && targetGroup === groupId
            && targetItemId === localItem.value?.id;

        localItem.value.isEditing = editActive.value;
        localItem.value.isSelected = editActive.value;
        if(!editActive.value) return;
        await nextTick();
        htmlEditor.value?.focus?.();
    }

    function refreshEditor() {
        if(isCollapsed.value) return;
        // RQO-14447 - this is a workaround to an existing bug with the TinyMCE vue component: https://github.com/tinymce/tinymce-vue/issues/230
        htmlEditor.value?.refresh();
    }

    function toggleCollapse(val=null) {
        isCollapsed.value = _.isNil(val) ? !isCollapsed.value : val;
    }

    function onContentClick(e) {
        emit("content-click", { itemId: localItem.value?.id });
    }

    function updateScrollState() {
        if(!localItem.value.scrollIntoView) return;
        componentElement.value.scrollIntoView({ behavior: "smooth", block: "center" });
        localItem.value.scrollIntoView = false;
    }

    const emitToggleNoteAction = (item=null) => emit("action", {
        actionKey: "toggle-note",
        target: notesIconId.value,
        item
    });

    const showNote = () => emitToggleNoteAction(localItem.value);
    const hideNote = () => emitToggleNoteAction();

    onMounted(() => {
        localItem.value.isMounted = true;
        GlobalEventManager.on(LIST_EVENTS.GLOBAL_ACTION, onGlobalAction);
        updateScrollState();
    });

    onBeforeUnmount(() => {
        GlobalEventManager.off(LIST_EVENTS.GLOBAL_ACTION, onGlobalAction);
    });

    defineExpose({
        refreshEditor,
        toggleCollapse
    });
</script>