
export const ADD_FILE = {
    automation_id: "btn_add_file",
    key: "add-file",
    text: "Add File",
    tooltip: "Create New File",
    permissionKeys: ["AllowCreateOrder"]
};
export const ADD_SECONDARY_FILE = {
    automation_id: "btn_add_secondary_file",
    key: "add-secondary-file",
    text: "Add Secondary File",
    tooltip: "Create New Secondary File",
    permissionKeys: ["AllowSecondaryFileCreate"],
    disableWhenReadOnly: true
};
export const ADD_FILE_GROUP = {
    automation_id: "btn_add_file_group",
    key: "add-file-group",
    text: "Add File",
    tooltip: "Create New File",
    children: [ADD_FILE, ADD_SECONDARY_FILE]
};
export const SAVE = {
    automation_id: "btn_save",
    key: "save",
    text: "Save",
    tooltip: "Save [entity]",
    disableWhenReadOnly: true
};
export const CANCEL = {
    automation_id: "btn_cancel",
    key: "cancel",
    text: "Cancel",
    tooltip: "Cancel/Discard Changes",
    //disableWhenReadOnly: true
};
export const VIEW_DOCS = {
    automation_id: "btn_print",
    key: "print",
    text: "View Docs",
    tooltip: "View Docs",
    accessKey: "Documents_ScreenAccess",
    disableWhenReadOnly: false
};
export const APPLY_TEMPLATE = {
    automation_id: "btn_apply_template",
    key: "applyTemplate",
    text: "Apply Template",
    tooltip: "Apply Template",
    permissionKeys: ["CanUseOrderTemplate"],
    disableWhenReadOnly: true
};

export const PUBLISH = {
    automation_id: "btn_r2c_publish",
    key: "publishToR2C",
    text: "Publish to r2c",
    tooltip: "Publish to ready2close",
    disableWhenReadOnly: false,
};
export const UNPUBLISH = {
    automation_id: "btn_r2c_unpublish",
    key: "unpublishFromR2C",
    text: "Unpublish from ready2close",
    tooltip: "Unpublish from ready2close",
    disableWhenReadOnly: false,
};
export const DOWNLOAD_R2C_ACTIVITY = {
    automation_id: "btn_r2c_download_activity",
    key: "downloadR2CActivity",
    text: "Generate Activity Report",
    tooltip: "Generate Activity Report",
    disableWhenReadOnly: false,
};
export const OPEN_IN_R2C = {
    automation_id: "btn_r2c_open",
    key: "viewInR2C",
    text: "Open File in ready2close",
    tooltip: "Open File in ready2close",
    disableWhenReadOnly: false,
};
export const PUSH_TO_R2C = {
    automation_id: "btn_r2c_push",
    key: "pushToR2C",
    text: "Push File to ready2close",
    tooltip: "Push File to ready2close",
    disableWhenReadOnly: false,
};
export const READY2CLOSE = {
    automation_id: "btn_r2c",
    key: "ready2close",
    text: "ready2close",
    tooltip: "ready2close",
    disableWhenReadOnly: false,
    children: [UNPUBLISH, DOWNLOAD_R2C_ACTIVITY, OPEN_IN_R2C, PUSH_TO_R2C]
};

export const ADD_NOTE = (category="") => ({
    automation_id: "btn_add_note",
    key: "addNote",
    text: _.isEmpty(category) ? "Add Note" : `Add ${category} Note`,
    tooltip: "Create new Note",
    accessKey: "Notes_ScreenAccess",
    disableWhenReadOnly: false
});
export const VIEW_NOTES = (category="") => ({
    automation_id: "btn_view_notes",
    key: "viewNotes",
    text: _.isEmpty(category) ? "View Notes" : `View ${category} Notes`,
    tooltip: _.isEmpty(category) ? "View Notes" : `View ${category} Notes`,
    disableWhenReadOnly: false
});
export const MANAGE_NOTES = (category="") => ({
    automation_id: "btn_manage_notes",
    key: "manageNotes",
    text: "Manage Notes",
    tooltip: _.isEmpty(category) ? "Manage Notes" : `Manage ${category} Notes`,
    accessKey: "Notes_ScreenAccess",
    disableWhenReadOnly: false,
    children: [ADD_NOTE(category), VIEW_NOTES(category)]
});

export const SET_POLICY_OPTIONS = {
    automation_id: "btn_policy_options",
    key: "setPolicyOptions",
    text: "Policy Options",
    tooltip: "Set Policy Options",
    disableWhenReadOnly: true
};

export const SPACER = { key: "action-spacer" };
export const DIVIDER = { key: "action-divider" };
export const R2C_SPACER = { key: "r2c-action-spacer" };