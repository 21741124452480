export default class DxFilterBuilder {
    constructor(options) {
        options = options || {};
        let expr = [];
        if(_.isArray(options))
            expr = options.slice();
        else {
            let filterProp = _.getFrom(options, ["filter","filters"], null);
            if(_.isArray(filterProp))
                expr = [filterProp.slice()];
        }
        this.filterExpression = expr;
    }

    extend(opr="AND", forceNew=false) {
        let firstPart = _.first(this.filterExpression);
        if(_.isEmpty(this.filterExpression)){
            this.filterExpression = [];
            return;
        }
        else if(forceNew || _.isString(firstPart) || (_.isArray(firstPart) && _.isString(firstPart[0]))) {
            this.filterExpression = [this.filterExpression.slice()];
        }
        this.filterExpression.push(opr);
        return this;
    }

    append(filter, opr="AND", forceNew=false) {
        if(this.filterExpression.length > 0){
            this.extend(opr, forceNew);
            this.filterExpression.push(filter);
        }
        else {
            this.filterExpression = filter;
        }
        return this;
    }

    and() { return this.extend(); }
    or() { return this.extend("OR"); }
    exprByKey(key, targetExpr=null) {
        let expr = targetExpr || this.filterExpression;
        if(_.isEmpty(expr)) return null;
        if(_.isString(expr[0]) && expr[0] === key) return expr;
        let result = [];
        _.forEach(expr, ex => {
            if(!_.isArray(ex)) return;
            let val = this.exprByKey(key, ex);
            if(!_.isNil(val) && !_.isEmpty(val)) {
                result = _.concat(result, [val])
            }
        });
        return result;
    }

    exprValueByKey(key) {
        let targetExpr = this.exprByKey(key);
        return _.get(targetExpr, "[0]", null);
    }
}