import { SETTLEMENT_ACTIONS } from '../actions';
import { SET_ERROR, SETTLEMENT_MUTATIONS } from '../mutations';
import PayoffsApi from '../../api/payoffs';
import ProrationsApi from '../../api/prorations';
import SettlementDashboardApi from '../../api/settlementDashboard';
import { BASIC_ACTION, ACTION_WITH_COMMIT } from "../helpers";

const state = {
//RQO-5366 - moved to system lookup
    // payoffTypes: [],
    // prorationLookups: {},
    payoffs: [],
    selectedLoanId: null
};

const getters = {
    //RQO-5366 - moved to system lookup
    // payoffTypes: state => { return state.payoffTypes; },
    // prorationDefaults: state => {
    //     let items = _.cloneDeep(state.prorationLookups.defaults) || [];
    //     _.each(items, item => item.display = `Tax Category ${item.taxCategory} - ${item.description}`);
    //     items.splice(0, 0, { taxCategory: 0, description: 'Custom', display: 'Custom', rent: false, includeIn1099: false, daysInYear: 0, periodsPerYear: 0});
    //     return items;
    // }

};

const actions = {

    [SETTLEMENT_ACTIONS.GET_PAYOFF_DATA](context, params) {
        let apiPromise = PayoffsApi.getPayoffData(params.loanId);
        return ACTION_WITH_COMMIT(context, apiPromise, SETTLEMENT_MUTATIONS.SET_PAYOFF_DATA);
    },

    [SETTLEMENT_ACTIONS.SAVE_PAYOFF] (context, request) {
        let apiPromise = PayoffsApi.savePayoff(request);
        let commitMutation = (request.data.payoff.payoffID === 0 || request.data.payoff.payoffID === -1) ? SETTLEMENT_MUTATIONS.ADD_PAYOFF : SETTLEMENT_MUTATIONS.UPDATE_PAYOFF;
        return BASIC_ACTION(context, apiPromise, result => {
            context.commit(commitMutation, result);
        });
    },

    [SETTLEMENT_ACTIONS.CALCULATE_PAYOFF] (context, request) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            PayoffsApi.calculatePayoff(request)
                .then(response => resolve(response))
                .catch(error => { context.commit(SET_ERROR, error.errorMessage || error.message, { root: true }); reject(error); });
        });
    },

    [SETTLEMENT_ACTIONS.ADD_PAYOFF] (context, request) {
        let apiPromise = PayoffsApi.savePayoff(request);
        return BASIC_ACTION(context, apiPromise, result => {
            context.commit(SETTLEMENT_MUTATIONS.ADD_PAYOFF, result);
        });
    },

    [SETTLEMENT_ACTIONS.DELETE_PAYOFF] (context, request) {
        let apiPromise = PayoffsApi.deletePayoff(context.getters.orderId, request.payOffIds);
        return BASIC_ACTION(context, apiPromise, () => { context.commit(SETTLEMENT_MUTATIONS.REMOVE_PAYOFF, request.payOffIds); });
    },

    [SETTLEMENT_ACTIONS.GET_PRORATION_DATA] (context, orderId) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.getProrationData(orderId)
                .then(response => { resolve(response); })
                .catch(error => { context.commit(SET_ERROR, error.errorMessage || error.message, { root: true }); reject(error); });
        });
    },

    [SETTLEMENT_ACTIONS.CALCULATE_PRORATION_DATES] (context, proration) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.calculateDates(proration)
                .then(response => { resolve(response); })
                .catch(error => { context.commit(SET_ERROR, error.errorMessage || error.message, { root: true }); reject(error); });
        });
    },

    [SETTLEMENT_ACTIONS.CALCULATE_ALL_PRORATIONS] (context, request) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.calculateAll(request)
                .then(response => { resolve(response); })
                .catch(error => { context.commit(SET_ERROR, error.errorMessage || error.message, { root: true }); reject(error); });
        });
    },

    [SETTLEMENT_ACTIONS.UPDATE_PRORATION] (context, request) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.saveProration(request)
                .then(response => resolve(response))
                .catch(error => { context.commit(SET_ERROR, error.errorMessage || error.message, { root: true }); reject(error); });
        });
    },

    [SETTLEMENT_ACTIONS.CALCULATE_PRORATION] (context, request) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.calculateProration(request)
                .then(response => resolve(response))
                .catch(error => { context.commit(SET_ERROR, error.errorMessage || error.message, { root: true }); reject(error); });
        });
    },

    [SETTLEMENT_ACTIONS.UPDATE_AND_CALC_ALL] (context, request) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.updateAndCalcAll(request)
                .then(response => resolve(response))
                .catch(error => { context.commit(SET_ERROR, error.errorMessage || error.message, { root: true }); reject(error); });
        });
    },

    [SETTLEMENT_ACTIONS.ADD_PRORATION] (context, request) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.addProration(request)
                .then(response => resolve(response))
                .catch(error => { context.commit(SET_ERROR, error.errorMessage || error.message, { root: true }); reject(error); });
        });
    },

    [SETTLEMENT_ACTIONS.DELETE_PRORATION] (context, prorationId) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.deleteProration(prorationId)
                .then(response => resolve(response))
                .catch(error => {
                    context.commit(SET_ERROR, error.errorMessage || error.message, { root: true });
                    reject(error);
            });
        });
    },

    [SETTLEMENT_ACTIONS.DELETE_PRORATIONS] (context, prorationIds) {
        context.commit(SET_ERROR, null, { root: true });
        return new Promise((resolve, reject) => {
            ProrationsApi.deleteProrations(prorationIds)
                .then(response => resolve(response))
                .catch(error => {
                    context.commit(SET_ERROR, error.errorMessage || error.message, { root: true });
                    reject(error);
            });
        });
    },

    [SETTLEMENT_ACTIONS.MAP_SETTLEMENT] (context ) {
        let orderSummary = context.getters.orderSummary;
        let apiPromise = SettlementDashboardApi.mapSettlementData(orderSummary.settlementStatementType, orderSummary.defaultLoanID);
        return BASIC_ACTION(context, apiPromise);
    }
};

const mutations = {
    [SETTLEMENT_MUTATIONS.SET_PAYOFF_DATA] (state, value) { state.payoffs = value; },
    [SETTLEMENT_MUTATIONS.ADD_PAYOFF] (state, value) { state.payoffs.push(value); },
    [SETTLEMENT_MUTATIONS.UPDATE_PAYOFF] (state, value) {
        let propIndex = _.findIndex(state.payoffs, (i) => {
            return _.get(i, "payoffID", -1) === _.get(value, "payoffID", -1);
        });
        if (propIndex >= 0) state.payoffs.splice(propIndex, 1, value);
    },
    [SETTLEMENT_MUTATIONS.REMOVE_PAYOFF] (state, values) {
        state.payoffs = _.filter(state.payoffs, p => !_.includes(values, p.payoffID));
    },
    [SETTLEMENT_MUTATIONS.SET_SELECTED_LOAN_ID] (state, value) { state.selectedLoanId = value; },
};

export default { state, getters, actions, mutations };
