import axios from "axios";
import store from "@/store";

const parseUrl = url => _.startsWith(url, '/') ? url.substr(1) : url;
export default class RestClient {
    constructor ({ baseUrl }) {
        this.client = null;
        this.baseUrl = baseUrl;
        this.init();
    }

    init() {
        if(!_.isNil(this.client)) return;
        this.client = axios.create({
            baseURL: this.baseUrl
        });
    }

    mergeAuthHeader(config) {
        if(!_.isEmpty(config?.headers?.Authorization)) return config;

        let token = _.get(store, "state.authentication.identityToken", "");
        if(_.isEmpty(token)) {
            console.error("Invalid auth token.");
        }
        return _.merge({}, config, {
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    async get (url, config) { return await this.callApi("get", url, null, config); }
    async post (url, data, config) { return await this.callApi("post", url, data, config); }
    async put (url, data, config) { return await this.callApi("put", url, data, config); }
    async delete (url, config) { return await this.callApi("delete", url, null, config); }

    async callApi(method, url, data, config) {
        this.init();//ensures proper base URL; returns if already initialized
        let apiUrl = parseUrl(url);
        let apiConfig = this.mergeAuthHeader(config);
        let params = _.includes(["post", "put"], method)
            ? [apiUrl, data, apiConfig]
            : [apiUrl, apiConfig];

        try {
            let apiResponse = await this.client[method](...params);
            return apiResponse.data;
        }
        catch(err) {
            console.error(err);
            throw err;
        }
    }
}