import Store from '@/store';
import { DateTime } from "luxon";
import { TimeZoneInfo } from "./Timezones";

const defaultFormat = "yyyy-MM-dd'T'HH:mm:ss";

const DateTimeHelper = {

    // timeZoneConvert (config) {

    //     let cfg = _.defaultsDeep({},
    //         config,
    //         {
    //             dateTime: null,
    //             sourceTimeZone: "UTC",
    //             destinationTimeZone: Store.getters.clientTimeZone,
    //         });

    //     let dateTime = _.get(cfg, 'dateTime', null);

    //     if (!dateTime) return null;

    //     let dateTimeValue;

    //     if (_.isDate(dateTime)) {
    //         // This is needed since momentjs, if instantiated with a date object,
    //         // ignores the timezone we provide (for some reason).
    //         // This effectively reads the value AS IS and gives a flat textual representation.
    //         dateTimeValue = moment(dateTime).format(momentFormat);
    //     }
    //     else {
    //         dateTimeValue = dateTime;
    //     }

    //     let sourceValue = moment.tz(dateTimeValue, cfg.sourceTimeZone);
    //     let destinationValue = sourceValue.isValid() ? sourceValue.clone().tz(cfg.destinationTimeZone).format(momentFormat) : null;

    //     if (!destinationValue) return null;

    //     return destinationValue;
    // },

    currentTimeZones() {
        var currentDate = (new Date()).toString();
        var tz = currentDate.substring(currentDate.indexOf("(") + 1, 100);
        tz = tz.substring(0, tz.length - 1);
        return [tz, Intl.DateTimeFormat().resolvedOptions().timeZone].join(",");
    },

    diff(dateTime, relativeToDateTime, unit) {
        if(_.isEmpty(unit)) {
            throw new Error('unit cannot be undefined or empty');
        }

        if(dateTime instanceof DateTime && relativeToDateTime instanceof DateTime) {
            return dateTime.diff(relativeToDateTime, unit).toObject();
        }

        var dt = DateTimeHelper.getDateFromString(dateTime);
        var rdt = DateTimeHelper.getDateFromString(relativeToDateTime);
        return dt.diff(rdt, unit).toObject();
    },

    nowClient(format = defaultFormat) {
        return DateTime.now().toFormat(format);
    },

    nowClientStartOfDay(format = defaultFormat) {
        return DateTime.now().startOf('day').toFormat(format);
    },

    nowTenant(format = defaultFormat) {
        let nowWithTz = DateTime.now().setZone(Store.getters.tenantTimeZone);
        return nowWithTz.isValid ? nowWithTz.toFormat(format) : null;
    },

    nowTenantStartOfDay(format = defaultFormat) {
        let nowWithTz = DateTime.now().startOf('day').setZone(Store.getters.tenantTimeZone);
        return nowWithTz.isValid ? nowWithTz.toFormat(format) : null;
    },

    now(format = defaultFormat) {
        return DateTime.now().toFormat(format);
    },

    nowStartOf (unit, format = defaultFormat) {
        return DateTime.now().startOf(unit).toFormat(format);
    },

    nowStartOfDay (format = defaultFormat) {
        return DateTime.now().startOf('day').toFormat(format);
    },

    toLocalTimeString(dateTimeString, format = defaultFormat){
        return DateTime.fromISO(dateTimeString, {zone: 'utc'}).toLocal().toFormat(format);
    },

    toTenantTimeString(dateTimeString, format = defaultFormat){
        let dateTimeValue = DateTime.fromISO(dateTimeString, {zone: 'utc'}).setZone(Store.getters.tenantTimeZone);
        return dateTimeValue.isValid ? dateTimeValue.toFormat(format) : null;
    },

    toFormat(dateTimeValue, format="MM/dd/yyyy") {
        let dtVal = null;
        if(_.isString(dateTimeValue)) {
            if(_.isEmpty(dateTimeValue)) return "";
            dtVal = DateTime.fromISO(dateTimeValue);
        }
        if(_.isDate(dateTimeValue)) {
            dtVal = DateTime.fromJSDate(dateTimeValue);
        }
        return _.isNil(dtVal) ? "" : dtVal.toFormat(format);
    },

    getDateParts(dtString, partFormat="yyyy-MM-dd'T'HH:mm:ss") {
        if(_.isEmpty(dtString)) return { date: null, time: null, timezone: null };
        let dt = DateTime.fromISO(dtString, { setZone: true });
        return {
            date: dt.startOf("day").toFormat(partFormat),
            time: dt.toFormat(partFormat),
            timezone: dt.zoneName
        };
    },

    joinDateParts({ date, time, timezone, format="yyyy-MM-dd'T'HH:mm:ss" }) {
        if(_.isNullOrEmpty(date)) return null;

        let dateDt = _.isDate(date)
            ? DateTime.fromJSDate(date)
            : DateTime.fromISO(date);

        let timeDt = _.isNullOrEmpty(time)
            ? null
            : _.isDate(time)
                ? DateTime.fromJSDate(time)
                : DateTime.fromISO(time);

        if (!dateDt.isValid) return null;

        let dtConfig = {
            year: dateDt.year,
            month: dateDt.month,
            day: dateDt.day
        };
        if (!_.isNil(timeDt) && timeDt.isValid) {
            dtConfig.hour = timeDt.hour;
            dtConfig.minute = timeDt.minute;
            dtConfig.second = timeDt.second;
        }

        let dtOpts = { zone: _.isEmpty(timezone) || timezone === "UTC" ? "utc" : timezone };

        let resultDt = DateTime.fromObject(dtConfig, dtOpts);

        return _.toLower(format) === "iso"
            ? resultDt.toISO()
            : resultDt.toFormat(format);
    },

    getOffset(timeZoneId=null) {
        return TimeZoneInfo.getOffset(timeZoneId);
    },

    getDateFromString(dateString) {
        if(_.isEmpty(dateString)) {
            throw new Error('dateString cannot be empty');
        }

        let dateTime = DateTime.fromISO(dateString);
        if(dateTime.isValid) {
            return dateTime;
        }

        const formats = [
            'yyyy-MM-dd', 'MM/dd/yyyy', 'M/dd/yyyy', 'M/d/yyyy',
            'dd/MM/yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd\'T\'HH:mm:ss', 'MM/dd/yyyy HH:mm:ss',
            'MM-dd-yyyy'
        ];

        for (const fmt of formats) {
            const dt = DateTime.fromFormat(dateString, fmt);
            if (dt.isValid) {
                return dt;
            }
        }

        throw new Error('dateString cannot be parsed using commonly used formats');
    },

    getStartOf(customDate = '', unit = 'day', outputFormat = defaultFormat) {
        let dateTime = null;

        if (!_.isEmpty(customDate)) {
            dateTime = DateTimeHelper.getDateFromString(customDate);
            if (_.isEmpty(dateTime)) {
                throw new Error('Invalid date format of customDate');
            }
        } else {
            dateTime = DateTime.local();
        }

        const start = dateTime.startOf(unit);

        if (!_.isEmpty(outputFormat)) {
            return start.toFormat(outputFormat);
        }

        return start;
    },

    isValidDate(dateString) {
        const  knownFormats = [
            'yyyy-MM-dd', 'MM/dd/yyyy', 'M/dd/yyyy', 'M/d/yyyy',
            'dd/MM/yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd\'T\'HH:mm:ss',
            'MM-dd-yyyy'
        ];

        if (DateTime.fromISO(dateString).isValid) {
            return true;
        }

        for (const format of knownFormats) {
            if (DateTime.fromFormat(dateString, format).isValid) {
              return true;
            }
        }

        return false;
    }
}

export default DateTimeHelper;