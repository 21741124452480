import { ref } from "vue";
import { defineStore } from "pinia";

export const useManageCompaniesStore = defineStore("manageCompanies", () => {
    const activeTabIndex = ref(-1);
    const targetCompany = ref({});

    function resetState() {
        activeTabIndex.value = -1;
        targetCompany.value = {};
    }

    return  {
        activeTabIndex,
        targetCompany,
        resetState
    }
});