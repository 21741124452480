<template>
    <div class="rq-list-manager">
        <div :class="{ 'form-group': true, 'has-error': newItemInvalid }">
            <label v-if="label">{{label}}</label>
            <input type="text" class="form-control" :placeholder="newItemPlaceholder" :disabled="disabled" v-model="newItemValue" @keydown="onNewItemKeyDown">
            <rq-validation-feedback :no-field-label="!label">{{newItemErrorMessage}}</rq-validation-feedback>
        </div>
        <div class="row">
            <div class="col">
                <div class="selection-container" tabindex="0" @keydown="onListKeyDown">
                    <rq-list-box
                        ref="listBox"
                        :data-source="listItems"
                        :disabled="disabled"
                        v-model="listBoxValue"
                        multi-select
                    />
                </div>
            </div>
            <div class="col-auto">
                <div class="action-group">
                    <b-btn variant="theme" :disabled="editDisabled" @click="onEditClick">Edit</b-btn>
                    <b-btn variant="theme" :disabled="deleteDisabled" @click="onDeleteClick">Delete</b-btn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { ListBoxItemModel } from "@/shared/models/models";
    import RqListBox from "./RqListBox";
    import RqListManagerItem from "./RqListManagerItem";

    const DS_ACTION = {
        load: "load",
        add: "add",
        update: "update",
        delete: "delete"
    };
    export default {
        components: { RqListBox },
        props: {
            label: { type: String, default: "" },
            dataSource: { default: () => ({ data: [] }) },
            hasInactive: { type: Boolean, default: false },
            allowDuplicates: { type: Boolean, default: false },
            newItemPlaceholder: { type: String, default: "Type a value and press \"Enter\"..." },
            disabled: { type: Boolean, default: false }
        },
        data: function() {
            return {
                newItemValue: "",
                newItemErrorMessage: "",
                newItemInvalid: false,
                listItems: [],
                selectedItems: [],
                listBoxValue: [],
                isLoaded: false,
                skipNextInit: false
            }
        },
        watch: {
            dataSource: {
                handler(newValue, oldValue) {
                    if(this.skipNextInit) {
                        this.skipNextInit = false;
                        return;
                    }
                    this.initDataSource(newValue);
                },
                immediate: true,
                deep: true
            },
            listBoxValue(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                let newItem = null;
                if(_.isEmpty(newValue)) return null;
                this.selectedItems = newValue.slice();
            }
        },
        computed: {
            editDisabled() { return _.isEmpty(this.selectedItems) || this.selectedItems.length > 1; },
            deleteDisabled() { return _.isEmpty(this.selectedItems); }
        },
        methods: {

            initDataSource(ds) {
                const self = this;
                let dataSource = ds || self.dataSource;
                let setItems = items => {
                    self.listItems = !_.isArray(items) || _.isEmpty(items) ? [] : items.slice();
                    self.selectedItems = [];
                };
                if(_.isArray(dataSource)) {
                    setItems(dataSource);
                    return;
                }
                self.handleDataSourceAction(DS_ACTION.load, null, setItems)
                    .then(handled => {
                        self.isLoaded = true;
                        if(_.parseBool(handled)) return;
                        setItems(dataSource.data || null);
                    });
            },

            onNewItemKeyDown(e){
                const self = this;
                self.newItemInvalid = false;
                if(e.key !== "Enter" || _.isEmpty(self.newItemValue)) return;

                let commit = item => {
                    self.selectedItems = [];
                    if(_.getNumber(item, "itemID", 0) === 0) return;
                    self.listItems.push(item);
                };

                let newItemText = self.newItemValue;
                self.handleValidation(newItemText)
                    .then(validationResult => {
                        if(!validationResult.isValid) {
                            self.newItemErrorMessage = validationResult.message;
                            self.newItemInvalid = true;
                            return;
                        }
                        let commit = item => {
                            if(_.getNumber(item, "itemID", 0) === 0) return;
                            self.listItems.push(item);
                            self.selectedItems = [];
                        };
                        self.handleDataSourceAction(DS_ACTION.add, { itemName: newItemText }, commit)
                            .then(handled => {
                                if(_.parseBool(handled)) return;
                                self.$emit("add-item", { value: newItemText });
                            });
                        self.newItemValue = "";
                    });
            },

            onListKeyDown(e) {
                if(e.key !== "Delete" || this.deleteDisabled || this.disabled) return;
                this.handleDelete();
            },

            onEditClick() {
                const self = this;
                let originalData = _.clone(self.selectedItems[0]);
                let commit = item => {
                    self.selectedItems = [];
                    self.updateItem(item);
                };
                let okHandler = function(e){
                    return self.handleValidation(e.component.item.itemName)
                        .then(validationResult => {
                            e.component.errorMessage = validationResult.message;
                            e.component.hasError = !validationResult.isValid;
                            if(!validationResult.isValid) return false;

                            let data = _.toPlainObject(e.component.item);
                            self.handleDataSourceAction(DS_ACTION.update, { originalData, data }, commit)
                                .then(handled => {
                                    if(_.parseBool(handled)) return;
                                    self.$emit("update-item", { data, commit });
                                });
                            return true;
                        });
                };
                self.$dialog.open({
                    title: `Edit ${self.label}`,
                    height: "auto",
                    width: 425,
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    props: {
                        originalValue: _.clone(originalData),
                        showInactive: self.hasInactive
                    },
                    component: RqListManagerItem,
                    onOk: okHandler
                });
            },

            onDeleteClick() {
                this.handleDelete();
            },

            handleDelete() {
                const self = this;
                if(self.deleteDisabled) return;
                let okHandler = function(e) {
                    let itemIds = _.map(self.selectedItems, item => item.itemID);
                    let commit = () => {
                        self.selectedItems = [];
                        self.removeItems(itemIds);
                    };
                    self.handleDataSourceAction(DS_ACTION.delete, itemIds, commit)
                        .then(handled => {
                            if(_.parseBool(handled)) return;
                            self.$emit("delete-items", { data: itemIds.slice(), commit });
                        });
                    return true;
                }
                self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete the selected item(s)?", okHandler);
            },

            handleValidation(val) {
                const self = this;
                let dsValidationHandler = _.get(self, "dataSource.validationHandler", null);
                let dsValidationMessage = _.get(self, "dataSource.validationMessage", null);
                if(_.isFunction(dsValidationHandler)) {
                    let dsValidationResult = dsValidationHandler(val);
                    let message = dsValidationMessage || "The value entered is invalid.";
                    if(_.isObject(dsValidationResult) && _.isFunction(dsValidationResult.then)) {
                        return dsValidationResult
                            .then(result => ({ isValid: result, message }));
                    }
                    else if(_.isBoolean(dsValidationResult)) {
                        return Promise.resolve({ isValid: dsValidationResult, message });
                    }
                }
                let isValid = true;
                let message = "";
                if(_.isEmpty(val)) {
                    isValid = false;
                    message = "This value is required.";
                }
                else if(!self.allowDuplicates)
                {
                    isValid = _.every(self.listItems, item => _.trim(item.itemName) !== _.trim(val));
                    message = isValid ? "" : "An item with this value already exists.";
                }
                return Promise.resolve({ isValid, message });
            },

            handleDataSourceAction(action, args, callback) {
                const self = this;
                if(_.isArray(self.dataSource) || !_.isFunction(self.dataSource[action])) {
                    return Promise.resolve(false);
                }
                let dsPromise = self.dataSource[action](args);
                return self.$rqBusy.wait(dsPromise)
                    .then(result => {
                        if(result !== false)
                            callback(result);
                        return true;
                    })
                    .catch(err => {
                        console.error(err);
                        return err;
                    });
            },

            updateItem(targetItem) {
                const self = this;
                let itemIndex = _.findIndex(self.listItems, item => item.itemID === targetItem.itemID);
                if(itemIndex < 0) return;
                self.listItems.splice(itemIndex, 1, _.clone(targetItem));
                self.syncDataSource();
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.listItems, item => item.itemID === key);
                    if(itemIndex < 0) return;
                    self.listItems.splice(itemIndex, 1);
                });
                self.syncDataSource();
            },

            syncDataSource() {
                const self = this;
                if(!_.isArray(self.dataSource)) return;
                self.skipNextInit = true;
                self.$emit("update:dataSource", self.listItems.slice());
            }
        }
    }
</script>