import { UserSecuritySettings } from '../models/models';
import { UserScreenAccessLevel } from '../models/enums';

export default {

    install (app) {
        app.mixin({
            data () {
                return {
                    userAccess: {
                        none: false,
                        readOnly: false,
                        full: true
                    }
                };
            },
            computed: {
                securitySettings () {
                    let settings = _.get(this, '$store.getters.currentSession.settings', null);
                    return settings ?
                        new UserSecuritySettings(settings) :
                        new UserSecuritySettings();
                }
            },
            created () {
                this.setUserAccess();
            },
            methods: {
                setUserAccess () {
                    if (this.$route && this.$route.path.indexOf('oe') >= 0) {
                        const oeAccess = this.securitySettings.findValue('OrderEntry_ScreenAccess');
                        this.userAccess = {
                            none: oeAccess === UserScreenAccessLevel.None,
                            readOnly: oeAccess === UserScreenAccessLevel.Read,
                            full: oeAccess === UserScreenAccessLevel.Full
                        };
                    }
                }
            }
        });
    }
};
