import { RATE_ACTIONS, ORDER_ACTIONS} from '../actions';
import { RATE_MUTATIONS } from '../mutations';
import { RatesApi, RateEngineApi, OrderEndorsementsApi } from '../../api/';
import { SystemLookupItem } from '@/shared/models/models';

import { BASIC_ACTION, ACTION_WITH_COMMIT } from "../helpers";
import { DateTimeHelper } from '@/shared/utilities';

const state = {
    rateOrderInfo: null,
    rates: [],
    ratesByDate: {
        collection: [],
        effectiveDate: null
    },
    premiums: [],
    cdfPremiums: [],
    calculationDetails: [],
    endorsements: [],
    outsideParties: [],
    loans: [],
    premiumNameCollections: [],
    warningMessages: [],
    alerts: [],
    alertTarget: "",
    alertMessage: ""
};

const getters = {
    alertInfo: state => { return { target: state.alertTarget, message: state.alertMessage }; },
    premiumNames: state => {
        let rates = _.get(state, "ratesByDate.collection", null) || [];
        let mapped = _.map(rates, r => new SystemLookupItem({ id: _.parseNumber(r.rateID), name: `${r.code} - ${r.state} - ${r.extendedDescription}`, data: r.state, inactive: r.isInactive }));
        return _.sortBy(mapped, "name");
    }
};

const handleRateInfoResponse = (context, response) => {
    let rateData = _.pick(response, [
        "premiums",
        "cdfPremiums",
        "calculationDetails",
        "endorsements",
        "outsideParties",
        "loans",
        "premiumNameCollections",
        "alerts"
    ]);
    context.commit(RATE_MUTATIONS.SET_RATE_DATA, rateData);
};

const actions = {

    [RATE_ACTIONS.GET_RATE_ORDER_INFO] (context) {
        let orderId = context.getters.orderId;
        return ACTION_WITH_COMMIT(context, RateEngineApi.getRateOrderInfo(orderId), RATE_MUTATIONS.SET_RATE_ORDER_INFO);
    },
    [RATE_ACTIONS.REFRESH_ALWAYS_APPLY_ENDORSEMENTS] (context,orderRateCalculationIDs) {
        let orderId = context.getters.orderId;
        return BASIC_ACTION(context, RateEngineApi.refreshAlwaysApplyEndorsements(orderId,orderRateCalculationIDs), response => {
                if(_.isEmpty(response))return;
                context.commit(RATE_MUTATIONS.SET_ORDER_ENDORSEMENTS, response);

        });
    },
    [RATE_ACTIONS.GET_RATES] (context) {
        let orderId = context.getters.orderId;
        return BASIC_ACTION(context, RateEngineApi.getRates(orderId), response => {
            handleRateInfoResponse(context, response);
        });
    },

    [RATE_ACTIONS.GET_RATES_BY_DATE] (context, { effectiveDate=null, forceRefresh=false }={ effectiveDate: null, forceRefresh: false }) {
        let dateValue = _.isEmpty(effectiveDate)
            ? DateTimeHelper.now("MMddyyyy")
            : DateTimeHelper.getDateFromString(effectiveDate).toFormat("MMddyyyy");
        let currentEffDate = _.get(context, "state.ratesByDate.effectiveDate", null);
        let currentRateCollection = _.get(context, "state.ratesByDate.collection", null) || [];

        if(!forceRefresh && dateValue === currentEffDate && !_.isEmpty(currentRateCollection)) {
            return Promise.resolve(currentRateCollection);
        }

        return BASIC_ACTION(context, RatesApi.getAll(dateValue), result => {
            context.commit(RATE_MUTATIONS.SET_RATES_BY_DATE, {
                effectiveDate: dateValue,
                collection: result
            });
            return result;
        });
    },

    [RATE_ACTIONS.SAVE_RATE_ORDER_INFO] (context, rateOrderInfo) {
        return BASIC_ACTION(context, RateEngineApi.saveRateOrderInfo(rateOrderInfo), ()=>{
            context.commit(RATE_MUTATIONS.SET_RATE_ORDER_INFO, rateOrderInfo);
            context.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, rateOrderInfo.ordersID);
            context.dispatch(RATE_ACTIONS.GET_RATES);
        });
    },

    [RATE_ACTIONS.CALCULATE_RATES] (context, calcRequest) {
        return BASIC_ACTION(context, RateEngineApi.calculate(calcRequest), (response) => {
            context.commit(RATE_MUTATIONS.SET_RATE_DATA, response);
            context.commit(RATE_MUTATIONS.SET_OUTSIDE_PARTIES, response.outsideParties);
        }, false);
    },

    [RATE_ACTIONS.RESET_RATE] (context, data) {
        let orderID = context.getters.orderId;
        return BASIC_ACTION(context, RateEngineApi.resetPremium(orderID, data.orderRateCalculationID, data.simultaneousOrderRateCalculationIDs), (response) => {
            handleRateInfoResponse(context, response);
        });
    },
    [RATE_ACTIONS.DELETE_OUTSIDE_PARTY] (context, data) {
        return BASIC_ACTION(context, RateEngineApi.deleteOutsideParties(data.ids), (response)=>{
            context.dispatch(RATE_ACTIONS.GET_OUTSIDE_PARTY, data.orderId);
        });
    },
    [RATE_ACTIONS.GET_OUTSIDE_PARTY](context, orderId) {
        return BASIC_ACTION(context, RateEngineApi.getOutsideParties(orderId), (response) => {
            context.commit(RATE_MUTATIONS.SET_OUTSIDE_PARTIES, response);
        });
    },

    [RATE_ACTIONS.ADD_OUTSIDE_PARTY] (context, newOutsideParty) {
        return BASIC_ACTION(context, RateEngineApi.addOutsideParty(newOutsideParty), (response)=>{
            context.dispatch(RATE_ACTIONS.GET_OUTSIDE_PARTY, newOutsideParty.ordersID);
        });
    },

    [RATE_ACTIONS.SAVE_OUTSIDE_PARTIES] (context, outsideParty) {
        return BASIC_ACTION(context, RateEngineApi.saveOutsideParties(outsideParty));
    },
    [RATE_ACTIONS.ADD_ORDER_ENDORSEMENTS] (context, { orderRateCalculationId, endorsementIds }) {
        let orderId = context.getters.orderId;
        return ACTION_WITH_COMMIT(context, OrderEndorsementsApi.addOrderEndorsements(orderId, orderRateCalculationId, endorsementIds), RATE_MUTATIONS.ADD_ORDER_ENDORSEMENTS);
    },

    [RATE_ACTIONS.UPDATE_ORDER_ENDORSEMENTS] (context, orderEndorsements) {
        let oeData = _.isArray(orderEndorsements) ? orderEndorsements : [orderEndorsements];
        _.forEach(oeData, oe => context.commit(RATE_MUTATIONS.UPDATE_ORDER_ENDORSEMENT, oe));
        // no need to specifically update endorsements; calculate api call handles this
        // return BASIC_ACTION(context, OrderEndorsementsApi.updateOrderEndorsements(oeData), () => {
        //     _.forEach(oeData, oe => context.commit(RATE_MUTATIONS.UPDATE_ORDER_ENDORSEMENT, oe));
        // });
    },

    [RATE_ACTIONS.DELETE_ORDER_ENDORSEMENT] (context, ids) {
        return BASIC_ACTION(context, OrderEndorsementsApi.deleteOrderEndorsements(ids), ()=>{
            _.forEach(ids, id => context.commit(RATE_MUTATIONS.REMOVE_ORDER_ENDORSEMENT, id))
        });
    },

    [RATE_ACTIONS.SEARCH_RATES] ({ getters }, searchTerm) {
        let result = _.filter(getters.premiumNames, p => _.includes(_.toLower(p.name), _.toLower(searchTerm)));
        return Promise.resolve(result);
    },

    [RATE_ACTIONS.GET_RATE_BY_KEY] ({ getters }, key) {
        let result = _.find(getters.premiumNames, p => p.id === key);
        return Promise.resolve(result);
    }
};

const removeBy = function(list, comparer) {
    while(_.some(list, comparer)) {
        let itemIndex = _.findIndex(list, comparer);
        if(itemIndex < 0) return;
        list.splice(itemIndex, 1);
    }
}

const mutations = {
    [RATE_MUTATIONS.SET_RATE_ORDER_INFO] (state, value) { state.rateOrderInfo = value; },
    [RATE_MUTATIONS.SET_RATES] (state, value) { state.rates = value; },
    [RATE_MUTATIONS.SET_RATES_BY_DATE] (state, value) { state.ratesByDate = _.isEmpty(value) ? { effectiveDate: null, collection: [] } : value; },
    [RATE_MUTATIONS.SET_PREMIUMS] (state, value) { state.premiums = value; },
    [RATE_MUTATIONS.SET_CDF_PREMIUMS] (state, value) { state.cdfPremiums = value; },
    [RATE_MUTATIONS.ADD_OUTSIDE_PARTY] (state, value) { state.outsideParties.push(value); },
    [RATE_MUTATIONS.SET_OUTSIDE_PARTIES] (state, value) { state.outsideParties = value; },
    [RATE_MUTATIONS.REMOVE_OUTSIDE_PARTY] (state, value) {
        let itemIndex = _.findIndex(state.outsideParties, outsideParty => outsideParty.orderOutsidePartyToPayID === value);
        if(itemIndex < 0) return;
        state.outsideParties.splice(itemIndex, 1);
    },
    [RATE_MUTATIONS.SET_RATE_DATA] (state, value) {
        state.premiums = _.get(value, "premiums", []);
        state.cdfPremiums = _.get(value, "cdfPremiums", []);
        state.endorsements = _.get(value, "endorsements", []);
        state.calculationDetails = _.get(value, "calculationDetails", []);
        state.alerts = _.get(value, "alerts", []);

        //Nil check because not all calls return a DTO with values for these items
        if(!_.isNil(value.loans)) state.loans = value.loans;
        if(!_.isNil(value.outsideParties)) state.outsideParties = value.outsideParties;
        if(!_.isNil(value.premiumNameCollections)) state.premiumNameCollections = value.premiumNameCollections;
    },
    [RATE_MUTATIONS.SET_ORDER_ENDORSEMENTS] (state, value) { state.endorsements = value; },
    [RATE_MUTATIONS.UPDATE_ORDER_ENDORSEMENT] (state, value) {
        let itemIndex = _.findIndex(state.endorsements, item => item.orderEndorsementID === value.orderEndorsementID);
        state.endorsements.splice(itemIndex, 1, value);
    },
    [RATE_MUTATIONS.ADD_ORDER_ENDORSEMENTS] (state, value) { state.endorsements.push(...value); },
    [RATE_MUTATIONS.REMOVE_ORDER_ENDORSEMENT] (state, value) {
        _.remove(state.endorsements, item => item.orderEndorsementID === value);
        //reassign copy of endorsements to itself to trigger compute reactivety for vue 3
        state.endorsements = _.cloneDeep(state.endorsements);
    },
    [RATE_MUTATIONS.SET_ALERTS] (state, value) { state.alerts = value; },
    [RATE_MUTATIONS.ADD_ALERT] (state, { target, message, orderRateCalculationID=0, severity=1 }) {
        let existingAlertIndex = _.findIndex(state.alerts, a => a.orderRateCalculationID === orderRateCalculationID && a.target === target);
        if(existingAlertIndex >= 0) {
            state.alerts[existingAlertIndex].message = message;
        }
        else {
            state.alerts.push({ orderRateCalculationID, target, message, severity });
        }
    },
    [RATE_MUTATIONS.REMOVE_ALERT] (state, { target, orderRateCalculationID=0 }) {
        const comparer = a => a.target === target && a.orderRateCalculationID === orderRateCalculationID;
        removeBy(state.alerts, comparer);
    },
    [RATE_MUTATIONS.REMOVE_ALERTS_BY_TARGET] (state, target) {
        const comparer = a => a.target === target;
        removeBy(state.alerts, comparer);
    },
    [RATE_MUTATIONS.CLEAR_GENERIC_ALERTS] (state) {
        const comparer = a => a.orderRateCalculationID === 0;
        removeBy(state.alerts, comparer);
    }
};

export default { state, getters, actions, mutations };
