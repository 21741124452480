import { ref, nextTick, onMounted } from "vue";

export default function useRqTableSpacer(tableRefName="tableElement") {
    const _tableElement = ref(null);
    const spacerHeight = ref(0);

    function updateSpacerHeight() {
        spacerHeight.value = 0;
        nextTick(() => {
            let tableHeight = _.getNumber(_tableElement, "value.clientHeight", 0);

            if(tableHeight === 0) return;

            let containerHeight = _.getNumber(_tableElement, "value.parentElement.clientHeight", 0);

            if(containerHeight === 0 || tableHeight >= containerHeight) return;

            spacerHeight.value = containerHeight - tableHeight;
        });
    }

    onMounted(() => {
        updateSpacerHeight();
    });

    return {
        [tableRefName]:_tableElement,
        spacerHeight,
        updateSpacerHeight
    };
}