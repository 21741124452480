<template>
    <div class="content-wrapper h-100">
        <report-designer-iframe
            :key="reportDesignerKey"
            :report-path="reportPathValue"
            @report-designer-launcher="onReportDesignerLauncherRefresh"
        />
    </div>
</template>
<script>
    import { EXAGO_SESSION_MUTATIONS } from "@/store/mutations";
    import { computed } from "vue";
    import { useStore } from "vuex";
    import ReportDesignerIframe from "./ReportDesignerIframe.vue";

    export default {
        name: "ReportDesignerLauncher",
        components: { ReportDesignerIframe },
        data() {
            return {
                reportDesignerKey: 0,
                reportPathValue: null
            }
        },
        setup() {
            const vuexStore = useStore();
            const reportApiBaseUrl = computed({
                get() { return vuexStore.state.exagoSession.reportAccess?.rsApi; },
            });
            return {
                reportApiBaseUrl,
            };
        },
        mounted() {
            const self = this;
            self.refreshForm();
        },
        watch: {
            reportApiBaseUrl: {
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    if(newValue && !_.isEmpty(newValue)) {
                        this.refreshForm();
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            refreshForm() {
                const self = this;
                if(!_.isNullOrEmpty(self.reportApiBaseUrl))
                {
                    const RSApiRef = {
                        id: "j$vm",
                        type: "text/javascript",
                        src: self.reportApiBaseUrl,
                        crs: self.reportApiBaseUrl.replace('lib/jreportapi.js',''),
                    };

                    let scrTag = document.createElement('script');
                    scrTag.setAttribute('id', RSApiRef.id);
                    scrTag.setAttribute('src', RSApiRef.src);
                    scrTag.setAttribute('crs', RSApiRef.crs);
                    scrTag.setAttribute('type', RSApiRef.type);
                    scrTag.onload = () => {
                        this.refreshReportDesignerFrame();
                    };
                    document.head.appendChild(scrTag);
                }
            },
            refreshReportDesignerFrame() {
                const self = this;
                self.reportDesignerKey += 1;
            },
            onReportDesignerLauncherRefresh() {
                const self = this;
                self.reportDesignerKey += 1;
            },
        }
    };
</script>