/* For more info on ua-parser-js, visit https://github.com/faisalman/ua-parser-js */
import UAParser from "ua-parser-js";

class UAService {
    constructor() {
        this.initialResult = null;
        this.currentResult = null;
    }

    get currentDevice() { return _.get(this, "currentResult.device", null); }

    isDeviceSupported(logResult=false) {
        if(_.parseBool(appSettings.allowAnyDevice)) return true;
        if(_.isEmpty(this.currentResult)) this.refreshResult();
        if(logResult) this.log();
        return _.get(this, "currentDevice.type", null) !== "mobile";
    }

    refreshResult(logResult=false) {
        let uaParser = new UAParser(window.navigator.userAgent);
        this.currentResult = uaParser.getResult();

        if(!_.isEmpty(this.initialResult)) {
            if(logResult) this.log();
            return false;
        }

        this.initialResult = _.cloneDeep(this.currentResult);
        if(logResult) this.log();
        return true;
    }

    log() {
        if(_.isEmpty(this.currentResult)) this.refreshResult();
        console.log("/**********************/");
        console.log("User Agent Parser Results");
        console.log("Current Result: ", this.currentResult);
        console.log("Initial Result: ", this.initialResult);
        console.log("/**********************/");
    }
}

var uaService = new UAService();

export default uaService;