<template>
    <rq-document-editor
        ref="dxRichEdit"
        :document-name="editorDocumentName"
        :file-type="editorFileType"
        :custom-toolbar-items="customToolbarItems"
        :show-insert-doc-link="showInsertDocLink"
        :read-only="readOnly"
        @created="onEditorCreated"
        @toolbar-command="onToolbarCommand"
    />
</template>

<script>
    import RqDocumentEditor from "../editors/RqDocumentEditor";
    import AdvancedToolbarItems from "./DocEditorToolbarItems";
    import StandardLanguageDialog from "@documents/components/prompts/StandardLanguageDialog";
    import { DocumentFileType } from "@documents/enums";
    import { AppliesToType } from "@/modules/file/title-policy/enums";
    import {
        MergeFieldSelection,
        PromptForm,
        PromptValueForm,
        StoredProcedureForm,
        StoredProcedureValueForm,
        StandardLanguagePromptForm,
        MergeConditionForm,
        MergeDateTimeForm,
        MergeDocumentForm,
        MergeCharCountForm
    } from "@documents/components/editor-dialogs";

    export default {
        name: "DocumentEditorDialog",
        components: { RqDocumentEditor },
        props: {
            documentTemplateId: { type: Number, default: 0 },
            documentTemplateRegionId: { type: Number, default: 0 },
            documentName: { type: String, default: null },
            documentContent: { type: String, default: null },
            fileType: { type: Number, default: DocumentFileType.RTF },
            customFields: { type: Array, default: () => [] },
            showInsertClauses: { type: Boolean, default: false },
            enableClausePromptEntry: { type: Boolean, default: false },
            showPrompt: { type: Boolean, default: false},
            showPromptValues: { type: Boolean, default: false},
            showAdvancedToolbar: { type: Boolean, default: false },
            showAppliesTo: { type: Boolean, default: false },
            defaultCategoryId: { type: Number, default: 0 },
            categories: { type: Array, default: () => [] },
            readOnly: { type: Boolean, default: false },
            showInsertDocLink: { type: Boolean, default: false },
            orderId: { type: Number, default: 0 },
            loanId: { type: Number, default: 0 }
        },
        data() {
            const self = this;
            return {
                contentReady: false,
                documentLoaded: false,
                editorInitialized: false,
                customToolbarItems: [],
                customFieldsDropDownId: "rq-rtf-custom-fields",
                appliesToDropDownId: "rq-rtf-applies-to",
                editorContent: null,
                editorFileType: null,
                mergeFieldDialogState: {
                    items: [],
                    selectedKey: null,
                    scrollPosition: 0
                },
                promptResult: null
            };
        },

        computed: {
            editorDocumentName() { return _.isEmpty(this.documentName) ? "Untitled Document" : this.documentName; },
            hasContent() { return !_.isEmpty(this.documentContent); }
        },

        watch: {
            documentContent: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.editorContent || (_.isEmpty(newValue) === _.isEmpty(this.editorContent) && !this.editorInitialized)) return;
                    this.setEditorContent(newValue);
                },
                immediate: true
            }
        },

        created() {
            this.initToolbarItems();
        },

        methods: {

            onEditorCreated(e) {
                const self = this;
                self.editorInitialized = true;
                self.loadDocument();
            },

            onToolbarCommand(e) {
                let itemId = _.get(e, "event.commandName", null);
                if(_.isEmpty(itemId)) return;
                const self = this;
                let itemIDs = _.mapValues(AdvancedToolbarItems, "id");
                switch(itemId) {
                    case itemIDs.MergeLoop: self.launchMergeFieldDialog(); break;
                    case itemIDs.Prompt: self.launchPromptDialog(); break;
                    case itemIDs.PromptValue: self.launchPromptValueDialog(); break;
                    case itemIDs.StoredProcedure: self.launchStoredProcedureDialog(); break;
                    case itemIDs.StoredProcedureValue: self.launchStoredProcedureValueDialog(); break;
                    case itemIDs.ClausePrompt: self.launchClausePromptDialog(); break;
                    case itemIDs.Clause: self.launchClauseSelectionDialog(); break;
                    case itemIDs.Condition: self.launchConditionDialog(); break;
                    case itemIDs.Lock: self.lockSelection(); break;
                    case itemIDs.MergeDocument: self.launchMergeDocumentDialog(); break;
                    case itemIDs.CharCount: self.launchFieldCharCountDialog(); break;
                    case itemIDs.DateTime: self.launchDateTimeDialog(); break;
                    case itemIDs.PageCount: self.insertText("[PAGECOUNT]"); break;
                    case itemIDs.RemoveLine: self.insertText("[REMOVELINE]"); break;
                    case itemIDs.ClearRowspace: self.insertText("[CLEARROWSPACE]"); break;
                }
                if(_.indexOf(itemId, ".") < 0) return;
                let key = itemId.substr(_.indexOf(itemId, ".") + 1);
                if(_.startsWith(itemId, self.customFieldsDropDownId)) {
                    self.onCustomFieldSelect(key);
                }
                if(_.startsWith(itemId, self.appliesToDropDownId)) {
                    self.onAppliesToSelect(_.parseNumber(key, 1));
                }
            },

            setEditorContent(content=null, fileType=null) {
                const self = this;
                let contentVal = _.isNil(content) ? self.documentContent : content;
                self.editorFileType = _.isNil(fileType) ? self.fileType : fileType;
                if(_.isString(contentVal)) {
                    self.editorContent = _.isEmpty(contentVal) || _.startsWith(contentVal, "data:") || _.isBase64String(contentVal)
                        ? contentVal
                        : btoa(contentVal);
                }
                self.contentReady = true;
                self.loadDocument();
            },

            initToolbarItems() {
                const self = this;

                let toolbarItems = self.showAdvancedToolbar
                    ? _.map(AdvancedToolbarItems)
                    : [];

                if (self.showInsertClauses) { toolbarItems.push(AdvancedToolbarItems.Clause); }
                if (self.showPrompt) {
                    toolbarItems.push(AdvancedToolbarItems.Prompt);
                    toolbarItems.push(AdvancedToolbarItems.ClausePrompt);
                }
                if (self.showPromptValues) { toolbarItems.push(AdvancedToolbarItems.PromptValue); }

                toolbarItems = _.uniqBy(toolbarItems, 'id');

                if(self.showAppliesTo) {
                    toolbarItems.push({
                        id: self.appliesToDropDownId,
                        text: "Applies to...",
                        items: AppliesToType.getLookupItems("key", "text")
                    });
                }

                if(!_.isEmpty(self.customFields)) {
                    toolbarItems.push({
                        id: self.customFieldsDropDownId,
                        text: "Custom Fields",
                        items: _.map(self.customFields, f => ({ text: f.label }))
                    });
                }

                self.customToolbarItems = toolbarItems;
            },

            onCustomFieldSelect(key) {
                const self = this;
                if(_.isNil(key)) return;
                let item = _.find(self.customFields, f => f.key === key);
                if(_.isNil(item) || _.isEmpty(item.value)) return;
                self.insertText(item.value);
            },

            onAppliesToSelect(key) {
                const self = this;
                let itemName = AppliesToType.displayValue(key);
                if(_.isNil(itemName)) return;
                let itemTag = _.first(itemName) === "L" ? "M" : _.first(itemName);
                self.invokeEditorMethod("tagSelection", `[${itemTag}]`, `[/${itemTag}]`);
                self.$emit("action", { name: "applies-to", data: itemTag })
            },

            loadDocument(content=null, fileType=null) {
                const self = this;
                if(!_.isNil(content)) {
                    self.setEditorContent(content, fileType);
                    return;
                }

                if(!self.editorInitialized || !self.contentReady) return;

                self.invokeEditorMethod("load", self.editorDocumentName, self.editorFileType, self.editorContent);
                self.documentLoaded = true;
            },

            getPrompts() {
                const self = this;
                if(!_.isNil(self.promptResult)) Promise.resolve(self.promptResult);
                return new Promise((resolve, reject) => {
                    self.dispatchContentRequest("getPrompts")
                        .then(result => {
                            self.promptResult = result;
                            resolve(result);
                        })
                        .catch(error => {
                            let msg = error.errorMessage || error.message;
                            self.$toast.error(`Error getting prompts: ${msg}`);
                            console.error(error);
                            reject(error);
                        });
                });
            },

            showStatementDialog(dialogOptions) {
                const self = this;
                let mergedOptions = _.merge({}, {
                    title: "Insert Merge Statement",
                    component:{ template: "<div class='lead'>No component provided</div>" },
                    height: window.innerHeight > 768 ? "80%" : 700,
                    width: window.innerWidth > 1367 ? "80%" : 1200,
                    minHeight: 685,
                    minWidth: 1100,
                    scrollable: false,
                    onOk(e) {
                        let statement = e.component.currentStatement;
                        return self.insertText(statement);
                    }
                }, dialogOptions);

                self.$dialog.open(mergedOptions);
            },

            insertText(content) { return this.invokeInsert(content, "insertText"); },
            insertRtf(content) { return this.invokeInsert(content, "insertRtf"); },
            invokeInsert(content, method) {
                const self = this;
                if(_.isEmpty(content)) return true;
                self.invokeEditorMethod(method, content);
                return true;
            },

            lockSelection() {
                const self = this;
                self.invokeEditorMethod("tagSelection", "[BL]", "[EL]");
            },

            launchMergeFieldDialog() {
                const self = this;
                const okHandler = function(e) {
                    let statement = e.component.fieldStatement;
                    self.mergeFieldDialogState = e.component.getTreeState();
                    return self.insertText(statement);
                }
                const cancelHandler = function(e) {
                    self.mergeFieldDialogState = e.component.getTreeState();
                    return true;
                }
                self.showStatementDialog({
                    title: "Merge Field/Merge Loop",
                    component: MergeFieldSelection,
                    props: { treeState: self.mergeFieldDialogState },
                    onOk: okHandler,
                    onCancel: cancelHandler
                });
            },

            launchPromptDialog(){
                const self = this;
                const okHandler = function(e) {
                    let isValid = e.component.validate();
                    if(isValid){
                        let statement = e.component.currentStatement;
                        return self.insertText(statement);
                    }
                    return isValid;
                }
                self.showStatementDialog({
                    title: "Add a PROMPT statement",
                    component: PromptForm,
                    onOk: okHandler
                });
            },

            launchPromptValueDialog(){
                const self = this;
                self.getPrompts()
                    .then(prompts => {
                        if(_.isNil(prompts) || _.isEmpty(prompts)){
                            self.$dialog.messageBox("No Prompts","No Prompts detected on this Document Template");
                            return;
                        }
                        self.showStatementDialog({
                            title: "Add a PROMPTVALUE Statement",
                            component: PromptValueForm,
                            props: { prompts }
                        });
                    });
            },

            launchConditionDialog(){
                const self = this;
                const okHandler = function(e) {
                    let statement = e.component.currentStatement;
                    self.mergeFieldDialogState.items = e.component.getFieldTreeItems();
                    return self.insertText(statement);
                }
                const cancelHandler = function(e) {
                    self.mergeFieldDialogState.items = e.component.getFieldTreeItems();
                    return true;
                }
                let fetchPromise = self.getPrompts();
                self.$rqBusy.wait(fetchPromise)
                    .then(prompts => {
                        self.showStatementDialog({
                            title: "Add a Condition Statement",
                            component: MergeConditionForm,
                            props: { prompts, fields: self.mergeFieldDialogState.items },
                            onOk: okHandler,
                            onCancel: cancelHandler
                        });
                    });
            },

            launchFieldCharCountDialog(){
                const self = this;
                const okHandler = function(e) {
                    let statement = e.component.currentStatement;
                    self.mergeFieldDialogState.items = e.component.getFieldTreeItems();
                    return self.insertText(statement);
                }
                const cancelHandler = function(e) {
                    self.mergeFieldDialogState.items = e.component.getFieldTreeItems();
                    return true;
                }
                let fetchPromise = self.getPrompts();
                self.$rqBusy.wait(fetchPromise)
                    .then(prompts => {
                        self.showStatementDialog({
                            title: "Add a Character Count Statement",
                            component: MergeCharCountForm,
                            props: { prompts, fields: self.mergeFieldDialogState.items },
                            height: 700,
                            width: 1100,
                            onOk: okHandler,
                            onCancel: cancelHandler
                        });
                    });
            },

            launchMergeDocumentDialog(){
                const self = this;
                self.showStatementDialog({
                    title: "Insert Document Statement",
                    component: MergeDocumentForm,
                    props: {
                        documentTemplateId: self.documentTemplateId,
                        documentTemplateRegionId: self.documentTemplateRegionId
                    },
                    height: 700,
                    width: 1100
                });
            },

            launchDateTimeDialog(){
                const self = this;
                self.showStatementDialog({
                    title: "Insert Date/Time",
                    component: MergeDateTimeForm,
                    height: "auto",
                    width: 600,
                    minHeight: 200,
                    minWidth: 600,
                    resizable: false,
                    adaptive: true
                });
            },

            launchStoredProcedureDialog(){
                const self = this;
                let apiPromise = self.$api.DocumentTemplatesApi.getAvailableStoredProcedures();
                self.$rqBusy.wait(apiPromise)
                    .then(self.showStoredProcedureDialog)
                    .catch(error => {
                        let msg = error.errorMessage || error.message;
                        self.$toast.error(msg);
                    });
            },

            showStoredProcedureDialog(storedProcedures){
                const self = this;

                const okHandler = function(e) {
                    let statement = e.component.currentStatement;
                    let proc = e.component.selectedStoredProcedure;
                    return self.dispatchContentRequest("getDocumentStoredProcedures")
                        .then(results => {
                            let match = _.some(results, r => { return r.alias === proc.alias;});
                            if(match){
                                e.component.validationErrors.push(`There is already a stored procedure with the alias ${proc.alias}`);
                                return false;
                            }
                            self.insertText(statement);
                            return true;
                        }).catch(error => {
                            let msg = error.errorMessage || error.message;
                            self.$toast.error(`Error getting prompts: ${msg}`);
                            console.error(error);
                            return true;
                        });
                }

                if(_.isNil(storedProcedures) || _.isEmpty(storedProcedures)){
                    self.$dialog.confirm("No Stored Procedures","No Stored Procedures available in the current system");
                    return;
                }
                self.showStatementDialog({
                    title: "Add a SP (Stored Procedure) statement",
                    component: StoredProcedureForm,
                    props: {storedProcedures: storedProcedures},
                    onOk: okHandler
                });
            },

            launchStoredProcedureValueDialog(){
                const self = this;

                let fetchPromise = self.dispatchContentRequest("getDocumentStoredProcedures")
                    .then(self.showStoredProcedureValueDialog)
                    .catch(error => {
                        let msg = error.errorMessage || error.message;
                        self.$toast.error(`Error getting prompts: ${msg}`);
                        console.error(error);
                    });

                self.$rqBusy.wait(fetchPromise);
            },

            showStoredProcedureValueDialog(storedProcedures){
                const self = this;
                if(_.isNil(storedProcedures) || _.isEmpty(storedProcedures)){
                    self.$dialog.confirm("No Stored Procedures","No Stored Procedures on the current document");
                    return;
                }
                self.showStatementDialog({
                    title: "Add a SPV (Stored Procedure Value) statement",
                    component: StoredProcedureValueForm,
                    props: {storedProcedures: storedProcedures}
                });

            },

            launchClausePromptDialog(){
                const self = this;

                const okHandler = function(e) {
                    let isValid = e.component.validate();

                    if(!isValid) return Promise.resolve(false);

                    let statement = e.component.currentStatement;
                    let name = `@${e.component.name}`;

                    let promise = self.dispatchContentRequest("getDocumentStandardLanguagePrompts")
                        .then(results => {
                            let match = _.some(results, r => { return r.name === name;});
                            if(match){
                                e.component.validationErrors.push(`There is already a Clause Prompt with the Name ${name}`);
                                return false;
                            }
                            self.insertText(statement);
                            return true;
                        })
                        .catch(error => {
                            let msg = error.errorMessage || error.message;
                            self.$toast.error(`Error getting clause prompts: ${msg}`);
                            console.error(error);
                            return true;
                        });

                    return promise;
                }

                self.showStatementDialog({
                    title: "Add a Clause PROMPT statement",
                    component: StandardLanguagePromptForm,
                    onOk: okHandler
                });
            },

            launchClauseSelectionDialog () {
                const self = this;
                let okHandler = async (e) => {
                    let isValid = await e.component.validate();
                    if(isValid) {
                        let dialogResult = e.component.getResult();
                        self.insertStandardLanguages(dialogResult.items, dialogResult.linesBetweenClauses);
                    }
                    return isValid;
                };
                self.$dialog.open({
                    title: "Clause Selection",
                    height: "80%",
                    width: "80%",
                    component: StandardLanguageDialog,
                    props: {
                        categoryId: self.defaultCategoryId,
                        categories: self.categories,
                        skipPromptEntry: !self.enableClausePromptEntry
                    },
                    onOk: okHandler
                });
            },

            insertStandardLanguages(standardLanguages, linesBetweenClauses) {
                const self = this;
                let requestData = {
                    standardLanguageIDs:[],
                    standardLanguages: [],
                    linesBetweenClauses: linesBetweenClauses
                };
                if(self.enableClausePromptEntry) {
                    requestData.standardLanguages = _.map(standardLanguages, sl => sl.toDataObject());
                    requestData.ordersID = self.orderId;
                    requestData.loanID = self.loanId;
                }
                else {
                    requestData.standardLanguageIDs = _.map(standardLanguages, "standardLanguageID");
                }
                let apiPromise = self.$api.DocumentsApi.getStandardLanguageListContent(requestData, "rtf");
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.insertRtf(result);
                    });
            },

            dispatchContentRequest(endpointName) {
                const self = this;
                return self.invokeEditorMethod("getContent", DocumentFileType.DOCX)
                    .then(content => self.$api.DocumentTemplatesApi[endpointName]({ documentTemplateID: self.documentTemplateId, content }));
            },

            encodeRequestContent(blob) {
                return new Promise((resolve,reject) => {
                    let reader = new FileReader();
                    reader.onload = function(e) {
                        resolve(btoa(e.target.result));
                    }
                    reader.readAsBinaryString(blob);
                });
            },

            async getContentByFormat(fileType, html=false, fragment=false) {
                const self = this;
                let content = await self.invokeEditorMethod("getContent", fileType, true);
                let htmlContent = html && fileType === DocumentFileType.RTF
                    ? await self.$api.UtilitiesApi.rtfToHtml(content, false, fragment)
                    : html && fileType === DocumentFileType.DOCX
                        ? await self.$api.UtilitiesApi.docxToHtml(content, fragment)
                        : null;
                return {
                    content,
                    htmlContent
                };
            },

            async getContentInternal({ html=false, rtf=false, docx=false, pdf=false, fragment=false, encoded=false }) {
                const self = this;
                let result = { html: null, rtf: null, docx: null, pdf: null, changed: true };
                if(rtf) {
                    let rtfContent = await self.invokeEditorMethod("getContent", DocumentFileType.RTF, true);
                    result.rtf = encoded || _.isNil(rtfContent)
                        ? rtfContent
                        : atob(rtfContent);
                }

                if(docx || pdf || (!rtf && html)) {
                    result.docx = await self.invokeEditorMethod("getContent", DocumentFileType.DOCX, true);
                    if(pdf && !_.isEmpty(result.docx))
                        result.pdf = await self.$api.UtilitiesApi.docxToPdf(result.docx);
                }

                if(html) {
                    result.html = !_.isEmpty(result.rtf)
                        ? await self.$api.UtilitiesApi.rtfToHtml(result.rtf, false, fragment)
                        : !_.isEmpty(result.docx)
                            ? await self.$api.UtilitiesApi.docxToHtml(result.docx, fragment)
                            : null;
                }

                return result;
            },

            async getContent(resultOptions) {
                const self = this;
                let contentResult = { html: null, rtf: null, docx: null, pdf: null, changed: false };
                if(!self.isDirty()) {
                    return contentResult;
                }
                let opts = _.defaults(resultOptions, { html: false, rtf: false, docx: false, pdf: false, fragment: false, encoded: false, showBusy: true });
                return opts.showBusy
                    ? await self.$rqBusy.wait(self.getContentInternal(opts))
                    : await self.getContentInternal(opts);
            },

            getRtfContent() {
                return this.getContent({ rtf: true });
            },

            getHtmlContent(fragment=false) {
                return this.getContent({ html: true, fragment });
            },

            isDirty() {
                return this.invokeEditorMethod("isDirty");
            },

            invokeEditorMethod(method, ...params) {
                return _.invoke(this, `$refs.dxRichEdit.${method}`, ...params);
            }
        }
    };

</script>