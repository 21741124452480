
import { ref, unref, watchEffect } from "vue";
export function usePopoverVisibleBindings(visibleRef, delayShown=300, delayHidden=0) {
    const isActive = ref(false);
    const isVisible = ref(false);

    const setIsVisible = (val, ms) => {
        if(ms <= 0)
            isVisible.value = val;
        else
            _.delay(() => { isVisible.value = val; }, ms);
    };
    watchEffect(() => {
        let visibleVal = unref(visibleRef);
        let delayShownVal = unref(delayShown);
        let delayHiddenVal = unref(delayHidden);

        if(visibleVal) {
            isActive.value = true;
            setIsVisible(true, delayShownVal);
        }
        else {
            //update "isActive" to false in "@hidden" DxPopover event to allow transition to complete
            setIsVisible(false, delayHiddenVal);
        }
    });
    return {
        isVisible,
        isActive
    };
}