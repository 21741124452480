
/* More info on store.js: https://github.com/marcuswestin/store.js/ */
import storeEngine from 'storeEngine';
import storeSessionStorage from 'storeSessionStorage';
import storeExpirePlugin from 'storeExpirePlugin';

const store = storeEngine.createStore(
    [storeSessionStorage], // storages
    [storeExpirePlugin] // plugins
);

/**
 * Static service for managing localStorage or sessionStorage items
 * utilizing a designated storage library
 */
export class StoreService {

    /**
     * Returns the stored object or data at the provided key
     * @param {string} key
     */
    static getItem (key) {
        return store.get(key);
    }

    /**
     * Sets a new store value at the storeItem key then sets the storeItem's expire value
     * based on the provided lifetime value OR the default lifetime of 20 min.
     * Passing 0 for lifetime results in setting no expiration for the item
     * @param {string} key
     * @param {any} value
     * @param {int} lifetime
     */
    static setItem (key, value, lifetime = 1200000) {
        if (lifetime > 0) {
            const expires = Date.now() + lifetime;
            store.set(key, value, expires);
        } else {store.set(key, value);}
    }

    /**
     * Remove a storage entry
     * @param {string} key
     */
    static removeItem (key) {
        store.remove(key);
    }

    /**
     * Clears out all storage entries
     */
    static clearAll () {
        store.clearAll();
    }

    /**
     * Loops over all stored values.
     * @param {function} looperFunction
     */
    static each (looperFunction) {
        store.each(looperFunction);
    }
}
