import { findRecursive } from "./helpers";
import { UserSecuritySettings, SystemLookupItem, BranchDto, Region, RoleLookupItem } from "../shared/models/models";
import { UserScreenAccessLevel } from "../shared/models/enums";
import { TitleCompanyLookupItem, BiRoleLookupItem } from "../modules/file/order-entry/models";
import { PropertyLayoutModel } from "../modules/file/order-entry/property/models";
import { ProrationDefault } from "../modules/file/settlement/models";
import LookupNames from "./lookupNames";

// import JwtService from "../shared/services/jwt.service";

export const isSessionValid = state => {return state.authentication.isAuthenticated;};
export const currentSession = state => {return state.authentication.session;};
export const currentSystem = state => {return state.authentication.system;};
export const userDefaultRegion = state => { return _.find(state.authentication.session.regions, r => r.isDefault) || {}; };
export const lastError = state => { return state.error; };
export const taskCounts = state => {return { assignedToMe: 20, assignedToMyDepartmens: 30, dueToday: 2, overdue: 4 };};
export const lastOrderId = state => {return state.orders.orderId || _.get(state, "authentication.session.user.lastOrderId", -3);};

export const getNavPath = state => navId => {
    let item = findRecursive(state.navItems, "id", navId, "children");
    return _.get(item, "path", null) || null;
};

export const getRouteAccess = state => (route, forcedDefault=null )=> {

    let accessKeyValue = _.get(route, "meta.accessKey", null);
    let permissionKeyValue = _.get(route, "meta.permissionKeys", []);
    let sysAdminRequired = _.get(route, "meta.sysAdminRequired", false);

    let accessKeys = _.parseKeyListValue(accessKeyValue, "_ScreenAccess");
    let permissionKeys = _.parseKeyListValue(permissionKeyValue);

    let authSettings = _.get(state, "authentication.session.settings", null);
    let settings = new UserSecuritySettings(authSettings);
    let isAdmin = settings.findValue("IsAdmin");
    let isSysAdmin = settings.findValue("IsSysAdmin");
    let isConfigRoute = _.startsWith(_.toLower(route.name || _.get(route, "children[0].name", "")), "cfg:");
    let isConfigSearch = _.toLower(route.name) === "cfg:search";

    if(sysAdminRequired && !isSysAdmin && !isConfigSearch) {
        return { keys: ["Admin Access"], accessLevel: UserScreenAccessLevel.None, hasAccess: false}
    }

    if(isAdmin && isConfigRoute) {
        return { keys: ["Admin Access"], accessLevel: UserScreenAccessLevel.Full, hasAccess: true };
    }

    //let defaultLevel = isConfigRoute ? UserScreenAccessLevel.None : UserScreenAccessLevel.Full;
    let defaultLevel = _.isNil(forcedDefault)
        ? isConfigRoute
            ? UserScreenAccessLevel.None
            : UserScreenAccessLevel.Full
        :   forcedDefault;

    if(_.isEmpty(accessKeys) && _.isEmpty(permissionKeys)) {
        return {
            keys: [`Default Access - ${UserScreenAccessLevel.displayValue(defaultLevel)}`],
            accessLevel: defaultLevel,
            hasAccess: defaultLevel !== UserScreenAccessLevel.None
        };
    }

    let allKeys = [];
    let accessLevel = defaultLevel;
    let accessLevelAggr = isConfigSearch ? "max" : "min";

    if (!_.isEmpty(accessKeys)) {
        allKeys = accessKeys.slice();
        accessLevel = settings.getAccessLevel(accessKeyValue, accessLevelAggr);
    }

    let isAccessResolved = accessLevel !== defaultLevel;

    if(!_.isEmpty(permissionKeys)) {
        allKeys.push(...permissionKeys);
        if(!isAccessResolved) {
            let hasAccess = isConfigSearch
                ? settings.hasAnyPermission(permissionKeyValue)
                : settings.hasPermission(permissionKeyValue);
            accessLevel = hasAccess ? UserScreenAccessLevel.Full : UserScreenAccessLevel.None;
            isAccessResolved = hasAccess === isConfigRoute || accessLevel !== defaultLevel;
            if(isConfigSearch){
                accessLevel = UserScreenAccessLevel.Full;
                isAccessResolved = true;
            }

        }
    }

    if(isAccessResolved || isConfigRoute) return { keys: allKeys, accessLevel, hasAccess: accessLevel !== UserScreenAccessLevel.None };

    let readOnlyWhenLocked = _.getBool(route, "meta.readOnlyWhenLocked") || _.includes(route.path, "order");
    let isFileLocked = _.getBool(state, "orders.orderSummary.isLocked");
    if(readOnlyWhenLocked && isFileLocked) {
        allKeys.push("file-locked");
        accessLevel = UserScreenAccessLevel.Read;
    }

    let readOnlyWhenSettlementLocked = _.startsWith(route.name, "settlement:");
    let isSettlementLocked = _.getBool(state, "orders.orderSummary.isSettlementLocked");
    if(readOnlyWhenSettlementLocked && isSettlementLocked) {
        allKeys.push("settlement-locked");
        accessLevel = UserScreenAccessLevel.Read;
    }

    let isCheckWriting = _.includes(route.path, "check-writing");
    if(isCheckWriting){
        //note this setting is reversed in the UI.  The label is "Check Writing R/O when bank is outside User's region", so if this is true, the permission is actually disallowed
        let allowCheckWritingEscrowOutOfRegion = settings.findValue("AllowCheckWritingEscrowOutOfRegion");
        if(!allowCheckWritingEscrowOutOfRegion){
            let bankRegionID = _.get(state, "orders.order.bankCompanyRegionID", 0);
            if(bankRegionID > 0){
                let userRegions = _.get(state, "authentication.session.regions", []);
                //if the user isn't in the region of the bank, make check writing read-only
                if(!_.some(userRegions, r => r.regionID === bankRegionID)){
                    accessLevel = UserScreenAccessLevel.Read;
                }
            }
        }
    }

    return { keys: allKeys, accessLevel, hasAccess: accessLevel !== UserScreenAccessLevel.None };
};

export const lookupItems = () => LookupNames;

export const userSecuritySettings = state => {
    let authSettings = _.get(state, 'authentication.session.settings', null);
    return authSettings ? new UserSecuritySettings(authSettings) : null;
};

export const permissionService = (state, getters) => {
    return {
        hasPermission: key => { return getters.userSecuritySettings && key ? getters.userSecuritySettings.findValue(key) : false; },
        getDate: key => { return getters.userSecuritySettings && key ? getters.userSecuritySettings.findValue(key) : null; },
        getSetting: key => { return getters.userSecuritySettings && key ? getters.userSecuritySettings.findValue(key) : UserScreenAccessLevel.None; },
        hasCompanyAccess: regionId => {
            if(_.parseNumber(regionId, 0) <= 0) return false;
            let currentUserAccessibleRegions = state.authentication.session.regions;
            let companyRegion = _.find(currentUserAccessibleRegions, r => _.parseNumber(r.regionID) === regionId);
            let adminRegion = _.find(currentUserAccessibleRegions, r => r.isAdminRegion);
            let userHasAccessToRegion = !_.isNil(companyRegion);
            let userHasAdminRegion = !_.isNil(adminRegion);

            // Permission settings
            let allowEditCompanyDetails = _.parseBool(getters.userSecuritySettings.findValue("AllowEditCompanyDetails"));

            return userHasAdminRegion || (allowEditCompanyDetails && userHasAccessToRegion);
        }
    };
};

export const executionService = state => {
    let settingsVal = _.get(state, 'authentication.session.settings', null);
    let securitySettings = new UserSecuritySettings(settingsVal);
    return {
        canExecuteAction: (action, evaluateAsBinary=false) => {
            let permissionKeys = action.permissionKeys || [];
            let accessKey = action.accessKey || "";

            if (_.isEmpty(permissionKeys) && _.isEmpty(accessKey)) return true;

            let hasPermission = securitySettings.hasPermission(permissionKeys);
            let hasAccess = securitySettings.hasAccess(accessKey);
            let isReadOnly = securitySettings.isReadOnly(accessKey);
            return hasPermission && hasAccess && (evaluateAsBinary || !isReadOnly);
        }
    };
};

export const lookupHelpers = (state, getters) => {
    return {
        getLookupItems (lookupKey, regionId=null, selectedId=-1) {
            let regionID = _.parseNumber(regionId, 0);
            let globalRegionId = _.getNumber(state.system, 'globalRegionId', 0);
            let items = _.filter(_.get(state.system, `lookups.${lookupKey}`, []), item => !_.parseBool(item.inactive) || item.id === selectedId);
            if (regionID === 0 || globalRegionId === regionID) return _.map(items, item => new SystemLookupItem(item));
            items = _.filter(items, item => _.includes([0, regionID, globalRegionId], _.parseNumber(item.regionID, 0)));
            return _.map(items, item => new SystemLookupItem(item));
        },
        getAllLookupItems (lookupKey, regionId=null, selectedId=-1) {
            let regionID = _.parseNumber(regionId, 0);
            let globalRegionId = _.getNumber(state.system, 'globalRegionId', 0);
            let items = _.get(state.system, `lookups.${lookupKey}`, []);
            if (regionID === 0 || globalRegionId === regionID) return _.map(items, item => new SystemLookupItem(item));
            items = _.filter(items, item => _.includes([0, regionID, globalRegionId], _.parseNumber(item.regionID, 0)) || item.id === selectedId);
            return _.map(items, item => new SystemLookupItem(item));
        },
        getOrderLookupItems (lookupKey, selectedId=-1) {
            let regionID = _.get(state.orders, 'orderSummary.regionID', null) || _.get(state.orders, 'currentRegionId', -1);
            let globalRegionId = _.getNumber(state.system, 'globalRegionId', 0);
            let items = _.filter(_.get(state.system, `lookups.${lookupKey}`, []), item => !_.parseBool(item.inactive) || item.id === selectedId);
            items = _.filter(items, item => _.includes([regionID, globalRegionId], _.parseNumber(item.regionID, 0)) || (item.regions && _.some(item.regions, r => r.regionID === regionID || r.regionID === globalRegionId)));
            return _.map(items, item => new SystemLookupItem(item));
        },
        getLookupItem (lookupKey, id=0) {
            let items = _.get(state.system, `lookups.${lookupKey}`, null);
            if (_.isNil(items)) return new SystemLookupItem({id:-1 , name:'Invalid lookupKey', data: null });
            let item = _.find(items, item => item.id == _.parseNumber(id)) || new SystemLookupItem();
            return item;
        },
        getLookupItemName (lookupKey, id=0) {
            let item = getters.lookupHelpers.getLookupItem(lookupKey, id);
            return item.name;
        },
        getPropertyLayouts (regionId=null) {
            let regionID = _.parseNumber(regionId, 0);
            let globalRegionId = _.getNumber(state.system, 'globalRegionId', 0);
            let items = _.get(state.system, 'lookups.propertyLayouts', []);
            if (regionID === 0 || globalRegionId === regionID) return _.map(items, item => new PropertyLayoutModel(item));
            items = _.filter(items, item => _.includes([0, regionID, globalRegionId], _.parseNumber(item.regionID)));
            return _.map(items, item => new PropertyLayoutModel(item));
        },
        getOrderPropertyLayouts (regionId=null) {
            let regionID = regionId || _.get(state.orders, 'orderSummary.regionID', null) || _.get(state.orders, 'currentRegionId', -1);
            return getters.lookupHelpers.getPropertyLayouts(regionID);
        },
        getAllStaff() {
            return _.get(state.system, 'lookups.staff', []);
        },
        getBranch (branchId=0) {
            let items = _.get(state.system, 'lookups.branches', []);
            let branch = _.find(items, {id: branchId});
            return branch ? new BranchDto(branch) : new BranchDto();
        },
        getBranches (regionId=0, includeRegionName=false) {
            let items = _.get(state.system, 'lookups.branches', []);
            if (_.isArray(regionId)) {
                items = _.filter(items, item => _.includes(regionId, _.parseNumber(item.regionID)));
            } else {
                items = _.filter(items, item => item.regionID == regionId);
            }
            if(includeRegionName) {
                items = _.map(items, item => {
                    return {...item, name: `${item.regionDisplay}/${item.name}`}
                })
            }
            return _.map(items, item => new SystemLookupItem(item));
        },
        getUnderwriters (regionId=0, includeInactive=false) {
            let globalRegionId = _.get(state.system, 'globalRegionId', 0);
            let items = _.get(state.system, 'lookups.underwriters', []);
            let regionList = [];
            if (_.isArray(regionId)) {
                regionList = regionId;
            } else {
                regionList = [regionId];
            }
            if (_.indexOf(regionList, globalRegionId) == -1) {
                regionList.push(globalRegionId);
                items = _.filter(items, item => (!item.inactive || includeInactive) && _.includes(regionList, _.parseNumber(item.regionID)));
                return _.map(items, item => new SystemLookupItem(item));
            } else {
                return _.map(items, item => new SystemLookupItem(item));
            }
        },
        getRegion (regionId=null) {
            let items = getters.lookupHelpers.getRegions();
            let regionID = regionId || _.get(state.orders, 'orderSummary.regionID', null) || _.get(state.orders, 'currentRegionId', -1);
            let region = _.find(items, {regionID: regionID});
            return region ? new Region(region) : new Region();
        },
        getRegions () {
            let items = _.sortBy(_.get(getters, 'currentSession.regions', []), ['regID']);
            return _.map(items, item => new Region(item));
        },
        getLoanOptions () {
            let items = _.get(state.orders, 'loans', []);

            let formatMoneyValue = function (v) {
                return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } });
            }

            return _.map(items, (item, index) => {
                return {
                    id: item.loanID,
                    name: `${index + 1} of ${items.length} : ${formatMoneyValue(item.amount)}`
                };
            });
        },
        getOrderServices (alta=null) {
            let items = _.get(state.system, "lookups.orderServices", []);
            let defaultAltaVal = _.getBool(state.system, "systemDefaults.defaultCMT", false);
            let isAlta = _.isNil(alta) ? _.getBool(state.orders, "order.isAlta", defaultAltaVal) : _.parseBool(alta);
            items = _.filter(items, item => _.parseBool(item.data) === isAlta);
            return _.map(items, item => new SystemLookupItem(item));
        },
        getProrationDefaults (regionId=null) {
            let regionID = regionId || _.get(state.orders, 'orderSummary.regionID', null) || _.get(state.orders, 'currentRegionId', -1);
            let items = _.get(state.system, 'lookups.prorationDefaults', []);
            items = _.filter(items, item => _.parseNumber(item.regionID) === _.parseNumber(regionID));
            return _.map(items, item => new ProrationDefault(item));
        },
        getTitleCompanies (regionId=null, selectedId=-1) {
            let globalRegionId = _.get(state.system, 'globalRegionId', 0);
            let regionID = regionId || _.get(state.orders, 'orderSummary.regionID', null) || _.get(state.orders, 'currentRegionId', -1);
            let items = _.filter(_.get(state.system, 'lookups.titleCompanies', []), item => !_.parseBool(item.inactive) || item.id == selectedId);
            if (globalRegionId === regionID) return _.map(items, item => new TitleCompanyLookupItem(item));
            items = _.filter(items, item => _.includes([0, regionID, globalRegionId], _.parseNumber(item.regionID)));
            return _.map(items, item => new TitleCompanyLookupItem(item));
        },

        getBiRoles (regionId=null) {
            let globalRegionId = _.get(state.system, 'globalRegionId', 0);
            let regionID = regionId || _.get(state.orders, 'orderSummary.regionID', null) || _.get(state.orders, 'currentRegionId', -1);
            let items = _.filter(_.get(state.system, `lookups.biRoles`, []), item => item.inactive == false);
            if (globalRegionId === regionID) return _.map(items, item => new BiRoleLookupItem(item));
            //items = _.filter(items, item => _.includes([0, regionID, globalRegionId], _.parseNumber(item.regionID)));
            return _.map(items, item => new BiRoleLookupItem(item));
        },

        getCountiesByState (stateAbbr='') {
            let items = _.get(state.system, 'lookups.counties', []);
            items = _.filter(items, item => item.data === stateAbbr);
            return _.map(items, item => new SystemLookupItem(item));
        },

        getCountiesInStates (stateList=[]) {
            let items = _.get(state.system, 'lookups.counties', []);
            items = _.filter(items, item => _.includes(stateList, item.data));
            return _.map(items, item => new SystemLookupItem(item));
        },
        getLocations (placeOfClosingId=0) {
            let items = _.get(state.system, 'lookups.locations', []);
            items = _.filter(items, item => _.isEqual(item.data, placeOfClosingId.toString()));
            return _.map(items, item => new SystemLookupItem(item));
        },
        getStates () {
            let items = _.get(state.system, 'lookups.states', []);
            return _.map(items, item => new Object({id: item.data, name: item.name}));
        },
        getStatesWithID () {
            let items = _.get(state.system, 'lookups.states', []);
            return _.map(items, item => new SystemLookupItem(item));
        },
        getRoles (filterIDs=[], filterOn=null, filterValue=null, includeDisabled = false) {
            let roles = _.get(state.system, 'lookups.roles', []);
            roles = includeDisabled ? roles : _.filter(roles, {enabled: true});
            if (filterOn) roles = _.filter(roles, role => _.parseBool(_.get(role, filterOn), false) == filterValue);
            if (!_.isEmpty(filterIDs)) roles = _.filter(roles, role => _.includes(filterIDs, _.parseNumber(role.id)));
            return _.map(roles, r => new RoleLookupItem(r));
        },
        getCompanyRoles (roleTypeId=0, includeAdditionalVariants=false) {
            let allRoles = _.get(state.system, 'lookups.roles', []);
            let roles = includeAdditionalVariants ? allRoles : _.filter(allRoles, role => !(_.includes(role.name, "Additional", 0) && role.canDelete));
            let mapped = _.map(roles, r => ({...r, inactive: !r.enabled}))
            if (roleTypeId <= 0) return _.map(mapped, role => new SystemLookupItem(role));
            roles = _.filter(roles, role => _.parseNumber(role.id) === _.parseNumber(roleTypeId, 0));
            return _.map(roles, role => new SystemLookupItem(role));
        },
        getDefaulyLines (lineTypeId=0) {
            // need to return a hard list:: [Description("None") = 0], [Description("One") = 1], [Description("Two") = 2]
        },
        getOutsideParties(contactList=null) {
            let listToMap = _.isNil(contactList)
                ? _.get(state, "orders.contacts.contacts", [])
                : contactList;
            return _.map(listToMap, c => {
                let id = c.rolesID;
                let name = `${c.role} - Contact name not available`;

                if(!_.isNil(c.buyerSellerID)){
                    name = _.isEmpty(c.name)
                        ? `${c.role} - Name not available`
                        : c.name;
                }
                else if(!_.isNil(c.companyID)) {
                    let contactName = _.isEmpty(c.firstName) && _.isEmpty(c.lastName)
                        ? null
                        : _.joinParts([c.firstName, c.lastName], " ", true);
                    if(contactName)
                        name = contactName;
                    else if(c.name)
                        name = c.name;
                }

                return { id, name };
            });
        },
        getOrderTimeTrackingCategoriesByState (stateAbbr='') {
            let items = _.get(state.system, 'lookups.orderTimeTrackingCategories', []);
            items = _.filter(items, item => item.data === stateAbbr);
            return _.map(items, item => new SystemLookupItem(item));
        },
        getOrderTimeTrackingAccountCodesByState (stateAbbr='') {
            let items = _.get(state.system, 'lookups.orderTimeTrackingAccountCodes', []);
            items = _.filter(items, item => item.data === stateAbbr);
            return _.map(items, item => new SystemLookupItem(item));
        }
    };
};

export const regionsLookup = (state, getters) => getters.lookupHelpers.getRegions();
export const usStatesLookup = (state, getters) => getters.lookupHelpers.getStates();