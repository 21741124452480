<template>
    <DxPopover
        v-if="isActive"
        :target="`#${popover.target}`"
        :container="container"
        :position="placement"
        title-template="rq-popover-title"
        :show-title="true"
        :wrapper-attr="{ class: 'rq-popover rq-check-detail-popover' }"
        v-model:visible="isVisible"
        @hidden="onHidden">
        <template #rq-popover-title>
            <div class="rq-popover-header">
                <span class="title">{{title}}</span>
                <b-btn variant="icon" class="close-button" v-rq-tooltip.top.hover title="Close" @click="isVisible = false">
                    <FontAwesomeIcon icon="fas fa-times" />
                </b-btn>
            </div>
        </template>
        <div class="rq-container">
            <dl class="row" v-if="popover.isCheck">
                <dt class="col-3 popover-label">Date:</dt> <dd class="col-9">{{formatDate(popover.item.checkDate || popover.item.datePrinted)}}</dd>
                <dt class="col-3 popover-label">Amount:</dt> <dd class="col-9">{{formatMoney(popover.item.amount)}}</dd>
                <dt class="col-3 popover-label">Payee:</dt> <dd class="col-9">{{formatPayee()}}</dd>
                <dt class="col-3 popover-label">Payee Address:</dt> <dd class="col-9" v-html="formatAddress(popover.item)"></dd>
                <template v-if="hasExpectedWireOutDate">
                    <dt class="col-3 popover-label">Expected Wire Date:</dt> <dd class="col-9">{{formatDate(popover.item.expectedWireOutDate)}}</dd>
                </template>
                <dt class="col-3 popover-label">Memo:</dt> <dd class="col-9">{{popover.item.memo}}</dd>
            </dl>
            <dl class="row" v-else>
                <dt class="col-3 popover-label">Date:</dt> <dd class="col-9">{{formatDate(popover.item.depositDate || popover.item.datePrinted)}}</dd>
                <dt class="col-3 popover-label">Amount:</dt> <dd class="col-9">{{formatMoney(popover.item.amount)}}</dd>
                <template v-if="hasExpectedWireDate">
                    <dt class="col-3 popover-label">Expected Wire Date:</dt> <dd class="col-9">{{formatDate(popover.item.expectedWireDate)}}</dd>
                </template>
                <template v-if="hasWireNumber">
                    <dt class="col-3 popover-label">Wire Number:</dt> <dd class="col-9">{{popover.item.wireNumber}}</dd>
                </template>
                <dt class="col-3 popover-label">Payor:</dt> <dd class="col-9">{{popover.item.payor}}</dd>
                <dt class="col-3 popover-label">Receipt ID:</dt> <dd class="col-9">{{popover.item.receiptDisplay}}</dd>
                <dt class="col-3 popover-label">Reference Number:</dt> <dd class="col-9">{{popover.item.referenceNumber}}</dd>
            </dl>
            <rqdx-action-data-grid
                ref="dataGrid"
                automation_id="tbl_lines"
                :config="gridConfig"
                :data-source="popover.itemDetail"
                :persist-state="false"
                class="pb-0"
                fixed-header
                hide-toolbar
            />
        </div>
    </DxPopover>
</template>

<script>
    import { computed } from "vue";
    import { mapGetters, mapState } from "vuex";
    import { DateTime } from "luxon";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { usePopoverVisibleBindings } from "./usePopoverVisibleBindings";

    export default {
        name: "CheckWritingPopover",
        props: {
            placement: { type: [String,Object], default: "left" },
            container: { type: [String, HTMLElement, Object], default: null },
            popover: { type: Object, default: () => ({target: "", isCheck: false, item: {}, itemDetail: [], visible: false}) },
            visible: { type: Boolean, default: false },
        },
        setup(props, { emit }) {
            const visibleProp = computed(() => props.visible);
            const { isActive, isVisible } = usePopoverVisibleBindings(visibleProp);
            const onHidden = () => emit("update:visible", false);
            return { isVisible, isActive, onHidden };
        },
        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                order: state => state.orders.order,
            }),
            hasExpectedWireOutDate() { return !_.isNullOrEmpty(this.popover.item.expectedWireOutDate); },
            hasExpectedWireDate() { return !_.isNullOrEmpty(this.popover.item.expectedWireDate); },
            hasWireNumber() { return !_.isNullOrEmpty(this.popover.item.wireNumber); },
            title() { return _.parseBool(this.popover.isCheck) ? "Disbursement Information" : "Receipt Information"; },
        },
        methods: {
            formatAddress(item) {
                let address = `${_.toString(item.payeeAddress1)}`;
                if (!_.isNullOrEmpty(item.payeeAddress2)) address += `,&nbsp;${_.toString(item.payeeAddress2)}`;
                if ((_.size(item.payeeCity) + _.size(item.payeeState) + _.size(item.payeeZip)) > 0) {
                    address += _.size(item.payeeCity) > 0 ? `<br/>${_.toString(item.payeeCity)}, ${_.toString(item.payeeState)}&nbsp;&nbsp;${_.toString(item.payeeZip)}` : `<br/>${_.toString(item.payeeState)}&nbsp;&nbsp;${_.toString(item.payeeZip)}`;
                }
                return address;
            },

            formatDate(v) { return !_.isNullOrEmpty(v) && DateTime.fromISO(v).isValid ? DateTime.fromISO(v).toFormat("MM/dd/yyyy") : ""; },

            formatPayee() { return _.parseNumber(this.popover.item.payeeCompanyID, 0) > 0 ? `${this.popover.item.payeeCompanyID} - ${this.popover.item.payee}` : this.popover.item.payee; },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            initNonReactiveVariables() {
                const self = this;
                self.itemKey = "id";
                self.accountingCodes = self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES, self.order.regionID);
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    allowColumnReordering: false,
                    focusedRowEnabled: false,
                    paging: { enabled: false },
                    selection: { mode: "none", showCheckBoxesMode: "never" },
                    columns: [
                        {
                            dataField: "lineNumber",
                            dataType: "number",
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false,
                            sortIndex: 0,
                            sortOrder: "asc",
                        },
                        {
                            dataField: "lineNumberStr",
                            dataType: "string",
                            caption: "Line",
                            width: 80,
                            calculateCellValue: function(rowData){
                                return DxGridUtils.lineNumberStrDisplay(rowData)
                            },
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "accountingCodeID",
                            dataType: "number",
                            caption: "Account Code",
                            lookup: {
                                dataSource: self.accountingCodes,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                    ],
                };
            },
        }
    }
</script>
