<template>
    <RqTooltip
        v-for="opts in activeTooltips"
        :key="opts.key"
        ref="tooltipComponents"
        :container="opts.container"
        :delay="opts.delay"
        :placement="opts.placement"
        :target="opts.target"
        :trigger="opts.trigger"
        :show="opts.visible"
        :disabled="opts.disabled">
        <span v-html="opts.title"></span>
    </RqTooltip>
</template>

<script setup>
    import { reactive, onBeforeUnmount, computed } from "vue";
    import { GlobalEventManager, GLOBAL_EVENTS } from "@/app.events";
    import RqTooltip from "./RqTooltip.vue";

    const tooltips = reactive([]);
    const activeTooltips = computed(() => _.filter(tooltips, t => !_.isEmpty(_.trim(t.title))));

    const applyDefaultOptions = opts => ({
        key: opts.key || _.uniqueId("rq-dx-tooltip-"),
        container: opts.container || "body",
        disabled: _.parseBool(opts.disabled),
        trigger: opts.trigger || "hover",
        placement: opts.placement || opts.position || "top",
        target: opts.target || null,
        visible: _.parseBool(opts.visible),
        title: opts.title || null
    });

    function setTargetKeyDataAttr({ target, key }) {
        let targetElement = _.isString(target)
            ? document.querySelector(target)
            : target;
        if(!targetElement) return false;
        targetElement.dataset.rqTooltipKey = key;
        return true;
    }

    function registerTooltip(options) {
        let tooltipOpts = applyDefaultOptions(options);
        try {
            let targetElementValid = setTargetKeyDataAttr(tooltipOpts);
            if(!targetElementValid) {
                console.error("RqDxTooltipManager :: An issue occurred while assigning tooltip key data attr.", tooltipOpts);
                return;
            }
            tooltips.push(tooltipOpts);
        }
        catch(err) {
            console.error("RqDxTooltipManager :: An issue occurred during tooltip registration.", err, tooltipOpts);
        }
    }

    function updateTooltip(options) {
        let tooltipOpts = applyDefaultOptions(options);
        try {
            let idx = _.findIndex(tooltips, { key: options.key });
            if(idx < 0) {
                registerTooltip(options);
                return;
            }
            if(_.isEqual(tooltipOpts, tooltips[idx])) return;
            tooltips[idx] = tooltipOpts;
        }
        catch(err) {
            console.error("RqDxTooltipManager :: An issue occurred during tooltip update.", err, tooltipOpts);
        }
    }

    function removeTooltip(key) {
        let idx = _.findIndex(tooltips, { key });
        if(idx < 0) return;
        tooltips.splice(idx, 1);
    }

    const sourceKey = "RqDxTooltipManager";

    GlobalEventManager.on(GLOBAL_EVENTS.REGISTER_TOOLTIP, registerTooltip, sourceKey);
    GlobalEventManager.on(GLOBAL_EVENTS.UPDATE_TOOLTIP, updateTooltip, sourceKey);
    GlobalEventManager.on(GLOBAL_EVENTS.REMOVE_TOOLTIP, removeTooltip, sourceKey);

    onBeforeUnmount(() => {
        GlobalEventManager.unregister(sourceKey);
    });
</script>
