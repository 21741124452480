
import { SET_SAVED_ROUTE } from "@/store/mutations";
import { GlobalEventManager } from "@/app.events";

export const saveHorizonRoute = (route, store) => {
    return new Promise(resolve => {
        let oneTimeSkipSave = _.getBool(route, "meta.oneTimeSkipSave");
        let skipSave = (_.getBool(route, "meta.skipSaveOnLeave"))
            || _.getBool(store, "state.isPageReadOnly")
            || oneTimeSkipSave;

        if(oneTimeSkipSave){
            route.meta.oneTimeSkipSave = false;
        }

        if(skipSave) {
            resolve(true);
            return;
        }
        let sourceId = _.uniqueId("saveCurrentRoute_");
        const completedHandler = e => {
            GlobalEventManager.offSaveCompleted(sourceId, completedHandler);
            resolve(!_.parseBool(e.abort));
        };
        GlobalEventManager.onSaveCompleted(sourceId, completedHandler);
        GlobalEventManager.save();
        store.commit(SET_SAVED_ROUTE, route.name);
    });
};