<template>
    <div class="rq-container rq-selection-list-box">
        <div class="row">
            <div class="col">
                <div class="row">
                    <label class="col col-auto pe-0">{{leftLabel}}</label>
                    <div class="col">
                        <slot name="left-actions"></slot>
                    </div>
                </div>
                <rq-list-box
                    ref="listBox0"
                    :data-source="leftList"
                    :height="listHeight"
                    :disabled="disabled"
                    v-model="selected[0]"
                    @item-dblclick="onListDblClick"
                    multi-select
                />
            </div>
            <div class="col-auto action-group">
                <div class="btn-group-vertical"
                    role="group">
                    <span v-rq-tooltip.top.hover title="Add All to Selected">
                        <b-btn class="btn-action"
                            automation_id="btn_add_all"
                            variant="theme"
                            :disabled="!hasItems(0) || disabled"
                            @click="onTransferAll(0)">
                            <FontAwesomeIcon icon="fas fa-angle-double-right" />
                        </b-btn>
                    </span>
                    <span v-rq-tooltip.right.hover title="Add item to Selected">
                        <b-btn class="btn-action"
                            automation_id="btn_add_one"
                            variant="theme"
                            :disabled="!hasSelected(0) || disabled"
                            @click="onTransferSelected(0)">
                            <FontAwesomeIcon icon="fas fa-angle-right" />
                        </b-btn>
                    </span>
                    <span v-rq-tooltip.left.hover title="Remove item from Selected">
                        <b-btn class="btn-action"
                            automation_id="btn_remove_one"
                            variant="theme"
                            :disabled="!hasSelected(1) || disabled"
                            @click="onTransferSelected(1)">
                            <FontAwesomeIcon icon="fas fa-angle-left" />
                        </b-btn>
                    </span>
                    <span v-rq-tooltip.bottom.hover title="Remove All from Selected">
                        <b-btn class="btn-action"
                            automation_id="btn_remvoe_all"
                            variant="theme"
                            :disabled="!hasItems(1) || disabled"
                            @click="onTransferAll(1)">
                            <FontAwesomeIcon icon="fas fa-angle-double-left" />
                        </b-btn>
                    </span>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <label class="col col-auto pe-0">{{rightLabel}}</label>
                    <div class="col">
                        <slot name="right-actions"></slot>
                    </div>
                </div>
                <rq-list-box
                    ref="listBox1"
                    :data-source="rightList"
                    :height="listHeight"
                    :disabled="disabled"
                    v-model="selected[1]"
                    @item-dblclick="onListDblClick"
                    multi-select
                />
            </div>
            <div class="col-auto action-group" v-if="showOptionalActions">
                <div v-if="hasActions" class="extra-action-btn-grp" role="group">
                    <rq-list-box-action v-for="action in actions"
                        :key="action.automation_id"
                        :action="action"
                        :selected-items="selected[1]"
                        @action-click="onExtraAction(action)"
                    />
                </div>
                <div v-if="sequenceEnabled" class="btn-group-vertical" role="group">
                    <span v-rq-tooltip.top.hover title="Move items(s) up">
                        <b-btn class="btn-action"
                            automation_id="btn_move_up"
                            variant="theme"
                            :disabled="moveUpDisabled || disabled"
                            @click="onMoveSelected(-1)">
                            <FontAwesomeIcon icon="fas fa-angle-up" />
                        </b-btn>
                    </span>
                    <span v-rq-tooltip.bottom.hover title="Move items(s) down">
                        <b-btn class="btn-action"
                            automation_id="btn_move_down"
                            variant="theme"
                            :disabled="moveDownDisabled || disabled"
                            @click="onMoveSelected(1)">
                            <FontAwesomeIcon icon="fas fa-angle-down" />
                        </b-btn>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { ListBoxItemModel } from "@/shared/models/models";
    import RqListBox from "./RqListBox";
    import RqListBoxAction from "./RqListBoxAction";

    export default {
        name: "RqSelectionListBox",
        components: { RqListBox, RqListBoxAction },
        props: {
            leftLabel: { type: String, default: "" },
            rightLabel: { type: String, default: "" },
            items: { type: Array, default: () => [] },
            actions: { type: Array, default: () => [] },
            sequenceEnabled: { type: Boolean, default: false },
            modelValue: { type: Array, default: () => [] },
            listHeight: { type: [Number, String], default: 250 },
            disabled: { type: Boolean, default: false }
        },
        data: function() {
            return {
                selected: [[],[]],
                allItems: []
            }
        },
        computed: {
            leftList() { return _.sortBy(_.filter(this.allItems, { parentIndex: 0 }), "itemName"); },
            rightList() { return _.sortBy(_.filter(this.allItems, { parentIndex: 1 }), this.sequenceEnabled ? "sequence" : "itemName"); },
            rightSortField() { return this.sequenceEnabled ? "sequence" : "itemName" },
            moveUpDisabled() { return this.isSeqButtonDisabled("up"); },
            moveDownDisabled() { return this.isSeqButtonDisabled("down"); },
            hasActions() { return !_.isEmpty(this.actions); },
            showOptionalActions() { return this.sequenceEnabled || this.hasActions; }
        },
        watch: {
            items: {
                handler(newValue, oldValue) {
                    if(this.compareLists(newValue, this.allItems)) return;
                    this.allItems = _.sortBy(_.map(this.items, item => new ListBoxItemModel(item)), ["parentIndex"]);
                },
                immediate: true,
                deep: true
            },
            allItems: {
                handler(newValue, oldValue) {
                    if(this.compareLists(newValue, this.items)) return;
                    this.$emit("update:items", newValue.slice());
                },
                deep: true
            }
        },
        methods: {
            onListDblClick(e) {
                let targetListIndex = 1 - e.item.parentIndex;
                this.transferItem(e.item);
                this.resetLists();
                this.$nextTick(() => {
                    _.invoke(this, `$refs.listBox${targetListIndex}.scrollItemIntoView`, e.item.itemID);
                });
                this.emitChange(targetListIndex === 1 ? "left-right" : "right-left");
            },
            onTransferSelected(sourceIndex) {
                let selected = _.nth(this.selected, sourceIndex);
                if(_.isEmpty(selected)) return;
                _.each(selected, item => this.transferItem(item));
                this.resetLists();
                this.emitChange(sourceIndex === 0 ? "left-right" : "right-left");
            },
            onTransferAll(sourceIndex) {
                let newList = sourceIndex === 1
                    ? _.concat(this.leftList, this.rightList)
                    : _.concat(this.rightList, this.leftList);
                _.each(newList, (item,index) => {
                    item.parentIndex = 1-sourceIndex;
                    item.sequence = item.parentIndex * (index + 1);
                    item.isSelected = false;
                });
                this.allItems = newList;
                this.emitChange(sourceIndex === 0 ? "left-right" : "right-left");
            },
            onMoveSelected(delta) {
                let selected = this.selected[1];
                if(_.isEmpty(selected)) return;
                let selectedKeys = _.map(_.sortBy(selected, item => item.sequence * -delta), "itemID");
                _.each(selectedKeys, itemID => {
                    let item = _.find(this.rightList, { itemID });
                    let oldSeq = item.sequence;
                    let newSeq = oldSeq + delta;
                    let otherItem = _.find(this.rightList, { sequence: newSeq });
                    if(newSeq > this.rightList.length || newSeq < 1) return;
                    item.sequence = newSeq;
                    otherItem.sequence = oldSeq;
                });
                this.emitChange(`move-${_.eq(delta,1) ? "down" : "up"}`);
            },
            onExtraAction(action) {
                let selected = _.nth(this.selected, 1);
                if(_.isEmpty(selected)) return;
                let eventArgs = {
                    action,
                    selectedKeys: _.map(selected, "itemID"),
                    selectedItems: _.cloneDeep(selected)
                };
                this.$emit("extra-action", eventArgs);
                _.updateAll(this.allItems, "isSelected", false);
            },
            transferItem(item) {
                let targetItem = _.find(this.allItems, { itemID: item.itemID });
                targetItem.parentIndex ^= 1;
                let targetListCount = _.sumBy(this.allItems, i => i.parentIndex === targetItem.parentIndex ? 1 : 0);
                targetItem.sequence = targetItem.parentIndex===1 ? targetListCount : 0;
            },
            isSeqButtonDisabled(direction) {
                let selected = _.nth(this.selected, 1);
                if(_.isEmpty(selected)) return true;
                let getSeq = minMax => _.reduce(selected, (result,item) => {
                    return _.isNil(result)
                        || (minMax === "min" && item.sequence < result)
                        || (minMax === "max" && item.sequence > result)
                        ? item.sequence
                        : result;
                }, 0);
                let listLen = this.rightList.length;
                return direction === "up"
                    ? getSeq("max") === selected.length
                    : getSeq("min") === (listLen - selected.length) + 1;
            },
            hasSelected(index) { return !_.isEmpty(this.selected[index]); },
            hasItems(parentIndex) { return _.some(this.allItems, { parentIndex }); },
            resetLists(){
                _.each(this.allItems, (item,index) => {
                    item.isSelected = false;
                    item.isMarked = item.parentIndex === 1 && item.isMarked;
                });
                if(!this.sequenceEnabled) return;
                _.updateAll(this.rightList, "sequence", (item,index) => item.parentIndex * (index + 1));
            },
            compareLists(listA, listB) {
                return listA.length === listB.length && _.isEmpty(_.differenceWith(listA, listB, _.isEqual));
            },
            emitChange(type) {
                this.$emit("change", {
                    type,
                    items: _.cloneDeep(this.allItems),
                    markedKeys: _.map(_.filter(this.leftList, "isMarked"), "itemID")
                });
            }
        }
    }
</script>
