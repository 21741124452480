import { ref, computed, unref } from "vue";
import { useStore } from "vuex";
import { DocumentsApi } from "@/api/";
import { DOCUMENT_ACTIONS } from "@/store/actions";
import { DOCUMENT_MUTATIONS } from "@/store/mutations";
import { useRqDialog } from "@/shared/plugins/composables";
import PromptEntry from "@documents/components/prompts/PromptEntry";
import DocumentViewer from "@documents/views/DocumentViewer";
import { DocumentPromptGroup } from "@documents/models";

export default function useDocumentGeneration() {

    const vuexStore = useStore();
    const { openDialog } = useRqDialog();

    const scopeOrderId = computed(() => {
        return vuexStore.getters.orderId
    });
    const targetOrderId = ref(0);

    const activePromptDocuments = computed({
        get() { return vuexStore.state.documents.activePromptDocuments; },
        set(val) { vuexStore.commit(DOCUMENT_MUTATIONS.SET_ACTIVE_PROMPT_DOCUMENTS, val); }
    });

    const queuedDocuments = computed({
        get() { return vuexStore.state.documents.queuedDocuments; },
        set(val) { vuexStore.commit(DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS, val); }
    });

    async function generateDocuments({
        ordersID=0,
        documentTemplateIDs=[],
        documentPackageIDs=[],
        loanId=0,
        cssMainId=0,
        invoiceId=0,
        skipQueueUpdate=false,
        clearState=false }) {

        targetOrderId.value = !_.isNil(ordersID) && ordersID > 0 ? ordersID : scopeOrderId.value;
        if(clearState) {
            vuexStore.dispatch(DOCUMENT_ACTIONS.CLEAR_STATE);
        }

        if(!skipQueueUpdate && !clearState) {
            let orderDocs = await addDocToOrder({ documentTemplateIDs, documentPackageIDs });
            queuedDocuments.value = [...queuedDocuments.value, ...orderDocs];
        }
        
        await vuexStore.dispatch(DOCUMENT_ACTIONS.UPDATE_MERGE_OPTIONS, { loanId, cssMainId, invoiceId });
        let orderDocumentIds = _.map(queuedDocuments.value, "orderDocumentID");
        let promptDocResult = await DocumentsApi.getUnsavedOrderDocumentPrompts(targetOrderId.value, _.join(orderDocumentIds, ","));
        let promptDocs = _.map(promptDocResult, p => {
            p.orderId = targetOrderId.value;
            return new DocumentPromptGroup(p);
        });

        if(_.some(promptDocs, d => !_.isEmpty(d.prompts))) {
            launchPromptEntry(promptDocs);
            return;
        }

        finishAndPreview(promptDocs);
    }

    async function finishAndPreview(promptGroups) {
        await savePromptGroups(promptGroups);
        activePromptDocuments.value = promptGroups;
        launchDocumentViewer();
    }

    async function addDocToOrder({ documentTemplateIDs=[], documentPackageIDs=[] }) {
        const invokeAddItemsApi = async (ids, method) => _.isEmpty(ids) ? [] : await DocumentsApi[method](ids, targetOrderId.value);

        let docsResult = await invokeAddItemsApi(documentTemplateIDs, "addDocumentsToOrder");
        let pkgsResult = await invokeAddItemsApi(documentPackageIDs, "addPackagesToOrder");

        _.updateAll(docsResult, "documentSource", 1);
        _.updateAll(pkgsResult, "documentSource", 2);

        return [...docsResult, ...pkgsResult];
    }

    async function savePromptGroup(promptGroup) {
        let doc = _.find(queuedDocuments.value, d2 => d2.orderDocumentID === promptGroup.orderDocumentId);
        _.forEach(promptGroup.prompts, p => {
            if (p.promptLevelType <= 0) return;
            p.scopeDocumentDescription = doc.description;
        });
        promptGroup.setOrderId(targetOrderId.value);
        promptGroup.prompts = await saveAndRemovePromptsWithSaveOption(promptGroup.prompts);
        return promptGroup;
    }

    async function savePromptGroups(promptGroups) {
        let promptPromises = _.map(promptGroups, savePromptGroup);
        return await Promise.all(promptPromises);
    }

    async function savePrompt(promptInfo) {
        const savePromptValue = p => vuexStore.dispatch(DOCUMENT_ACTIONS.SAVE_PROMPT_VALUE, p);
        promptInfo.ordersID = targetOrderId.value;
        let result = [];
        if (promptInfo.prompt.requiresSave) {
            await savePromptValue(promptInfo.prompt);
        }
        else {
            result.push(promptInfo);
        }

        if (promptInfo.promptDataType === 6 && promptInfo.prompt.standardLanguageList.length > 0) {
            _.forEach(promptInfo.prompt.standardLanguageList, async sl => {
                if (sl.prompts.length === 0) return;
                let subPrompts = await saveAndRemovePromptsWithSaveOption(sl.prompts);
                result.push(...subPrompts);
            });
        }
        return result;
    }

    async function saveAndRemovePromptsWithSaveOption(promptInfoList) {
        let promptRequests = [];
        let savePromises = _.map(promptInfoList, savePrompt);
        let saveResults = await Promise.all(savePromises);
        return _.flatten(saveResults);
    }

    function launchPromptEntry(promptGroups, clearOnCancel=false) {
        const self = this;
        let okHandler = async (e) => {
            let isValid = await e.component.validate();
            if(!isValid) return false;
            finishAndPreview(promptGroups);
            return true;
        };
        openDialog({
            title: "Fill Out Document Prompts",
            height: "90%",
            width: "90%",
            props: { promptGroups },
            component: PromptEntry,
            onOk: okHandler,
            onCancel(e) {
                if(!clearOnCancel) return;
                vuexStore.dispatch(DOCUMENT_ACTIONS.CLEAR_STATE);
            }
        });
    }

    function launchDocumentViewer(documentDescription) {
        openDialog({
            title: "Preview Documents",
            height: "95%",
            width: "95%",
            resizable: false,
            component: DocumentViewer,
            closeOnly: true,
            closeTitle: "Close",
            props: { documentDescription }
        });
    }

    return {
        generateDocuments
    };
}