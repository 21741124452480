import { COMMITMENT_ACTIONS } from "../../actions";
import { COMMITMENT_MUTATIONS } from "../../mutations";
import { BASIC_ACTION, ACTION_WITH_COMMIT, updateStateArray } from "../../helpers";
import { PolicyType, AssignedLanguageListType } from "../../../modules/file/title-policy/enums";
import commitmentsApi from "@/api/commitments";
import utilitiesApi from "@/api/utilities";

const getDefaultState = () => ({
    assignedLanguages: [],
    commitment: null,
    commitmentDefaults: [],
    customTabs: [],
    standardLanguageSections: [],
    usersWithSignatures: [],
    scheduleD: null,
    contentChangePending: false
});

const state = getDefaultState();

const getters = {
    assignedLanguages: state => state.assignedLanguages,
    commitment: state => state.commitment,
    commitmentDefaults: state => state.commitmentDefaults,
    titleProduction: state => state.titleProduction,
    getDefaultSectionCategoryId: state => sectionName => {
        let section = _.find(state.standardLanguageSections, s => s.name === sectionName);
        return section && (section.regionDefaultCategoryID || section.globalDefaultCategoryID);
    },
    getSectionCategories: state => sectionName => {
        let section = _.find(state.standardLanguageSections, s => s.name === sectionName);
        return section && section.categories;
    }
};

const actions = {

    [COMMITMENT_ACTIONS.GET_ASSIGNED_LANGUAGES]: (context, { commitmentPolicyDetailID, listType, sectionId, checkState=false }) => {
        const hasStateItems = () => _.some(context.state.assignedLanguages, item =>
            item.commitmentPolicyDetailID === commitmentPolicyDetailID
                && (_.isNil(listType) || item.listType === listType)
                && (_.isNil(sectionId) || item.standardLanguageSectionID === sectionId));

        if(checkState && hasStateItems()) return Promise.resolve(context.state.assignedLanguages);
        return ACTION_WITH_COMMIT(context, commitmentsApi.getAssignedLanguagesByDetail(commitmentPolicyDetailID, listType, sectionId), COMMITMENT_MUTATIONS.UPDATE_ASSIGNED_LANGUAGE_RANGE);
    },

    [COMMITMENT_ACTIONS.FETCH_ASSIGNED_LANGUAGE_HTML]: (context, assignedLanguage) => {
        if(!_.isEmpty(assignedLanguage.htmlText) || _.isEmpty(assignedLanguage.rtfText)) return Promise.resolve(assignedLanguage);
        let apiPromise = utilitiesApi.rtfToHtml(assignedLanguage.rtfText, false, true)
            .then(html => {
                assignedLanguage.htmlText = html;
                return assignedLanguage;
            });
        return ACTION_WITH_COMMIT(context, apiPromise, COMMITMENT_MUTATIONS.UPDATE_ASSIGNED_LANGUAGE);
    },

    [COMMITMENT_ACTIONS.SAVE_ASSIGNED_LANGUAGE] (context, { item, refresh=false }) {
        if(refresh) {
            let refreshParams = _.pick(item, ["commitmentPolicyDetailID", "listType"]);
            //using ".then" on api promise instead of BASIC_ACTION callback so if in $rqBusy it waits until all operations are complete
            return BASIC_ACTION(context, commitmentsApi.saveAssignedLanguage(item)
                .then(() => context.dispatch(COMMITMENT_ACTIONS.GET_ASSIGNED_LANGUAGES, refreshParams))
            );
        }
        return ACTION_WITH_COMMIT(context, commitmentsApi.saveAssignedLanguage(item),
            _.parseNumber(item.assignedLanguageID, 0) === 0
                ? COMMITMENT_MUTATIONS.ADD_ASSIGNED_LANGUAGE
                : COMMITMENT_MUTATIONS.UPDATE_ASSIGNED_LANGUAGE
        );
    },

    [COMMITMENT_ACTIONS.SAVE_ASSIGNED_LANGUAGES] (context, assignedLanguages) {
        let apiPromise = commitmentsApi.saveAssignedLanguageRange(assignedLanguages);
        return ACTION_WITH_COMMIT(context, apiPromise, COMMITMENT_MUTATIONS.UPDATE_ASSIGNED_LANGUAGE_RANGE);
    },

    [COMMITMENT_ACTIONS.DELETE_ASSIGNED_LANGUAGE](context, assignedLanguageID) {
        return BASIC_ACTION(context, commitmentsApi.deleteAssignedLanguage(assignedLanguageID), () => context.commit(COMMITMENT_MUTATIONS.REMOVE_ASSIGNED_LANGUAGE, assignedLanguageID));
    },

    [COMMITMENT_ACTIONS.GET_TITLE_PRODUCTION_DATA](context, id=0) {
        let loanID = _.parseNumber(id, 0) || _.getNumber(context, "state.commitment.detail.loanID", 0) || _.getNumber(context, "state.loans[0].loanID", 0);
        if(loanID === 0) return;
        let getStaticData = Promise.all([
            context.dispatch(COMMITMENT_ACTIONS.GET_USERS_WITH_SIGNATURES)
        ]);
        return getStaticData
            .then(() => BASIC_ACTION(context, commitmentsApi.getTitleProductionData(context.state.orderId, loanID), result => {
                let commitmentPolicyData = _.get(result, "commitmentData.commitmentPolicyData", { header: {}, detail: {} });
                let customTabs = _.get(result, "commitmentData.customTabs", null) || [];
                let sections = _.get(result, "commitmentData.sections", null) || [];
                let defaults = _.get(result, "commitmentData.defaults", null);
                context.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DATA, _.pick(commitmentPolicyData, ["header","detail"]));
                context.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DEFAULTS, defaults);
                context.commit(COMMITMENT_MUTATIONS.SET_CUSTOM_TABS, customTabs);
                context.commit(COMMITMENT_MUTATIONS.SET_STANDARD_LANGUAGE_SECTIONS, sections);
            }));
    },

    [COMMITMENT_ACTIONS.GET_COMMITMENT_DATA]: (context, id=0) => {
        let loanID = _.parseNumber(id, 0) || _.getNumber(context, "state.commitment.detail.loanID", 0) || _.getNumber(context, "state.loans[0].loanID", 0);
        if(loanID === 0) return;
        return BASIC_ACTION(context, commitmentsApi.getData(context.state.orderId, loanID), result => {
            let commitmentData = {
                header: result.commitmentPolicyData.header,
                detail: result.commitmentPolicyData.detail
            };
            context.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DATA, commitmentData);
            context.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DEFAULTS, result.defaults);
            context.commit(COMMITMENT_MUTATIONS.SET_CUSTOM_TABS, result.customTabs);
            context.commit(COMMITMENT_MUTATIONS.SET_STANDARD_LANGUAGE_SECTIONS, result.sections);
        });
    },

    [COMMITMENT_ACTIONS.GET_CUSTOM_TABS](context) {
        return ACTION_WITH_COMMIT(context, commitmentsApi.getCustomTabs(), COMMITMENT_MUTATIONS.SET_CUSTOM_TABS);
    },

    [COMMITMENT_ACTIONS.GET_SCHEDULE_D]: context => {
        if(!_.isEmpty(context.state.scheduleD)) return Promise.resolve(context.state.scheduleD);
        let orderId = context.state.orderId;
        return ACTION_WITH_COMMIT(context, commitmentsApi.getScheduleD(orderId), COMMITMENT_MUTATIONS.SET_SCHEDULE_D);
    },

    [COMMITMENT_ACTIONS.GET_STANDARD_LANGUAGE_SECTIONS](context, forceRefresh=false) {
        if(!forceRefresh && !_.isEmpty(context.state.standardLanguageSections)) return Promise.resolve(context.state.standardLanguageSections);
        return ACTION_WITH_COMMIT(context, commitmentsApi.getStandardLanguageSections(), COMMITMENT_MUTATIONS.SET_STANDARD_LANGUAGE_SECTIONS);
    },

    [COMMITMENT_ACTIONS.GET_USERS_WITH_SIGNATURES]: (context) => {
        if(!_.isEmpty(context.state.usersWithSignatures)) return Promise.resolve(context.state.usersWithSignatures);
        return ACTION_WITH_COMMIT(context, commitmentsApi.getUsersWithSignatures(), COMMITMENT_MUTATIONS.SET_USERS_WITH_SIGNATURES);
    },

    [COMMITMENT_ACTIONS.VOID_POLICY]: (context, data) => {
        return BASIC_ACTION(context, commitmentsApi.voidPolicy(data), () => context.dispatch(COMMITMENT_ACTIONS.GET_COMMITMENT_DATA));
    },

    [COMMITMENT_ACTIONS.UPDATE_COMMITMENT_DATA]: (context, { request, updateClauses=false }) => {
        return BASIC_ACTION(context, commitmentsApi.updateData(request), result => {
            context.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DATA, _.pick(result, ["detail","header"]));
            if(!updateClauses) return;
            let clauses = _.get(result, "assignedLanguages", null) || [];
            context.commit(COMMITMENT_MUTATIONS.SET_ASSIGNED_LANGUAGES, clauses);
        });
    },

    [COMMITMENT_ACTIONS.UPDATE_POLICY_OPTIONS]: (context, { request }) => {
        return BASIC_ACTION(context, commitmentsApi.updatePolicyOptions(request), result => {
            context.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DATA, _.pick(result, ["detail","header"]));
        });
    },

    [COMMITMENT_ACTIONS.CLEAR_POLICY_NUMBERS]: (context) => {
        return BASIC_ACTION(context, commitmentsApi.clearPolicyNumbers(context.state.orderId));
    },

    [COMMITMENT_ACTIONS.ADD_ASSIGNED_LANGUAGE_PACKAGES]: (context, { packages, commitmentPolicyDetailID, listType }) => {
        let apiPromises = _.map(packages, pkg => commitmentsApi.addAssignedLanguagePackage(pkg));
        return BASIC_ACTION(context, apiPromises.then(() => context.dispatch(COMMITMENT_ACTIONS.GET_ASSIGNED_LANGUAGES, { commitmentPolicyDetailID, listType })));
    },

    [COMMITMENT_ACTIONS.SET_ASSIGNED_LANGUAGE_PARENT]: (context, { assignedLanguageId, parentId, commitmentPolicyDetailID, listType }) => {
        let apiPromise = commitmentsApi.setAssignedLanguageParent(assignedLanguageId, parentId)
            .then(() => context.dispatch(COMMITMENT_ACTIONS.GET_ASSIGNED_LANGUAGES, { commitmentPolicyDetailID, listType }));
        return BASIC_ACTION(context, apiPromise);
    },

    [COMMITMENT_ACTIONS.SWAP_ASSIGNED_LANGUAGES]: (context, { id1, id2, commitmentPolicyDetailID, listType }) => {
        let apiPromise = commitmentsApi.swapAssignedLanguageOrdinals(id1, id2)
            .then(() => context.dispatch(COMMITMENT_ACTIONS.GET_ASSIGNED_LANGUAGES, { commitmentPolicyDetailID, listType }));
        return BASIC_ACTION(context, apiPromise);
    },

    [COMMITMENT_ACTIONS.DELETE_SECTION_ASSIGNED_LANGUAGES]: (context, { commitmentPolicyHeaderID=null, commitmentPolicyDetailID=null, section=null }) => {
        if(_.isNil(section)) return Promise.resolve(false);

        let apiPromise = section.isCustomTabSection
            ? commitmentsApi.deleteAssignedLanguagesBySectionId(commitmentPolicyDetailID, section.standardLanguageSectionID)
            : section.listType === AssignedLanguageListType.OwnerSchedB
                ? commitmentsApi.deleteAssignedLanguagesByListType(commitmentPolicyHeaderID, null, section.listType)
                : commitmentsApi.deleteAssignedLanguagesByListType(null, commitmentPolicyDetailID, section.listType);

        return BASIC_ACTION(context, apiPromise.then(() => context.dispatch(COMMITMENT_ACTIONS.GET_ASSIGNED_LANGUAGES, commitmentPolicyDetailID)) );
    },

    [COMMITMENT_ACTIONS.REMOVE_POLICY_OVERRIDE]: (context, { policyKey, policyType }) => {
        let apiPromise = policyType === PolicyType.Owner
            ? commitmentsApi.removeOwnerPolicyClauseOverride(policyKey)
            : commitmentsApi.removeLoanPolicyClauseOverride(policyKey);
        return BASIC_ACTION(context, apiPromise, () => {
            context.commit(COMMITMENT_MUTATIONS.SET_ASSIGNED_LANGUAGES, []);
            return context.dispatch(COMMITMENT_ACTIONS.GET_COMMITMENT_DATA);
        });
    },

    [COMMITMENT_ACTIONS.CLEAR_STATE]: (context, clearAll=false) => {
        context.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DATA, null);
        context.commit(COMMITMENT_MUTATIONS.SET_SCHEDULE_D, null);
        context.commit(COMMITMENT_MUTATIONS.SET_ASSIGNED_LANGUAGES, []);
        context.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DEFAULTS, []);
        context.commit(COMMITMENT_MUTATIONS.SET_STANDARD_LANGUAGE_SECTIONS, []);
        context.commit(COMMITMENT_MUTATIONS.SET_CUSTOM_TABS, []);
        if(!clearAll) return;
        context.commit(COMMITMENT_MUTATIONS.SET_USERS_WITH_SIGNATURES, []);
    }
};

const mutations = {
    [COMMITMENT_MUTATIONS.SET_CONTENT_CHANGE_PENDING] (state, value) { _.set(state, "contentChangePending", value); },
    [COMMITMENT_MUTATIONS.SET_ASSIGNED_LANGUAGES] (state, value) { _.set(state, "assignedLanguages", value); },
    [COMMITMENT_MUTATIONS.SET_COMMITMENT_DATA] (state, value) { _.set(state, "commitment", value); },
    [COMMITMENT_MUTATIONS.SET_COMMITMENT_DEFAULTS] (state, value) { _.set(state, "commitmentDefaults", value); },
    [COMMITMENT_MUTATIONS.SET_CUSTOM_TABS] (state, value) { _.set(state, "customTabs", value); },
    [COMMITMENT_MUTATIONS.SET_SCHEDULE_D] (state, value) { _.set(state, "scheduleD", value); },
    [COMMITMENT_MUTATIONS.SET_STANDARD_LANGUAGE_SECTIONS] (state, value) { _.set(state, "standardLanguageSections", value); },
    [COMMITMENT_MUTATIONS.SET_USERS_WITH_SIGNATURES] (state, value) { _.set(state, "usersWithSignatures", value); },
    [COMMITMENT_MUTATIONS.ADD_ASSIGNED_LANGUAGE] (state, value) {
        let assignedLanguages = _.cloneDeep(state.assignedLanguages);
        assignedLanguages.push(value);
        _.set(state, "assignedLanguages", _.sortBy(assignedLanguages, ["listPath"]));
    },
    [COMMITMENT_MUTATIONS.UPDATE_ASSIGNED_LANGUAGE] (state, value) {
        updateStateArray(state.assignedLanguages, "assignedLanguageID", value, true);
    },
    [COMMITMENT_MUTATIONS.UPDATE_ASSIGNED_LANGUAGE_RANGE] (state, items) {
        let assignedLanguages = _.cloneDeep(state.assignedLanguages);
        _.forEach(items, item => {
            updateStateArray(assignedLanguages, "assignedLanguageID", item, true);
        });
        _.set(state, "assignedLanguages",  _.sortBy(assignedLanguages, ["listPath"]));
    },
    [COMMITMENT_MUTATIONS.REMOVE_ASSIGNED_LANGUAGE] (state, value) {
        let assignedLanguages = _.cloneDeep(state.assignedLanguages);
        _.remove(assignedLanguages, item => item.assignedLanguageID === value || item.parentID === value );
        _.set(state, "assignedLanguages", assignedLanguages);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};