<template>
    <div class="input-group outside-party-picker">
        <dx-select-box
            ref="selectBoxComponent"
            :element-attr="{ automation_id, class: 'form-control' }"
            :items="contactList"
            display-expr="name"
            value-expr="id"
            placeholder="Not Assigned"
            :search-enabled="true"
            v-model="selectedRoleId"
            @key-down="onKeyDown"
            @key-up="onKeyUp"
            :disabled="disabled"
        />
        <b-btn
            variant="theme"
            class="input-group-icon"
            @click="onSelectCompany"
            :disabled="disabled">
            <FontAwesomeIcon icon="fas fa-search" aria-hidden="true" />
        </b-btn>
    </div>
</template>

<script>
    import { RoleType } from '@/shared/models/enums';
    import { mapState, mapGetters } from "vuex";
    import { CONTACT_ACTIONS } from "@/store/actions";
    import { CompanyContactLookup } from "@order-entry/contacts/components";

    export default {
        props: {
            automation_id: { type: String, default: null },
            orderId: { type: Number, default: null },
            modelValue: { type: Number, default: null},
            disabled: { type: Boolean, default: false }
        },

        data() {
            return {
                orderContacts: [],
                selectedRoleId: 0
            };
        },

        computed: {
            ...mapState({
                storedOrderId: state => state.orders.orderId
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            routeOrderId() { return _.getNumber(this, "$route.params.orderId", 0); },
            contextOrderId() { return this.orderId || this.routeOrderId || this.storedOrderId; },
            isCurrentOrderContext() { return this.contextOrderId === this.routeOrderId || this.contextOrderId === this.storedOrderId; },
            contactList() {
                return this.lookupHelpers.getOutsideParties(this.orderContacts);
            }
        },

        watch: {
            orderId: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.fetchOrderContacts();
                },
                immediate: true
            },
            modelValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.selectedRoleId) return;
                    this.selectedRoleId = newValue;
                },
                immediate: true
            },
            selectedRoleId(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            }
        },

        methods: {
            fetchOrderContacts(forceStoreRefresh=false) {
                const self = this;

                let contactPromise = self.isCurrentOrderContext
                    ? self.$store.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS, forceStoreRefresh)
                    : self.$api.OrdersApi.getOrderContacts(self.contextOrderId);

                return self.$rqBusy.wait(contactPromise)
                    .then(result => {
                        self.orderContacts = _.uniq(result);
                        return result;
                    });
            },

            addRole (role) {
                const self = this;
                let newRoleId = null;
                let addRolePromise = self.$api.OrdersApi.addRole(role);
                return self.$rqBusy.wait(addRolePromise)
                    .then(data => {
                        self.fetchOrderContacts(true)
                            .then(() => {
                                self.$nextTick().then(() => {
                                    self.selectedRoleId = data.rolesID;
                                });
                            });
                        return data.rolesID;
                    })
                    .catch(err => {
                        self.$toast.error("Failed to create new Role.");
                    });
            },

            onSelectCompany () {
                this.showCompanyDialog();
            },

            /* DX component prevents Mousetrap from firing so kb shorcuts have to be handled manually */
            /******************************************************************************************/
            onKeyDown(e) {
                if(!e.event.ctrlKey || e.event.key !== "l") return;
                e.event.preventDefault();
                e.event.stopImmediatePropagation();
            },
            onKeyUp(e) {
                if(!e.event.ctrlKey || e.event.key !== "l") return;
                e.event.preventDefault();
                e.event.stopImmediatePropagation();
                this.showCompanyDialog();
            },
            /******************************************************************************************/

            showCompanyDialog() {
                const self = this;
                _.invoke(self, "$refs.selectBoxComponent.instance.close");
                self.$dialog.open({
                    title: "Select Company",
                    height: "90%",
                    width: "90%",
                    component: CompanyContactLookup,
                    closeOnEsc: true,
                    onOk (e) {
                        let role = {
                            orderId: self.orderId,
                            roleTypeId: -1,
                            roleTypeName: '',
                            companyId: null,
                            company: null,
                            contactId: null,
                            contact: null
                        };

                        let result = _.get(e, "originalEvent.data", e.data);
                        role.companyId = result.companyID;
                        role.company = result.companyName;
                        role.contactId = result.contactID;
                        role.contact = result.contactName;
                        role.roleTypeId = RoleType.None;

                        self.addRole(role);
                        return true;
                    }
                });
            }
        }
    };
</script>