
// const breakText = text => breakTextAt(text, 1);
// const breakTextAt = function(text, wordIndex) {
//     let words = text.split(" ");
//     if(words.length === wordIndex || wordIndex === 0) return text;
//     let beforeBreak = _.joinParts(_.take(words, wordIndex), " ");
//     let afterBreak = _.joinParts(_.takeRight(words, words.length - wordIndex), " ");
//     return `${beforeBreak}<div class='e-de-text-wrap'>${afterBreak}</div>`
// }

export default {
    MergeLoop: {
        id: "rq-insert-merge-field-loop-stmt",
        text: "Merge Field/Loop",
        tooltipText: "Insert a field instance or loop merge statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fad fa-repeat")
    },
    Prompt: {
        id: "rq-insert-prompt-stmt",
        text: "Ask Prompt",
        tooltipText: "Insert an ask prompt statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("far fa-question-circle")
    },
    PromptValue: {
        id: "rq-insert-prompt-value-stmt",
        text: "Prompt Value",
        tooltipText: "Insert a prompt value statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("far fa-exclamation-circle")
    },
    StoredProcedure: {
        id: "rq-insert-sp-stmt",
        text: "Stored Procedure",
        tooltipText: "Insert a stored procedure statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fad fa-database")
    },
    StoredProcedureValue: {
        id: "rq-insert-sp-value",
        text: "Stored Procedure Value",
        tooltipText: "Insert a stored procedure value statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fad fa-database")
    },
    ClausePrompt: {
        id: "rq-insert-clause-prompt-stmt",
        text: "Clause Prompt",
        tooltipText: "Insert a clause prompt statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("far fa-question-circle")
    },
    Clause: {
        id: "rq-insert-clauses",
        text: "Insert Clause(s)",
        tooltipText: "Insert a clause or clauses at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fal fa-file-invoice")
    },
    Condition: {
        id: "rq-insert-condition-stmt",
        text: "Condition",
        tooltipText: "Insert a condition statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fas fa-lambda")
    },
    Lock: {
        id: "rq-lock-selection",
        text: "Lock Selection",
        tooltipText: "Surround selection with lock tags to lock selection upon document generation.",
        prefixIcon: _.getSvgSymbolIcon("fas fa-lock-alt")
    },
    MergeDocument: {
        id: "rq-insert-document",
        text: "Merge Document",
        tooltipText: "Insert a merge document statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fas fa-file-invoice")
    },
    CharCount: {
        id: "rq-insert-field-char-count",
        text: "Character Count",
        tooltipText: "Insert a field character count statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fas fa-hashtag")
    },
    DateTime: {
        id: "rq-insert-date-time",
        text: "Insert Date/Time",
        tooltipText: "Insert a date/time merge statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fas fa-calendar-alt")
    },
    PageCount: {
        id: "rq-insert-page-count",
        text: "Page Count",
        tooltipText: "Insert a page count merge statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fas fa-hashtag")
    },
    RemoveLine: {
        id: "rq-insert-remove-line",
        text: "Remove Line",
        tooltipText: "Insert a remove line merge statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fad fa-bars")
    },
    ClearRowspace: {
        id: "rq-insert-clear-rowspace",
        text: "Clear Row Space",
        tooltipText: "Insert a clear row space merge statement at the current cursor position.",
        prefixIcon: _.getSvgSymbolIcon("fas fa-bars")
    }
};