import { useRqDialog } from "../plugins/composables";
import DocumentTemplatesApi from "@/api/documentTemplates";
import { SearchRequest } from "@/shared/models/models";
import { DocumentTemplateShortDto } from "@config/ssAndCalcs/models";

export default function useGridDocumentTemplatePicker() {
    const { openDialog } = useRqDialog();

    async function fetchDocumentTemplateData(loadOptions, cellInfo, activeOnly) {
        if(_.isEmpty(loadOptions.searchValue)) return {
            data: [],
            totalCount: 0
        };

        var searchRequest = SearchRequest.fromLoadOptions(loadOptions);
        searchRequest.sortBy.push({ field: "name", direction: "ASC" });
        searchRequest.addFilter("regionID", "=", cellInfo.data.regionID);

        let response = await DocumentTemplatesApi.search(searchRequest);
        return {
            data: response.results,
            totalCount: response.totalRecords
        };
    }

    function getDocumentTemplateGridColumn({
        column,
        dialogTitle,
        documentTemplateIDExpr="documentTemplateID",
        documentTemplateNameExpr="name",
        regionID=0,
        disabled=false,
        activeOnly=false
    }) {
        let resultCol = _.cloneDeep(column);

        let getGridData = v => {
            let result = { };
            result[documentTemplateIDExpr] = _.get(v, "documentTemplateID", null) || null;
            result[documentTemplateNameExpr] = _.get(v, "name", "");
            return result;
        };

        let getDocumentTemplateData = v => {
            let result = {
                documentTemplateID: _.get(v, documentTemplateIDExpr, null) || null,
                name: _.get(v, documentTemplateNameExpr, ""),
            };

            return new DocumentTemplateShortDto(result);
        };

        //resultCol.calculateDisplayValue = rowData => getDocumentTemplateData(rowData).name;
        resultCol.setCellValue = function(rowData, value) {
            let documentData = getGridData(value);
            _.assign(rowData, documentData);
        };

        const documentTemplatePickerTemplate = (cellElement, cellInfo) => {
            let currentData = getDocumentTemplateData(cellInfo.data);
            let filter = cellInfo.data.regionID > 0 ? ["RegionID", "=", cellInfo.data.regionID] : [];
            let $dxInstance = null;

            const stopEventPropagation = e => {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            };
            // const updateButtonVisibility = (visible=null) => {
            //     if(_.isNil($dxInstance)) return;
            //     let buttonInstance = $dxInstance.getButton("viewDocumentTemplate");
            //     buttonInstance.option("visible", _.isNil(visible) ? currentData.documentTemplateID > 0 : visible);
            // };

            //assigned to action button "id" attr to be used as toolitp targets
            let viewEditButtonId = _.uniqueId("rq-editor-action-");

            let $dxElement = $("<div>").dxSelectBox({
                dataSource:{
                    paginate: true,
                    pageSize: 50,
                    filter,
                    byKey: key => ({ documentTemplateID: key, name: key === currentData.documentTemplateID ? currentData.name : "" }),
                    load: _.debounce(function(e) {
                        return fetchDocumentTemplateData(e, cellInfo, activeOnly);
                    }, 300, { leading: true, trailing: true })
                },
                dropDownOptions: {
                    elementAttr: { class: "rq-dx-theme-blue" },
                    minWidth: 350
                },
                displayExpr: "name",
                valueExpr: "documentTemplateID",
                value: currentData.documentTemplateID,
                searchEnabled: true,
                showDataBeforeSearch: false,
                openOnFieldClick: false,
                opened: false,
                noDataText: "Enter a value to search for a document template",
                //buttons: [
                    // {
                    //     name: "viewDocumentTemplate",
                    //     location: "after",
                    //     options: {
                    //         elementAttr: {
                    //             id: viewEditButtonId,
                    //             class: "rq-grid-editor-action"
                    //         },
                    //         visible: _.parseNumber(currentData.documentTemplateID, 0) > 0,
                    //         icon: "<svg class='rq-icon-symbol'><use href='#rq-far-building'></use></svg>",
                    //         tabIndex: -1,
                    //         activeStateEnabled:false,
                    //         focusStateEnabled:false,
                    //         onClick() {
                    //             showEditDocumentTemplate(currentData, $dxInstance, cellInfo);
                    //         }
                    //     }
                    // },
                //],
                itemTemplate: itemData => {
                    if (!itemData) return;
                    var reg = new RegExp($dxInstance.option("text"), 'ig');
                    var display = _.replace(itemData.name, reg, '<strong>$&</strong>');
                    return $('<span>').addClass('d-flex justify-content-between')
                            .append($('<span>').append(display));
                },
                onValueChanged(e) {
                    let selectedItem = e.component.option("selectedItem");console.log(selectedItem);
                    currentData = new DocumentTemplateShortDto(selectedItem);
                    cellInfo.setValue(currentData);
                    //updateButtonVisibility();
                    
                },
                onOpened(e) {
                    let $contentElement = e.component._popup.content();
                    if($contentElement.hasClass("rq-dx-theme-default")) return;
                    $contentElement.addClass("rq-dx-theme-default");
                    //updateButtonVisibility(false);
                },
                onClosed(e) {
                    //updateButtonVisibility();
                },
            });
            $dxInstance = $dxElement.dxSelectBox("instance");

            /* Tooltips */
            let commonTooltipConfig = { wrapperAttr: { class: "rq-dx-tooltip" }, position: "top", showEvent: "mouseenter", hideEvent: "mouseleave" };
            cellElement
                .append($("<span/>").text("View/Edit Document Template").dxTooltip({ target: `#${viewEditButtonId}`, ...commonTooltipConfig }));
            /************/

            return $dxElement;
        };

        const disabledEditTemplate = (cellElement, cellInfo) => {
            let currentData = getDocumentTemplateData(cellInfo.data);
            return $("<div/>").dxTextBox({ value: currentData.displayName, readOnly: true });
        };

        resultCol.editCellTemplate = function(cellElement, cellInfo) {

            if (_.isNil(cellInfo.data.regionID)) {
                $("<div/>")
                  .addClass("d-flex align-items-center text-muted ps-1")
                  .height(cellElement[0].clientHeight)
                  .text("Select a region first...")
                  .appendTo(cellElement);
                return;
              }

            let isDisabled = _.isFunction(disabled) ? disabled(cellInfo) : _.parseBool(disabled);
            return isDisabled
                ? disabledEditTemplate(cellElement, cellInfo)
                : documentTemplatePickerTemplate(cellElement, cellInfo);
        };

        return resultCol;
    }

    return {
        getDocumentTemplateGridColumn
    };
}