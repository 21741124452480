/* More info on store.js: https://github.com/marcuswestin/store.js/ */
import storeEngine from 'storeEngine';

export default {
    install (app, options) {
        const rqPluginStore = storeEngine.createStore(options.storages, options.plugins);
        Object.defineProperty(app.config.globalProperties, '$rqStore', {
            get () {
                return {
                    getItem (key) {
                        return rqPluginStore.get(key);
                    },
                    setItem (key, value, lifetime = 1200000) {
                        let itemValue = _.isString(value)
                            ? value
                            : JSON.stringify(value);
                        if (lifetime > 0) {
                            const expires = Date.now() + lifetime;
                            rqPluginStore.set(key, itemValue, expires);
                        } else {
                            rqPluginStore.set(key, itemValue);
                        }
                    },
                    removeItem (key) {
                        rqPluginStore.remove(key);
                    },
                    clearAll () {
                        rqPluginStore.clearAll();
                    },
                    each (looperFunction) {
                        rqPluginStore.each(looperFunction);
                    }
                };
            }
        });
    }
};
