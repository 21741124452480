import { h } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    name: "RqBoolCellTemplate",
    render() {
        return this.value
            ? h(FontAwesomeIcon, { icon: "fas fa-check", class: "bool-cell-icon" })
            : h("span");
    },
    props: {
        value: { type: Boolean, default: false }
    }
}