import { OrderStatus } from "@/shared/models/enums";
import { JobTypes } from "@config/enums";
import { REPORT_ACTIONS } from '../actions';
import { REPORT_MUTATIONS } from '../mutations';
import { DepositStatus } from '@check-writing/enums';
import { CheckStatusFilter } from '@check-writing/enums';
import { SystemLookupItem, SystemLookupItemDto } from "@/shared/models/models";
import LookupNames from "@/store/lookupNames";
import { ACTION_WITH_COMMIT } from "../helpers";
import * as api from '@/api'
import { CheckActions } from '@escrow-accounting/enums';

const state = {
    parameterValues: {
        p_StartDate: null,
        p_EndDate: null,
        p_StaticEndDate: null,
        p_Bank: null,
        p_Regions: [],
        p_Departments: [],
        p_StaffTypes1099: [],
        p_StaffTypes: null
    },
    parametersKeys: [],
    beginningBalance: 0,

    bool: [
        { id: false, name: "No" },
        { id: true, name: "Yes" },
    ],
    openClosed: [
        { id: 0, name: 'Closed' },
        { id: 1, name: 'Open' },
    ],
    roleTypes: [
        { id: 6, name: 'Lender' },
        { id: 10, name: 'Real Estate Agent' },
        { id: 19, name: 'Referral' },
        { id: 22, name: 'Additional Lender' },
    ],
    realtorRoleTypes: [
        { id: 10, name: 'Real Estate Agent' },
        { id: 6, name: 'Lender' },
        { id: 35, name: 'Loan Originator' },
        { id: 15, name: 'Buyer' },
        { id: 16, name: 'Seller' }, 
    ],
    reportTypes: [
        { id: 1, name: "Invoice" },
        { id: 2, name: "Disbursement" },
        { id: 3, name: "Settlement Statement" },
    ],
    signatureBlockOptions: [
        { id: 2, name: "Exclude Signature Blocks (No Signature lines)" },
        { id: 1, name: "Include Signature Blocks " },
        { id: 3, name: "Include Signature Blocks with Disclaimers" },
    ],
    orderStatuses: OrderStatus.lookupItems,
    staffTypes: JobTypes.lookupItems,
    grouping: [
        { id: 0, name: "No Grouping" },
        { id: 1, name: "By Branch" },
        { id: 2, name: "By Escrow Officer" },
        { id: 3, name: "By Title Officer" },
        { id: 4, name: "By Sales Representative" },
    ],
    staffTypes1099: _.filter(JobTypes.lookupItems, (item) => _.includes([JobTypes.EscrowOfficer, JobTypes.ClosingAgent], item.id)),
    option1099: [
        { value: 0, text: 'All 1099 Files'},
        { value: 1, text: 'Missing Info Files Only'},
    ],
    lookupTypes: [
        { id: 0, name: 'Source of Business'},
        { id: 1, name: 'Referral' },
    ],
    depositStatuses: DepositStatus.lookupItems,
    depositStatusesExt: DepositStatus.lookupItemsExt,
    searchBy: [
        { id: 1, name: 'Fund Date' },
        { id: 2, name: 'Close Date' },
    ],
    invoiceStatuses: [
        { id: 0, name: "Both" },
        { id: 1, name: "Open" },
        { id: 2, name: "Closed" },
    ],
    checksAndOrDeposits: [
        { id: 0, name: "Both" },
        { id: 1, name: "Receipts" },
        { id: 2, name: "Disbursements" },
    ],
    partiesReceiving: [
        { id: 0, name: 'Without Parties Receiving %' },
        { id: 1, name: 'With Parties Receiving %' },
    ],
    escrowActionTypes: CheckActions.lookupItems,
    // Placeholders for more logic.
    deposits: [],

    // Derived/populated.
    departmentUsers: [],
    payeeCompanies: [],
    payeeContacts: [],
    persons: [],
    securityRoles: [],
    allCompanyRoles: [],
    customers: [],
    buyersSellers: [],
    companyContacts: [],

    checkStatuses: CheckStatusFilter.lookupItems,
    clauseReportTypes: [
        { id: 0, name: "Clean" },
        { id: 1, name: "Coded" },
    ],
    averyLabelTypes: [
        {id: 0, name: "Label Per Contact"},
        {id: 1, name: "Label Per Company"}
    ],
    userStatuses: [
        { id: 0, name: "Active" },
        { id: 1, name: "Inactive" },
        { id: 2, name: "Both" },
    ],
    reportInfo: null,
}

const getters = {
    // These are helpers so that we don't lose the RegionID on lookups because the LookupHelpers in the store
    // inherently strip out the potentially needed value.
    getLookupSelections: (state, getters) => {
        return (key, showInactive=false) => {
            let showAll = _.parseBool(showInactive, false);
            let items = getters[key]
                ? _.isFunction(getters[key]) ? getters[key](showAll) : getters[key]
                : state[key];
            if (showAll) return items;
            return _.filter(items, (item) => !_.parseBool(item.inactive, false) || showAll);
        }
    },

    beginningBalance: (state) => {
        return state.beginningBalance;
    },

    getLookupSelectionsRaw: (state, getters, rootState, rootGetters) => {
        return (lookupKey) => {
            let items = _.get(rootGetters, `systemLookups.${lookupKey}`, []);
            return _.map(items, item => new SystemLookupItemDto(item));
            // Would use this but hesitant to change the underlying lookupHelpers
            // to return SystemLookupItemDto (because that retains the regionID)
            // and that is needed for "multi-region" report filtering/selections.
            //return rootGetters.getLookupItems(lookupKey);
        }
    },

    // Pass-through/manipulations of data from other modules.
    globalRegionId: (state, getters, rootState, rootGetters) => rootState.system.globalRegionId,

    user: (state, getters, rootState, rootGetters) => rootState.authentication.session.user,

    userRegions: (state, getters, rootState, rootGetters) => {
        return () => _.map(rootGetters.lookupHelpers.getRegions(), (it) => ({ id: it.regionID, name: it.displayName, inactive: false }));
    },
    branches: (state, getters, rootState, rootGetters) => {
        return (showInactive=false) => {
            let branches = _.get(rootState.system, `lookups.branches`, []);
            let regions = getters.getLookupSelections('regions', showInactive);
            //branches = _.listToListExist(_.filter, branches, regions, (branch) => ({ id: branch.regionID }));
            branches = _.filter(branches, (branch) => _.some(regions, (region) => region.id == branch.regionID));
            return _.map(branches, (item) => new SystemLookupItemDto({ ...item, name: `${item.regionDisplay}/${item.name}` }));
        }
    },
    /*===================================================================================================
    =====================================================================================================
    ===================================================================================================*/
    accountCodes: (state, getters) => {
        return () => getters.getLookupSelectionsRaw(LookupNames.ACCOUNTING_CODES);
    },
    revenueAccountCodes: (state, getters) => {
        return () => getters.getLookupSelectionsRaw(LookupNames.REVENUE_ACCOUNTING_CODES);
    },
    regions: (state, getters, rootState, rootGetters) => {
        return () => getters.userRegions();
    },
    regionsPlusRegion0: (state, getters, rootState, rootGetters) => {
        return () => { 
            let globalRegion = rootState.system.globalRegion;
            let userRegions = _.filter(getters.userRegions(), r => r.id !== globalRegion.regionID);
            return [{id: globalRegion.regionID, name: `${globalRegion.regID} - ${globalRegion.description}`, inactive: false}, ...userRegions];
        }
    },
    staff: (state, getters) => {
        return () => getters.getLookupSelectionsRaw(LookupNames.STAFF);
    },
    banks: (state, getters) => {
        return () => getters.getLookupSelectionsRaw(LookupNames.ESCROW_ACCOUNTS);
    },
    isCdf: (state) => {
        return () => state.bool;
    },
    workflowTasks: (state, getters) => {
        return () => {
            let nonConfiguredTaskItem = new SystemLookupItem({ id: 0, name: 'Non-Configured Tasks' });
            let workflowTaskItems = getters.getLookupSelectionsRaw(LookupNames.WORKFLOW_TASKS);
            return [nonConfiguredTaskItem, ...workflowTaskItems];
        }
    },
    workflowDepartments: (state, getters) => {
        return () => getters.getLookupSelectionsRaw(LookupNames.WORKFLOW_DEPARTMENTS);
    },
    titleCompanies: (state, getters) => {
        return () => {
            let titleCompanies = getters.getLookupSelectionsRaw(LookupNames.TITLE_COMPANIES);
            let regions = getters.userRegions();
            let hasGlobalRegion = _.some(regions, (region) => region.id == getters.globalRegionId)
            
            //Get Title Companies based on the selected regions of the current user if the user does not have the Global Region
            if (!hasGlobalRegion)
                titleCompanies = _.filter(titleCompanies, (titleCompany) => _.some(regions, (region) => titleCompany.regionID == getters.globalRegionId || region.id == titleCompany.regionID));
            return titleCompanies;
        }
    },
    underwriterCompanies: (state, getters) => {
        return () => getters.getLookupSelectionsRaw(LookupNames.UNDERWRITERS);
    },
    texasCounties: (state, getters, rootState, rootGetters) => {
        return () => rootGetters.lookupHelpers.getCountiesByState('TX');
    },
    listCodes: (state, getters) => {
        return () => _.sortBy(getters.getLookupSelectionsRaw(LookupNames.LIST_CODES), item => _.toLower(item.name));
    },
    /*===================================================================================================
        Getters with dependencies.
    ===================================================================================================*/
    regionDepartmentTasks: (state, getters) => {
        return (showInactive=false) => {
            let regions = state.parameterValues.p_Regions;
            let departments = state.parameterValues.p_Departments;
            let workflowTasks = getters.getLookupSelections('workflowTasks', showInactive);
            let region0 = _.filter(getters.getLookupSelections('regions', showInactive), r => r.name.indexOf("0 -") >= 0);
            if(!region0 || !_.includes(regions, region0.length > 0 ? region0[0].id : 1))  // Reverse logic: search only if there are no RegID == 0 (RegionID = '1'). Otherwise pull in all tasks.
                workflowTasks = _.filter(workflowTasks, (task) => _.some(regions, (region) => region == task.regionID));
            workflowTasks = _.filter(workflowTasks, (task) => _.some(departments, (dept) => dept == task.additionalIdentity));
            return [new SystemLookupItem({ id: 0, name: 'Non-Configured Tasks' }), ...workflowTasks];
        }
    },
    regionBranches: (state, getters) => {
        return (showInactive=false) => {
            let branches = getters.getLookupSelections('branches', showInactive);
            branches = _.filter(branches, (branch) => _.some(state.parameterValues.p_Regions, (region) => region == branch.regionID));
            return branches;
        }
    },
    staffMembers: (state, getters, rootState, rootGetters) => {
        return (showInactive=false) => {
            let staffType = state.parameterValues.p_StaffTypes ?? state.parameterValues.p_StaffTypes1099;
            let staffTypesToFilter = [];

            let membersFinal = rootGetters.systemLookups.staff;

            if (!staffType) {
                staffTypesToFilter = [];
            }
            else if (!_.isArray(staffType)) {
                staffTypesToFilter = [staffType];
            }
            else {
                staffTypesToFilter = staffType;
            }

            // We have a filter.
            if (!_.isEmpty(staffTypesToFilter)) {
                membersFinal = _.filter(membersFinal, (item) => {
                    return _.some(item.jobDutyIDs, (duty) =>
                        _.some(staffTypesToFilter, (staffDuty) => staffDuty == duty ));
                });
            }

            let staffMembers = _.map(_.uniqBy(membersFinal, 'id'), (item) => ({ id: item.id, name: item.name, inactive: item.isInactive }));

            return _.sortBy(staffMembers, item => item.name.substr(item.name.indexOf("-") + 2));
        }
    },
    persons: (state, getters) => {
        return _.map(_.uniqBy(state.persons, 'id'), (item) => ({ id: item.id, name: item.name, inactive: item.isInactive }));
    },
    userDepartments: (state, getters) => {
        return (showInactive=false) => {
            let departments = getters.getLookupSelections('workflowDepartments', showInactive);
            return [new SystemLookupItem({ id: 0, name: 'None' }), ...departments];
        }
    },
    customers: (state, getters) => {
        return (showInactive=false) => {
            let regions = getters.userRegions();
            let hasGlobalRegion = _.some(regions, (region) => region.id == getters.globalRegionId)
            
            let customersTemp = state.customers;
            customersTemp = _.filter(customersTemp, (cust) => (showInactive == true || cust.inactive == false));
            
            //Get Customers based on the selected regions of the current user if the user does not have the Global Region
            if (!hasGlobalRegion){
                customersTemp = _.filter(customersTemp, (cust) => _.some(regions, (region) => region.regionID == cust.regionID));
            }
            return [new SystemLookupItem({ id: -1, name: 'None' }), ...customersTemp];
        }
    },
    roleTypeCompanies: (state, getters) => {
        return (showInactive=false) => {
            let regions = getters.userRegions();
            let hasGlobalRegion = _.some(regions, (region) => region.id == getters.globalRegionId)
            
            let customersTemp = state.customers;
            customersTemp = _.filter(customersTemp, (cust) => (showInactive == true || cust.inactive == false));
            
            //Get Customers based on the selected regions of the current user if the user does not have the Global Region
            if (!hasGlobalRegion){
                customersTemp = _.filter(customersTemp, (cust) => _.some(regions, (region) => region.regionID == cust.regionID));
            }
            
            //Will be uncomment when p_RealtorRoleTypes parameter added to BiParameter table
            customersTemp = _.filter(customersTemp, (cust) => _.some(state.parameterValues.p_RealtorRoleTypes, (role) => role == cust.additionalIdentity));
            
            return [new SystemLookupItem({ id: -1, name: 'None' }), ...customersTemp];
        }
    },
    buyersSellers: (state, getters) => {
        return () => {
            let regions = getters.userRegions();
            let hasGlobalRegion = _.some(regions, (region) => region.id == getters.globalRegionId)
            
            let buyersSellersTemp = state.buyersSellers;
            
            //Get BuyersSellers based on the selected regions of the current user if the user does not have the Global Region
            if (!hasGlobalRegion){
                buyersSellersTemp = _.filter(buyersSellersTemp, (bs) => _.some(regions, (region) => region.regionID == bs.regionID));
            }
            return [new SystemLookupItem({ id: 0, name: 'None' }), ...buyersSellersTemp];
        }
    },
    companyContacts: (state, getters) => {
        return (showInactive=false) => {
            let regions = getters.userRegions();
            let hasGlobalRegion = _.some(regions, (region) => region.id == getters.globalRegionId)
            
            let companyContactsTemp = state.companyContacts;
            companyContactsTemp = _.filter(companyContactsTemp, (contact) => (showInactive == true || contact.inactive == false));
            
            //Get Customers based on the selected regions of the current user if the user does not have the Global Region
            if (!hasGlobalRegion){
                companyContactsTemp = _.filter(companyContactsTemp, (contact) => _.some(regions, (region) => region.regionID == contact.regionID));
            }
            
            //Will be uncomment when p_RoleTypeCompanies parameter added to BiParameter table
            companyContactsTemp = _.filter(companyContactsTemp, (contact) => _.some(state.parameterValues.p_RoleTypeCompanies, (company) => company == contact.additionalIdentity));

            return [new SystemLookupItem({ id: -1, name: 'None' }), ...companyContactsTemp];
        }
    },
    securityRoles: (state, getters) => {
        let securityRoles_temp = _.filter(state.securityRoles, (r) => _.some(state.parameterValues.p_Regions, (region) => region == r.regionID || r.regionID == 0));
        return _.map(_.uniqBy(securityRoles_temp, 'id'), (item) => ({ id: item.id, name: item.name, inactive: item.inactive }));
    },
    allCompanyRoles: (state, getters, rootState, rootGetters) => {
        return (showInactive=false) => {
            let roles = rootGetters.lookupHelpers.getCompanyRoles(0, true);
            return showInactive ? roles : _.filter(roles, r => !r.inactive);
        }
    },
    typeOfFunds: (state, getters) => {
        return () => getters.getLookupSelectionsRaw(LookupNames.TYPE_FUNDS);
    },

    clauseCategories: (state, getters) => {
        return getters.getLookupSelectionsRaw(LookupNames.STANDARD_LANGUAGE_CATEGORIES);
    },

    clauseReportTypes: state => state.clauseReportTypes,

    userStatuses: state => state.userStatuses,
    
    sourceOfBusinesses: (state, getters) => {
        return () => getters.getLookupSelectionsRaw(LookupNames.SOURCE_OF_BUSINESSES);
    },
    
    usStates: (state, getters) => {
        return () => {
            let states = getters.getLookupSelectionsRaw(LookupNames.STATES);
            return _.map(states, (item) => new SystemLookupItemDto({ ...item, name: `${item.data}` }));
        }
    },
    
    escrowOfficerStaffM: (state, getters) => {
        return () => {
            let staffMembers = getters.getLookupSelectionsRaw(LookupNames.ESCROW_OFFICERS);

            return _.sortBy(staffMembers, item => item.name.substr(item.name.indexOf("-") + 2));
        }
    },

    titleOfficerStaffM: (state, getters) => {
        return () => {
            let staffMembers = getters.getLookupSelectionsRaw(LookupNames.TITLE_OFFICERS);

            return _.sortBy(staffMembers, item => item.name.substr(item.name.indexOf("-") + 2));
        }
    },
    
    notaryStaffM: (state, getters) => {
        return () => {
            let staffMembers = getters.getLookupSelectionsRaw(LookupNames.NOTARIES);

            return _.sortBy(staffMembers, item => item.name.substr(item.name.indexOf("-") + 2));
        }
    },
    
    salesRepStaffM: (state, getters) => {
        return () => {
            let staffMembers = getters.getLookupSelectionsRaw(LookupNames.SALES_REPS);

            return _.sortBy(staffMembers, item => item.name.substr(item.name.indexOf("-") + 2));
        }
    },

    closingAgentStaffM : (state, getters) => {
        return () => {
            let staffMembers = getters.getLookupSelectionsRaw(LookupNames.CLOSING_AGENTS);

            return _.sortBy(staffMembers, item => item.name.substr(item.name.indexOf("-") + 2));
        }
    },

    fl_Category: (state, getters) => {
        return () => {
            let categories = getters.getLookupSelectionsRaw(LookupNames.ORDER_TIME_TRACKING_CATEGORIES);
            categories = _.filter(categories, (c) => c.data == "FL");
            return categories;
        }
    },

    orderTrackingAccountCodeIDs: (state, getters) => {
        return () => {
            let lineItems = getters.getLookupSelectionsRaw(LookupNames.ORDER_TIME_TRACKING_ACCOUNT_CODES);
            lineItems = _.filter(lineItems, (c) => c.data == "FL");
            return lineItems;
        }
    },

    reportInfo: state => state.reportInfo,
}

const actions = {
    [REPORT_ACTIONS.SET_PARAMETER_DATES](context, dates) {
        // Done like this to force the "change trigger" that evaluates PAYEE_COMPANY_CONTACTS.
        context.commit(REPORT_MUTATIONS.SET_PARAMETER_VALUE, { key: 'p_StartDate', value: dates.startDate });
        context.commit(REPORT_MUTATIONS.SET_PARAMETER_VALUE, { key: 'p_StaticStartDate', value: dates.startDate });
        context.commit(REPORT_MUTATIONS.SET_PARAMETER_VALUE, { key: 'p_StaticEndDate', value: dates.endDate });

        var promise = context.dispatch(REPORT_ACTIONS.SET_PARAMETER_VALUE, { key: 'p_EndDate', value: dates.endDate })
        .then(() => { return context.state.parameterValues; });
        
        if (context.state.parametersKeys.includes('p_BeginningBalance')){
            context.dispatch(REPORT_ACTIONS.SET_BEGINNING_BALANCE, { bankID: context.state.parameterValues.p_Bank, endDate: context.state.parameterValues.p_StaticEndDate} )    
        }

        return promise;
    },

    [REPORT_ACTIONS.SET_PARAMETER_VALUE](context, parameterValue) {
        context.commit(REPORT_MUTATIONS.SET_PARAMETER_VALUE, parameterValue);

        switch (parameterValue.key) {
            case 'p_Departments':
                return context.dispatch(REPORT_ACTIONS.SET_DEPARTMENT_USERS_AVAILABLE, parameterValue.value)
                    .then(() => {
                        return context.state.parameterValues;
                    });
            case 'p_StartDate':
            case 'p_EndDate':
                return context.dispatch(REPORT_ACTIONS.SET_PAYEE_COMPANY_CONTACTS, { startDate: context.state.parameterValues.p_StartDate, endDate: context.state.parameterValues.p_EndDate} )
                    .then(() => {
                        return context.state.parameterValues;
                    });
            case 'p_Bank':
            case 'p_StaticEndDate':
                if (context.state.parametersKeys.includes('p_BeginningBalance')){
                    return context.dispatch(REPORT_ACTIONS.SET_BEGINNING_BALANCE, { bankID: context.state.parameterValues.p_Bank, endDate: context.state.parameterValues.p_StaticEndDate} )
                        .then(() => {
                            return context.state.parameterValues;
                        });
                }
                return Promise.resolve(context.state.parameterValues);
            default:
                return Promise.resolve(context.state.parameterValues)
        }
    },
    [REPORT_ACTIONS.SET_DEPARTMENT_USERS_AVAILABLE](context, departments) {
        let promise = api.OrderWorkflowApi
            .getDepartmentUsersByDepartmentIds(departments)
            .then((users) => {
                let mappedUsers = _.map(_.uniqBy(users, 'usersID'), u => ({ id: u.usersID, name: u.fullName }));
                return [new SystemLookupItem({ id: 0, name: 'None' }), ...mappedUsers];
            });

        return ACTION_WITH_COMMIT(context, promise, REPORT_MUTATIONS.SET_DEPARTMENT_USERS_AVAILABLE);
    },
    [REPORT_ACTIONS.SET_PAYEE_COMPANY_CONTACTS](context, dates) {
        if (_.isNil(dates?.startDate) || _.isNil(dates?.endDate))
        {
            let emptyCompanyContacts = {
                    payeeCompanies: [],
                    payeeContacts: [],
            };
            context.commit(REPORT_MUTATIONS.SET_PAYEE_COMPANY_CONTACTS, emptyCompanyContacts);
            return Promise.resolve(emptyCompanyContacts);
        }

        let promise = api.CheckWritingApi
            .getCheckPayeeCompanyContacts(dates.startDate, dates.endDate)
            .then((result) => {
                return {
                    payeeCompanies: _.concat(result.payeeCompanies, _.map(result.manualPayees, item => ({...item, id: item.name}))),
                    payeeContacts: result.payeeContacts,
                };
            });

        return ACTION_WITH_COMMIT(context, promise, REPORT_MUTATIONS.SET_PAYEE_COMPANY_CONTACTS);
    },
    [REPORT_ACTIONS.SET_BEGINNING_BALANCE](context, reconInfo) {
        if (_.isNil(reconInfo?.endDate) || _.isNil(reconInfo?.bankID))
        {
            context.commit(REPORT_MUTATIONS.SET_BEGINNING_BALANCE, { key: 'p_BeginningBalance', value: 0 });
            return Promise.resolve(0);
        }
        let promise = api.ReconciliationApi.getAllReconciliations(reconInfo.bankID)
            .then((result) => {
                let mappedRecon = _.find(result, item => item.reconciliationDate == reconInfo.endDate);
                let priRecon = _.find(result, item => item.reconciliationID == mappedRecon?.priorReconciliationID);
                return{
                    key: 'p_BeginningBalance',
                    value:  priRecon?.escrowTrialBalance
                }
            });
        return ACTION_WITH_COMMIT(context, promise, REPORT_MUTATIONS.SET_BEGINNING_BALANCE);
    },
    [REPORT_ACTIONS.GET_ACTIVITY_DATE](context, bankID) {
        if (_.isNil(bankID) || context.state.reportInfo.label != "Reconciliation Daily Report")
        {
            return Promise.resolve(0);
        }

        const promise = api.ReconciliationApi.getAllReconciliations(bankID)
            .then((result) => {
                let mappedRecon = _.findLast(_.orderBy(result, item => item.reconciliationDate));
                return {
                    key: 'p_StaticActivityDate',
                    value: mappedRecon?.reconciliationDate
                }
            });

        return promise;
    },

    [REPORT_ACTIONS.CLEAR_PARAMETER_VALUES](context) {
        context.commit(REPORT_MUTATIONS.CLEAR_PARAMETER_VALUES);
        return Promise.resolve(context.state.parameterValues);
    },

    [REPORT_ACTIONS.INITIALIZE_LOOKUPS](context) {
        let promises = [];

        let staffsPromise = api.EscrowAccountingApi.getEscrowUsers(0);
        promises.push(ACTION_WITH_COMMIT(context, staffsPromise, REPORT_MUTATIONS.SET_PERSONS));

        let securityRolesPromise = api.UsersApi.getGroups()
        .then((users) => {
            let mappedUsers = _.orderBy(_.map(_.uniqBy(users, 'groupUsersID'), u => ({ id: u.groupUsersID, name: u.login, inactive: u.isInactive, regionID: u.regionID })), o => o.name.toLowerCase());
            return [new SystemLookupItem({ id: 0, name: '[None]', inactive: 0 , regionID: 0}), ...mappedUsers];
        });
        promises.push(ACTION_WITH_COMMIT(context, securityRolesPromise, REPORT_MUTATIONS.SET_SECURITY_ROLES));
        
        let customersPromise = api.LookupsApi.getCustomers();
        promises.push(ACTION_WITH_COMMIT(context, customersPromise, REPORT_MUTATIONS.SET_CUSTOMERS));
        
        let buyersSellersPromise = api.LookupsApi.getBuyersSellers();
        promises.push(ACTION_WITH_COMMIT(context, buyersSellersPromise, REPORT_MUTATIONS.SET_BUYERS_SELLERS));

        let companyContactsPromise = api.LookupsApi.getCompanyContacts();
        promises.push(ACTION_WITH_COMMIT(context, companyContactsPromise, REPORT_MUTATIONS.SET_COMPANY_CONTACTS));

        return Promise.all(promises);
    },
    [REPORT_ACTIONS.SET_PARAMETERS_KEYS](context, keys){
        context.commit(REPORT_MUTATIONS.SET_PARAMETERS_KEYS, keys);
        return Promise.resolve(keys);
    },
    [REPORT_ACTIONS.SET_REPORT_INFO](context, values){
        context.commit(REPORT_MUTATIONS.SET_REPORT_INFO, values);
        return Promise.resolve(values);
    },
}

const mutations = {
    [REPORT_MUTATIONS.SET_PERSONS] (state, values) {
        state.persons = values;
    },
    [REPORT_MUTATIONS.SET_PARAMETER_VALUE] (state, parameter) {
        state.parameterValues[parameter.key] = parameter.value;
    },
    [REPORT_MUTATIONS.SET_BEGINNING_BALANCE] (state, parameter) {
        state.parameterValues[parameter.key] = parameter.value;
        state.beginningBalance =parameter.value;
    },
    [REPORT_MUTATIONS.CLEAR_PARAMETER_VALUES] (state) {
        state.parameterValues = {
            p_StartDate: null,
            p_EndDate: null,
            p_StaticEndDate: null,
            p_Bank: null,
            p_Regions: [],
            p_Departments: [],
            p_StaffTypes1099: [],
            p_StaffTypes: null
        };
    },
    [REPORT_MUTATIONS.SET_DEPARTMENT_USERS_AVAILABLE] (state, values) {
        state.departmentUsers = values;
    },
    [REPORT_MUTATIONS.SET_PAYEE_COMPANY_CONTACTS] (state, values) {
        state.payeeCompanies = values.payeeCompanies;
        state.payeeContacts = values.payeeContacts;
    },
    [REPORT_MUTATIONS.SET_SECURITY_ROLES] (state, values) {
        state.securityRoles = values;
    },
    [REPORT_MUTATIONS.SET_CUSTOMERS] (state, values) {
        state.customers = values;
    },
    [REPORT_MUTATIONS.SET_BUYERS_SELLERS] (state, values) {
        state.buyersSellers = values;
    },
    [REPORT_MUTATIONS.SET_COMPANY_CONTACTS] (state, values) {
        state.companyContacts = values;
    },
    [REPORT_MUTATIONS.SET_PARAMETERS_KEYS] (state, values) {
        state.parametersKeys = values;
    },
    [REPORT_MUTATIONS.SET_REPORT_INFO] (state, values) {
        state.reportInfo = values;
    },
}

export default { namespaced: true, state, getters, actions, mutations };