//import { _ } from "core-js";
import { mapGetters, mapState } from "vuex";
export default {
    computed: {
        ...mapGetters([
            "lookupHelpers",
            "lookupItems"
        ]),
            ...mapState({
                    globalRegionId: state => state.system.globalRegionId,
            })
        },

    methods: {
        getSystemLookupGridColumn({
            column,
            lookupKey = "",
            regionId = null,
            customSort = null,
            allowEdit = null
        }) {
            let resultCol = _.cloneDeep(column);
            let regionID = _.parseNumber(regionId, this.globalRegionId);
            let dataFieldID = column.dataField;
            let allLookupItems = this.lookupHelpers.getAllLookupItems(lookupKey);
            let displayExpr = "name";
            let editorOptions = { showClearButton: true };
            if (!_.hasIn(resultCol, 'lookup')) {
                resultCol["lookup"] = {dataSource: allLookupItems, valueExpr: "id", displayExpr: "name" };
            } else {
                displayExpr = _.get(column, "dataSource.displayExpr") || "name";
            }
            if (_.hasIn(resultCol, 'editorOptions')) {
                editorOptions = resultCol.editorOptions;
            }
            let sortFunc = (_.isFunction(customSort) && !_.isNull(customSort))
                         ? customSort
                         : function(i) { return _.toLower(_.get(i, displayExpr)); };


            resultCol.editCellTemplate = function(cellElement, cellInfo) {
                //if the row in the grid is region specific, reassign region to filter
                if (_.hasIn(cellInfo.data, "regionID")) {
                    regionID = _.parseNumber(cellInfo.data.regionID, 0);
                } else if (_.hasIn(cellInfo.data, "regionId")){//just in case
                    regionID = _.parseNumber(cellInfo.data.regionId, 0);
                }

                let isDisabled = false;
                if (typeof allowEdit === 'function') {
                    isDisabled = !allowEdit(cellInfo.data);
                }
                else if (typeof allowEdit === 'boolean') {
                    isDisabled = !allowEdit
                }
                
                let selectedID = cellInfo.value;
                let editLookupItems = (regionID === 1) ? _.sortBy(_.filter(allLookupItems, item => (!_.parseBool(item.inactive) || item.id === selectedID), [sortFunc]))
                                                       : _.sortBy(_.filter(allLookupItems, item => (_.includes([0, regionID, 1], _.parseNumber(item.regionID, 0)) && !_.parseBool(item.inactive)) || item.id === selectedID), [sortFunc]);
                $("<div />").dxSelectBox({
                    dataSource: {
                        loadMode: "raw",
                        load() {
                            return editLookupItems;
                        }
                    },
                    displayExpr: "name",
                    valueExpr: "id",
                    value: selectedID,
                    disabled: isDisabled,
                    ...editorOptions,
                    onValueChanged(e) {
                        cellInfo.setValue(e.value);
                    },
                }).appendTo(cellElement);
            };
            resultCol.calculateSortValue = function(rowData) {
                let value = this.calculateCellValue(rowData);
                return this.lookup.calculateCellValue(value);
            },
            resultCol.setCellValue = function(rowData, value) {
                _.set(rowData, dataFieldID, value);
            };
            resultCol.sortingMethod = function (value1, value2) {
                // Handling null values
                if(!value1 && value2) return -1;
                if(!value1 && !value2) return 0;
                if(value1 && !value2) return 1;
                // Case-insensitive
                return value1.localeCompare(value2, undefined, { sensitivity: 'accent' });
            }

            return resultCol;
        },
    }
}