import { ADD_FILE_GROUP, VIEW_DOCS, SAVE, SPACER } from "./global";
export const Actions = {
    SAVE_ALL: {
        automation_id: 'btn_save_all',
        key: "doc-viewer:save_all",
        text: "Save All",
        icon: "fa-save",
        tooltip: "Save All Documents"
    },
    EMAIL: {
        automation_id: 'btn_email',
        key: "doc-viewer:email",
        text: "Email",
        icon: "fa-email",
        tooltip: "Email Documents"
    }
};

export default {
    DOC_GEN: [
        SPACER, SPACER, ADD_FILE_GROUP
    ],
    DOC_VIEWER: [
        SPACER, SAVE, Actions.SAVE_ALL,
        SPACER, VIEW_DOCS, Actions.EMAIL
    ]
};