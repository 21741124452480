import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useStore } from "vuex";
import HealthApi from "@/api/health";


export const useHealthStore = defineStore("health", () => {
    const vuexStore = useStore();

    const validEnvs = ["dev","ci","qa","staging","uat","prod"];
    const getDomainEnv = () => _.reduce(validEnvs, (env,val) => _.includes(_.split(window.location.host, "."), env) ? env : val, "");

    const health = ref({});

    const username = computed(() => vuexStore?.getters?.currentSession?.username);
    const env = computed(() => health.value?.buildEnv || getDomainEnv());
    const buildImage = computed(() => health.value?.image);
    const hash = computed(() => health.value?.gitHash);
    const branch = computed(() => health.value?.gitBranch);
    const buildDate = computed(() => health.value?.buildDate);
    const sessionInfoText = computed(() => `USER:   \t${username.value}\nENV:    \t${env.value}\nHASH:   \t${hash.value}\nBRANCH: \t${branch.value}\nDATE:   \t${buildDate.value}`);

    async function getHealth(force=false) {
        if(!_.isEmpty(health.value) && !force) return;
        try {
            let response = await HealthApi.getUiHealth();
            health.value = response.data;
        }
        catch(err) {
            console.error("An issue occurred retreiving API health info.");
        }
    }

    getHealth();

    return {
        health,
        username,
        env,
        buildImage,
        hash,
        branch,
        buildDate,
        sessionInfoText,
        getHealth
    };
});