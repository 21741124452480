// rqBusy.js
import { ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { START_WAIT, END_WAIT, END_ALL_WAITS } from "@/store/actions";
import { SET_BUSY_MESSAGE } from "@/store/mutations";

//generic use
const createRqBusyInterface = function({ scopeKey=null, initialMessage=null, store=null }={ scopeKey: null, initialMessage: null, store: null }) {
    if(_.isNil(store)) {
        console.error("RqBusy - createRqBusyInterface - Vuex store context must be provided.");
        return {};
    }
    let busyScope = scopeKey || _.uniqueId("rq-busy-key-");

    const disAction = action => store.dispatch(action, busyScope);
    const commitMsg = message => store.commit(SET_BUSY_MESSAGE, { key: busyScope, message });
    const clearMsg = () => store.commit(SET_BUSY_MESSAGE);

    commitMsg(initialMessage);

    const startWait = () => disAction(START_WAIT);
    const endWait = () => disAction(END_WAIT);
    const clearWaits = () => disAction(END_ALL_WAITS);
    const setBusyMessage = msg => commitMsg(msg);
    const clearBusyMessage = () => clearMsg();
    const waitFor = (promise, message=null) => {
        let hasMessage = _.isString(message) && !_.isEmpty(message);
        if(hasMessage) commitMsg(message);
        startWait();
        return promise.finally(() => {
            endWait();
            if(!hasMessage) return;
            clearMsg();
        });
    }
    return {
        waitFor,
        startWait,
        endWait,
        clearWaits,
        setBusyMessage,
        clearBusyMessage
    };
}

//plugin
const rqBusyPlugin = {
    install(app) {
        Object.defineProperty(app.config.globalProperties, "$rqBusy", {
            get() {
                let scopeKey = this.$route?.name || null;
                let busyInterface = createRqBusyInterface({ scopeKey, store: this.$store });
                const isBusy = () => {
                    console.warn("RqBusy plugin can't properly keep track of caller scope.  Use RqBusy composable instead (shared/composables/busy.js).");
                    return false;
                };
                return {
                    ...busyInterface,
                    wait: busyInterface.waitFor,
                    endAll: busyInterface.clearWaits,
                    isBusy
                }
            }
        });
    }
};

//composable
const useRqBusy = function({ scopeKey=null, initialMessage=null }={ scopeKey: null, initialMessage: null }) {
    const store = useStore();
    let busyInterface = createRqBusyInterface({ scopeKey, initialMessage, store });

    const localBusyCount = ref(0);
    const isBusy = computed(() => localBusyCount.value > 0);

    const decrementBusyCount = () => {
        if(!isBusy.value) return;
        localBusyCount.value--;
    };

    function startWait() {
        localBusyCount.value++;
        busyInterface.startWait();
    }
    function endWait() {
        busyInterface.endWait();
        decrementBusyCount();
    }
    function clearWaits() {
        busyInterface.clearWaits();
        localBusyCount.value = 0;
    }
    function setBusyMessage(message) {
        busyInterface.setBusyMessage(message);
    }
    function clearBusyMessage() {
        busyInterface.clearBusyMessage();
    }
    function waitFor(...params) {
        localBusyCount.value++;
        return busyInterface
            .waitFor(...params)
            .finally(() => {
                decrementBusyCount();
            });
    }

    onUnmounted(() => clearBusyMessage());

    return {
        waitFor,
        startWait,
        endWait,
        clearWaits,
        setBusyMessage,
        clearBusyMessage,
        isBusy,
        localBusyCount
    };
}

export {
    createRqBusyInterface,
    rqBusyPlugin,
    useRqBusy
}