import { ORDER_ACTIONS, INVOICE_ACTIONS } from '../../actions';
import { INVOICE_MUTATIONS } from '../../mutations';
import { BASIC_ACTION, ACTION_WITH_COMMIT, updateStateArray, removeFromStateArrayByValue } from '../../helpers';
import invoicesApi from '@/api/invoices';

/*
    TG - 5/31/19:
    This module is currently not being referenced as an orders sub-module to avoid app-wide refactor of the order loans reference ($store.state.orders.loans),
    so for now is simply being used for organization and merged in directly with the orders module (./index.js).  For now this is simply for organization/readability porposes.
    This state object is left here mostly for reference and/or when/if updates are made to make this a proper sub-module.  The loan actions/mutatiosn are also merged with the order
    actions/mutations for backward compatibility.
*/

const state = {
    invoices: []
};

const getters = {
    invoiceOptions: state => _.map(state.invoices, (item, index) => {
        let customerName = _.get(item, "customerCompanyName", null) || "";
        return {
            id: item.invoiceID,
            name: `Invoice ${index + 1} of ${state.invoices.length}: ${customerName}`
        };
    })
};

const actions = {

    [INVOICE_ACTIONS.GET_INVOICES](context) {
        let orderId = context.state.orderId;
        let apiPromise = invoicesApi.getByOrderId(orderId);
        return ACTION_WITH_COMMIT(context, apiPromise, INVOICE_MUTATIONS.SET_ORDER_INVOICES);
    }
};

const mutations = {
    [INVOICE_MUTATIONS.SET_ORDER_INVOICES] (state, value) { state.invoices = value; }
};

export default {
    state,
    getters,
    actions,
    mutations
};