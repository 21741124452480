<template>
    <div :class="{ 'rq-html-editor-dialog rq-container content-wrapper':true, 'rq-html-editor-dialog-seamless': seamless }">
        <rq-html-editor
            ref="htmlEditor"
            :custom-toolbar-items="customToolbarItems"
            :custom-menu-items="customMenuItems"
            :standard-language-options="{
                enabled: includeStandardLanguage,
                defaultCategoryId: defaultCategoryId,
                categories: categories
            }"
            :readOnly="readOnly"
            v-model="editorContent"
            @toolbar-action="onToolbarAction"
        />
    </div>
</template>

<script>
    import RqHtmlEditor from "../editors/RqHtmlEditor";

    export default {
        name: "HtmlEditorDialog",
        components: { RqHtmlEditor },
        props: {
            documentName: { type: String, default: null },
            content: { type: String, default: null },
            customToolbarItems: { type: Array, default: () => [] },
            customMenuItems: { type: Array, default: () => [] },
            includeStandardLanguage: { type: Boolean, default: false },
            defaultCategoryId: { type: Number, default: 0 },
            categories: { type: Array, default: () => [] },
            readOnly: { type: Boolean, default: false },
            seamless: { type: Boolean, default: false },
        },
        data() {
            const self = this;
            return {
                contentReady: false,
                editorContent: ""
            };
        },

        computed: {
            editorInstance() { return _.get(this, "$refs.htmlEditor", null); },
            madeChanges() { return _.getBool(this, "editorInstance.madeChanges"); }
        },

        created() {
            this.prepareContent();
        },

        methods: {

            prepareContent() {
                const self = this;
                if(_.isEmpty(self.content)) {
                    self.contentReady = true;
                    return;
                }
                if(_.startsWith(self.content, "{\\rtf")) {
                    let apiPromise = self.$api.UtilitiesApi.rtfToHtml(self.content);
                    self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.editorContent = result;
                            self.contentReady = true;
                        })
                        .catch(err => {
                            console.error(err);
                            self.$toast.error(err.errorMessage);
                        });
                    return;
                }
                self.editorContent = self.content;
                self.contentReady = true;
            },

            onToolbarAction(e) {
                this.$emit("action", { name: "toolbar-action", data: e });
            },

            getContent(resultOptions) {
                const self = this;
                if(!self.madeChanges) {
                    return Promise.resolve({ html: null, rtf: null, changed: false });
                }
                let opts = _.defaults(resultOptions, { html: false, rtf: false, fragment: false });
                let html = (opts.html === opts.rtf || opts.html) ? self.editorContent : null;
                let rtfPromise = (opts.html === opts.rtf || opts.rtf)
                    ? self.$api.UtilitiesApi.htmlToRtf(self.editorContent)
                    : Promise.resolve(null);

                return self.$rqBusy.wait(rtfPromise)
                    .then(rtf => ({ html, rtf, changed: true }));
            },

            getRtfContent() {
                return this.getContent({ rtf: true });
            },

            getHtmlContent(fragment=false) {
                return this.getContent({ html: true, fragment });
            },

            dialogResult() {
                return this.editorContent;
            }
        }
    };

</script>