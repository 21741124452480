<template>
    <DxPopover
        v-if="isActive"
        :target="`#${popover.target}`"
        :container="container"
        :position="placement"
        title-template="rq-popover-title"
        :show-title="true"
        :wrapper-attr="{ class: 'rq-popover rq-ebank-recon-detail-popover' }"
        v-model:visible="isVisible"
        @hidden="onHidden">
        <template #rq-popover-title>
            <div class="rq-popover-header">
                <span class="title">{{popover.title}}</span>
                <b-btn variant="icon" class="close-button" v-rq-tooltip.top.hover title="Close" @click="isVisible = false"><FontAwesomeIcon icon="fas fa-times" /></b-btn>
            </div>
        </template>
        <div class="rq-container">
            <b>Bank Import Info</b>
            <dl class="row">
                <dt class="col-3 popover-label">Type Code:</dt> <dd class="col-9">{{popover.bankItem.autoReconTransactionTypeCode}}</dd>
                <dt class="col-3 popover-label">Description:</dt> <dd class="col-9">{{popover.bankItem.transactionDescription}}</dd>
                <dt class="col-3 popover-label">Issue:</dt> <dd class="col-9">{{popover.bankItem.issue}}</dd>
                <dt class="col-3 popover-label">Amount:</dt> <dd :class="{ 'col-9': true, 'text-success': hasAmountMismatch }">{{formatMoney(popover.bankItem.transactionAmount)}}</dd>
                <dt class="col-3 popover-label">Bank Ref#:</dt> <dd class="col-9">{{popover.bankItem.bankReferenceNumber}}</dd>
                <dt class="col-3 popover-label">Customer Ref#:</dt> <dd class="col-9">{{popover.bankItem.customerReferenceNumber}}</dd>
                <dt class="col-3 popover-label">Bank Notes:</dt> <dd class="col-9">{{popover.bankItem.notes}}</dd>
            </dl>
            <hr/>
            <b>{{isWire ? 'Wire' : popover.isCheck ? 'Disbursement' : 'Receipt'}} Info&nbsp;&nbsp;<b-btn variant="link" tabindex="0" @click="onGotoCheckWriting">(Goto Check Writing)</b-btn></b>
            <dl class="row" v-if="popover.isCheck">
                <dt class="col-3 popover-label">File#:</dt> <dd class="col-9">{{popover.item.gfNo}}&nbsp;&nbsp;<b-btn variant="link" tabindex="0" @click="onGotoFile">(Goto File)</b-btn></dd>
                <template v-if="isWire">
                    <dt class="col-3 popover-label">Wire#:</dt> <dd class="col-9">{{popover.item.wireNumber}}</dd>
                </template>
                <template v-else>
                    <dt class="col-3 popover-label">Check#:</dt> <dd class="col-9">{{popover.item.checkNumber}}</dd>
                </template>
                <dt class="col-3 popover-label">Date:</dt> <dd class="col-9">{{formatDate(popover.item.checkDate)}}</dd>
                <template v-if="isWire">
                    <dt class="col-3 popover-label">Expected Wire Date:</dt> <dd class="col-9">{{formatDate(popover.item.expectedWireOutDate)}}</dd>
                </template>
                <dt class="col-3 popover-label">Amount:</dt> <dd :class="{ 'col-9': true, 'text-warning': hasAmountMismatch }">{{formatMoney(popover.item.amount)}}</dd>
                <dt class="col-3 popover-label">Payee:</dt> <dd class="col-9">{{formatPayee()}}</dd>
                <dt class="col-3 popover-label">Payee Address:</dt> <dd class="col-9" v-html="formatAddress(popover.item)"></dd>
                <template v-if="hasReconciliationID">
                    <dt class="col-3 popover-label">Reconciliation ID:</dt> <dd class="col-9">{{popover.item.reconciliationID}}&nbsp;&nbsp;<b-btn variant="link" tabindex="0" @click="onGotoReconciliation">(Goto Reconciliation)</b-btn></dd>
                    <dt class="col-3 popover-label">Reconciliation Date:</dt> <dd class="col-9">{{formatDate(popover.item.reconciliationDate)}}</dd>
                </template>
                <dt class="col-3 popover-label">Memo:</dt> <dd class="col-9">{{popover.item.memo}}</dd>
            </dl>
            <dl class="row" v-else>
                <dt class="col-3 popover-label">File#:</dt> <dd class="col-9">{{popover.item.gfNo}}&nbsp;&nbsp;<b-btn variant="link" tabindex="0" @click="onGotoFile">(Goto File)</b-btn></dd>
                <template v-if="isWire">
                    <dt class="col-3 popover-label">Wire#:</dt> <dd class="col-9">{{popover.item.wireNumber}}</dd>
                </template>
                <template v-else>
                    <dt class="col-3 popover-label">Reference#:</dt> <dd class="col-9">{{popover.item.referenceNumber}}</dd>
                </template>
                <dt class="col-3 popover-label">Date:</dt> <dd class="col-9">{{formatDate(popover.item.depositDate)}}</dd>
                <template v-if="isWire">
                    <dt class="col-3 popover-label">Expected Wire Date:</dt> <dd class="col-9">{{formatDate(popover.item.expectedWireDate)}}</dd>
                </template>
                <dt class="col-3 popover-label">Amount:</dt> <dd :class="{ 'col-9': true, 'text-warning': hasAmountMismatch }">{{formatMoney(popover.item.amount)}}</dd>
                <dt class="col-3 popover-label">Payor:</dt> <dd class="col-9">{{popover.item.payor}}</dd>
                <template v-if="!isWire">
                    <dt class="col-3 popover-label">Deposit Slip#:</dt> <dd class="col-9">{{popover.item.depositSlipID}}</dd>
                </template>
                <template v-if="hasReconciliationID">
                    <dt class="col-3 popover-label">Reconciliation ID:</dt> <dd class="col-9">{{popover.item.reconciliationID}}&nbsp;&nbsp;<b-btn variant="link" tabindex="0" @click="onGotoReconciliation">(Goto Reconciliation)</b-btn></dd>
                    <dt class="col-3 popover-label">Reconciliation Date:</dt> <dd class="col-9">{{formatDate(popover.item.reconciliationDate)}}</dd>
                </template>
            </dl>
        </div>
    </DxPopover>
</template>

<script>
    import { computed } from "vue";
    import { mapGetters, mapState } from "vuex";
    import { DateTime } from "luxon";
    import { usePopoverVisibleBindings } from "./usePopoverVisibleBindings";
    const AMOUNT_MISMATCH = "Amount Mismatch";

    export default {
        name: "EbankReconPopover",
        props: {
            placement: { type: String, default: "left" },
            container: { type: [String, HTMLElement, Object], default: null },
            popover: { type: Object, default: () => ({target: "ebank-recon-main", isCheck: false, bankItem: {}, item: {}, visible: false, title: ""}) },
            visible: { type: Boolean, default: false },
        },
        setup(props, { emit }) {
            const visibleProp = computed(() => props.visible);
            const { isActive, isVisible } = usePopoverVisibleBindings(visibleProp);
            const onHidden = () => emit("update:visible", false);
            return { isVisible, isActive, onHidden };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                order: state => state.orders.order,
            }),
            isWire() { return _.endsWith(this.popover.bankItem.transactionType, "Wire"); },
            hasAmountMismatch() { return _.isEqual(this.popover.bankItem.issue, AMOUNT_MISMATCH); },
            hasDespoitSlip() { return !_.isNullOrEmpty(this.popover.item.depositSlipID); },
            hasReconciliationID() { return _.parseNumber(this.popover.item.reconciliationID, 0) > 0; },
            localSecurity(){
                return this.securitySettings.findValues([
                    "CheckWriting_ScreenAccess"
                    ]);
            },
        },
        methods: {
            formatAddress(item) {
                let address = `${_.toString(item.payeeAddress1)}&nbsp;${_.toString(item.payeeAddress2)}`;
                if ((_.size(item.payeeCity) + _.size(item.payeeState) + _.size(item.payeeZip)) > 0) {
                    address += _.size(item.payeeCity) > 0 ? `<br/>${_.toString(item.payeeCity)}, ${_.toString(item.payeeState)}&nbsp;&nbsp;${_.toString(item.payeeZip)}` : `<br/>${_.toString(item.payeeState)}&nbsp;&nbsp;${_.toString(item.payeeZip)}`;
                }
                return address;
            },

            formatDate(v) { return DateTime.fromISO(v).isValid ? DateTime.fromISO(v).toFormat("MM/dd/yyyy") : ""; },

            formatPayee() { return _.parseNumber(this.popover.item.payeeCompanyID, 0) > 0 ? `${this.popover.item.payeeCompanyID} - ${this.popover.item.payee}` : this.popover.item.payee; },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            onGotoFile() {
                if(!this.popover.item) return;
                let orderId = _.parseNumber(this.popover.item.ordersID, 0);
                this.$router.push({ name: "oe:main", params: { orderId } });
            },

            onGotoCheckWriting() {
                if(!this.popover.item) return;
                let orderId = this.popover.item.ordersID;
                let tab = this.popover.isCheck ? 2 : 1;
                if(this.localSecurity.CheckWriting_ScreenAccess) {
                    this.$router.push({ name: "check-writing", params: { orderId, tab } });
                } else {
                    this.$router.push({ name: "oe:main", params: { orderId } });
                }
            },

            onGotoReconciliation() {
                if(!this.popover.item.reconciliationID) return;
                this.$router.push({ name: 'ea:reconciliation', params: { reconciliationID: this.popover.item.reconciliationID }});
            },
        }
    }
</script>
