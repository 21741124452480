<template>
    <div :class="[colClass, 'form-group', isFieldRequired ? 'form-required' : '', hasValidationError ?  'has-error': '']">
        <label>{{customDataDefinition.label}}</label>
        <b-form-checkbox
            v-if="customDataDefinition.customDataType === customDataType.Boolean"
            :id="elementId('txt')"
            :automation_Id="elementId('txt')"
            :disabled="readOnly"
            v-model="v$.localCustomDataValue.value.$model"
        >
        </b-form-checkbox>
        <rqInputNumber
            v-else-if="customDataDefinition.customDataType === customDataType.Integer"
            :id="elementId('tx')"
            :automation_Id="elementId('txt')"
            :disabled="readOnly"
            defaultValue="0"
            :allowNull="true"
            cssClass="form-control"
            v-model="v$.localCustomDataValue.value.$model"
        />
        <rqInputNumber
            v-else-if="customDataDefinition.customDataType === customDataType.Decimal"
            :id="elementId('tx')"
            :automation_Id="elementId('txt')"
            :disabled="readOnly"
            defaultValue="0"
            decimals="2"
            :allowNull="true"
            cssClass="form-control"
            v-model="v$.localCustomDataValue.value.$model"
        />
        <rqInputNumber
            v-else-if="customDataDefinition.customDataType === customDataType.Money"
            :id="elementId('txt')"
            :automation_Id="elementId('txt')"
            formatType="money"
            defaultValue="0"
            :disabled="readOnly"
            decimals="2"
            :allowNull="true"
            input-group
            no-prefix
            prependIcon="fas fa-dollar-sign"
            value-event="input"
            v-model="v$.localCustomDataValue.value.$model"
        />
        <rqdx-date-box
            v-else-if="isDateTimeField"
            :id="elementId('dtp')"
            :automation_Id="elementId('dtp')"
            :type="dateType"
            :disabled="readOnly"
            :show-clear-button="false"
            v-model="v$.localCustomDataValue.value.$model"
        />
        <input
            v-else-if="customDataDefinition.customDataType === customDataType.Text"
            :id="elementId('txt')"
            :automation_Id="elementId('txt')"
            type="text"
            :maxlength="customDataDefinition.maxLength"
            :disabled="readOnly"
            class="form-control"
            v-model="v$.localCustomDataValue.value.$model"
        />
        <rq-textarea
            v-else-if="customDataDefinition.customDataType === customDataType.Memo"
            :id="elementId('memo')"
            :automation_Id="elementId('memo')"
            class="form-control"
            :disabled="readOnly"
            :maxlength="customDataDefinition.maxLength"
            :max-height="88"
            auto-resize
            v-model="v$.localCustomDataValue.value.$model"
        />
        <rq-html-editor
            v-else-if="isRtfField"
            :id="elementId('rtf')"
            :automation_Id="elementId('rtf')"
            ref="editorInstance"
            :read-only="readOnly"
            :height="300"
            v-model="v$.editorContent.$model"
            @blur="onHtmlEditorBlur"
            @change="onHtmlEditorChange"
        />
        <company-picker
            ref="companyPicker"
            v-else-if="isCompanyField"
            :id="elementId('company')"
            :automation_Id="elementId('company')"
            :disabled="readOnly"
            v-model="v$.companyObject.$model"
            @change="onCompanyPickerChange"
        />
        <rq-select-box
            v-else-if="isPickListField"
            :id="elementId('drp')"
            :automation_Id="elementId('drp')"
            :items="dropdownItems"
            :disabled="readOnly"
            v-model="v$.localCustomDataValue.value.$model"
            data-text-field="name"
            data-value-field="name"
            @change="onPickListChange"
        />
        <rq-select-box
            v-else-if="isChildPickListField"
            :id="elementId('drp')"
            :automation_Id="elementId('drp')"
            :items="dropdownItems"
            :disabled="readOnly"
            v-model="v$.localCustomDataValue.value.$model"
            data-text-field="name"
            data-value-field="name"
        />
        <rq-validation-feedback
            :offset="validationFeedbackOffset"
            messages="Field is Required"
        />
    </div>
</template>
<script>
import  { CustomDataType, CustomData } from './models';
import { SystemLookupItem, CompanyPickerModel } from '@/shared/models/models';
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf, minValue } from '@vuelidate/validators';

export default {
    name: "CustomDataField",
    components: {},
    props: {
        customDataDefinition: { type: Object, default: () => {} },
        customDataValue: { type: Object, default: () => {} },
        readOnly: { type: Boolean, default: false }
    },
    setup: () => ({ v$: useVuelidate() }),
    data: function () {
        return {
            customDataType: CustomDataType,
            dropdownItems: [],
            childDropdownItems: [],
            localCustomDataValue: new CustomData(),
            contentReady: false,
            editorContent: "",
            editorHasChange: false,
            companyObject: new CompanyPickerModel(),
            skipRTFAndCompanyApiCall: false
        }
    },
    computed: {
        colClass() {
            let colValue = "";
            switch (this.customDataDefinition.customDataType) {
                case CustomDataType.Boolean:
                case CustomDataType.Integer:
                case CustomDataType.Decimal:
                case CustomDataType.Date:
                case CustomDataType.Time:
                case CustomDataType.DateTime:
                case CustomDataType.Text:
                case CustomDataType.PickList:
                case CustomDataType.Money:
                case CustomDataType.ChildPickList:
                    colValue = "col-3";
                    break;
                case CustomDataType.Company:
                    colValue = "col-6";
                    break;
                case CustomDataType.Memo:
                case CustomDataType.RTF:
                    colValue = "col-12";
                    break;
            }

            return colValue;
        },
        isFieldRequired() {
            return this.customDataDefinition.gridListValueRequired;
        },
        dateType() {
            switch(this.customDataDefinition.customDataType) {
                case CustomDataType.DateTime:
                    return "datetime";
                case CustomDataType.Time:
                    return "time";
                default:
                    return "date";
            }
        },
        isDateTimeField() {
            return this.customDataDefinition.customDataType === CustomDataType.DateTime
                    || this.customDataDefinition.customDataType === CustomDataType.Time
                    || this.customDataDefinition.customDataType === CustomDataType.Date;
        },
        isPickListField() {
            return this.customDataDefinition.customDataType === CustomDataType.PickList;
        },
        isChildPickListField() {
            return this.customDataDefinition.customDataType === CustomDataType.ChildPickList;
        },
        isDropdownField() {
            return this.customDataDefinition.customDataType === CustomDataType.PickList
                    || this.customDataDefinition.customDataType === CustomDataType.ChildPickList;
        },
        isCheckBoxField() {
            return this.customDataDefinition.customDataType === CustomDataType.Boolean;
        },
        isCompanyField() {
            return this.customDataDefinition.customDataType === CustomDataType.Company;
        },
        isRtfField() {
            return this.customDataDefinition.customDataType === CustomDataType.RTF;
        },
        editorMadeChanges() {
            return _.getBool(this, "$refs.editorInstance.madeChanges");
        },
        validationFeedbackOffset() {
            return this.isCompanyField ? 80 : 0;
        },
        hasValidationError() {
            if(this.isRtfField) return this.v$.editorContent.$error;
            if(this.isCompanyField) return this.v$.companyObject.$error;
            return this.v$.localCustomDataValue.$error
        }
    },
    created() {
        let self = this;
        if(self.isDropdownField) {
            let apiPromise = self.$api.CustomDataApi.getCustomDataListValues(self.customDataDefinition.customDataDefinitionID);
            self.$rqBusy.wait(apiPromise)
                .then(result => {
                    if(self.isPickListField) {
                        self.dropdownItems = _.map(result, x => {
                            return new SystemLookupItem({
                                id: x.customDataListValueID,
                                name: x.availableValue
                            })
                        });
                    }
                    else if(self.isChildPickListField) {
                        self.childDropdownItems = _.map(result, x => {
                            return new SystemLookupItem({
                                id: x.customDataListValueID,
                                name: x.availableValue,
                                data: x.parentAvailableValue
                            })
                        });
                        // request parent to raise event
                        self.$emit('picklist-data-ready', { parentID: self.customDataDefinition.parentID});
                    }
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error getting list data.` });
                    return error;
                });
        }
    },
    watch: {
        customDataValue: {
            handler(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                let self = this;
                self.localCustomDataValue = _.cloneDeep(self.customDataValue);
                if(self.isRtfField) {
                    self.convertRtfToHtml();
                }
                if(self.isCompanyField) {
                    self.loadCompanyContactData();
                }
                self.skipRTFAndCompanyApiCall = false;
            },
            deep: true,
            immediate: true
        },
        "localCustomDataValue.value": {
            handler(newValue, oldValue) {
                // if(_.isEqual(newValue, oldValue) || _.isNull(newValue)) return;
                if(_.isEqual(newValue, oldValue)) return;
                this.$emit('update:custom-data-value', this.localCustomDataValue);
            }
        }
    },
    validations() {
        const self = this;
        const companyValid = (value) => {
            //return (self.isCompanyField && self.isFieldRequired) ? ((_.isNull(value) || !value.companyID) ? false : value.companyID > 0 || !_.isNull(value.companyID)) : true;
            return (self.isCompanyField && self.isFieldRequired) ? (!_.isNull(value) && _.gt(value.companyID, 0)) : true;
        };
        const rtfValid = (value) => {
            return (self.isRtfField && self.isFieldRequired) ? !_.isEmpty($(value).text()) : true;
        };

        return {
            localCustomDataValue: {
                value: {
                    required: requiredIf(function (vm) { return self.isFieldRequired && (!self.isCompanyField && !self.isCompanyField); })
                    //required: requiredIf(function (vm) { return self.isFieldRequired && !self.isCompanyField; })

                }
            },
            companyObject: {
                companyValid
            },
            editorContent: {
                rtfValid
            }
        };
    },
    methods: {
        elementId(el){
            return `${el}_customDataField_element_${this.customDataDefinition.customDataDefinitionID}`;
        },
        raisePickListChangeEvent(selectedValue=null) {
            let self = this;
            self.$emit("parent-picklist-change", {
                customDataDefinitionID: self.customDataDefinition.customDataDefinitionID,
                value: _.isNull(selectedValue) ? self.localCustomDataValue.value : selectedValue
            });
        },
        updateChildDropdownItems(args) {
            this.dropdownItems = _.filter(this.childDropdownItems, x => x.data === args.value);
        },
        isValid() {
            this.v$.$touch();
            return !this.v$.$error;
        },
        resetValidation() {
            this.v$.$reset();
        },
        isDirty(){
            return this.v$.$anyDirty;
        },
        getCompanyContactString(companyObject) {
            let companyID = _.get(companyObject, "companyID", 0);
            let contactID = _.get(companyObject, "contactID", 0);
            return `${_.isNull(companyID) ? 0 : companyID}-${_.isNull(contactID) ? 0 : contactID}`;
        },
        loadCompanyContactData() {
            let self = this;

            if(_.isNullOrEmpty(self.localCustomDataValue.value)) {
                self.companyObject = new CompanyPickerModel();
                return;
            }
            let companyObjectVal = self.getCompanyContactString(self.companyObject);
            if(self.skipRTFAndCompanyApiCall && companyObjectVal === self.localCustomDataValue.value) return;

            let companyContactIDs = _.split(self.localCustomDataValue.value, "-");
            let companyID = _.parseInt(_.get(companyContactIDs, "[0]"));
            let contactID = _.parseInt(_.get(companyContactIDs, "[1]"));
            self.$api.CustomDataApi.getCustomDataCompanyContact(_.isNaN(companyID) ? 0 : companyID, _.isNaN(contactID) ? 0 : contactID)
                .then(result => {
                    self.companyObject = result;
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error getting Company Contact data.` });
                    return error;
                });
        },
        convertRtfToHtml() {
            const self = this;
            let content = self.localCustomDataValue.value;
            if(_.isNullOrEmpty(content)) {
                self.editorContent = "";
                self.contentReady = true;
                return;
            }
            // if(self.skipRTFAndCompanyApiCall) return;
            if(_.startsWith(content, "{\\rtf")) {
                self.$api.UtilitiesApi.rtfToHtml(content, false, true)
                    .then(htmlContent => {
                        self.editorContent = htmlContent;
                        self.editorHasChange = false;
                        self.contentReady = true;
                    })
                    .catch(err => {
                        console.error(err);
                        self.$toast.error(err.errorMessage);
                    });
                return;
            }
        },
        onPickListChange(e) {
            this.raisePickListChangeEvent(e.selectedValue);
        },
        onCompanyPickerChange(e) {
            this.skipRTFAndCompanyApiCall = true;
            if(_.isNull(e)){
                this.localCustomDataValue.value = e;
            }
            else{
                let newCompanyObjectString = this.getCompanyContactString(e);
                let oldCompanyObjectString = this.getCompanyContactString(e.previousValue);
                if(_.isEqual(newCompanyObjectString, oldCompanyObjectString)) return;
                this.localCustomDataValue.value = newCompanyObjectString;
            }
        },
        onHtmlEditorBlur(e) {
            let self = this;
            if(!self.editorHasChange) return;
            return self.$api.UtilitiesApi.htmlToRtf(self.editorContent)
                    .then(rtf => {
                        self.localCustomDataValue.value = rtf;
                        self.skipRTFAndCompanyApiCall = true;
                        self.editorHasChange = false;
                    });
        },
        onHtmlEditorChange(e) {
            this.editorHasChange = true;
        }
    }
}
</script>