import axios from 'axios';

import store from '@/store';
import { AUTH_ACTIONS } from '@/store/actions';
import { TimeZoneInfo } from "@/shared/utilities/Timezones";

const axiosClient = axios.create({ baseURL: `${location.origin}/api` });
const domParser = new DOMParser();

class RestServiceException extends Error {
    constructor(errorData) {
        let _errorMessage = errorData?.message || errorData?.errorMessage || null;
        super(_errorMessage);
        this.axiosError = (!_.isNil(errorData) && errorData instanceof axios.AxiosError)
            ? errorData
            : null;
        this.errorCode = errorData?.errorCode || errorData?.code || null;
        this.errorMessage = _errorMessage;
        this.stackTrace = errorData?.stackTrace || null;
        this.modelState = errorData?.modelState || null;
    }
}

/**
 * Determines whether the result from a web service is a WebServiceResponse object.
 */
function isWebResponse (response) {
    let webResponseProps = [ "isSuccessful", "errorCode", "errorMessage" ];
    return _.has(response, "data") && _.every(webResponseProps, p => _.has(response.data, p));
}

async function HandleRequestProcessing(requestPromise) {
    try {
        let response = await requestPromise;
        // Determine whether the response contains a WebResponse object to process.
        if (isWebResponse(response)) {
            // WebResponse found -- determine if the call was successful
            if (response.data.isSuccessful) {
                // Webresponse was successful so just return the requested data (if any).
                return sanitizeObjectData(response.data.result);
            } else {
                throw response.data;
            }
        } else {
            // WebResponse not found -- allow it to pass through as-is
            return response;
        }
    }
    catch(error) {
        // Determine if the error is an authorization error
        if (error?.request?.status == 401) {
            // Redirect the user back to the login page -- update to use route when available
            //Vue.$log.debug('Encountered a 401 calling web service - redirecting user to login page.');
            await store.dispatch(AUTH_ACTIONS.LOGOUT, {
                caller: "HttpWrapperClass.HandleRequestProcessing",
                redirectUrl: location.href
            });
        }
        throw new RestServiceException(error);
    }
}

function sanitizeObjectData(data){

    if(!data) return data;

    if(_.isString(data)) return sanitizeStringData(data);

    if(Array.isArray(data)){

        for(let x=0;x < data.length; x++){
            let item = data[x];
            if(_.isString(item)){
                let sanitizedString = sanitizeStringData(item);
                data[x] = sanitizedString;
            }
            else{
                let sanitizedObject = sanitizeObjectData(item);
                data[x] = sanitizedObject;
            }
        }

        return data;
    }

    if(_.isObject(data)){
        for(let propName in data){
            data[propName] = sanitizeObjectData(data[propName]);
        }
        return data;
    }

    return data;
}

function sanitizeStringData(data){

    if(!data || !_.isString(data)) return data;

    let isDangerous = domParser.parseFromString(data, 'text/html').querySelector('script')

    if(!isDangerous) return data;

    //unescape existing &amp; so we don't re-escape the & in that tag
    data = data.replace('&amp;', '&');
    //now escape &
    data = data.replace(/&/g, '&amp;');
    //< and > can be replaced directly because the escape code doesn't also contain &
    data = data.replace(/</g, '&lt;');
    data = data.replace(/>/g, '&gt;');
    return data;
}

/**
 * Updates the axios configuration to include an authorization header.
 * @param {any} cfg - axios configuration to update with authorization header info.
 */
function setupAuthHeader (cfg) {
    let token = _.get(store, 'state.authentication.identityToken', '');
    let config = cfg || {};

    if (!_.has(cfg, "headers.Authorization")) {
        config = _.merge(config, { headers: { Authorization: `Bearer ${token}` } });
    }
    config = _.merge(config, {
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate, pre-check=0, post-check=0, max-age=0, s-maxage=0',
            'Pragma': 'no-cache',
            ClientTimeZone: TimeZoneInfo.clientTimeZoneId
        }
    });

    return config;
}

/**
 * Mimics the functionality found in the Axios HTTP object but wraps each so custom processing may
 * be applied to the response before passing it back to the caller.  All non-grid based API calls in
 * the RQO Online application return a WebServiceResponse that provides more detailed information
 * about the web service response.  This class makes working with that information more seamless.
 */
class HttpWrapperClass {
    /**
     * Issues a request using the GET HTTP verb.
     * @param {any} url - URL of the request.
     * @param {any} config - (Optional) Axios configuration for the request.
     */
    async get(url, config) {
        if (_.startsWith(url, '/')) url = url.substr(1);
        config = setupAuthHeader(config);
        return await HandleRequestProcessing(axiosClient.get(url, config));
    }

    /**
     * Issues a request using the POST HTTP verb.
     * @param {any} url - URL of the request.
     * @param {any} data - data to include in the request.
     * @param {any} config - (Optional) Axios configuration for the request.
     */
    async post (url, data, config) {
        if (_.startsWith(url, '/')) url = url.substr(1);
        config = setupAuthHeader(config);
        return await HandleRequestProcessing(axiosClient.post(url, data, config));
    }

    /**
     * Issues a request using the PUT HTTP verb.
     * @param {any} url - URL of the request.
     * @param {any} data - data to include in the request.
     * @param {any} config - (Optional) Axios configuration for the request.
     */
    async put (url, data, config) {
        if (_.startsWith(url, '/')) url = url.substr(1);
        config = setupAuthHeader(config);
        return await HandleRequestProcessing(axiosClient.put(url, data, config));
    }

    /**
     * Issues a request using the DELETE HTTP verb.
     * @param {any} url - URL of the request.
     * @param {any} config - (Optional) Axios configuration for the request.
     */
    async delete (url, config) {
        if (_.startsWith(url, '/')) url = url.substr(1);
        config = setupAuthHeader(config);
        return await HandleRequestProcessing(axiosClient.delete(url, config));
    }
}

//export const HTTP = axiosClient;
export const HttpWrapper = new HttpWrapperClass();