import OrdersApi from "@/api/orders";
import { CONTACT_ACTIONS, ORDER_ACTIONS } from "../../actions";
import { CONTACT_MUTATIONS } from "../../mutations";
import { BASIC_ACTION, ACTION_WITH_COMMIT, removeFromStateArrayByValue } from "../../helpers";

const state = {
    contacts: [],
    buyers: [],
    sellers: []
};

const getters = {
    buyers: state => {
        return _.filter(state.contacts, buyer => buyer.roleTypeID == 15);
    },

    sellers: state => {
        return _.filter(state.contacts, seller => seller.roleTypeID == 16);
    }
};

const actions = {

    [CONTACT_ACTIONS.GET_ORDER_CONTACTS](context, forceRefresh=true) {
        let orderId = context.getters.orderId;
        if(!forceRefresh && !_.isEmpty(context.state.contacts)) {
            return Promise.resolve(context.state.contacts);
        }
        let apiPromise = OrdersApi.getOrderContacts(orderId);
        return ACTION_WITH_COMMIT(context, apiPromise, CONTACT_MUTATIONS.SET_ORDER_CONTACTS);
    },

    [CONTACT_ACTIONS.ADD_ORDER_CONTACT](context, { role, waitForRefresh=false }) {
        let apiPromise = OrdersApi.addRole(role);
        return new Promise((resolve, reject) => {
            BASIC_ACTION(context, apiPromise, result => {
                context.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS)
                    .then(() => {
                        if(waitForRefresh)
                            resolve(result);
                        return result;
                    })
                    .catch(reject);
            }).then(result => {
                if(!waitForRefresh)
                    resolve(result);
                context.dispatch(ORDER_ACTIONS.GET_ORDER_DNDBW_WARNINGS);
                return result;
            })
            .catch(reject);
        });
    },

    [CONTACT_ACTIONS.UPDATE_ORDER_CONTACT] (context, { data, changes }) {
        let orderId = context.getters.orderId;
        let apiPromise = OrdersApi.updateRole({ orderId, data, changes });
        return BASIC_ACTION(context, apiPromise, result => {
            context.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS);
            context.dispatch(ORDER_ACTIONS.GET_ORDER_DNDBW_WARNINGS);
        });
    },

    [CONTACT_ACTIONS.DELETE_ORDER_CONTACT](context, id) {
        let apiPromise = OrdersApi.deleteRole(context.getters.orderId, id);
        return BASIC_ACTION(context, apiPromise, () => {
            context.commit(CONTACT_MUTATIONS.REMOVE_ORDER_CONTACT, id);
            context.dispatch("refreshOrderSummary", true);
        });
    },

    [CONTACT_ACTIONS.DELETE_ORDER_CONTACTS](context, data) {
        let apiPromise = OrdersApi.deleteContacts(context.getters.orderId, data);
        return BASIC_ACTION(context, apiPromise, () => {
            context.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS);
            context.dispatch("refreshOrderSummary", true);
        });
    },

    [CONTACT_ACTIONS.DELETE_BUYER](context, { buyerSellerId, roleId=null }) {
        let apiPromise = OrdersApi.deleteBuyer(context.getters.orderId, buyerSellerId);
        return BASIC_ACTION(context, apiPromise, () => {
            context.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS);
            context.dispatch("refreshOrderSummary", true);
        });
    },

    [CONTACT_ACTIONS.DELETE_SELLER](context, { buyerSellerId, roleId=null }) {
        let apiPromise = OrdersApi.deleteSeller(context.getters.orderId, buyerSellerId);
        return BASIC_ACTION(context, apiPromise, () => {
            context.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS);
            context.dispatch("refreshOrderSummary", true);
        });
    }
};

const mutations = {
    [CONTACT_MUTATIONS.SET_ORDER_CONTACTS] (state, value) { _.set(state, "contacts", value); },
    [CONTACT_MUTATIONS.REMOVE_ORDER_CONTACT] (state, value) { removeFromStateArrayByValue(state.contacts, "rolesID", value); },
};

export default {
    state,
    getters,
    actions,
    mutations
};