export const Actions = {
    ASSIGN_ME: {
        automation_id: 'btn_assign_tasks_me',
        key: 'assign-tasks-me',
        text: 'Assign to me',
        icon: 'fa-user',
        tooltip: 'Assign selected Tasks to me',
        permissionKeys: ['AllowEditWorkflowTask'],
        accessKey: 'UpdateWorkflow_ScreenAccess',
        shortcutKeys: []
    },
    ASSIGN: {
        automation_id: 'btn_assign_tasks',
        key: 'assign-tasks',
        text: 'Assign',
        icon: 'fa-exchange',
        tooltip: 'Assign selected Tasks to Department and/or Department User',
        permissionKeys: ['AllowEditWorkflowTask'],
        accessKey: 'UpdateWorkflow_ScreenAccess',
        shortcutKeys: []
    },
    MARK_COMPLETE: {
        automation_id: 'btn_mark_task_complete',
        key: 'mark-task-complete',
        text: 'Complete',
        icon: 'fa-check',
        tooltip: 'Mark selected Tasks Complete',
        permissionKeys: ['AllowEditWorkflowTask'],
        accessKey: 'UpdateWorkflow_ScreenAccess',
        shortcutKeys: ['ctrl+m']
    },
    MARK_NA: {
        automation_id: 'btn_mark_task_na',
        key: 'mark-task-na',
        text: 'N/A',
        icon: 'fa-adjust',
        tooltip: 'Mark selected Tasks N/A',
        permissionKeys: ['AllowMaskTaskNA'],
        accessKey: 'UpdateWorkflow_ScreenAccess',
        shortcutKeys: []
    },
    DELETE: {
        automation_id: 'btn_delete_task',
        key: 'delete-task',
        text: 'Delete',
        icon: 'fa-times-circle',
        tooltip: 'Delete selected Task',
        permissionKeys: ['AllowDeleteWorkflowTask'],
        accessKey: 'UpdateWorkflow_ScreenAccess',
        shortcutKeys: []
    }
};

export default {
    WORKFLOW_DASHBOARD: {
        ASSIGN_ME: Actions.ASSIGN_ME,
        ASSIGN: Actions.ASSIGN,
        MARK_COMPLETE: Actions.MARK_COMPLETE,
        MARK_NA: Actions.MARK_NA,
        DELETE: Actions.DELETE
    }
}
