import { createRouter, createWebHistory  } from "vue-router";
import Store from "@/store";
import { ROOT_MUTATIONS } from "@/store/mutations";
import { SET_PAGE, GET_NOTIFICATION_INFO, GET_SYSTEM_DATA, REFRESH_LOOKUP, SETTLEMENT_ACTIONS } from "@/store/actions";
import { GlobalEventManager } from "@/app.events";
import { routes } from "./routes";
import { UserScreenAccessLevel } from "@/shared/models/enums";
import { Identity } from '@/shared/services';
import { saveHorizonRoute } from "@/shared/utilities/Helpers";

import OrderBeforeEach from "@/modules/file/route-guards";
import { createRqDialogInterface } from "@/shared/plugins/rqDialog";

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "open active",
    linkExactActiveClass: "rq-exact-active",
    scrollBehavior: () => ({ y: 0 }),
    routes
});

const resetRouteFlags = function() {
    GlobalEventManager.busyEnd();
    Store.commit(ROOT_MUTATIONS.SET_SAVED_ROUTE, null);
    Store.commit(ROOT_MUTATIONS.SET_ROUTE_LOOKUPS_REFRESHED, null);
    Store.commit(ROOT_MUTATIONS.SET_ROUTE_SS_MAPPED, null);
};

const sanitizeRoutePath = route => {
    /*
        TG - This cleans up the leftover hash value tacked on by Keycloak on login. Also protects
        against the posibility of that hash value somehow getting replaced (by NGINX maybe?)
        and replacing the hash with "&" (was an issue in ARC) which is interpreted as part of
        the route path since there's no "?"
    */
    let keyOpts = _.pick(route, ["path", "params", "query", "hash"]);
    let sanitized = {};
    let qmarkIndex = _.indexOf(route.path, "?");
    let ampIndex = _.indexOf(route.path, "&");

    if(_.includes(route.path, "&state") && (qmarkIndex < 0 || qmarkIndex > ampIndex))
        sanitized.path = route.path.substring(0, ampIndex);

    if(_.startsWith(route.hash, "#state"))
        sanitized.hash = "";

    return _.isEmpty(sanitized) ? null : { ...keyOpts, ...sanitized };
};

const rqDialog = createRqDialogInterface();

router.beforeEach((to, from, next) => {
    // Vue.$log.debug("[router/index.js]", { to, from, next });

    if (_.getBool(to, "query.forceLogOut")) {
        Store.dispatch("logout").then(() => { next(false); });
        return;
    }

    let sanitizedRoute = sanitizeRoutePath(to);
    if(sanitizedRoute) {
        next(sanitizedRoute);
        return;
    }

    if(to.fullPath === from.fullPath && to.name === "signing") return;

    rqDialog.closeAll();
    GlobalEventManager.busyStart();

    // Look at meta data to see if the route requires authorization.
    // Lesser of two evils assumes that the route REQUIRES authorization.
    let requiresAuth = _.getBool(to, "meta.requiresAuth", true);

    if (!requiresAuth) {
        GlobalEventManager.busyEnd();
        next();
        return;
    }

    let isAuthenticated = Identity.isAuthenticated;
    let currentError = _.get(Store, "state.error", "");
    // Keycloak
    if (!isAuthenticated) {
        localStorage.to = to.redirectedFrom || to.path;
        const loginUrl = Identity.createLoginUrl();
        window.location.replace(loginUrl);
        next(false);
        return;
    }
    else if(localStorage.to) {
        const url = localStorage.to;
        localStorage.removeItem('to');
        next(url);
        return;
    }

    Identity.stopInterval();
    Identity.registerRefreshTokenEvent();

    let sessionValid = Store.getters.isSessionValid;
    let lastSavedRoute = Store.state.savedRoute;
    let lastRouteLookupsRefreshed = Store.state.routeLookupsRefreshed;
    let pageTitle = _.get(to, "meta.label", "");
    let systemDataLoaded = _.getBool(Store, "state.system.systemDataLoaded");
    let refreshLookupNames = _.get(from, "meta.refreshLookup", "");

    let accessReroute = (message) => {
        let defaultMessage = `Access to ${pageTitle || "this section"} is not authorized.<br>Please contact your administrator.`;
        let unauthorizedMessage = _.get(to, "meta.unauthorizedMessage", null) || message || defaultMessage;

        Store.commit(ROOT_MUTATIONS.SET_ERROR, `INACCESSIBLE_ROUTE::${unauthorizedMessage}`);

        if (_.isNil(from.name) || !sessionValid) {
            return { name: "NotAuthorized", params: { message: unauthorizedMessage } };
        }
        else {
            return false;
        }
    };

    // If the session is not valid, we know they're not authorized for this tenant.
    // As such, there's no reason to go through the other checks because they're superfluous.
    // Ideally, this user shouldn't even be hitting any Api calls so it's best to drop out ASAP.
    let sessionValidationPromise = sessionValid
        ? Promise.resolve(true)
        : (function() {
            next(accessReroute('You do not have access to this application.'));
            return Promise.reject('Invalid session');
        })();

    sessionValidationPromise
        .then(() => {
            return systemDataLoaded ? Promise.resolve(true) : Store.dispatch(GET_SYSTEM_DATA);
        })
        .then(() => {
            let savePromise = from.name === lastSavedRoute || (to.name === from.name && to.path === from.path) || _.isNil(from.name)
                ? Promise.resolve(true)
                : saveHorizonRoute(from, Store);

            return savePromise;
        })
        .then(canContinue => {
            if(!canContinue) return false;
            return Store.dispatch(SET_PAGE, to);
        })
        .then(accessLevel => {
            // Handle the "abort" situation.
            if (_.isBoolean(accessLevel) && !_.parseBool(accessLevel, true)) return false;
            if(accessLevel === UserScreenAccessLevel.None){
                return accessReroute()
            }
            else {
                return _.includes(to.path, "order")
                    ? OrderBeforeEach(to, from)
                    : true;
            }
        })
        .then(nextVal => {
            if(_.isEmpty(refreshLookupNames) || from.name === lastRouteLookupsRefreshed) return Promise.resolve(nextVal);
            Store.commit(ROOT_MUTATIONS.SET_ROUTE_LOOKUPS_REFRESHED, from.name);
            return Store.dispatch(REFRESH_LOOKUP, { lookupKeys: refreshLookupNames })
                .then(() => nextVal);
        })
        .then(nextVal => {
            let lastRouteSSMap = Store.state.routeSSMapped;
            if(!_.getBool(to, "meta.requireSSMapping") || to.name === lastRouteSSMap) return Promise.resolve(nextVal);
            Store.commit(ROOT_MUTATIONS.SET_ROUTE_SS_MAPPED, to.name);

            return Store.dispatch(SETTLEMENT_ACTIONS.MAP_SETTLEMENT).then(() => nextVal);
        })
        .then(nextVal => {
            if(nextVal !== true) return Promise.resolve(nextVal);
            if(!_.isEmpty(pageTitle)) document.title = `Horizon - ${pageTitle}`;
            if(to.path !== from.path) GlobalEventManager.navigated({ to, from });
            // Initiate token refresh and queue up the refresh interval.
            return Identity.refreshToken(-1)
                .then(() => {
                    Store.dispatch(GET_NOTIFICATION_INFO);
                    Identity.queueInterval(660000); // 11 minutes
                    return true;
                });
        })
        .then(nextVal => {
            // Have to remove this from the "last route" because it effectively terminated.
            if (_.isBoolean(nextVal) && !_.parseBool(nextVal, true)) {
                resetRouteFlags();
            }
            next(nextVal);
        })
        .catch(err => {
            console.error("Error occurred during route processing...", err);
        })
        .finally(() => {
            GlobalEventManager.busyEnd();
        });
});

router.afterEach((to, from) => {
    resetRouteFlags();
});

export default router;
