import { ref, unref, computed, nextTick } from "vue";
import { UtilitiesApi } from "@/api/";
import { ClauseListItemModel } from "../models/clause-management";

export function useClauseDragAndDrop(itemData=[], itemType=ClauseListItemModel, onChanged=null, flattenOnChange=false) {

    const _items = ref([]);
    const _deleted = ref([]);

    const getItemCount = items => {
        let resultCount = items.length;
        _.forEach(items, item => {
            if(item.children.length === 0) return;
            resultCount += getItemCount(item.children);
        });
        return resultCount;
    };
    const totalItemCount = computed(() => getItemCount(_items.value));

    function mapItems(items) {
        let itemsValue = unref(items);
        if(_.isEmpty(itemsValue)) return [];
        let itemInstances = _.map(itemsValue, item => new itemType(item));
        if(itemInstances.length === 1) return itemInstances;
        let minLevel = _.reduce(itemInstances, (lvl, item) => item.level > lvl ? lvl : item.level, 20);
        let rootItems = _.filter(itemInstances, item => item.level === minLevel);
        if(rootItems.length === itemInstances.length) return rootItems;
        const getChildItems = (parentID=0) => {
            let childItems = _.filter(itemInstances, item => _.parseNumber(item.parentID, 0) === parentID && !item.isDeleted);
            let sortedItems = _.sortBy(childItems, "ordinal");
            if(_.isEmpty(sortedItems)) return [];
            _.forEach(sortedItems, child => {
                child.children = getChildItems(child.id);
            });
            return sortedItems;
        };
        _.forEach(rootItems, item => {
            item.children = getChildItems(item.id);
        });
        return rootItems;
    }

    function setItemList(items) {
        let itemsValue = unref(items);
        if(_.isEmpty(itemsValue)) return [];

        let itemInstances = _.map(itemsValue, item => new itemType(item));
        const getChildItems = (parentID=0) => {
            let childItems = _.filter(itemInstances, item => _.parseNumber(item.parentID, 0) === parentID && !item.isDeleted);
            let sortedItems = _.sortBy(childItems, "ordinal");
            if(_.isEmpty(sortedItems)) return [];
            _.forEach(sortedItems, child => {
                child.children = getChildItems(child.id);
            });
            return sortedItems;
        };
        _items.value = getChildItems();
    }

    setItemList(itemData);

    function refreshComplete() {
        if(!_.isFunction(onChanged)) return;
        nextTick()
            .then(() => {
                let flattened = flattenOnChange
                    ? getFlattenedList()
                    : null;
                onChanged(flattened);
            });
    }

    function refreshListValues() {
        let sequence = 0;
        const refreshPositionalValues = (items, parentId=0, parentLevel=0) => {
            _.forEach(items, (item, index) => {
                if(item.parentID !== parentId || !item.keepCurrentLevel){
                    item.level = parentLevel + 1;
                    item.keepCurrentLevel = false;
                }
                item.parentID = parentId;
                item.ordinal = index + 1;
                item.sequence = sequence;
                item.siblingCount = items.length - 1;
                sequence += 10;
                if(_.isEmpty(item.children)) return;
                item.childCount = item.children.length;
                refreshPositionalValues(item.children, item.id, item.level);
            });
        };
        refreshPositionalValues(_items.value);

        //brute forcing items value refresh for now to ensure reactive elements propagate and are visible
        _items.value = _.map(_items.value, item => new itemType(item));

        refreshComplete();
    }

    function appendListItems(items, parentId=0, scrollIntoView=false) {
        let parentIdValue = _.parseNumber(parentId, 0);
        let mappedItems = mapItems(items);
        mappedItems[0].scrollIntoView = scrollIntoView;
        const appendItems = levelItems => {
            let complete = false;
            _.forEach(levelItems, item => {
                if(item.id === parentIdValue) {
                    item.children.push(...mappedItems);
                    complete = true;
                }
                else if(!_.isEmpty(item.children)) {
                    complete = appendItems(item.children);
                }
                return !complete;
            });
            return complete;
        }

        if(parentIdValue === 0) {
            _items.value.push(...mappedItems);
        }
        else {
            appendItems(_items.value);
        }
        refreshListValues();
    }

    function updateListItem(clause) {
        const updateChildItem = items => {
            let clauseIndex = _.findIndex(items, item => item.id === clause.id);
            if(clauseIndex >= 0) {
                items[clauseIndex] = _.cloneDeep(clause);
                return true;
            }
            let complete = false;
            _.forEach(items, item => {
                if(_.isEmpty(item.children)) return true;
                complete = updateChildItem(item.children);
                return !complete;
            });
            return complete;
        }
        updateChildItem(_items.value);
        refreshListValues();
    }

    function deleteAllItems() {
        let flattened = getFlatMap(unref(_items));
        _.remove(flattened, item => item.id <= 0);
        _deleted.value.push(...flattened);
        _items.value = [];
        if(!_.isFunction(onChanged) || flattened.length === 0) return;
        onChanged(null);
    }

    function deleteListItem(id) {
        const deleteChildItem = (items) => {
            let itemIndex = _.findIndex(items, { id });
            if(itemIndex >= 0) {
                let removed = items.splice(itemIndex, 1);
                let flattened = getFlatMap(removed);
                _.remove(flattened, o => o.id <= 0);
                _deleted.value.push(...unref(flattened));
                return true;
            }
            let complete = false;
            _.forEach(items, item => {
                if(_.isEmpty(item.children)) return true;
                complete = deleteChildItem(item.children);
                return !complete;
            });
            return complete;
        };

        deleteChildItem(_items.value);

        refreshListValues();
    }

    async function setItemContent({ item, html, rtf="{convert}", emitChanged=true }) {
        let rtfContent = rtf === "{convert}"
            ? await UtilitiesApi.htmlToRtf(html)
            : rtf;
        if(item.htmlText !== html) {
            item.htmlText = html;
            item.rtfText = rtfContent;
            if(emitChanged) updateListItem(item);
        }
        return true;
    }

    const getFlatMap = items => _.flatMap(items, item => item.toFlattenedList());

    function getFlattenedList() {
        let items = getFlatMap(unref(_items));
        let deleted = unref(_deleted);

        _.updateAll(deleted, "isDeleted", true);

        return [
            ...items,
            ...deleted
        ];
    }

    return {
        items: _items,
        deletedItems: _deleted,
        totalItemCount,
        setItemList,
        appendListItems,
        updateListItem,
        deleteListItem,
        deleteAllItems,
        refreshListValues,
        setItemContent,
        getFlattenedList
    };
}