<template>
    <div ref="dropdownElement" class="dropdown rq-faux-select-box w-100">
        <button
            :automation_id="automation_id"
            class="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="onToggleClick">
            <span v-if="noValue" class="rq-dropdown-placeholder">{{ placeholder }}</span>
            <span v-else>{{ displayValue }}</span>
        </button>
        <ul ref="dropdownMenu" class="dropdown-menu dropdown-menu-end py-2 w-100" :style="{ maxHeight }">
            <template v-for="(item, index) in mappedItems" :key="item.key">
                <li v-if="item.isHeader" :class="{ 'mt-1': index === 0, 'mt-3': index > 0 }">
                    <h5 class="dropdown-header">{{item.text}}</h5>
                </li>
                <li v-else-if="item.isDivider">
                    <hr class="dropdown-divider">
                </li>
                <li v-else>
                    <button
                        :automation_id="item.automationId"
                        type="button"
                        class="dropdown-item"
                        @click="onItemClick(item)">{{ item.text }}
                    </button>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup>
    import { computed, ref } from "vue";
    const props = defineProps({
        automation_id: { type: String, default: () => _.uniqueId("rq-faux-select-") },
        placeholder: { type: String, default: "Select..." },
        displayValue: { type: String, default: "" },
        items: { type: Array, default: () => [] }
    });

    //setting default to taller than current global max height style so popper flips
    //the drop-up/drop-down direction accurately
    const DEFAULT_MAX_HEIGHT = "75vh";

    const emit = defineEmits(["item-click"]);

    const dropdownElement = ref(null);
    const dropdownMenu = ref(null);
    const maxHeight = ref(DEFAULT_MAX_HEIGHT);
    const noValue = computed(() => _.isEmpty(props.displayValue));
    const mappedItems = computed(() => _.map(props.items, item => ({
        key: _.uniqueId("rq-faux-select-item-"),
        ...item
    })));

    function onToggleClick() {
        if(!dropdownElement.value || !dropdownMenu.value) return;
        let winHeight = window.innerHeight;
        let menuHeight = dropdownMenu.value?.scrollHeight > dropdownMenu.value?.clientHeight
            ? dropdownMenu.value?.scrollHeight
            : dropdownMenu.value?.clientHeight;
        if(menuHeight <= 0) {
            //menu height resolves to 0 height when the menu is already visible and the click event is hiding it
            maxHeight.value = DEFAULT_MAX_HEIGHT;
            return;
        }
        let elementRect = dropdownElement.value.getBoundingClientRect();
        let topVal = elementRect?.top;
        let bottomVal = winHeight - (elementRect?.top + 38); //elementRect.bottom is not reliable due to dialog drag/drop
        let maxHeightVal = menuHeight > topVal || bottomVal > topVal ? bottomVal : topVal;
        maxHeight.value = maxHeightVal > 100 ? `${maxHeightVal}px` : DEFAULT_MAX_HEIGHT;
    }

    function onItemClick(item) {
        emit("item-click", { item });
    }
</script>