<template>
    <div class="content-wrapper diff-container">
        <div v-if="hasLabels" class="diff-labels">
            <div>{{ leftLabel }}</div>
            <div>{{ rightLabel }}</div>
        </div>
        <div class="diff-layout">
            <div class="diff-col">
                <RqRtfDisplay
                    id="content_diff_left_value"
                    :placeholder="leftPlaceholderValue"
                    height="100%"
                    :model-value="leftValue"
                    :rtf="isRtf"
                    borderless
                    no-hover
                />
            </div>
            <div class="diff-col">
                <RqRtfDisplay
                    id="content_diff_right_value"
                    :placeholder="rightPlaceholderValue"
                    height="100%"
                    :model-value="rightValue"
                    :rtf="isRtf"
                    borderless
                    no-hover
                />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from "vue";
    const props = defineProps({
        //left side options
        leftLabel: { type: String, default: "Old Value" },
        leftPlaceholder: { type: String, default: "" },
        leftValue: { type: String, default: "" },

        //right side options
        rightLabel: { type: String, default: "New Value" },
        rightPlaceholder: { type: String, default: "" },
        rightValue: { type: String, default: "" },

        //if set to "rtf", RqRtfDisplay will auto-convert to html
        contentType: { type: String, default: "html" }
    });

    const isRtf = computed(() => props.contentType === "rtf");
    const leftPlaceholderValue = computed(() =>
        _.isEmpty(props.leftPlaceholder)
            ? `No ${props.leftLabel}`
            : props.leftPlaceholder
    );
    const rightPlaceholderValue = computed(() =>
        _.isEmpty(props.rightPlaceholder) ?
            `No ${props.rightLabel}`
            : props.rightPlaceholder
    );
    const hasLabels = computed(() => !_.isEmpty(props.leftLabel) || !_.isEmpty(props.rightLabel));
</script>