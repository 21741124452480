
<template>
    <div class="exago-frame-container">
        <slot name="top" />
        <report-actions
            v-if="showActions"
            variant="outline-dark"
            :file-types="['PDF']"
            :get-export-content="exportReport"
            hide-save-local
            @print="print"
        />
        <iframe
            v-if="!displayErrorMessage && showFrame"
            ref="exagoIframeElement"
            :src="frameUrl"
            class="exago-iframe"
            seamless
            @load="onIframeLoaded"
        />
        <template v-else-if="displayErrorMessage">
            <slot name="message-display" v-bind="{ hasError, pathInvalid, mode }">
                <rq-no-data
                    :variant="hasError ? 'error' : 'default'"
                    size="lg">
                    <template #message-content>
                        <FontAwesomeIcon v-if="hasError" icon="fas fa-exclamation-circle" />
                        {{errorMessage}}
                    </template>
                </rq-no-data>
            </slot>
        </template>
        <rq-pdf-printer ref="pdfViewer" />
        <slot />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { EXAGO_SESSION_ACTIONS } from "@/store/actions";
    import { EXAGO_SESSION_MUTATIONS } from "@/store/mutations";
    import { ReportIframeMode, ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import { DocumentFileType }  from "@documents/enums";
    import ReportActions from "./ReportActions";
    import { RqPdfPrinter } from "@/shared/components/rq/";

    export default {
        name: "ExagoWidgetFrame",

        components: { ReportActions, RqPdfPrinter },

        props: {
            reportPath: { type: String, default: null },
            persist: { type: Boolean, default: false },
            reportOptions: { type: Object, default: () => new ReportOptionsDto() },
            requireReportPath: { type: Boolean, default: false },
        },

        data() {
            return {
                frameUrl: "",
                frameLoaded: false,
                pathInvalid: false,
                hasError: false,
                showFrame: false,
                isBusy: true,
                actionsReady: false
            };
        },

        watch: {
            definition: {
                handler(newValue, oldValue) {
                    this.refresh();
                },
                deep: true,
                immediate: true,
            },
        },

        computed: {
            ...mapGetters(["exagoAppBaseUrl"]),
            mode() {
                return !_.isNullOrEmpty(this.reportPath) || this.requireReportPath
                    ? ReportIframeMode.REPORT_VIEWER
                    : ReportIframeMode.STAND_ALONE;
            },
            isReportDesigner() { return this.mode == ReportIframeMode.STAND_ALONE; },
            displayErrorMessage() { return this.hasError || this.pathInvalid; },
            errorMessage() {
                return this.pathInvalid
                    ? "Report path invalid."
                    : this.hasError
                        ? `An issue occurred loading ${this.mode == ReportIframeMode.REPORT_VIEWER ? 'this report' : 'the Report Designer'}.`
                        : '';
            },
            definition() {
                return {
                    reportPath: this.reportPath,
                    reportOptions: this.reportOptions,
                }
            },
            DOC_FILE_TYPES() {
                return DocumentFileType;
            },
            showActions() { return !this.isReportDesigner && !this.isBusy && this.actionsReady; },
            pdfViewerInstance() { return _.get(this, "$refs.pdfViewer", null); },
        },

        beforeUnmount() {
            this.endWait();
            window.removeEventListener("message", this.onPostMessageRecieved, false);
        },

        created() {
            this.initExagoNavigationEvent();
        },

        methods: {
            initExagoNavigationEvent() {
                const self = this;
                window.addEventListener("message", self.onPostMessageRecieved, false);
            },
            refresh() {
                const self =  this;

                self.showFrame = false;

                self.$nextTick()
                    .then(() => {
                        self.showFrame = true;
                        self.process();
                    });
            },
            process() {
                switch(this.mode) {
                    case ReportIframeMode.STAND_ALONE:
                        this.createSession();
                        break;
                    case ReportIframeMode.REPORT_VIEWER:
                        this.loadReport();
                        break;
                    default:
                        this.clearIframe();
                        break;
                }
            },

            loadFrame(dispatchAction, params) {
                const self = this;

                self.clearIframe();

                self.hasError = false;
                self.isBusy = true;

                if(!self.validatePath()) return;

                let promise =
                    self.$nextTick()
                        .then(() => {
                            self.$emit('loading');
                            return self.$store.dispatch(dispatchAction, params);
                        })
                        .then((session) => {
                            self.frameUrl = session.sessionAppUrl;
                            self.isBusy = false;
                            _.delay(() => {
                                self.actionsReady = true;
                            }, 2000);
                        })
                        .then(() => {
                            if(self.persist) return;
                            return self.clearSession();
                        })
                        .catch((error) => {
                            self.hasError = true;
                        });

                self.$rqBusy.wait(promise, false)
                    .finally(() => {
                        self.$emit('loaded')
                    })
            },

            createSession() {
                const self = this;

                self.loadFrame(EXAGO_SESSION_ACTIONS.CREATE_SESSION, { refresh: !self.persist });
            },

            loadReport() {
                const self = this;

                let opts = self.getReportOptions()

                self.loadFrame(EXAGO_SESSION_ACTIONS.LOAD_REPORT, { options: opts });
            },

            getReportOptions() {
                const self = this;

                let opts = _.cloneDeep(self.reportOptions);

                if(_.isNullOrEmpty(opts.reportPath) && !_.isNullOrEmpty(self.reportPath))
                    opts.reportPath = self.reportPath;

                return opts;
            },

            clearSession() {
                return this.$store.dispatch(EXAGO_SESSION_ACTIONS.CLEAR_SESSION);
            },
            clearIframe() {
                this.frameUrl = '';
                this.frameLoaded = false;
                this.$store.commit(EXAGO_SESSION_MUTATIONS.SET_RENDERED, false);
            },
            validatePath() {
                const self = this;
                if(!self.requireReportPath || !_.isEmpty(self.reportPath) || (this.mode === ReportIframeMode.REPORT_VIEWER && !_.isEmpty(self.reportOptions?.reportPath))) {
                    self.pathInvalid = false;
                    return true;
                }
                self.pathInvalid = true;
                self.endWait();
                return false;
            },
            onIframeLoaded() {
                if (this.frameUrl) this.frameLoaded = true;
                if(!this.persist) return;
                this.$store.commit(EXAGO_SESSION_MUTATIONS.SET_RENDERED, true);
            },
            startWait() { this.$rqBusy.startWait(false); },
            endWait() { this.$rqBusy.endWait(true); },
            onPostMessageRecieved(e) {
                const self = this;

                if(self.exagoAppBaseUrl === '') return;

                let exagoUrl = new URL(self.exagoAppBaseUrl);

                switch (e.origin) {
                    case exagoUrl.origin:
                        self.exagoActionEvent(e)
                        break;
                }
            },

            exagoActionEvent(e) {
                const self = this;

                let event;

                self.$log.debug("Exago -- Action Event fired.");

                try {
                    event = JSON.parse(e.data);
                }
                catch(err) {
                    self.$log.error("Exago -- Unable to parse Action Event message data.", err);
                    return;
                }

                let eventName = _.get(event, 'name', null);
                let eventData = _.get(event, "data", {});

                switch (eventName) {
                    case "OnNavigateToOrder":
                        self.exagoNavigateToOrder(eventData.orderId, eventData.toPage);
                        break;
                    default:
                        self.$log.debug("Exago -- Unhandled Action Event received: " + (eventName || ""));
                        break;
                }
            },

            exagoNavigateToOrder(orderId, toPage) {
                const self = this;
                if (!orderId) return;
                // RQO-16390 If the user does not have access, it will go to the first place they have access to.  Defaulting to filemain
                self.$rq.goToFile({ orderId, name: toPage });
            },

            exportReport(fileType) {
                const self = this;
                let opts = self.getReportOptions();
                return self.$api.ExagoApi.exportReport(opts, fileType)
                    .then(result => ({
                        fileName: result.fileName,
                        description: result.name,
                        content: result.contentBase64
                    }));
            },
            print(data){
                let self = this;
                self.pdfViewerInstance.load(data);
            }
        }
    };
</script>