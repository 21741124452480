<template>
    <ul class="nav clause-toolbar">
        <li v-for="(action, index) in visibleActions"
            :key="`clause_list_item_${index}`"
            class="nav-item action"
            :class="{ 'show-always': action.showAlways }">
            <span v-rq-tooltip="{ title: action.title, html:true, placement:'top', trigger:'hover' }">
                <b-btn
                    variant="icon"
                    class="clause-toolbar-button"
                    :disabled="action.disabled"
                    @click="onActionClick(action)">
                    <FontAwesomeIcon :icon="action.icon" />
                </b-btn>
            </span>
        </li>
    </ul>
</template>

<script>
    import { ref, watchEffect } from "vue";
    export default {
        name: "ClauseToolbar",

        props: {
            item: { type: Object, default: () => ({}) },
            actions: { type: Array, default: () => [] }
        },

        setup(props, { emit }) {
            const visibleActions = ref([]);
            watchEffect(() => {
                visibleActions.value = _.filter(props.actions, a => _.isFunction(a.visible) ? a.visible(props.item) : _.parseBool(a.visible, true));
            });
            function onActionClick(action) {
                emit("action-click", { actionKey: action.key });
            }
            return {
                visibleActions,
                onActionClick
            };
        }
    };
</script>
