<template>
    <ul class="nav report-actions">
        <li v-if="showSaveDocMgmt || showSaveLocal" class="nav-item">
            <b-dropdown text="Save" :variant="variant" :right="right">
                <b-dropdown-group
                    v-if="showSaveDocMgmt"
                    header="To Document Mgmt As...">
                    <b-dropdown-item-button
                        v-for="fileType in docMgmtFileTypesValue"
                        :key="`save-doc-mgmt-${fileType}`"
                        @click="onSaveClick(DOC_FILE_TYPES[fileType], SAVE_DOC_LOCATIONS.DocumentManagement)">
                        {{fileType}}
                    </b-dropdown-item-button>
                </b-dropdown-group>
                <b-dropdown-divider v-if="showSaveDocMgmt && showSaveLocal"></b-dropdown-divider>
                <b-dropdown-group
                    v-if="showSaveLocal"
                    header="To My Computer As...">
                    <b-dropdown-item-button
                        v-for="fileType in localFileTypesValue"
                        :key="`save-local-${fileType}`"
                        @click="onSaveClick(DOC_FILE_TYPES[fileType], SAVE_DOC_LOCATIONS.Local)">
                        {{fileType}}
                    </b-dropdown-item-button>
                </b-dropdown-group>
            </b-dropdown>             
        </li>
        <li v-if="showEmail" class="nav-item">
            <b-dropdown text="Email" :variant="variant" :right="right">
                <b-dropdown-group header="Report as...">
                    <b-dropdown-item-button
                        v-for="fileType in emailFileTypesValue"
                        :key="`email-${fileType}`"
                        @click="onEmailClick(DOC_FILE_TYPES[fileType])">
                        {{fileType}}
                    </b-dropdown-item-button>
                </b-dropdown-group>
            </b-dropdown> <!--  Change -->
        </li>
        <li class="nav-item">
            <b-button
                automation_id="btn_print_report"
                :variant="variant"
                @click="onPrintReport">
                Print
            </b-button>
        </li>
        <li v-if="exportDownloadReady" class="hidden-elements d-none">
            <a ref="downloadLink" class="d-none" :href="exportDownload.downloadUrl" :download="exportDownload.fileNameWithTimeStamp">Download</a>
        </li>
    </ul>
</template>
<script>
    import { GLOBAL_EVENTS, GlobalEventManager } from "@/app.events";
    import { DocumentFileType, DocumentDownloadModel } from "@documents/models";
    import { SaveDocumentLocation } from "@documents/enums";
    import { SaveDocumentViewModel, DocumentModel } from "@documents/models";

    export default {
        name: "ReportActions",
        props: {
            variant: { type: String, default: "theme" },
            fileTypes: { type: Array, default: () => [] },
            saveFileTypes: { type: Array, default: () => [] },
            emailFileTypes: { type: Array, default: () => [] },
            hideSaveLocal: { type: Boolean, default: false },
            hideSaveDocMgmt: { type: Boolean, default: false },
            hideEmail: { type: Boolean, default: false },
            exportOptions: { type: Array, default: () => [] },
            getExportContent: { type: Function, default: (() => Promise.resolve("")) },
            right: { type: Boolean, default: false },
        },
        data() {
            return {
                exportDownload: {}
            };
        },
        computed: {
            orderId() { return _.getNumber(this, "$route.params.orderId", 0); },
            saveFileTypesValue() { return _.isEmpty(this.fileTypes) ? this.saveFileTypes : this.fileTypes; },
            emailFileTypesValue() { return _.isEmpty(this.fileTypes) ? this.emailFileTypes : this.fileTypes; },
            docMgmtFileTypesValue() { return this.hideSaveDocMgmt ? [] : this.saveFileTypesValue; },
            localFileTypesValue() { return this.hideSaveLocal ? [] : this.saveFileTypesValue; },
            showSaveDocMgmt() { return !_.isEmpty(this.docMgmtFileTypesValue); },
            showSaveLocal() { return this.hasExportOptions && !_.isEmpty(this.localFileTypesValue); },
            showEmail() { return !_.isEmpty(this.emailFileTypesValue); },
            hasExportOptions() { return !_.isEmpty(this.exportOptions); },
            exportDownloadReady() { return !_.isEmpty(this.exportDownload); },
        },
        created() {
            const self = this;
            self.DOC_FILE_TYPES = DocumentFileType;
            self.SAVE_DOC_LOCATIONS = SaveDocumentLocation;
        },
        methods: {
            onSaveClick(fileType, saveLocation) {
                if(saveLocation === SaveDocumentLocation.DocumentManagement) {
                    this.saveExport(fileType);
                }
                else {
                    let exportItem = _.find(this.exportOptions, { fileType });
                    this.downloadExport(exportItem);
                }
            },

            onEmailClick(fileType) {
                const self = this;
                let promise = self.getExportContent(fileType);
                self.$rqBusy.wait(promise, true)
                    .then(({ fileName, content="" }) => {
                        if(_.isEmpty(content)) return;
                        let attachments = [{
                            name: fileName,
                            type: "BASE64",
                            contentBase64: content
                        }];
                        GlobalEventManager.email({
                            title: "Email Report",
                            subject: `Report: ${fileName}`,
                            attachments
                        });
                    });
            },

            onPrintReport() {
                const self = this;
                let promise = self.getExportContent(DocumentFileType.PDF);
                self.$rqBusy.wait(promise, true)
                    .then(({ content="" }) => {
                        if(_.isEmpty(content)) return;
                        self.$emit('print', { content: content, printOnLoad: true })
                    });
            },

            downloadExport(downloadInfo) {
                const self = this;
                self.exportDownload = new DocumentDownloadModel(downloadInfo, `${location.origin}/api`, true);
                self.$nextTick(() => {
                    _.invoke(this, "$refs.downloadLink.click");
                });
                self.$toast.success("File download should begin shortly...");
            },

            saveExport(fileType, orderId=0) {
                const self = this;
                let ordersID = orderId > 0 ? orderId : self.orderId;
                if (ordersID > 0) {
                    let promise = self.getExportContent(fileType);
                    self.$rqBusy.wait(promise, true)
                        .then(({ description="", content="" }) => {
                            if(_.isEmpty(content)) {
                                self.$toast.error("Export document could not be found.  Please generate the report again.");
                                return;
                            }

                            self.$events.emit(GLOBAL_EVENTS.SAVE_DOCUMENTS, {
                                title: "Save Report",
                                viewModel: new SaveDocumentViewModel({
                                    ordersID,
                                    fileType,
                                    fileScanDescription: description,
                                    saveLocation: SaveDocumentViewModel.SaveLocation.FileScan,
                                }),
                                documents: [new DocumentModel({
                                    ordersID,
                                    isBase64Encoded: true,
                                    fileType,
                                    fileContentType: fileType,
                                    fileBytes: content,
                                    description,
                                    fileScanDescription: description,
                                })],
                                isDocumentGeneration: false
                            });
                        })
                        .catch(e => {
                            self.$toast.error("An issue occurred during report export.");
                            console.error(e);
                        });
                }
                else {
                    self.promptFileNumber(fileType);
                }
                return true;
            },

            promptFileNumber(fileType) {
                const self = this;
                self.$events.emit(GLOBAL_EVENTS.FILE_NUMBER_PROMPT, {
                    title: "File Required",
                    allowLockedFiles: true,
                    onOk: e => self.saveExport(fileType, e.ordersID)
                });
            },
        }
    }
</script>