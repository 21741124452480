import ContactsModule from "./contacts";
import LoansModule from "./loans";
import InvoicesModule from "./invoices";
import TitlePolicyModule from "./titlePolicy";

import { ORDER_ACTIONS, COMMITMENT_ACTIONS, WORKFLOW_ACTIONS, NAV_ACTIONS } from '../../actions';
import { SET_ERROR, ORDER_MUTATIONS, CONTACT_MUTATIONS, PROPERTY_MUTATIONS, PREPAID_SECTION_DATA_MUTATIONS } from '../../mutations';
import { BASIC_ACTION, ACTION_WITH_COMMIT, updateStateArray } from '../../helpers';
import ordersApi from '@/api/orders';
import commitmentsApi from '@/api/commitments';
import { OrderSettingsViewModel } from "../../../shared/models/models";
import { DoNotDoBusinessWithApi } from "@/api";

const state = {
    checkWritingInUsePrompt: true,
    duplicateSearchDetails: [],
    order: null,
    orderId: null,
    orderLookups: null,
    orderSummary: null,
    orderCriticalNotes: [],
    orderDndbwWarnings: [],
    secondaryFileList: [],
    timeTracking: [],
    orderCriticalNoteModalOpen: false,
    region: null,
    branch: null,
    fileNumberPool: {},
    orderSettingChangeCount: 0,


    //merged module states
    ...LoansModule.state,
    ...InvoicesModule.state,
    ...TitlePolicyModule.state
};

const getters = {
    orderId: state => state.orderId,
    orderRegionId: state => state.order?.regionID,
    orderLookups: state => state.orderLookups,
    orderSettings: state => new OrderSettingsViewModel(state.orderSummary),
    orderSummary: state => state.orderSummary,
    orderCriticalNotes: state => state.orderCriticalNotes,
    secondaryFileList: state => state.secondaryFileList,
    timeTracking: state => state.timeTracking,
    orderCriticalNoteModalOpen: state => state.orderCriticalNoteModalOpen,
    defaultLoanId: state => _.getNumber(state, "orderSummary.defaultLoanID", null) || 0,

    ...TitlePolicyModule.getters,
    ...InvoicesModule.getters
};

const actions = {

    [ORDER_ACTIONS.APPLY_TEMPLATE] (context, data) {
        let apiPromise = null;
        let storeAction = "";
        if (data.Category == "tp") {
            apiPromise = commitmentsApi.applyTemplate(data);
            storeAction = data.routeName === "policy:loan:form"
                ? ORDER_ACTIONS.GET_COMMITMENT_DATA
                : ORDER_ACTIONS.GET_TITLE_PRODUCTION_DATA;
        } else {
            apiPromise = ordersApi.applyTemplate(data);
            storeAction = ORDER_ACTIONS.UPDATE_ORDER_DATA;
        }
        return BASIC_ACTION(context, apiPromise.then(result => _.isEmpty(storeAction) ? Promise.resolve(result) : context.dispatch(storeAction, result)));
    },

    [ORDER_ACTIONS.CREATE_NEW_ORDER](context, orderData) {
        let apiPromise = ordersApi.saveNewOrder(orderData);
        return BASIC_ACTION(context, apiPromise);
    },

    [ORDER_ACTIONS.GET_NEXT_FILE_NUMBER](context, { model }) {
        let apiPromise = Promise.resolve();

        apiPromise = ordersApi.getNextFileNumber(model);

        return ACTION_WITH_COMMIT(context, apiPromise, ORDER_MUTATIONS.SET_NEXT_FILE_NUMBER);
    },

    [ORDER_ACTIONS.UPDATE_FILE_NUMBER_SEQUENCE](context, request) {
        let apiPromise = ordersApi.setNextFileNumber(request);

        return ACTION_WITH_COMMIT(context, apiPromise, ORDER_MUTATIONS.SET_NEXT_FILE_NUMBER);
    },

    [ORDER_ACTIONS.COPY_EXISTING_ORDER](context, { sourceOrdersID, targetGFNo }) {
        let apiPromise = ordersApi.copyExistingOrder({ sourceOrdersID, targetGFNo });
        return BASIC_ACTION(context, apiPromise, result => {
            if(!_.getBool(result, "isSuccessful")) return null;
            context.dispatch(ORDER_ACTIONS.UPDATE_ORDER_DATA, result)
        });
    },

    [ORDER_ACTIONS.GET_ORDER_DATA]: ({ dispatch, state, commit }, { orderId=0, refresh=false, getWFNotification=true } = {}) => {
        if (_.parseNumber(state.orderId, 0) === _.parseNumber(orderId, 0) && !refresh) {
            return Promise.resolve(state.orderId);
        }

        let targetOrderId = orderId || state.orderId;
        return new Promise ((resolve, reject) => {
            ordersApi.getOrderData(targetOrderId)
                .then(data => {
                    data.getWFNotification=getWFNotification;
                    dispatch(ORDER_ACTIONS.UPDATE_ORDER_DATA, data);
                    resolve(targetOrderId);
                })
                .catch(error => {
                    commit(SET_ERROR, error.message);
                    reject(error);
                });
        });
    },

    [ORDER_ACTIONS.UPDATE_ORDER_DATA](context, data) {
        let orderID = _.get(data, "order.ordersID", -3);
        context.commit(ORDER_MUTATIONS.SET_ORDER_ID, orderID);
        context.commit(ORDER_MUTATIONS.SET_ORDER, _.get(data, "order", {}));
        context.commit(ORDER_MUTATIONS.SET_ORDER_LOOKUPS, _.get(data, "lookups", {}));
        context.commit(CONTACT_MUTATIONS.SET_ORDER_CONTACTS, _.get(data, "contacts", {}));
        context.commit(PROPERTY_MUTATIONS.SET_PROPERTIES, _.get(data, "properties", {}));
        context.commit(ORDER_MUTATIONS.SET_ORDER_LOANS,  _.get(data, "loans", []));
        context.commit(ORDER_MUTATIONS.SET_ORDER_SUMMARY, _.get(data, "summary", {}));
        context.commit(ORDER_MUTATIONS.SET_ORDER_CRITICAL_NOTES, _.get(data, "criticalNotes", []));
        context.commit(ORDER_MUTATIONS.SET_ORDER_DNDBW_WARNINGS, _.get(data, "dndbwWarnings", []));
        context.commit(ORDER_MUTATIONS.SET_ORDER_SECONDARY_FILE_LIST, _.get(data, "secondaryFileList", []));
        context.commit(PREPAID_SECTION_DATA_MUTATIONS.UPDATE_PREPAID_SECTION_DATA,  _.get(data, "prepaids", []));
        context.commit(ORDER_MUTATIONS.SET_ORDER_REGION,  _.get(data, "region", {}));
        context.commit(ORDER_MUTATIONS.SET_ORDER_BRANCH,  _.get(data, "branch", {}));

        if (_.parseBool(data.getWFNotification, true))
            context.dispatch(WORKFLOW_ACTIONS.GET_NOTIFICATION_INFO, { forceRefresh: true });

        context.dispatch(NAV_ACTIONS.UPDATE_ORDER_NAV);
    },

    [ORDER_ACTIONS.ADD_ORDER_CRITICAL_NOTE](context, data) {
        if(!data.isCriticalNote){
            return;
        }
        context.commit(ORDER_MUTATIONS.INSERT_ORDER_CRITICAL_NOTE, data);
    },

    [ORDER_ACTIONS.SET_ORDER_TIME_TRACKER]({ state, commit }, data) {
        let orderID = _.get(data, "ordersID", -3);
        let timeTracker = _.find(state.timeTracking, {orderID: orderID}) || null;
        if (!_.isNil(timeTracker)) {
            commit(ORDER_MUTATIONS.DELETE_ORDER_TIME_TRACKER, orderID);
        }
        commit(ORDER_MUTATIONS.INSERT_ORDER_TIME_TRACKER, data);
    },

    [ORDER_ACTIONS.UPDATE_ORDER_CRITICAL_NOTES](context, data) {
        context.commit(ORDER_MUTATIONS.SET_ORDER_CRITICAL_NOTES, data);
    },

    [ORDER_ACTIONS.UPDATE_ORDER_SECONDARY_FILE_LIST](context, data) {
        context.commit(ORDER_MUTATIONS.SET_ORDER_SECONDARY_FILE_LIST, data);
    },

    [ORDER_ACTIONS.GET_ORDER]: ({ state, commit }, { orderId=0, refresh=false } = {}) => {

        const commitOrderData = function(orderId, data) {
            commit(ORDER_MUTATIONS.SET_ORDER_ID, orderId);
            commit(ORDER_MUTATIONS.SET_ORDER, data);
        };

        if (_.parseNumber(state.orderId, 0) !== _.parseNumber(orderId, 0) || refresh)
            return new Promise ((resolve, reject) => {
                ordersApi.getOrderMain(orderId)
                    .then(data => {
                        commitOrderData(orderId, data);
                        resolve(data);
                    })
                    .catch(error => {
                        commit(SET_ERROR, error.message);
                        reject(error);
                    });
            });
        else
            return Promise.resolve(_.cloneDeep(state.order));
    },

    [ORDER_ACTIONS.UPDATE_ORDER](context, orderData) {
        context.commit(ORDER_MUTATIONS.SET_ORDER, orderData);
        context.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
        context.dispatch(NAV_ACTIONS.UPDATE_ORDER_NAV);
    },

    [ORDER_ACTIONS.UPDATE_ORDER_STATUS](context, request) {
        return ACTION_WITH_COMMIT(context, ordersApi.updateOrderStatus(request), ORDER_MUTATIONS.SET_ORDER_STATUS);
    },

    [ORDER_ACTIONS.REFRESH_ORDER_LOOKUPS]: ({state, dispatch, commit }, clearImmediately) => {
        if (clearImmediately === true) commit(ORDER_MUTATIONS.SET_ORDER_LOOKUPS, null);
        return new Promise((resolve, reject) => {
            ordersApi.getOrderLookups(state.orderId)
            .then(lookupData => {
                commit(ORDER_MUTATIONS.SET_ORDER_LOOKUPS, lookupData.lookups);
                resolve(lookupData.lookups);
            })
            .catch(error => {
                commit(SET_ERROR, error.message);
                reject(error);
            });
        });
    },

    [ORDER_ACTIONS.REFRESH_ORDER_SUMMARY]: ({state, dispatch, commit }, refresh) => {
        if (refresh !== true && state.orderSummary != null) {
            return Promise.resolve(state.orderSummary);
        }
        return new Promise((resolve, reject) => {
            ordersApi.getOrderSummary(state.orderId)
            .then(orderSummary => {
                commit(ORDER_MUTATIONS.SET_ORDER_SUMMARY, orderSummary);
                resolve(orderSummary);
                dispatch(ORDER_ACTIONS.GET_ORDER_DNDBW_WARNINGS);
            })
            .catch(error => {
                commit(SET_ERROR, error.message);
                reject(error);
            });
        });
    },

    [ORDER_ACTIONS.UPDATE_ORDER_SETTINGS]: (context, data) => {
        return new Promise((resolve, reject) => {
            ordersApi.saveOrderSettings(data)
            .then((result) => {
                if(result) {
                    //increment this count so any watchers on the orderSettingChangeCount will fire
                    //using this as a notification mechanism that the order settings changed to reload appropriate screens
                    let count = context.state.orderSettingChangeCount;
                    count++;
                    context.commit(ORDER_MUTATIONS.UPDATE_ORDER_SETTING_CHANGE_COUNT, count);
                    return context
                        .dispatch(ORDER_ACTIONS.GET_ORDER_DATA, {
                            orderId: data.ordersID, refresh: true, getWFNotification: data.getWFNotification
                        })
                        .then(()=>{
                            resolve(result);
                        });
                }
                else {
                    reject({
                        data: result,
                        message: 'No changes detected'
                    });
                }
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    [ORDER_ACTIONS.UPDATE_ORDER_MAIN]: (context, data) => {
        return new Promise((resolve, reject) => {
            ordersApi.saveOrderMain(data.order, data.changes)
            .then((data) => {
                if(data) {
                    context.dispatch(ORDER_ACTIONS.UPDATE_ORDER, data)
                    .then(() => {
                        resolve(true);
                    });
                }
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    [ORDER_ACTIONS.GET_SECONDARY_FILE_CREATE]: ({state}) => {
        return new Promise((resolve, reject) => {
            ordersApi.getSecondaryFileMerge(state.orderId, 'Create')
            .then((data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    [ORDER_ACTIONS.GET_DUPLICATE_SEARCH_SETUP](context) {
        let apiPromise = ordersApi.getDuplicateSearchSetup();
        return ACTION_WITH_COMMIT(context, apiPromise, ORDER_MUTATIONS.SET_DUPLICATE_SEARCH_SETUP);
    },

    [ORDER_ACTIONS.GET_ORDER_DNDBW_WARNINGS] (context) {
        let apiPromise = DoNotDoBusinessWithApi.getWarnings(context.state.orderId);
        return ACTION_WITH_COMMIT(context, apiPromise, ORDER_MUTATIONS.SET_ORDER_DNDBW_WARNINGS);
    },

    //merged module actions
    ...LoansModule.actions,
    ...InvoicesModule.actions,
    ...TitlePolicyModule.actions
};

const mutations = {
    [ORDER_MUTATIONS.SET_CHECK_WRITING_IN_USE_PROMPT] (state, value) { state.checkWritingInUsePrompt = value; },
    [ORDER_MUTATIONS.SET_NEXT_FILE_NUMBER] (state, value) { state.fileNumberPool = value; },
    [ORDER_MUTATIONS.SET_ORDER_ID] (state, value) { state.orderId = value; },
    [ORDER_MUTATIONS.SET_ORDER] (state, value) {
        state.order = value;
        let openedCriticalNotesOrdersID = _.parseNumber(localStorage.getItem("OpenedCriticalNotesOrdersID"));
        if(openedCriticalNotesOrdersID !== state.order.ordersID){
            localStorage.removeItem("OpenedCriticalNotesOrdersID");
        }
    },
    [ORDER_MUTATIONS.SET_ORDER_STATUS] (state, value) {
        state.order.status = value;
        state.orderSummary.status = value;
    },
    [ORDER_MUTATIONS.SET_ORDER_REGION] (state, value) { state.region = value; },
    [ORDER_MUTATIONS.SET_ORDER_BRANCH] (state, value) { state.branch = value; },
    [ORDER_MUTATIONS.SET_ORDER_LOOKUPS] (state, value) { state.orderLookups = value; },
    [ORDER_MUTATIONS.SET_ORDER_SUMMARY] (state, value) {
        state.orderSummary = value;

        // Set default loan to first loan if none is present
        let loanId = _.getNumber(state, "loans[0].loanID", 0);
        if (_.isNil(state.orderSummary.defaultLoanID) && loanId > 0) {
            state.orderSummary.defaultLoanID = loanId;
        }
    },
    [ORDER_MUTATIONS.INSERT_ORDER_CRITICAL_NOTE] (state, value) { state.orderCriticalNotes.push(value); },
    [ORDER_MUTATIONS.SET_ORDER_CRITICAL_NOTES] (state, value) { state.orderCriticalNotes = value; },
    [ORDER_MUTATIONS.SET_ORDER_DNDBW_WARNINGS] (state, value) { state.orderDndbwWarnings = value; },
    [ORDER_MUTATIONS.SET_ORDER_SECONDARY_FILE_LIST] (state, value) { state.secondaryFileList = value; },
    [ORDER_MUTATIONS.SET_ORDER_CRITICAL_NOTE_MODAL_OPEN] (state, data) {
        let openedCriticalNotesOrdersID = localStorage.getItem("OpenedCriticalNotesOrdersID");

        if(data.autoOpen && (_.isEmpty(state.orderCriticalNotes) || !_.isNil(openedCriticalNotesOrdersID))){
            return;
        }

        state.orderCriticalNoteModalOpen = data.open;
        localStorage.setItem("OpenedCriticalNotesOrdersID", state.order.ordersID);
    },
    [ORDER_MUTATIONS.INSERT_ORDER_TIME_TRACKER] (state, value) { state.timeTracking.push(value); },
    [ORDER_MUTATIONS.DELETE_ORDER_TIME_TRACKER] (state, value) {
        _.remove(state.timeTracking, item => item.ordersID === value);
        state.timeTracking = _.cloneDeep(state.timeTracking);
    },
    [ORDER_MUTATIONS.SET_ORDER_SUMMARY_ALL_PROPS_ON_SS] (state,value) {  state.orderSummary.allPropertiesOnAllSettlementStatements = value; },
    [ORDER_MUTATIONS.SET_DUPLICATE_SEARCH_SETUP] (state, value) { state.duplicateSearchDetails = value; },
    [ORDER_MUTATIONS.UPDATE_ORDER_SETTING_CHANGE_COUNT] (state, value) { state.orderSettingChangeCount = value; },

    //merged module mutations
    ...LoansModule.mutations,
    ...InvoicesModule.mutations,
    ...TitlePolicyModule.mutations
};

export default {
    state,
    getters,
    actions,
    mutations,
    modules: {
        contacts: ContactsModule
    }
};