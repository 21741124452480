import { EXAGO_SESSION_ACTIONS as MODULE_ACTIONS } from '../actions';
import { EXAGO_SESSION_MUTATIONS as MODULE_MUTATIONS } from '../mutations';
import { ACTION_WITH_COMMIT, BASIC_ACTION } from '../helpers';
import exagoSessionApi from '@/api/exago';
import reportsApi from '@/api/reports';

const getDefaultState = () => {
    return {
        sessionId: null,
        apiKey: null,
        appUrl: "",
        sessionAppUrl: "",
        reports: null,
        rendered: false,
        useLogiReports: true,
        reportApiBaseUrl: "",
        reportAppBaseUrl: "",
        reportAccess: null
    };
};

const flatten = (arr, currentPath, currentMap) => {
    if (_.isEmpty(arr)) return;
    _.forEach(arr, (row) => {
        // leaf_Flag will be false/undefined/null for folders.
        if (!_.getBool(row, 'leaf_Flag', true)) {
            flatten(row.entity, (currentPath ? currentPath + "/" : "") + row.name, currentMap);
        }
        else {
            let fullPath = (currentPath ? currentPath + "/" : "") + row.name;
            currentMap.push({
                reportPath: currentPath,
                reportName: row.name,
                fullPath: (currentPath ? currentPath + "/" : "") + row.name,
                displayName: _.replaceAll(_.replaceAll(fullPath, '/', '^&^'), '^&^', ' / '),
            });
        }
    });
}
const state = { exagoAppBaseUrl:  '', ...getDefaultState() };

const getters = {
    exagoAppBaseUrl: (state) => state.exagoAppBaseUrl,
    reportList: (state) => {
        let map = [];
        flatten(state.reports, null, map);
        return map;
    }
};

const actions = {
    [MODULE_ACTIONS.LOAD_REPORT](context, { options=null }={ options: null }) {
        context.commit(MODULE_MUTATIONS.SET_SESSION, null);
        context.commit(MODULE_MUTATIONS.SET_RENDERED, false);
        let apiPromise = exagoSessionApi.loadReport(options);
        return ACTION_WITH_COMMIT(context, apiPromise, MODULE_MUTATIONS.SET_SESSION);
    },
    [MODULE_ACTIONS.CREATE_SESSION](context, { refresh=false }={ refresh: false }) {
        if(!_.isNil(context.state.sessionId) && !refresh) return Promise.resolve(context.state);
        context.commit(MODULE_MUTATIONS.SET_SESSION, null);
        context.commit(MODULE_MUTATIONS.SET_RENDERED, false);
        let apiPromise = exagoSessionApi.createSession();
        return ACTION_WITH_COMMIT(context, apiPromise, MODULE_MUTATIONS.SET_SESSION);
    },
    [MODULE_ACTIONS.GET_REPORTS](context, { refresh=true }={ refresh: true }) {
        if(!_.isEmpty(context.state.reports) && !refresh) return Promise.resolve(context.state);

        context.commit(MODULE_MUTATIONS.SET_REPORTS, null);

        if (!_.isNil(context.state.sessionId) && !context.state.rendered) {
            let apiPromise = exagoSessionApi.getReportListFromSession(context.state.sessionId);
            return ACTION_WITH_COMMIT(context, apiPromise, MODULE_MUTATIONS.SET_REPORTS);
        }
        else {
            return context.dispatch(MODULE_ACTIONS.CREATE_SESSION);
        }
    },
    [MODULE_ACTIONS.CLEAR_SESSION](context) {
        return new Promise((resolve, reject) => {
            context.commit(MODULE_MUTATIONS.SET_SESSION, null);
            resolve();
        })
    },
    [MODULE_ACTIONS.GET_REPORT_API_URL](context) {
        if(!_.isNil(context.state.reportAccess)) return Promise.resolve(context.state);
        context.commit(MODULE_MUTATIONS.SET_REPORT_ACCESS, null);
        let apiPromise = reportsApi.getReportsAccess();                
        return ACTION_WITH_COMMIT(context, apiPromise, MODULE_MUTATIONS.SET_REPORT_ACCESS);
    }, 
};

const mutations = {
    [MODULE_MUTATIONS.SET_SESSION] (state, value) {
        let newState = _.isNil(value)
            ? getDefaultState()
            : value;
        _.assign(state, newState);
    },
    [MODULE_MUTATIONS.SET_REPORTS] (state, value) { state.reports = value; },
    [MODULE_MUTATIONS.SET_RENDERED] (state, value) { state.rendered = _.parseBool(value); },
    [MODULE_MUTATIONS.SET_USE_LOGI_REPORTS] (state, value) { state.useLogiReports = _.parseBool(value); },
    [MODULE_MUTATIONS.SET_REPORT_API_BASE_URL] (state, value) { state.reportApiBaseUrl = value; },
    [MODULE_MUTATIONS.SET_REPORT_APP_BASE_URL] (state, value) { state.reportAppBaseUrl = value; },
    [MODULE_MUTATIONS.SET_REPORT_ACCESS] (state, value) { state.reportAccess = value; }
};

export default {
    state,
    getters,
    actions,
    mutations
};