import { BASIC_ACTION, ACTION_WITH_COMMIT } from "../helpers";
import { GET_SYSTEM_DEFAULTS, GET_SYSTEM_LOOKUPS, GET_SYSTEM_DATA, REFRESH_LOOKUP, GET_USER_REGIONS } from "../actions";
import { SYSTEM_MUTATIONS } from "../mutations";
import { NAV_IDS } from "../../nav"
import { NAV_ACTIONS } from "../actions";
import { SystemDefaultApi, BranchesApi, RegionsApi, PropertyLayoutsApi, StaffApi, LookupsApi } from "../../api";
import { JobTypes } from "@config/enums";
import LookupNames from "../lookupNames";

const state = {
    systemDataLoaded: false,
    systemDefaults: null,
    cdfSystemDefaults: null,
    orderFacetSearch: null,
    lookups: null,
    globalRegionId: 0,
    globalRegion: null,
    showDocMgmtPreview: null,//set to null so it'll default when doc mgmt is loaded
    docMgmtGroupByCategory: null,
    duplicateSearchFields: [],
    settlementStatementDocuments: [],
    lookupNames: {
        entity: [],
        system: [],
        custom: []
    }
};

const getters = {
    orderFacetSearch: state => {return state.orderFacetSearch;},
    systemDefaults: state => {return state.systemDefaults;},
    cdfSystemDefaults: state => {return state.cdfSystemDefaults;},
    systemLookups: state => {return state.lookups;},
    showDocMgmtPreview: state => {return state.showDocMgmtPreview;},
    docMgmtGroupByCategory: state => {return state.docMgmtGroupByCategory},
    globalRegionId: state => {return state.globalRegionId;},
    globalRegion: state => {return state.globalRegion},
    duplicateSearchFields: state => {return state.duplicateSearchFields;},
    settlementStatementDocuments: state => {return state.settlementStatementDocuments;},
    systemLookupNames: state => _.get(state, "lookupNames.system", null) || [],
    entityLookupNames: state => _.get(state, "lookupNames.entity", null) || [],
    customLookupNames: state => _.get(state, "lookupNames.custom", null) || []
};

const devAlert = (msg, ...params) => {
    if(process.env.NODE_ENV !== "development") return;
    console.warn(msg, ...params);
};

const mapLookup = (items, keyName, nameTemplate=null, inactiveExpr=null) => _.map(items, item => {
    let name = _.isNil(nameTemplate) ? item.name : nameTemplate(item);
    let inactive = !_.isNil(inactiveExpr) && _.getBool(item, inactiveExpr);
    return { ...item, id: _.parseNumber(item[keyName]), name, inactive };
});

const actions = {

    [GET_SYSTEM_DATA]: context => {
        return BASIC_ACTION(context, SystemDefaultApi.getSystemData(), result => {
            context.commit(SYSTEM_MUTATIONS.SET_SYSTEM_DATA, result);
            context.commit(SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUP_COLLECTION, result.lookups);
            
            const { cplUnderwriterCompanyID } = context.rootState.system.systemDefaults;
            if (cplUnderwriterCompanyID == context.rootState?.orders?.order?.underwriterCompanyID) {
                context.dispatch(NAV_ACTIONS.SET_NAV_ITEM_ACTIVE, NAV_IDS.ORDER_ENTRY_CPL);
            }
            else {
                context.dispatch(NAV_ACTIONS.SET_NAV_ITEM_INACTIVE, NAV_IDS.ORDER_ENTRY_CPL);
            }
            
            return result;
        });
    },
    [GET_SYSTEM_DEFAULTS]: (context, refresh = false) => {
        if (state.systemDefaults !== null && !refresh) return Promise.resolve(state.systemDefaults);
        return BASIC_ACTION(context, SystemDefaultApi.getSystemDefaultData(), results => {
            return results.systemDefaults;
        });
    },
    [GET_SYSTEM_LOOKUPS]: (context, refresh = false) => {
        if (state.systemDefaults !== null && !refresh) return Promise.resolve(state.lookups);
        return ACTION_WITH_COMMIT(context, SystemDefaultApi.getSystemLookups(), SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUP_COLLECTION);
    },
    [REFRESH_LOOKUP]: (context, { lookupKeys, lookupData=[], regionId=0 }) => {
        let lookups = _.cloneDeep(context.state.lookups);
        if(_.isNil(lookups)) return [];

        let targetLookups = _.isArray(lookupKeys) ? lookupKeys : [lookupKeys];
        let apiPromises = [];

        let finalize = result => {
            _.each(targetLookups, (name, index) => {
                lookups[name] = result[index];
            });
            return lookups;
        };

        _.each(targetLookups, name => {
            if(name === LookupNames.STAFF) {
                finalize = results => {
                    let staffResult = {};
                    lookups[LookupNames.STAFF] = mapLookup(results[0], "staffID", item => `${item.initials} - ${item.name}`, "isInactive");
                    _.each(JobTypes.lookupItems, jobType => {
                        let staffLookup = JobTypes.lookupName(jobType.id);
                        let staffItems = _.filter(results[0], s => _.includes(s.jobDutyIDs, jobType.id));
                        lookups[staffLookup] = mapLookup(staffItems, "staffID", item => `${item.initials} - ${item.name}`, "isInactive");
                        staffResult[staffLookup] = lookups[staffLookup];
                    });
                    return lookups;
                };
                apiPromises.push(_.isEmpty(lookupData) ? StaffApi.getStaff() : Promise.resolve(lookupData));
            }
            else if(_.isEmpty(lookupData)) {
                switch(name) {
                    case LookupNames.BRANCHES: apiPromises.push(BranchesApi.getBranches()); break;
                    case LookupNames.REGIONS:
                        apiPromises.push(Promise.all([
                            RegionsApi.getRegions(),
                            context.dispatch(GET_USER_REGIONS)
                        ]).then(results => results[0]));
                    break;
                    case LookupNames.PROPERTY_LAYOUTS: apiPromises.push(PropertyLayoutsApi.getPropertyLayouts()); break;
                    case LookupNames.PRORATION_DEFAULTS: apiPromises.push(LookupsApi.getProrationDefaults()); break;
                    default:
                        if(_.includes(context.getters.systemLookupNames, name)) {
                            apiPromises.push(LookupsApi.getSystemLookup(name, regionId));
                        }
                        else if(_.includes(context.getters.customLookupNames, name)) {
                            apiPromises.push(LookupsApi.getCustomLookup(name, regionId));
                        }
                        else {
                            devAlert("Lookup refresh for, %s, not implemented.  Update REFRESH_LOOKUP in src/store/modules/system.js");
                        }
                        break;
                }
            }
            else {
                if(name === LookupNames.REGIONS) context.dispatch(GET_USER_REGIONS);
                apiPromises.push(Promise.resolve(lookupData));
            }
        });

        if(_.isEmpty(apiPromises)) return [];

        return ACTION_WITH_COMMIT(context, Promise.all(apiPromises).then(finalize), SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUPS);
    }
};

const mutations = {
    [SYSTEM_MUTATIONS.SET_SYSTEM_DATA] (state, value) {
        _.set(state, "systemDefaults", value.systemDefault || value.systemDefaults);
        _.set(state, "cdfSystemDefaults", value.cdfSystemDefaults);
        _.set(state, "globalRegionId", value.globalRegionId);
        _.set(state, "globalRegion", value.globalRegion);
        _.set(state, "duplicateSearchFields", value.duplicateSearchFields);
        _.set(state, "settlementStatementDocuments", value.settlementStatementDocuments);
        _.set(state, "systemDataLoaded", true);
    },
    [SYSTEM_MUTATIONS.SET_SYSTEM_DEFAULT_DATA] (state, value) {
        _.set(state, "systemDefaults", value.systemDefault || value.systemDefaults);
        _.set(state, "cdfSystemDefaults", value.cdfSystemDefaults);
        _.set(state, "settlementStatementDocuments", value.settlementStatementDocuments);
    },
    [SYSTEM_MUTATIONS.SET_SYSTEM_DEFAULTS] (state, value) { _.set(state, "systemDefaults", value); },
    [SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUP_COLLECTION] (state, value) {
        _.set(state, "lookups", {
            ...value.systemLookups,
            ...value.customLookups,
            propertyLayouts: value.propertyLayouts,
            branches: mapLookup(value.branches, "branchID"),
            regions: mapLookup(value.regions, "regionID", item => `${item.regID} - ${item.description}`),
            prorationDefaults: value.prorationDefaults,
            wopiInfo: value.wopiInfo
        });
        _.set(state, "lookupNames", {
            entity: value.entityLookupNames,
            system: value.systemLookupNames,
            custom: value.customLookupNames
        });
    },
    [SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUPS] (state, value) { _.set(state, "lookups", value); },
    [SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUP_DOCUMENT_TAGS] (state, value) { _.set(state, "lookups.documentTags", value); },
    [SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUP_STANDARD_LANGUAGE_CATEGORIES] (state, value) { _.set(state, "lookups.standardLanguageCategorie", value); },
    // [SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUP_SUBDIVISIONS] (state, value) { _.set(state, "lookups.subdivisions", value); },
    // [SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUP_SURVEYS] (state, value) { _.set(state, "lookups.surveys", value); },
    [SYSTEM_MUTATIONS.SET_ORDER_FACET_SEARCH] (state, value) { _.set(state, "orderFacetSearch", value); },
    [SYSTEM_MUTATIONS.SET_EBANK_RECON_SEARCH] (state, value) { _.set(state, "eBankReconSearch", value); },
    [SYSTEM_MUTATIONS.SET_RECONCILIATION_SEARCH] (state, value) { _.set(state, "reconciliationSearch", value); },
    [SYSTEM_MUTATIONS.SET_CONSOLIDATED_CHECKS_SEARCH] (state, value) { _.set(state, "consolidatedChecksSearch", value); },
    [SYSTEM_MUTATIONS.SET_SHOW_DOC_MGMT_PREVIEW] (state, value) { _.set(state, "showDocMgmtPreview", value); },
    [SYSTEM_MUTATIONS.SET_DOC_MGMT_GRP_BY_CATEGORY] (state, value) { _.set(state, "docMgmtGroupByCategory", value); },
    [SYSTEM_MUTATIONS.SET_CONFIG_SEARCH] (state, value) { _.set(state, "configSearchTerm", value); },
    [SYSTEM_MUTATIONS.SET_GLOBAL_REGION_ID] (state, value) { _.set(state, "globalRegionId", value); }
};

export default { state, getters, actions, mutations };