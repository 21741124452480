<template>
<div class="rq-offcanvas-container">
    <b-offcanvas
        :id="id"
        :backdrop="backdrop"
        :bodyScrolling="bodyScrolling"
        :dismissLabel="dismissLabel"
        :placement="placement"
        :title="title"
        v-model="boundValue"
        @shown="bubbleEvent('shown', $event)"
        @hidden="bubbleEvent('hidden', $event)"
        >
        <template #title>
            <FontAwesomeIcon v-if="hasTitleIcon" :icon="titleIcon" class="title-icon" />{{title}}
        </template>
        <slot></slot>
    </b-offcanvas>
</div>
</template>
<script setup>
    import { computed } from 'vue';
    const props = defineProps({
        id: { type: String, default: () => _.uniqueId("rq-offcanvas-") },
        bodyScrolling: { type: Boolean, default: true },
        backdrop: { type: Boolean, default: false },
        dismissLabel: { type: String, default: null },
        modelValue: { type: Boolean, default: false },
        placement: { type: String, default: "end" },
        title: { type: String, default: null },
        titleIcon: { type: String, default: null },
    });
    const emit = defineEmits(["shown", "hidden", "update:modelValue"]);
    const boundValue = computed({
        get() { return props.modelValue; },
        set(val) {
            emit("update:modelValue", val);
        }
    });
    const hasTitleIcon = computed(() => !_.isEmpty(props.titleIcon));

    function bubbleEvent(eventName, e) {
        emit(eventName, e);
    }
</script>