<template>
    <div v-if="isVisible" class="rq-widget rq-licensed-user-widget">
        <div class="rq-widget-part licensed-user-gauge">
            <CircleGauge
                :radius="50"
                descriptor="USED"
                :variant="gaugeVariant"
                :max="userLimit"
                :value="userCount"
            />
        </div>
        <div class="rq-widget-part licensed-user-detail">
            <div class="detail-label">Active / Total</div>
            <div class="detail-value">{{ userCount }} / {{ userLimit }}</div>
        </div>
        <div class="rq-widget-actions">
            <button
                class="btn btn-icon"
                type="button"
                :disabled="isBusy"
                @click="onHardRefresh">
                <FontAwesomeIcon icon="fas fa-rotate-right" />
            </button>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watchEffect } from "vue";
    import { LicenseApi } from "@/api";
    import CircleGauge from "./core/CircleGauge.vue";

    const props = defineProps({
        alertMessage: { type: String, default: "" }
    });

    const emit = defineEmits(["update:alertMessage"]);

    const userCount = ref(0);
    const userLimit = ref(0);
    const alertThreshold = ref(0);
    const isLicenseEnforced = ref(0);
    const isBusy = ref(false);
    const isLoaded = ref(false);
    const isVisible = computed(() => userLimit.value > 0 && isLicenseEnforced.value);
    const activePercentage = computed(() => (userCount.value / userLimit.value) * 100);
    const displayPercentage = computed(() => activePercentage.value > 100 ? 100 : Math.round(activePercentage.value));
    const alertThresholdExceeded = computed(() => isVisible.value && activePercentage.value > alertThreshold.value);
    const gaugeVariant = computed(() => alertThresholdExceeded.value ? "danger" : "success");

    watchEffect(() => {
        let message = alertThresholdExceeded.value
            ? `${displayPercentage.value}% of licensed users are active. Please contact your sales representative.`
            : "";
        emit("update:alertMessage", message);
    });

    async function init() {
        await fetchData();
    }

    async function fetchData(forceRefresh=false) {
        isBusy.value = true;
        try {
            let result = await LicenseApi.getLicensedUserDetail(forceRefresh);
            userCount.value = _.parseInt(result?.userCount, 0);
            userLimit.value = _.parseInt(result?.userLimit, 0);
            alertThreshold.value = _.parseNumber(result?.alertThreshold, 95);
            isLicenseEnforced.value = _.parseBool(result?.isLicenseEnforced);
            isLoaded.value = true;
        }
        catch(err) {
            console.error(err);
        }
        finally {
            isBusy.value = false;
        }
    }

    async function onHardRefresh() {
        await fetchData(true);
    }

    init();

    defineExpose({
        refresh: fetchData
    });
</script>