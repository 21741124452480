<template>
    <dx-select-box
        ref="selectBox"
        :input-attr="$utils.idAttrs(id)"
        :data-source="selectBoxDataSource"
        display-expr="name"
        :placeholder="placeholder"
        :show-data-before-search="true"
        :show-clear-button="true"
        :max-length="50"
        :disabled="disabled"
        no-data-text="No document template names found matching the entered value."
        v-model="selectedItem"
    />
</template>

<script>
    import { DocumentTemplateDto } from "@documents/models";
    import { SearchRequest } from "@/shared/models/models";

    export default {
        name: "RqDocumentTemplateSelect",

        props: {
            id: { type: String, default: "" },
            placeholder: { type: String, default: "Select..." },
            documentName: { type: String, default: null },
            modelValue: { type: Number, default: null },
            disabled: { type: Boolean, default: false }
        },

        data() {
            const self = this;
            return {
                selectedItem: null,
                selectBoxDataSource: null
            }
        },

        computed: {
            selectedValue: {
                get() { return this.modelValue; },
                set(val) { this.$emit("update:modelValue", val); }
            },
            selectedName: {
                get() { return this.documentName; },
                set(val) { this.$emit("update:documentName", val); }
            }
        },

        watch: {
            modelValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.selectedItem = { documentTemplateID: newValue, name: this.documentName };
                },
                immediate: true
            },
            documentName: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.selectedItem = { documentTemplateID: this.modelValue, name: newValue };
                },
                immediate: true
            },
            selectedItem(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.selectedValue = _.get(newValue, "documentTemplateID", null);
                this.selectedName = _.get(newValue, "name", null);
            }
        },
        created() {
            const self = this;
            self.selectBoxDataSource = {
                paginate: true,
                pageSize: 25,
                load: self.fetchData,
                byKey: key => self.selectedItem
            };
        },

        methods: {
            fetchData(loadOptions){
                const self = this;
                var request = SearchRequest.fromLoadOptions(loadOptions);
                request.sortBy.push({ field: "name", direction: "ASC" });
                request.addFilter("regID", "=", 0);

                //loading indicator displayed by select-box, so $rqBusy is not needed here
                return self.$api.DocumentTemplatesApi.search(request)
                    .then(response => {
                        return {
                            data: response.results,
                            totalCount: response.totalRecords
                        };
                    });
            }
        }
    };
</script>