import { SET_ERROR } from "./mutations";

export const NOT_IMPLEMENTED = function(context, returnType) {
    context.commit(SET_ERROR, null, { root: true });
    return returnType === "array"
        ? Promise.resolve([])
        : Promise.resolve(null);
};

export const BASIC_ACTION = function(context, apiPromise, callback=()=>{}, updateGlobalError=true) {
    if(updateGlobalError) context.commit(SET_ERROR, null, { root: true });
    return new Promise((resolve, reject) => {
        apiPromise
            .then(response => {
                callback(response);
                resolve(response);
            })
            .catch(error => {
                if(updateGlobalError) context.commit(SET_ERROR, error.errorMessage || error.message, { root: true });
                reject(error);
            });
    });
};

export const ACTION_WITH_COMMIT = function(context, apiPromise, commitMutation) {
    context.commit(SET_ERROR, null, { root: true });
    return new Promise((resolve, reject) => {
        apiPromise
            .then((response) => {
                context.commit(commitMutation, response);
                resolve(response);
            })
            .catch(error => {
                context.commit(SET_ERROR, error.errorMessage || error.message, { root: true });
                reject(error);
            });
    });
};

export const updateStateArray = function(stateArray, matchPath, matchObj, addIfAbsent=false) {
    let matchVal = _.get(matchObj, matchPath, null);
    if(_.isNil(matchVal)) return false;
    let itemIndex = _.findIndex(stateArray, item => _.get(item, matchPath, null) === matchVal);
    if(itemIndex < 0) {
        if(addIfAbsent) stateArray.push(matchObj);
        return false;
    }
    stateArray.splice(itemIndex, 1, matchObj);
    return true;
};

export const removeFromStateArray = function(stateArray, itemValue) {
    if(_.isNil(itemValue)) return false;
    let itemIndex = _.findIndex(stateArray, item => item === itemValue);
    if(itemIndex < 0) return false;
    stateArray.splice(itemIndex, 1);
    return true;
};

export const removeFromStateArrayByValue = function(stateArray, matchPath, matchVal) {
    if(_.isNil(matchVal)) return false;
    let itemIndex = _.findIndex(stateArray, item => _.get(item, matchPath, null) === matchVal);
    if(itemIndex < 0) return false;
    stateArray.splice(itemIndex, 1);
    return true;
};

export const reorderStateArray = function(stateArray, sortField, startWith) {
    if(_.isNil(sortField) || !_.isNumber(startWith)) return false;
    let sorted = _.sortBy(stateArray, [sortField]);
    _.forEach(sorted, (item, index) => { _.set(item, sortField, index + startWith); });
};

export const findRecursive = function(collection, matchPath, matchVal, childPath) {
    let result = null;
    _.forEach(collection, item => {
        if(_.hasIn(item, matchPath) && item[matchPath] === matchVal) {
            result = item;
            return false;
        }

        if(!_.hasIn(item, childPath)) return;

        result = findRecursive(item[childPath], matchPath, matchVal, childPath);

        if(!_.isNil(result)) return false;
    });
    return result;
}

export default {
    NOT_IMPLEMENTED,
    BASIC_ACTION,
    ACTION_WITH_COMMIT,
    updateStateArray,
    removeFromStateArrayByValue,
    reorderStateArray
};
