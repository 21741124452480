<template>
    <div class="rq-container py-3">
        <div v-if="appliesToEnabled" class="form-group">
            <label for="drp_applies_to_type">Clause applies to</label>
            <dx-select-box
                :input-attr="$utils.idAttrs('drp_applies_to_type')"
                value-expr="id"
                display-expr="name"
                :items="appliesToTypes"
                v-model="appliesToTypeValue"
            />
        </div>
        <document-list-options :options="listFormatValue" />
    </div>
</template>

<script>
    import { ref, unref, watchEffect } from "vue";
    import { DocumentListFormat } from "@/shared/models/models";
    import DocumentListOptions from "@documents/components/DocumentListOptions";
    import { AppliesToType } from "@title-policy/enums";

    export default {
        name: "ClauseListFormat",
        props: {
            listFormat: { type: Object, default: () => ({}) },
            nullDefaults: { type: Boolean, default: false },
            appliesToType: { type: Number, default: 0 },
            appliesToEnabled: { type: Boolean, default: false },
        },
        components: { DocumentListOptions },
        setup(props, { emit, expose }) {
            const listFormatValue = ref(new DocumentListFormat(props.listFormat, props.nullDefaults));
            const appliesToTypeValue = ref(props.appliesToType);
            const appliesToTypes = ref(AppliesToType.lookupItems);

            // const setOkEnabled = val => {
            //     let eventName = val ? "enable-ok" : "disable-ok";
            //     emit(eventName);
            // };

            // watchEffect(() => {
            //     let fmtVal = unref(listFormatValue);
            //     let hasListFormatChange = _.isEqual(fmtVal, props.listFormat);
            //     let hasAppliesToTypeChange = props.appliesToEnabled && appliesToTypeValue.value === props.appliesToType;
            //     let okEnabled = hasListFormatChange || hasAppliesToTypeChange;
            //     setOkEnabled(okEnabled);
            // });

            // setOkEnabled(false);

            const getResult = () => {
                let resultFormat = unref(listFormatValue);
                return {
                    listFormat: resultFormat.toDataObject(),
                    isOverridden: resultFormat.defaultOverridden(),
                    appliesToType: unref(appliesToTypeValue)
                };
            };

            expose({
                getResult
            });

            return {
                listFormatValue,
                appliesToTypeValue,
                appliesToTypes
            }
        }
    };
</script>