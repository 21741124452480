import { DateTime } from "luxon";

//update this if any changes are made to the log item object definition so it clears out older logs
const LOG_VERSION = "1.13.3";
const _legacyStorageKey = `hzn_log_storage_${window.location.origin}`;
const LOG_STORE_DEFAULTS = {
    consoleOutput: false,
    storageKey: "hzn_log_storage",
    retainValue: 3,
    retainUnit: "days",
    maxLength: 3000
};

class LogService {
    constructor({
        consoleOutput=LOG_STORE_DEFAULTS.consoleOutput,
        storageKey=LOG_STORE_DEFAULTS.storageKey,
        retainValue=LOG_STORE_DEFAULTS.retainValue,
        retainUnit=LOG_STORE_DEFAULTS.retainUnit,
        maxLength=LOG_STORE_DEFAULTS.maxLength
    }=LOG_STORE_DEFAULTS) {
        this.consoleOutput = consoleOutput;
        this.storageKey = storageKey;
        this.retainValue = retainValue;
        this.retainUnit = retainUnit;
        this.maxLength = maxLength;
        this.enabled = _.parseBool(process.env.VUE_APP_LOG_SERVICE_ENABLED);
        if(this.enabled) return;
        localStorage.removeItem(this.storageKey);
        localStorage.removeItem(_legacyStorageKey);
    }

    getLogs() {
        if(!this.enabled) return [];
        let storageValue = localStorage.getItem(this.storageKey);
        return _.isEmpty(storageValue) ? [] : JSON.parse(storageValue);
    }

    setLogs(logs) {
        if(!this.enabled) return;
        let trimmedLogs = this.trimLogs(logs);
        localStorage.setItem(this.storageKey, JSON.stringify(trimmedLogs));
    }

    trimLogs(logs) {
        const logAge = t => Math.abs(DateTime.fromISO(t).diffNow().as(this.retainUnit));
        let filtered = _.filter(logs, l => l.ver === LOG_VERSION && logAge(l.timestamp) < this.retainValue);
        let listLength = _.size(filtered);
        if(listLength <= this.maxLength) return filtered;
        let dropCount = listLength - this.maxLength;
        return _.drop(filtered, dropCount);
    }

    logInfo(message, ...attachments) {
        this.log({
            level: "info",
            message,
            attachments
        });
    }

    logError(message, error, ...attachments) {
        this.log({
            level: "error",
            message,
            error,
            attachments
        });
    }

    log({ level="info", message="", attachments=[], error=null }) {
        if(!this.enabled) return;
        let logItem = {
            ver: LOG_VERSION,
            level,
            message,
            attachments,
            error,
            pathname: window.location.pathname,
            timestamp: DateTime.now().toISO()
        };

        if(this.consoleOutput) {
            switch(level) {
                case "info":
                    console.info(message, ...attachments);
                    break;
                case "error":
                    console.error(message, error);
                    break;
                default:
                    console.log(message, ...attachments);
                    break;
            }
        }

        let logs = this.getLogs();
        logs.push(logItem);
        this.setLogs(logs);
    }

    report() {
        this.output("table");
    }

    dump() {
        this.output();
    }

    output(type="log") {
        if(!this.enabled) {
            console.log("The log service is currently disabled in this environment.");
            return;
        }
        let logs = this.getLogs();
        switch(type) {
            case "table":
                console.table(logs);
                break;
            default:
                console.log(logs);
                break;
        }
    }
}

const logService = new LogService();

window.__hzn_log_storage = {
    report: () => logService.report(),
    dump: () => logService.dump()
};

export default logService;