<template>
    <div class="rq-sortable-list">
        <div class="sortable-list-instruction">{{instruction}}</div>
        <draggable
            :group="{ name: groupName }"
            item-key="id"
            tag="ul"
            class="list-group sortable-list-group"
            animation="200"
            ghost-class="list-item-ghost"
            v-model="listItems"
            @change="onListOrderChange">
            <template #item="{ element }">
                <li
                    :key="element.id"
                    class="list-group-item">
                    <div class="list-item-content">
                        <span class="list-handle">
                            <FontAwesomeIcon icon="fas fa-grip-vertical" />
                        </span>
                        <span class="list-order">{{element.ordinal}}</span>
                        <span class="list-text">{{element.description}}</span>
                    </div>
                </li>
            </template>
        </draggable>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
    import { ReorderRequestChange } from "@/shared/models/models";
    export default {
        props: {
            orderId: { type: Number, default: 0, required: true },
            items: { type: Array, default: () => [] },
            instruction: { type: String, default: "Drag and drop to re-order." }
        },
        components: { Draggable },
        data () {
            return {
                groupName: _.uniqueId("rq-sortable-list-"),
                listItems: [],
                dragging: false
            };
        },
        watch: {
            items: {
                handler(newValue, oldValue){
                    const self = this;
                    if(_.isEqual(newValue, oldValue) || _.isEqual(newValue, self.listItems)) return;
                    let mapped = _.map(newValue, item => {
                        let result = new ReorderRequestChange(item);
                        if(self.orderId && !result.orderId)
                            result.orderId = self.orderId;
                        return result;
                    });
                    self.listItems = _.sortBy(mapped, "ordinal");
                    self.updateListOrder();
                },
                immediate: true
            }
        },
        methods: {
            onListOrderChange(e) {
                this.updateListOrder();
            },

            updateListOrder() {
                _.forEach(this.listItems, (item, index) => { item.ordinal = index + 1; });
            },

            getChanges() {
                const self = this;
                return _.filter(_.uniq(self.listItems), item => item.fromOrdinal !== item.toOrdinal);
            }
        }
    };
</script>