import { ListType, ListLevelFormat, ListLevelNumberAlignment, ParagraphFirstLineIndent } from "devexpress-richedit";

const BulletTypes = {
    bullet: {
        text: "\u2022",
        fontName: "Symbol"
    },
    circle: {
        text: "o",
        fontName: "Courier New"
    },
    square: {
        text: "\u00A7",
        fontName: "Wingdings"
    },
    arrow: {
        text: "\u00D8",
        fontName: "Wingdings"
    },
    check: {
        text: "\u00FC",
        fontName: "Wingdings"
    }
};

const defaultBulletTypes = [
    BulletTypes.bullet,
    BulletTypes.circle,
    BulletTypes.square,
    BulletTypes.bullet,
    BulletTypes.circle,
    BulletTypes.square,
    BulletTypes.bullet,
    BulletTypes.circle,
    BulletTypes.square,
];

const defaultNumberedFormats = [
    ListLevelFormat.Decimal,
    ListLevelFormat.LowerLetter,
    ListLevelFormat.LowerRoman,
    ListLevelFormat.Decimal,
    ListLevelFormat.LowerLetter,
    ListLevelFormat.LowerRoman,
    ListLevelFormat.Decimal,
    ListLevelFormat.LowerLetter,
    ListLevelFormat.LowerRoman
];

const mapBulletLevels = (bullet) => _.map(defaultBulletTypes, (defBullet, i) => {
    let bulletTypeInfo = (i === 0 && !_.isEmpty(bullet)) ? bullet : defBullet;
    return {
        ilvl: i,
        alignment: ListLevelNumberAlignment.Left,
        format: ListLevelFormat.Bullet,
        ...bulletTypeInfo
    };
});

const mapNumberedLevels = (firstChar, firstFmt) => _.map(defaultNumberedFormats, (f,i) => {
    let charAfter = (i === 0 && !_.isNil(firstChar)) ? firstChar : ".";
    return {
        ilvl: i,
        text: `{${i}}${charAfter}`,
        format: (i === 0 && !_.isNil(firstFmt)) ? firstFmt : f,
        alignment: i & 3 === 2
            ? ListLevelNumberAlignment.Right
            : ListLevelNumberAlignment.Left
    };
});

class ListOption {
    constructor(options) {
        options = options || {};
        this.name = options.name || "";
        this.title = options.title || "";
        this.type = _.isNil(options.type)
            ? ListType.Number
            : options.type;

        let defaultFirstLevelChar = this.type === ListType.Bullet ? "\u00B7" : ".";
        this.firstLevelChar = options.firstLevelChar || defaultFirstLevelChar;
        this.firstLevelFormat = options.firstLevelFormat
            || (this.type === ListType.Bullet ? ListLevelFormat.Bullet : ListLevelFormat.Decimal);
        this.firstLevelFont = options.firstLevelFont || null;

        this.displayText = _.isEmpty(options.displayText)
            ? _.isEmpty(this.title)
                ? ""
                : this.type === ListType.Bullet
                    ? `${this.firstLevelChar} - ${this.title}`
                    : this.title
            : options.displayText;

        this.setMenuItemDisplay();
        this.levels = options.levels || [];

        if(!_.isEmpty(this.levels)) return;

        this.levels = this.type === ListType.Bullet
            ? mapBulletLevels({
                text: this.firstLevelChar,
                fontName: this.firstLevelFont
            })
            : mapNumberedLevels(this.firstLevelChar, this.firstLevelFormat);
    }

    setMenuItemDisplay() {
        if(this.type === ListType.Bullet) {
            this.menuItemDisplay = this.firstLevelChar;
            return;
        }
        let levelVals = {
            [ListLevelFormat.Decimal]: ["1","2","3"],
            [ListLevelFormat.UpperLetter]: ["A","B","C"],
            [ListLevelFormat.LowerLetter]: ["a","b","c"],
            [ListLevelFormat.UpperRoman]: ["I","II","III"],
            [ListLevelFormat.LowerRoman]: ["i","ii","iii"],
        };

        this.menuItemDisplay = _.map(levelVals[this.firstLevelFormat], v => `${v}${this.firstLevelChar}`);
    }

    toSimpleObject() {
        return {
            name: this.name,
            text: this.displayText,
            title: this.title,
            menuItemDisplay: this.menuItemDisplay,
            fontName: this.firstLevelFont,
            listRef: null
        };
    }
}

class DocumentListHelper {
    constructor() {
        this.listOptions = [
            new ListOption({
                name: "defaultBullet",
                title: "Bullet",
                type: ListType.Bullet,
                firstLevelChar: BulletTypes.bullet.text,
                firstLevelFont: BulletTypes.bullet.fontName
            }),
            new ListOption({
                name: "circleBullet",
                title: "Circle",
                type: ListType.Bullet,
                firstLevelChar: BulletTypes.circle.text,
                firstLevelFont: BulletTypes.circle.fontName
            }),
            new ListOption({
                name: "squareBullet",
                title: "Square",
                type: ListType.Bullet,
                firstLevelChar: BulletTypes.square.text,
                firstLevelFont: BulletTypes.square.fontName
            }),
            // new ListOption({
            //     name: "arrowBullet",
            //     title: "Arrow",
            //     type: ListType.Bullet,
            //     firstLevelChar: BulletTypes.arrow.text,
            //     firstLevelFont: BulletTypes.arrow.fontName
            // }),
            // new ListOption({
            //     name: "checkBullet",
            //     title: "Check",
            //     type: ListType.Bullet,
            //     firstLevelChar: BulletTypes.check.text,
            //     firstLevelFont: BulletTypes.check.fontName
            // }),
            new ListOption({
                name: "decimalPeriod",
                title: "1. ... 2. ... 3.",
                displayText: "1. ----------",
            }),
            new ListOption({
                name: "decimalParenthesis",
                title: "1) ... 2) ... 3)",
                displayText: "1) ----------",
                firstLevelChar: ")"
            }),
            new ListOption({
                name: "upperLetterPeriod",
                title: "A. ... B. ... C.",
                displayText: "A. ----------",
                firstLevelFormat: ListLevelFormat.UpperLetter
            }),
            new ListOption({
                name: "upperLetterParenthesis",
                title: "A) ... B) ... C)",
                displayText: "A) ----------",
                firstLevelChar: ")",
                firstLevelFormat: ListLevelFormat.UpperLetter
            }),
            new ListOption({
                name: "lowerLetterPeriod",
                title: "a. ... b. ... c.",
                displayText: "a. ----------",
                firstLevelFormat: ListLevelFormat.LowerLetter
            }),
            new ListOption({
                name: "lowerLetterParenthesis",
                title: "a) ... b) ... c)",
                displayText: "a) ----------",
                firstLevelChar: ")",
                firstLevelFormat: ListLevelFormat.LowerLetter
            }),
            new ListOption({
                name: "upperRomanPeriod",
                title: "I. ... II. ... III.",
                displayText: "I. ----------",
                firstLevelFormat: ListLevelFormat.UpperRoman
            }),
            new ListOption({
                name: "lowerRomanPeriod",
                title: "i. ... ii. ... iii.",
                displayText: "i. ----------",
                firstLevelFormat: ListLevelFormat.LowerRoman
            })
        ];
    }

    get bulletOptions() { return _.filter(this.listOptions, o => o.type === ListType.Bullet); }

    get numberedOptions() { return _.filter(this.listOptions, o => o.type === ListType.Number); }

    getBulletMenuOptions() {
        return _.map(this.bulletOptions, o => o.toSimpleObject());
    }

    getNumberedMenuOptions() {
        return _.map(this.numberedOptions, o => o.toSimpleObject());
    }

    getListOption(name) {
        return _.find(this.listOptions, { name });
    }

    createDxList(editor, listName) {
        let listOption = this.getListOption(listName);
        let list = editor.document.lists.create(listOption.type);
        let levelProps = list.levelProperties;
        let leftIndent = 720;
        _.forEach(levelProps, (lvl, idx) => {
            let levelInfo = listOption.levels[idx];
            lvl.format = levelInfo.format;
            lvl.alignment = levelInfo.alignment;
            lvl.displayFormatString = levelInfo.text;
            // lvl.firstLineIndent = ((idx+1) % 3) === 0 ? 180 : 360;
            lvl.firstLineIndent = 360;
            lvl.firstLineIndentType = ParagraphFirstLineIndent.Hanging;
            lvl.leftIndent = leftIndent;
            lvl.start = 1;
            lvl.separator = "\u0009";
            leftIndent += 720;
            if(_.isEmpty(levelInfo.fontName)) return;
            this.addToDxFontCollection(editor, levelInfo.fontName);
            lvl.fontName = levelInfo.fontName;
        });
        list.levelProperties = levelProps;
        return list;
    }

    addToDxFontCollection(editor, fontName) {
        if(_.isEmpty(fontName)) return;
        let editorFontNames = editor.document.fonts.getAllFontNames();
        if(_.includes(editorFontNames, fontName)) return;
        editor.document.fonts.create(fontName, fontName);
    }
}

export default new DocumentListHelper();