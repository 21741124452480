<template>
    <div class="session-info">
        <dl class="row">
            <dt class="col-sm-5">Enterprise ID:</dt>
            <dd class="col-sm-7">{{item.enterpriseID}}</dd>
        </dl>
        <dl class="row">
            <dt class="col-sm-5">Enterprise Name:</dt>
            <dd class="col-sm-7">{{item.enterpriseName}}</dd>
        </dl>
        <dl class="row">
            <dt class="col-sm-5">Contact Name:</dt>
            <dd class="col-sm-7">{{item.authFName}} {{item.authLName}}</dd>
        </dl>
        <dl class="row">
            <dt class="col-sm-5">Contact Title:</dt>
            <dd class="col-sm-7">{{item.authTitle}}</dd>
        </dl>
    </div>
</template>
<script>
export default {
    name: "CmEnterpriseInfo",
    props: {
        item: {type: Object, required: true}
    },
}
</script>