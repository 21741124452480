<template>
    <div :class="classAttr">
        <slot name="label" v-bind="{ inputId: inputIdAttr }">
            <label v-if="label" :for="inputIdAttr">{{label}}</label>
        </slot>
        <div class="input-group">
            <dx-text-box
                :element-attr="{ id: inputIdAttr, automation_id }"
                class="form-control"
                :spellcheck="false"
                :show-clear-button="showClearButton"
                :placeholder="placeholder"
                :read-only="readOnly"
                :disabled="disabled"
                value-change-event="input"
                max-length="50"
                v-model="inputValue"
                :tab-index="tabindex"
                @valueChanged="onChange"
                @focusOut="onBlur"
            />
            <b-btn
                v-if="searchButtonVisible"
                :automation_id="`${automation_id}_search_button`"
                title="Search Files"
                variant="theme"
                :disabled="readOnly || disabled"
                @click="onSearchClick"
                v-rq-tooltip.hover.top>
                <FontAwesomeIcon icon="fas fa-search" />
            </b-btn>
        </div>
        <rq-loading-indicator
            v-if="enableAutoLookup"
            :offset="loadingOffset"
            for-input
        />
        <rq-validation-feedback
            :offset="feedbackOffset"
            :messages="feedbackMessages"
        />
        <slot></slot>
    </div>
</template>
<script>
    import { useVuelidate } from "@vuelidate/core";
    import { requiredIf } from "@vuelidate/validators";
    import SelectExistingOrder from "@file-shared/components/SelectExistingOrder";
    const ValidationMode = { EXISTS: "exists", UNIQUE: "unique" };
    export default {
        name: "FileNumberInput",
        props: {
            automation_id: { type: String, required: true, default: "" },
            allowLockedFiles: { type: Boolean, default: true },
            modelValue: { type: String, default: null },
            orderId: { type: Number, default: null },
            inputId: { type: String, default: null },
            label: { type: String, default: null },
            placeholder: { type: String, default: "File #" },
            validationMode: { type: String, default: "exists" },
            required: { type: Boolean, default: false },
            validators: { type: Object, default: null },
            hasError: { type: Boolean, default: false },
            isProcessing: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            enableAutoLookup: { type: Boolean, default: true },
            showSearchButton: { type: Boolean, default: false },
            showClearButton: { type: Boolean, default: false },
            minLength: { type: Number, default: 3 },
            searchColumns: { type: Array, default: () => ["gfNo", "status", "openDate", "closeDate", "regionID", "branchID", "lenderCompanyID", "bankCompanyID"] },
            orderFilter: { type: Array, default: () => [] },
            statusFilter: { type: String, default: null },
            tabindex: { type: Number, default: 0 }
        },
        setup() {
            return {
                v$: useVuelidate()
            };
        },
        data () {
            const self = this;
            return {
                inputValue: self.modelValue,
                originalValue: self.modelValue,
                hasErrorValue: false,
                orderData: {
                    ordersID: self.orderId,
                    gfNo: self.modelValue
                },
                isValidating: false
            };
        },
        computed: {
            inputIdAttr() { return this.inputId || this.automation_id; },
            classAttr() {
                return {
                    'form-group file-number-input': true,
                    'form-required': this.required,
                    'form-loading': this.isValidating || this.isProcessing,
                    'has-error': this.hasErrorValue
                };
            },
            validateExists() { return this.validationMode === ValidationMode.EXISTS; },
            validateUnique() { return this.validationMode === ValidationMode.UNIQUE; },
            searchButtonVisible() { return this.showSearchButton && !this.validateUnique; },
            feedbackVisible() { return this.hasErrorValue || (this.required && _.parseNumber(this.orderIdValue, 0) == 0); },
            loadingOffset() {
                let result = 0;
                if(this.searchButtonVisible) result += 40;
                if(this.feedbackVisible) result += 40;
                if(this.showClearButton) result += 30;
                return result;
            },
            feedbackOffset() {
                let result = 0;
                if(this.searchButtonVisible) result += 40;
                if(this.showClearButton && !_.isEmpty(this.inputValue)) result += 30;
                return result;
            },
            feedbackMessages() {
                const self = this;
                let messages = {
                    "File Number is required": self.v$.inputValue.required.$invalid,
                    [`File Number must have at least ${self.minLength} characters`]: self.v$.inputValue.minLengthValidator.$invalid,
                    "Restricted Access, File is locked": self.v$.inputValue.fileLockValidator.$invalid,
                    "File Number is already in use": self.v$.inputValue.uniqueValidator.$invalid,
                    "File Number does not exist": self.v$.inputValue.existsValidator.$invalid
                };
                if(!_.isEmpty(self.validators)) {
                    _.each(self.validators, (obj, key) => {
                        messages[obj.message] = !_.getBool(self.v$.inputValue, key) && (!self.required || _.getBool(self.v$.inputValue, "required", true));
                    });
                }
                return messages;
            },
            hasValue() { return !_.isEmpty(_.trim(this.inputValue)); },
            hasNewValue() { return this.hasValue && this.originalValue !== this.inputValue; },
            hasValidLength() { return _.size(_.trim(this.inputValue)) >= this.minLength; },
            orderIdValue() { return _.getNumber(this, "orderData.ordersID", null); },
            isLockedValue() { return _.getBool(this, "orderData.isLocked"); },
            gfNoValue() { return _.get(this, "orderData.gfNo", null); },
            isProcessingValue() {
                return this.isValidating
                    || (this.hasValidLength && !_.isEmpty(this.gfNoValue) && _.toLower(this.inputValue) !== _.toLower(this.gfNoValue));
            }
        },
        watch: {
            modelValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.inputValue) return;
                this.reset(newValue);
            },
            inputValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
                if (this.enableAutoLookup) this.validateFileNumber(newValue);
            },
            hasError(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.hasErrorValue) return;
                this.hasErrorValue = newValue;
            },
            hasErrorValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.hasError) return;
                this.$emit("update:hasError", newValue);
            },
            isProcessingValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.isProcessing) return;
                this.$emit("update:isProcessing", newValue);
            },
            orderIdValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.orderId) return;
                this.$emit("update:orderId", newValue);
            }
        },
        validations() {
            const self = this;
            let inputValue = {
                // if inputvalue is required this is set from outside the component, defaults to false
                required: requiredIf(self.required),

                // for text min length, default to 3
                // not sure why enableAutoLookup is needed.
                minLengthValidator: val => !self.enableAutoLookup || self.minLength === 0 || _.isEmpty(_.trim(val)) || _.size(_.trim(val)) >= self.minLength,

                //File Number is already in use - when mode is validateUnique - used for creating new/edit
                uniqueValidator: () => !self.enableAutoLookup || !self.hasNewValue || !self.validateUnique || _.isNil(self.orderIdValue) || self.orderIdValue === 0,

                //File Number does not exist - when mode is validateExists - used for searching
                existsValidator: () => !self.enableAutoLookup || !self.hasNewValue || !self.validateExists || _.isNil(self.orderIdValue) || self.orderIdValue > 0,

                // allowLockedFiles short circuits validation for locked files, default true
                // if allowLockedFiles is false then it will check if a file is locked.
                // used when moving files to a file, they cant do it if its locked.
                fileLockValidator: () => self.allowLockedFiles || !self.isLockedValue,

                // hasError is set outside of the component
                externalError: () => self.enableAutoLookup || !self.hasError
            };

            // for custom validators or validators added from outside the component, defaults to null
            if(!_.isEmpty(self.validators)) {
                _.assign(inputValue, _.mapValues(self.validators, "validator"));
            }
            return { inputValue };
        },
        methods: {
            validateFileNumber(val) {
                const self = this;
                //no need to validate
                if(_.isEmpty(val) || val.length < self.minLength) {
                    self.setOrderData();
                    self.isValidating = false;
                    return Promise.resolve(self.updateValidation());
                }
                if(val === self.gfNoValue){
                    self.isValidating = false;
                    return Promise.resolve(self.updateValidation());
                }

                self.isValidating = true;
                return self.processValidation(val);
            },
            processValidation: _.debounce(function (val) {
                const self = this;
                return self.$api.OrdersApi.getOrderEntity(val)
                    .then(result => {
                        let orderId = _.getNumber(result, "ordersID", 0);

                        if (orderId === 0) {
                            self.setOrderData();
                            return;
                        }
                        self.setOrderData(result);
                        self.$emit("file-selected", { data: result });
                    })
                    .finally(() => {
                        self.isValidating = false;
                        return self.updateValidation();
                    });
            }, 500, { leading: false, trailing: true }),
            setOrderData(val={}) {
                const self = this;
                if(_.isEmpty(val)) {
                    self.orderData = self.hasValue ? { ordersID: 0, gfNo: self.inputValue, isLocked: false } : {};
                    return;
                }
                self.orderData = _.clone(val);
            },
            updateValidation() {
                const self = this;
                self.v$.inputValue.$touch();
                self.hasErrorValue = self.v$.inputValue.$error;
                return self.hasErrorValue;
            },
            reset(inputVal=null, orderIdVal=null, lockedVal=false) {
                const self = this;
                if(inputVal !== self.gfNoValue) {
                    self.orderData = _.isEmpty(inputVal) ? {} : {
                        ordersID: orderIdVal || 0,
                        gfNo: inputVal,
                        isLocked: lockedVal
                    };
                }
                self.inputValue = inputVal;
                self.v$.inputValue.$reset();
                self.hasErrorValue = false;
            },
            onBlur(){
                this.inputValue = _.trim(this.inputValue);
                if (this.required) this.updateValidation();
            },
            onChange(e){
                this.$emit('valueChanged', e);
            },
            onSearchClick() {
                const self = this;
                self.$dialog.open({
                    title: "Select File",
                    height: "80%",
                    minHeight: 700,
                    width: "80%",
                    minWidth: 1200,
                    closeOnEsc: true,
                    component: SelectExistingOrder,
                    props: {
                        searchTerm: self.inputValue,
                        visibleColumns: self.searchColumns,
                        orderFilter: self.orderFilter,
                        statusFilter: self.statusFilter
                    },
                    onOk(e) {
                        let result = e.component.dialogResult();
                        self.orderData = _.clone(result);
                        self.isValidating = false;
                        self.inputValue = self.orderData.gfNo;
                        self.updateValidation();
                        self.$emit("file-selected", { data: result });
                        return true;
                    }
                });
            }
        }
    };
</script>
