import { ref, computed, unref, nextTick, onMounted, onBeforeUnmount } from "vue";
import { GlobalEventManager } from "@/app.events";

const POPOVER_DEFAULTS = {
    target: null,
    title: "",
    description: "",
    detail: {},
    visible: false
};

export const useIconPopover = ({ name, mergeDetail=false }) => {
    const popoverInfo = ref(POPOVER_DEFAULTS);
    const registerGlobalEvents = computed(() => _.isEmpty(unref(name)));
    const popoverEvents = computed(() => registerGlobalEvents.value ? {
        show: `${unref(name)}::show-popover`,
        clear: `${unref(name)}::clear-popover`
    } : {});

    function clearPopover() {
        popoverInfo.value = POPOVER_DEFAULTS;
    }
    async function showPopover({ target=null, title=null, description=null, detail=null }=POPOVER_DEFAULTS) {
        if(popoverInfo.value.visible
            && popoverInfo.value.target !== target) clearPopover();

        if(_.isNil(target)
            || (_.isNil(title)
                && _.isNil(description)
                && _.isNil(detail))) return;

        await nextTick();

        let info = {
            target,
            title,
            description,
            visible: true
        };

        popoverInfo.value = mergeDetail
            ? { ...info, ...detail }
            : { ...info, detail };
    }

    const setEventHandler = (state, ...params) => GlobalEventManager[state](...params);
    const toggleEventHandlers = state => {
        if(!registerGlobalEvents.value) return;
        setEventHandler(state, popoverEvents.value.show, showPopover);
        setEventHandler(state, popoverEvents.value.clear, clearPopover);
    };

    onMounted(() => {
        toggleEventHandlers("on");
    });

    onBeforeUnmount(() => {
        toggleEventHandlers("off");
    })

    return {
        popoverInfo,
        showPopover,
        clearPopover
    };
}

export const useGridIconPopover = gridName => useIconPopover({ name: gridName });