import { FILE_INFORMATION_ACTIONS } from "./actions";
import { FILE_INFORMATION_MUTATIONS } from "./mutations";

const CONTACT_VIEW = {
    CARD_VIEW: 'card',
    GRID_VIEW: 'grid'
};

const getDefaultState = () => {
    return {
        fileContactsDefaultView: CONTACT_VIEW.CARD_VIEW
    };
};

const state = getDefaultState();

const getters = {
    defaultContactView: state => state.fileContactsDefaultView
};

const actions = {
    [FILE_INFORMATION_ACTIONS.SET_DEFAULT_FILE_CONTACT_VIEW]({state, commit, dispatch}, view) {
        commit(FILE_INFORMATION_MUTATIONS.SET_DEFAULT_FILE_CONTACT_VIEW, view);
    }
};

const mutations = {
    [FILE_INFORMATION_ACTIONS.SET_DEFAULT_FILE_CONTACT_VIEW](state, value) { state.fileContactsDefaultView = value; }
};

export default { state, getters, actions, mutations };
