import { DOCUMENT_ACTIONS } from "../actions";
import { DOCUMENT_MUTATIONS, ORDER_MUTATIONS } from "../mutations";
import { BASIC_ACTION, ACTION_WITH_COMMIT, removeFromStateArrayByValue } from "../helpers";
import DocumentsApi from "../../api/documents";
import { DocumentModel, DocumentEditorModel, DocumentPromptInfo } from "@documents/models";

const state = {
    queuedDocuments: [],
    activePromptDocuments: [],
    savedActivePrompts: [],
    savedPrompts: [],
    generatedDocuments: [],
    loanID: 0,
    cssMainID: 0,
    invoiceID: 0,
    standardLanguageCategories: []
};

const getters = {
    getDocumentEditorModel: (state) => (ordersID, sendChecksToFileScan = false, fileScanCheckCategory = null) => {
        let result = new DocumentEditorModel({
            orderId: ordersID,
            loanID: state.loanID,
            cssMainID: state.cssMainID,
            invoiceID: state.invoiceID,
            sendChecksToFileScan: sendChecksToFileScan,
            fileScanCheckCategory: fileScanCheckCategory
        });
        let queue = _.cloneDeep(state.queuedDocuments);
        let activePrompts = _.cloneDeep(state.activePromptDocuments);
        result.documents = _.map(queue, d => {
            let doc = new DocumentModel(d);
            if (state.activePromptDocuments.length > 0) {
                let promptsDoc = _.find(activePrompts, d => d.orderDocumentId === doc.orderDocumentID);
                if (!promptsDoc) return;
                doc.prompts = _.map(promptsDoc.prompts, p => new DocumentPromptInfo({ ordersID, scopeDocumentDescription: doc.description, ...p }));
            }
            return doc;
        });
        return result;
    }
};

const actions = {

    [DOCUMENT_ACTIONS.GET_UNSAVED_PROMPTS] (context, orderDocumentIds) {
        let orderId = context.getters.orderId;
        return ACTION_WITH_COMMIT(context, DocumentsApi.getUnsavedOrderDocumentPrompts(orderId, orderDocumentIds), DOCUMENT_MUTATIONS.SET_ACTIVE_PROMPT_DOCUMENTS);
    },

    [DOCUMENT_ACTIONS.ADD_DOC_TO_ORDER] (context, { documentTemplateID, clearState=false }) {
        let orderId = context.getters.orderId;
        if(clearState) {
            context.dispatch(DOCUMENT_ACTIONS.CLEAR_STATE);
        }
        return ACTION_WITH_COMMIT(context, DocumentsApi.addDocumentToOrder(documentTemplateID, orderId), DOCUMENT_MUTATIONS.ADD_DOC_TO_QUEUE);
    },

    [DOCUMENT_ACTIONS.ADD_TO_ORDER] (context, { docs, pkgs }) {
        let orderId = context.getters.orderId;
        let docIds = _.map(docs, "documentTemplateID");
        let pkgIds = _.map(pkgs, "documentPackageID");

        let requests = [];
        if(!_.isEmpty(docIds)) requests.push(DocumentsApi.addDocumentsToOrder(docIds, orderId));
        if(!_.isEmpty(pkgIds)) requests.push(DocumentsApi.addPackagesToOrder(pkgIds, orderId));

        return BASIC_ACTION(context, Promise.all(requests));
    },

    [DOCUMENT_ACTIONS.REMOVE_FROM_ORDER] (context, { docs, pkgs }) {
        let orderId = context.getters.orderId;
        let requests = [];
        _.forEach(docs, d => requests.push(DocumentsApi.removeDocumentFromOrder(d.orderDocumentID, orderId)));
        _.forEach(pkgs, p => requests.push(DocumentsApi.removePackageFromOrder(p.documentPackageID, orderId)));
        return BASIC_ACTION(context, Promise.all(requests));
    },

    [DOCUMENT_ACTIONS.REMOVE_DOC_FROM_ORDER] (context, orderDocumentID) {
        let orderId = context.getters.orderId;
        return BASIC_ACTION(context, DocumentsApi.removeDocumentFromOrder(orderDocumentID, orderId));
    },

    [DOCUMENT_ACTIONS.REMOVE_PKG_FROM_ORDER] (context, documentPackageID) {
        let orderId = context.getters.orderId;
        return BASIC_ACTION(context, DocumentsApi.removePackageFromOrder(documentPackageID, orderId));
    },

    [DOCUMENT_ACTIONS.ADD_LIST_TO_QUEUE](context, { docs=[], pkgs=[] }) {
        if(_.isEmpty(docs) && _.isEmpty(pkgs)) return;

        let pendingOrderItems = {
            docs: [],
            pkgs: []
        };

        let orderDocs = [];

        if(!_.isEmpty(pkgs)) {
            orderDocs = _.flatMap(_.filter(pkgs, "isOrderItem"), pkg => _.map(pkg.packageDocuments, "data"));
            pendingOrderItems.pkgs = _.filter(pkgs, p => !p.isOrderItem);
            pendingOrderItems.docs = _.filter(orderDocs, { orderDocumentID: 0 });

            if(pendingOrderItems.docs.length)
                orderDocs = _.filter(orderDocs, o => _.parseNumber(o.orderDocumentID, 0) != 0);
        }

        if(!_.isEmpty(docs)) {
            orderDocs.push(..._.map(_.filter(docs, "isOrderItem"), "data").slice());
            pendingOrderItems.docs = [...pendingOrderItems.docs, ..._.filter(docs, d => !d.isOrderItem)];
        }

        const addToQueue = items => _.forEach(_.sortBy(items, ["documentPackageID", "packageItemSequence"]), doc => {
            doc.clientKey = _.uniqueId();
            context.commit(DOCUMENT_MUTATIONS.ADD_DOC_TO_QUEUE, doc);
        });

        if(!_.isEmpty(pendingOrderItems.docs) || !_.isEmpty(pendingOrderItems.pkgs)) {
            return context.dispatch(DOCUMENT_ACTIONS.ADD_TO_ORDER, pendingOrderItems)
                .then(results => {
                    let addedResults = _.map(_.flatMap(results), d => ({ ...d, documentSource: _.includes(_.map(pendingOrderItems.docs, "documentTemplateID"), d.documentTemplateID) ? 1 : 2}));
                    _.each(addedResults, ar => {
                        let foundDoc = _.find(docs, d => d.documentTemplateID === ar.documentTemplateID);
                        if(foundDoc){
                            ar.userSecurityAccess = foundDoc.userSecurityAccess;
                        }
                        else{
                            _.each(pkgs, pkg => {
                                let foundPkgDoc = _.find(pkg.packageDocuments, pd => pd.documentTemplateID === ar.documentTemplateID);
                                if(foundPkgDoc){
                                    ar.userSecurityAccess = foundPkgDoc.userSecurityAccess;
                                }
                            });
                        }
                    });
                    let allResults = _.concat(orderDocs, addedResults);
                    addToQueue(allResults);
                });
        }

        if(!_.isEmpty(orderDocs)) {
            addToQueue(orderDocs);
        }
        return Promise.resolve(orderDocs.slice());
    },

    [DOCUMENT_ACTIONS.REMOVE_FROM_QUEUE](context, docs) {
        let queue = _.cloneDeep(state.queuedDocuments);
        _.remove(queue, d => _.some(docs, rd => rd.documentTemplateID === d.documentTemplateID && (rd.sequence === 999 || rd.sequence === d.sequence)));
        _.updateAll(queue, "sequence", (value, index) => index + 1);
        context.commit(DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS, queue);
        return Promise.resolve(docs);
    },

    [DOCUMENT_ACTIONS.REORDER_QUEUE](context, { diff, indexes }) {
        if(_.isEmpty(context.state.queuedDocuments)) return;

        let queue = _.cloneDeep(context.state.queuedDocuments);
        let items = _.pullAt(queue, indexes);

        let newIndexes = _.map(indexes, idx => idx + diff);

        _.forEach(newIndexes, (idx, i) => { queue.splice(idx, 0, items[i]); });
        _.forEach(queue, (value, index) => { value.sequence = index + 1; });

        context.commit(DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS, queue);
    },

    [DOCUMENT_ACTIONS.ADD_CHECKS_TO_QUEUE](context, { ordersID, checksList, checkDocumentID, noSignaturePrint }) {
        context.commit(ORDER_MUTATIONS.SET_ORDER_ID, ordersID);
        let promises = _.map(checksList, async checkInfo => {
            let result = await DocumentsApi.addDocumentToOrder(checkDocumentID, ordersID);
            result.checkID = checkInfo.checksID;
            result.checkNumber = checkInfo.checkNumber;
            if (!_.isNil(noSignaturePrint)) {
                result.noSignaturePrint = _.parseBool(noSignaturePrint);
            }
            return result;
        });
        return BASIC_ACTION(context, Promise.all(promises), results => {
            context.commit(DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS, results);
        });
    },
    
    async [DOCUMENT_ACTIONS.ADD_CHECK_COVER_LETTER_TO_QUEUE](context, { ordersID, checkID, documentTemplateID }) {
        await context.dispatch(DOCUMENT_ACTIONS.CLEAR_STATE);
        context.commit(ORDER_MUTATIONS.SET_ORDER_ID, ordersID);
        let result = await DocumentsApi.addDocumentToOrder(documentTemplateID, ordersID);
        result.checkID = checkID;
        context.commit(DOCUMENT_MUTATIONS.ADD_DOC_TO_QUEUE, result);  
    },

    [DOCUMENT_ACTIONS.ADD_DEPOSIT_SLIPS_TO_QUEUE](context, { orderId, depositSlipIDs, docTemplateId }) {
        context.commit(ORDER_MUTATIONS.SET_ORDER_ID, orderId);
        let promises = _.map(depositSlipIDs, async id => {
            let result = await DocumentsApi.addDocumentToOrder(docTemplateId, orderId);
            result.depositSlipID = id;
            return result;
        });
        return BASIC_ACTION(context, Promise.all(promises), results => {
            context.commit(DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS, results);
        });
    },

    [DOCUMENT_ACTIONS.SAVE_PROMPT_VALUE](context, prompt) {
        if (!_.startsWith(prompt.promptName, "@")) prompt.promptName = `@${prompt.promptName}`;

        if(_.some(context.state.savedActivePrompts, sp => sp.promptName === prompt.promptName && sp.promptValue === prompt.promptValue && sp.isGlobal))
            return Promise.resolve(prompt);

        context.commit(DOCUMENT_MUTATIONS.ADD_SAVED_ACTIVE_PROMPT, prompt);
        return DocumentsApi.savedPromptValue(prompt);
    },

    [DOCUMENT_ACTIONS.GET_STANDARD_LANGUAGE_CATEGORIES](context, refresh=true) {
        if(!refresh && !_.isEmpty(context.state.standardLanguageCategories)) return context.state.standardLanguageCategories;
        return ACTION_WITH_COMMIT(context, DocumentsApi.getStandardLanguageCategories(), DOCUMENT_MUTATIONS.SET_STANDARD_LANGUAGE_CATEGORIES);
    },

    [DOCUMENT_ACTIONS.UPDATE_MERGE_OPTIONS](context, { loanId, cssMainId, invoiceId }) {
        context.commit(DOCUMENT_MUTATIONS.SET_LOAN_ID, loanId);
        context.commit(DOCUMENT_MUTATIONS.SET_CSS_MAIN_ID, cssMainId);
        context.commit(DOCUMENT_MUTATIONS.SET_INVOICE_ID, invoiceId);
        context.commit(DOCUMENT_MUTATIONS.SET_SAVED_ACTIVE_PROMPTS, []);
    },

    [DOCUMENT_ACTIONS.CLEAR_STATE](context, exclusions={queue: false, loanId: false, cssMainId: false, invoiceId: false, activePromptDocuments: false, savedActivePrompts: false}) {
        if(!exclusions?.queue)
            context.commit(DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS, []);
        if(!exclusions?.loanId)
            context.commit(DOCUMENT_MUTATIONS.SET_LOAN_ID, null);
        if(!exclusions?.cssMainId)
            context.commit(DOCUMENT_MUTATIONS.SET_CSS_MAIN_ID, null);
        if(!exclusions?.invoiceId)
            context.commit(DOCUMENT_MUTATIONS.SET_INVOICE_ID, null);
        if(!exclusions?.activePromptDocuments)
            context.commit(DOCUMENT_MUTATIONS.SET_ACTIVE_PROMPT_DOCUMENTS, []);
        if(!exclusions?.savedActivePrompts)
            context.commit(DOCUMENT_MUTATIONS.SET_SAVED_ACTIVE_PROMPTS, []);
    }
};

const mutations = {
    [DOCUMENT_MUTATIONS.SET_LOAN_ID](state, value) { state.loanID = value; },
    [DOCUMENT_MUTATIONS.SET_CSS_MAIN_ID](state, value) { state.cssMainID = value; },
    [DOCUMENT_MUTATIONS.SET_INVOICE_ID](state, value) { state.invoiceID = value; },
    [DOCUMENT_MUTATIONS.SET_QUEUED_DOCUMENTS](state, value) {  _.set(state, "queuedDocuments", value); },
    [DOCUMENT_MUTATIONS.ADD_LIST_TO_QUEUE](state, items) {
        _.forEach(items, (item,index) => {
            let itemData = _.toPlainObject(item);
            itemData.sequence = state.queuedDocuments.length + index + 1;
            state.queuedDocuments.push(itemData);
        });
    },
    [DOCUMENT_MUTATIONS.ADD_DOC_TO_QUEUE](state, item) {
        let itemData = _.toPlainObject(item);
        itemData.sequence = state.queuedDocuments.length + 1;
        state.queuedDocuments.push(itemData);
    },
    [DOCUMENT_MUTATIONS.REMOVE_DOC_FROM_QUEUE](state, id) {
        let success = removeFromStateArrayByValue(state.queuedDocuments, "orderDocumentID", id);
        if(!success) return;
        _.forEach(state.queuedDocuments, (value, index) => { value.sequence = index + 1; });
    },
    [DOCUMENT_MUTATIONS.REMOVE_DOC_FROM_QUEUE_AT_INDEX](state, itemIndex) {
        if(_.isEmpty(state.queuedDocuments)) return;
        if(itemIndex < 0) return;
        state.queuedDocuments.splice(itemIndex, 1);
        _.forEach(state.queuedDocuments, (value, index) => { value.sequence = index + 1; });
    },
    [DOCUMENT_MUTATIONS.REMOVE_PKG_FROM_QUEUE](state, id) {
        let success = removeFromStateArrayByValue(state.queuedDocuments, "documentPackageID", id);
        if(!success) return;
        _.forEach(state.queuedDocuments, (value, index) => { value.sequence = index + 1; });
    },
    [DOCUMENT_MUTATIONS.SET_ACTIVE_PROMPT_DOCUMENTS](state, value) { _.set(state, "activePromptDocuments", value); },
    [DOCUMENT_MUTATIONS.SET_SAVED_ACTIVE_PROMPTS](state, value) { _.set(state, "savedActivePrompts", value); },
    [DOCUMENT_MUTATIONS.ADD_SAVED_ACTIVE_PROMPT](state, value) { state.savedActivePrompts.push(value); },
    [DOCUMENT_MUTATIONS.SET_STANDARD_LANGUAGE_CATEGORIES](state, value) { _.set(state, "standardLanguageCategories", value); }
};

export default { state, getters, actions, mutations };
