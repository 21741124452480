export class NavItem {
    constructor(options){
        options = options || {};
        this.id = options.id || _.uniqueId("sb-nav-item-");
        this.clientKey = options.clientKey || _.uniqueId();
        this.accessKey = options.accessKey || null;
        this.adminOnly = _.parseBool(options.adminOnly);
        this.name = options.name || "";
        this.label = options.label || "";
        this.sublabel = options.sublabel || "";
        this.path = options.path || "#";
        this.icon = options.icon || null;
        this.badge = options.badge || null;
        this.theme = options.theme || "default";
        this.open = _.parseBool(options.open);
        this.level = _.parseNumber(options.level, 1);
        this.visibleMinimized = _.parseBool(options.visibleMinimized);
        this.matchRoutePrefix = options.matchRoutePrefix || "";
        this.exactMatch = _.parseBool(options.exactMatch);
        this.navigate = _.parseBool(options.navigate);
        this.action = options.action || null;
        this.disabled = _.parseBool(options.disabled);
        this.inactive = _.parseBool(options.inactive);
        this.featureFlag = options.featureFlag || "";

        let childList = options.children || [];
        this.children = childList.length > 0
            ? _.map(childList, c => new NavItem({ ...c, theme: c.theme || this.theme, level: this.level + 1 }))
            : null;
    }

    get isInactive() { return this.inactive || (this.hasChildren && !this.hasActiveChildren); }
    get hasChildren() { return !_.isEmpty(this.children); }
    get activeChildren() { return _.filter(this.children, item => !item.isInactive); }
    get hasActiveChildren() { return !_.every(this.children, "isInactive"); }
}
