export const loadScript = (src, type=null) => new Promise(resolve => {
    let existingScript = document.querySelector(`script[src='${src}']`);
    if(!_.isNil(existingScript)) {
        resolve();
        return;
    }
    let scriptElement = document.createElement("script")
    scriptElement.setAttribute("src", src);
    if(type) scriptElement.setAttribute("type", type);
    scriptElement.addEventListener("load", function() {
        resolve();
    });
    document.head.appendChild(scriptElement);
});