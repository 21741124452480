import { MainLayout } from "@/layouts";

// import stand-alone pages
import { ComingSoon, Error401, Error404 } from "@/pages";

// import modules
import HomeModule from "../modules/home";
import OrderModule from "../modules/file";
import ConfigurationModule from "../modules/configuration";
import EscrowAccountingModule from "../modules/escrow-accounting";
import ReportsModule from "../modules/reporting";
import DemoModule from "../modules/demos";

export const routes = [
    HomeModule,
    OrderModule,
    EscrowAccountingModule,
    ConfigurationModule,
    ReportsModule,
    DemoModule,
    {
        path: "/coming-soon",
        component: MainLayout,
        meta: { skipSaveOnLeave: true },
        children: [
            { name: "coming-soon", path: "", component: ComingSoon, meta: { label: "Coming Soon", skipSaveOnLeave: true } }
        ]
    },
    {
        path: "/NotAuthorized",
        component: MainLayout,
        meta: { label: "401", skipSaveOnLeave: true },
        children: [
            { name: "NotAuthorized", path: "", component: Error401, meta: { label: "Not Authorized", skipSaveOnLeave: true, requiresAuth: false }, props: true }
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        component: MainLayout,
        children: [
            { name: "404", path: "", component: Error404, meta: { label: "Page Not Found", skipSaveOnLeave: true, requiresAuth: false } }
        ]
    }
];
