/*
    Vue directive to select all content when child inputs are focused
    use by adding "v-focus-select-all" to input element
*/
const setElementBinding = function(el, listenerAction) {
    const editableTypes = ["date", "email", "number", "password", "tel", "text", "url"];
    const onDirectiveInputFocus = function(e) {
        if(_.parseBool(e.target.readOnly)){
            e.target.setSelectionRange(0,0);
        }
        else {
            e.target.select();
        }
    };

    let inputs = el.tagName === "INPUT" ? [el] : el.getElementsByTagName("input");
    _.forEach(inputs, inputElement => {
        let inputType = (inputElement.type || "").toLowerCase();
        if(!_.includes(editableTypes, inputType)) return;
        let actionName = `${listenerAction}EventListener`;
        inputElement[actionName]("focus", onDirectiveInputFocus);
    });
};
export default {
    mounted(el) {
        setElementBinding(el, "add");
    },
    unmounted(el) {
        setElementBinding(el, "remove");
    }
};