export class TreeViewItemModel {
    constructor(options={}, skipChildMapping=false) {
        this.key = options.key || _.uniqueId("tree-view-item-");
        this.name = options.name || "NO NAME PROVIDED";
        this.children = options.children || [];
        this.isExpanded = _.parseBool(options.isExpanded);
        this.isSelected = _.parseBool(options.isSelected);
        this.selectable = _.parseBool(options.selectable, true);
        if(!skipChildMapping) this.mapChildren(options.children);
        this.hasChildren = !_.isEmpty(this.children) || _.parseBool(options.hasChildren);
    }
    get displayName() { return this.name; }
    mapChildren(items=[]) {
        this.children = _.isEmpty(items) ? [] : _.map(items, c => new TreeViewItemModel(c));
    }
}