//shared
export const SET_PAGE = "setPage";
export const GET_LOOKUPS = "getLookups";
export const START_WAIT = "rqBusy_StartWait";
export const END_WAIT = "rqBusy_EndWait";
export const END_ALL_WAITS = "rqBusy_EndAll";
export const SET_WAIT_MESSAGE = "rqBusy_SetMessage";

// authentication
export const SET_IDENTITY_AUTH = "setIdentityAuth";
export const INITIALIZE_SESSION = "initializeSession";
export const CHECK_AUTH = "checkAuth";
export const GET_USER_SETTINGS = "getUserSettings";
export const SET_USER_SETTINGS = "setUserSettings";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_TOKEN = "updateToken";
export const UPDATE_SESSION_USER = "updateSessionUser";
export const APPEND_USER_DICTIONARY = "appendUserDictionary";
export const GET_CURRENT_SYSTEM = "getCurrentSystem";
export const GET_USER_REGIONS = "getUserRegions";
export const AUTH_ACTIONS = {
    SET_IDENTITY_AUTH,
    INITIALIZE_SESSION,
    CHECK_AUTH,
    GET_USER_SETTINGS,
    SET_USER_SETTINGS,
    LOGIN,
    LOGOUT,
    UPDATE_TOKEN,
    UPDATE_SESSION_USER,
    APPEND_USER_DICTIONARY,
    GET_CURRENT_SYSTEM,
    GET_USER_REGIONS
};

//Navigation
export const SET_NAV_ITEM_INACTIVE = "setNavItemInactive";
export const SET_NAV_ITEM_ACTIVE = "setNavItemActive";
export const SET_NAV_ITEM_DISABLED = "setNavItemDisabled";
export const SET_NAV_ITEM_ENABLED = "setNavItemEnabled";
export const UPDATE_INACTIVE_NAV_ITEMS = "updateInactiveNavItems";
export const UPDATE_DISABLED_NAV_ITEMS = "updateDisabledNavItems";
export const UPDATE_ORDER_NAV = "updateOrderNav";
export const REFRESH_NAV = "refreshNav";
export const GET_UNREAD_ALERT_COUNT = "getUndreadAlertCount";
export const NAV_ACTIONS = {
    SET_NAV_ITEM_INACTIVE,
    SET_NAV_ITEM_ACTIVE,
    SET_NAV_ITEM_DISABLED,
    SET_NAV_ITEM_ENABLED,
    UPDATE_INACTIVE_NAV_ITEMS,
    UPDATE_DISABLED_NAV_ITEMS,
    UPDATE_ORDER_NAV,
    REFRESH_NAV,
    GET_UNREAD_ALERT_COUNT
};

// system
export const GET_SYSTEM_DATA = "getSystemData";
export const GET_SYSTEM_DEFAULTS = "getSystemDefaults";
export const GET_SYSTEM_LOOKUPS = "getSystemLookups";
export const REFRESH_LOOKUP = "refreshSystemLookup";
export const SYSTEM_ACTIONS = {
    GET_SYSTEM_DATA,
    GET_SYSTEM_DEFAULTS,
    GET_SYSTEM_LOOKUPS,
    REFRESH_LOOKUP
};

//Loans
export const DELETE_LOAN = "deleteLoans";
export const GET_LOANS = "getLoans";
export const SAVE_LOAN = "saveLoan";
export const REORDER_LOANS = "reorderLoans";

export const LOAN_ACTIONS = {
    DELETE_LOAN,
    GET_LOANS,
    SAVE_LOAN,
    REORDER_LOANS
};

//Invoices
export const GET_INVOICES = "getInvoices";

export const INVOICE_ACTIONS = {
    GET_INVOICES
};

// commitment
export const ADD_ASSIGNED_LANGUAGE_PACKAGES = "addAssignedLanguagePackage";
export const CLEAR_POLICY_NUMBERS = "clearPolicyNumbers";
export const CLEAR_STATE = "clearCommitmentState";
export const DELETE_SECTION_ASSIGNED_LANGUAGES = "deleteSectionAssignedLanguages";
export const DELETE_ASSIGNED_LANGUAGE = "deleteAssignedLanguage";
export const GET_ASSIGNED_LANGUAGES = "getAssignedLanguages";
export const GET_COMMITMENT_DATA = "getCommitmentData";
export const GET_CUSTOM_TABS = "getCustomTabs";
export const GET_SCHEDULE_D = "getScheduleD";
export const GET_STANDARD_LANGUAGE_SECTIONS = "getStandardLanguageSections";
export const GET_TITLE_PRODUCTION_DATA = "getTitleProductionData";
export const GET_USERS_WITH_SIGNATURES = "getUsersWithSigantures";
export const FETCH_ASSIGNED_LANGUAGE_HTML = "fetchAssignedLanguageHtml";
export const REMOVE_POLICY_OVERRIDE = "removePolicyOverride";
export const SAVE_ASSIGNED_LANGUAGE = "saveAssignedLanguage";
export const SAVE_ASSIGNED_LANGUAGES = "saveAssignedLanguages";
export const SET_ASSIGNED_LANGUAGE_PARENT = "setAssignedLanguageParent";
export const SWAP_ASSIGNED_LANGUAGES = "swapAssignedLanguages";
export const UPDATE_COMMITMENT_DATA = "updateCommitmentData";
export const UPDATE_POLICY_OPTIONS = "updatePolicyOptions";
export const UPDATE_TITLE_PRODUCTION_DATA = "updateTitleProductionData";
export const VOID_POLICY = "voidPolicy";
export const COMMITMENT_ACTIONS = {
    ADD_ASSIGNED_LANGUAGE_PACKAGES,
    CLEAR_POLICY_NUMBERS,
    CLEAR_STATE,
    DELETE_ASSIGNED_LANGUAGE,
    DELETE_SECTION_ASSIGNED_LANGUAGES,
    GET_ASSIGNED_LANGUAGES,
    GET_COMMITMENT_DATA,
    GET_CUSTOM_TABS,
    GET_SCHEDULE_D,
    GET_STANDARD_LANGUAGE_SECTIONS,
    GET_TITLE_PRODUCTION_DATA,
    GET_USERS_WITH_SIGNATURES,
    FETCH_ASSIGNED_LANGUAGE_HTML,
    REMOVE_POLICY_OVERRIDE,
    SAVE_ASSIGNED_LANGUAGE,
    SAVE_ASSIGNED_LANGUAGES,
    SET_ASSIGNED_LANGUAGE_PARENT,
    SWAP_ASSIGNED_LANGUAGES,
    UPDATE_COMMITMENT_DATA,
    UPDATE_POLICY_OPTIONS,
    UPDATE_TITLE_PRODUCTION_DATA,
    VOID_POLICY
};

// orders
export const APPLY_TEMPLATE = "applyTemplate";
export const COPY_EXISTING_ORDER = "copyExistingOrder";
export const CREATE_NEW_ORDER = "createNewOrder";
export const GET_NEXT_FILE_NUMBER = "getNextFileNumber";
export const GET_ORDER = "getOrder";
export const GET_ORDER_DATA = "getOrderData";
export const GET_ORDER_DNDBW_WARNINGS = "getOrderDndbwWarnings";
export const GET_SECONDARY_FILE_CREATE = "getSecondaryFileCreate";
export const REFRESH_ORDER_LOOKUPS = "refreshOrderLookups";
export const REFRESH_ORDER_SUMMARY = "refreshOrderSummary";
export const UPDATE_FILE_NUMBER_SEQUENCE = "updateFileNumberSequence";
export const UPDATE_ORDER = "updateOrder";
export const UPDATE_ORDER_DATA = "updateOrderData";
export const UPDATE_ORDER_MAIN = "updateOrderMain";
export const UPDATE_ORDER_SETTINGS = "updateOrderSettings";
export const UPDATE_ORDER_STATUS = "updateOrderStatus";
export const ADD_ORDER_CRITICAL_NOTE = "addOrderCriticalNote";
export const UPDATE_ORDER_CRITICAL_NOTES = "updateOrderCriticalNotes";
export const UPDATE_ORDER_SECONDARY_FILE_LIST = "updateOrderSecondaryFileList";
export const SET_ORDER_TIME_TRACKER = "setOrderTimeTracker";

export const GET_DUPLICATE_SEARCH_SETUP = "getDuplicateSearchSetup";
export const GET_LOANS_AND_PROPERTIES = "getLoansAndProperties";
export const ORDER_ACTIONS = {
    APPLY_TEMPLATE,
    COPY_EXISTING_ORDER,
    CREATE_NEW_ORDER,
    GET_DUPLICATE_SEARCH_SETUP,
    GET_SECONDARY_FILE_CREATE,
    GET_LOANS_AND_PROPERTIES,
    GET_NEXT_FILE_NUMBER,
    GET_ORDER,
    GET_ORDER_DATA,
    GET_ORDER_DNDBW_WARNINGS,
    REFRESH_ORDER_LOOKUPS,
    REFRESH_ORDER_SUMMARY,
    UPDATE_FILE_NUMBER_SEQUENCE,
    UPDATE_ORDER,
    UPDATE_ORDER_DATA,
    UPDATE_ORDER_MAIN,
    UPDATE_ORDER_SETTINGS,
    UPDATE_ORDER_STATUS,
    ADD_ORDER_CRITICAL_NOTE,
    UPDATE_ORDER_CRITICAL_NOTES,
    UPDATE_ORDER_SECONDARY_FILE_LIST,
    SET_ORDER_TIME_TRACKER,
    ...LOAN_ACTIONS,
    ...COMMITMENT_ACTIONS
};

//Properties
export const ADD_PROPERTY = "addProperty";
export const COPY_BUYER_SELLER_ADDRESS = "copyPropertyWithBuyerSellerAddress";
export const DELETE_PROPERTY = "deleteProperty";
export const GET_BRIEF_LEGAL_DESCRIPTION = "getBriefLegalDescription";
export const GET_PROPERTIES = "getProperties";
export const UPDATE_PROPERTY = "updateProperty";
export const PROPERTY_ACTIONS = {
    ADD_PROPERTY,
    COPY_BUYER_SELLER_ADDRESS,
    DELETE_PROPERTY,
    GET_BRIEF_LEGAL_DESCRIPTION,
    GET_PROPERTIES,
    UPDATE_PROPERTY
};

// PrepaidSectionData
export const GET_PREPAID_SECTION_DATA = "getRecordsByOrderId";
// export const UPDATE_PREPAID_SECTION_DATA = "saveRecords";
export const UPDATE_PREPAID_SECTION_DATA = "saveDeleteRecords";
export const DELETE_PREPAID_SECTION_DATA = "deleteRecord";
export const PREPAID_SECTION_DATA_ACTIONS = {
    GET_PREPAID_SECTION_DATA,
    UPDATE_PREPAID_SECTION_DATA,
    DELETE_PREPAID_SECTION_DATA
};

// settlement
export const GET_PAYOFF_DATA = "getPayoffData";
export const SAVE_PAYOFF = "savePayoff";
export const CALCULATE_PAYOFF = "calculatePayoff";
export const DELETE_PAYOFF = "deletePayoff";
export const GET_PRORATION_DATA = "getProrationData";
export const ADD_PRORATION = "addProration";
export const UPDATE_PRORATION = "updateProration";
export const CALCULATE_PRORATION = "calculateProration";
export const DELETE_PRORATION = "deleteProration";
export const DELETE_PRORATIONS = "deleteProrations";
//export const GET_PRORATION_LOOKUPS = "getProrationLookups";
export const CALCULATE_ALL_PRORATIONS = "calculateAllProrations";
export const CALCULATE_PRORATION_DATES = "calculateProrationDates";
export const UPDATE_AND_CALC_ALL = "updateAndCalcAll";
export const MAP_SETTLEMENT = "mapSettlement";

export const SETTLEMENT_ACTIONS = {
    GET_PAYOFF_DATA,
    SAVE_PAYOFF,
    CALCULATE_PAYOFF,
    DELETE_PAYOFF,
    GET_PRORATION_DATA,
    ADD_PRORATION,
    UPDATE_PRORATION,
    CALCULATE_PRORATION,
    DELETE_PRORATION,
    DELETE_PRORATIONS,
//    GET_PRORATION_LOOKUPS,
    CALCULATE_ALL_PRORATIONS,
    CALCULATE_PRORATION_DATES,
    UPDATE_AND_CALC_ALL,
    MAP_SETTLEMENT
};

// recording fees and taxes; recording information
export const GET_RECORDING_INFORMATION = "getRecordingInformation";
export const ADD_RECORDING_INFORMATION = "addRecordingInformation";
export const UPDATE_RECORDING_INFORMATION = "updateRecordingInformation";
export const DELETE_RECORDING_INFORMATION = "deleteRecordingInformation";

export const GET_RECORDING_FEE_CALCULATIONS = "getRecordingFeeCalculations";
export const PULL_RECORDING_FEE_CALCULATIONS = "pullRecordingFeeCalculations";
export const ADD_RECORDING_FEE_CALCULATION = "addRecordingFeeCalculation";
export const UPDATE_RECORDING_FEE_CALCULATION = "updateRecordingFeeCalculation";
export const DELETE_RECORDING_FEE_CALCULATION = "deleteRecordingFeeCalculation";

export const GET_TRANSFER_TAX_CALCULATIONS = "getTransferTaxCalculations";
export const PULL_TRANSFER_TAX_CALCULATIONS = "pullTransferTaxCalculations";
export const ADD_TRANSFER_TAX_CALCULATION = "addTransferTaxCalculation";
export const UPDATE_TRANSFER_TAX_CALCULATION = "updateTransferTaxCalculation";
export const DELETE_TRANSFER_TAX_CALCULATION = "deleteTransferTaxCalculation";

export const RECORDING_ACTIONS = {
    GET_LOOKUPS,
    GET_LOANS_AND_PROPERTIES,
    GET_RECORDING_INFORMATION,
    ADD_RECORDING_INFORMATION,
    UPDATE_RECORDING_INFORMATION,
    DELETE_RECORDING_INFORMATION,
    GET_RECORDING_FEE_CALCULATIONS,
    PULL_RECORDING_FEE_CALCULATIONS,
    ADD_RECORDING_FEE_CALCULATION,
    UPDATE_RECORDING_FEE_CALCULATION,
    DELETE_RECORDING_FEE_CALCULATION,
    GET_TRANSFER_TAX_CALCULATIONS,
    PULL_TRANSFER_TAX_CALCULATIONS,
    ADD_TRANSFER_TAX_CALCULATION,
    UPDATE_TRANSFER_TAX_CALCULATION,
    DELETE_TRANSFER_TAX_CALCULATION
};

// rates/premiums
export const GET_RATE_ORDER_INFO = "getRateOrderInfo";
export const SAVE_RATE_ORDER_INFO = "saveRateOrderInfo";
export const GET_PREMIUMS = "getPremiums";
export const GET_RATES = "getRates";
export const GET_RATES_BY_DATE = "getRatesByDate";
export const GET_ALL_RATES = "getAllRates";
export const CALCULATE_RATES = "calculateRates";
export const RESET_RATE = "resetRates";
export const GET_OUTSIDE_PARTY="getOutsideParty";
export const ADD_OUTSIDE_PARTY = "addOutsideParty";
export const DELETE_OUTSIDE_PARTY = "deleteOutsideParty";
export const SAVE_OUTSIDE_PARTIES = "saveOutsideParties";
export const ADD_ORDER_ENDORSEMENTS = "addOrderEndorsements";
export const UPDATE_ORDER_ENDORSEMENTS = "updateOrderEndorsements";
export const DELETE_ORDER_ENDORSEMENT = "deleteOrderEndorsement";
export const SEARCH_RATES = "searchRates";
export const GET_RATE_BY_KEY = "getRateByKey";
export const REFRESH_ALWAYS_APPLY_ENDORSEMENTS = "refreshAlwaysApplyEndorsements"

export const RATE_ACTIONS = {
    GET_RATE_ORDER_INFO,
    SAVE_RATE_ORDER_INFO,
    GET_PREMIUMS,
    GET_RATES,
    GET_RATES_BY_DATE,
    CALCULATE_RATES,
    RESET_RATE,
    GET_OUTSIDE_PARTY,
    ADD_OUTSIDE_PARTY,
    DELETE_OUTSIDE_PARTY,
    SAVE_OUTSIDE_PARTIES,
    ADD_ORDER_ENDORSEMENTS,
    UPDATE_ORDER_ENDORSEMENTS,
    DELETE_ORDER_ENDORSEMENT,
    SEARCH_RATES,
    GET_RATE_BY_KEY,
    REFRESH_ALWAYS_APPLY_ENDORSEMENTS
};

// realtor commission
export const GET_REALTOR_COMMISSION = "getRealtorCommissionByOrderId";
export const SAVE_REALTOR_COMMISSION = "saveRealtorCommission";
export const DELETE_REALTOR_COMMISSION_PAYEE = "deleteRealtorCommissionPayee";

export const REALTOR_COMMISSION_ACTIONS = {
    GET_REALTOR_COMMISSION,
    SAVE_REALTOR_COMMISSION,
    DELETE_REALTOR_COMMISSION_PAYEE
};

// documents
export const DOCUMENT_ACTIONS = {
    ADD_DOC_TO_ORDER: "addDocumentToOrder",
    ADD_PKG_TO_ORDER: "addPackageToOrder",
    ADD_TO_ORDER: "addDocsAndPkgsToOrder",
    REMOVE_FROM_ORDER: "batchRemoveDocsFromOrder",
    REMOVE_DOC_FROM_ORDER: "removeDocumentFromOrder",
    REMOVE_PKG_FROM_ORDER: "removePackageFromOrder",
    ADD_LIST_TO_QUEUE: "addListToQueue",
    REMOVE_FROM_QUEUE: "removeFromQueue",
    REORDER_QUEUE: "reorderQueue",
    ADD_CHECKS_TO_QUEUE: "addChecksToQueue",
    ADD_CHECK_COVER_LETTER_TO_QUEUE: "addCheckCoverLetterToQueue",
    ADD_DEPOSIT_SLIPS_TO_QUEUE: "addDepositSlipsToQueue",
    UPDATE_MERGE_OPTIONS: "updateMergeOptions",
    GET_PROMPT_DATA: "getPromptData",
    GET_UNSAVED_PROMPTS: "getUnsavedDocumentPrompts",
    GET_STANDARD_LANGUAGE_CATEGORIES: "getStandardLanguageCategories",
    SAVE_PROMPT_VALUE: "saveDocumentPromptValue",
    UPDATE_SAVED_PROMPTS: "updateSavedPrompts",
    DELETE_SAVED_PROMPTS: "deleteSavedPrompts",
    CLEAR_STATE: "clearDocumentGenerationState"
}

export const GET_ORDER_CONTACTS = "getOrderContacts";
export const GET_BUYERS = "getBuyers";
export const GET_SELLERS = "getSellers";
export const ADD_ORDER_CONTACT = "addOrderContact";
export const DELETE_ORDER_CONTACT = "deleteOrderContact";
export const DELETE_ORDER_CONTACTS = "deleteOrderContacts";
export const DELETE_BUYER = "deleteBuyer";
export const DELETE_SELLER = "deleteSeller";
export const UPDATE_ORDER_CONTACT = "updateOrderContact";

export const CONTACT_ACTIONS = {
    GET_ORDER_CONTACTS,
    GET_BUYERS,
    GET_SELLERS,
    ADD_ORDER_CONTACT,
    DELETE_ORDER_CONTACT,
    DELETE_ORDER_CONTACTS,
    DELETE_BUYER,
    DELETE_SELLER,
    UPDATE_ORDER_CONTACT
};

export const GET_NOTIFICATION_INFO = "GetTaskNotificationInfo";
export const UPDATE_DASHBOARD_VIEW_DATE = "updateDashboardLastViewDate";

export const WORKFLOW_ACTIONS = {
    GET_NOTIFICATION_INFO,
    UPDATE_DASHBOARD_VIEW_DATE
};

export const RQ_SECTION_ACTIONS = {
    ADD_SECTION: "addSectionState",
    UPDATE_SECTION: "updateSectionValue",
    REMOVE_SECTION: "removeSectionState",
    COLLAPSE_ALL: "collapseAllSections",
    EXPAND_ALL: "expandAllSections",
    RESET_STATE: "resetRqSectionsState"
};

export const CREATE_EXAGO_SESSION = "createExagoSession";
export const CLEAR_EXAGO_SESSION = "clearExagoSession";
export const GET_EXAGO_REPORTS = "getExagoReports";
export const LOAD_REPORT = "loadReport";
export const GET_REPORT_API_URL = "getReportAPIUrl";

export const EXAGO_SESSION_ACTIONS = {
    CREATE_SESSION: CREATE_EXAGO_SESSION,
    CLEAR_SESSION: CLEAR_EXAGO_SESSION,
    GET_REPORTS: GET_EXAGO_REPORTS,
    LOAD_REPORT: LOAD_REPORT,
    GET_REPORT_API_URL: GET_REPORT_API_URL,
};

// Reports
export const REPORTS_SET_PARAMETER_VALUE = "setParameterValue";
export const REPORTS_SET_PARAMETER_DATES = "setParameterDates";
export const REPORTS_CLEAR_PARAMETER_VALUES = "clearParameterValues";
export const REPORTS_SET_DEPARTMENT_USERS_AVAILABLE = "setDepartmentUsersAvailable";
export const REPORTS_SET_PAYEE_COMPANY_CONTACTS = "setPayeeCompanyContacts";
export const REPORTS_SET_BEGINNING_BALANCE = "setBeginningBalance";
export const REPORTS_SET_PARAMETERS_KEYS = "setParametersKeys";
export const REPORTS_INITIALIZE_LOOKUPS = "initializeLookups";
export const REPORTS_SET_REPORT_INFO = "setReportInfo";
export const REPORTS_GET_ACTIVITY_DATE = "getActivityDate";
export const REPORT_ACTIONS = {
    CLEAR_PARAMETER_VALUES: REPORTS_CLEAR_PARAMETER_VALUES,
    SET_PARAMETER_VALUE: REPORTS_SET_PARAMETER_VALUE,
    SET_PARAMETER_DATES: REPORTS_SET_PARAMETER_DATES,
    SET_DEPARTMENT_USERS_AVAILABLE: REPORTS_SET_DEPARTMENT_USERS_AVAILABLE,
    SET_PAYEE_COMPANY_CONTACTS: REPORTS_SET_PAYEE_COMPANY_CONTACTS,
    SET_BEGINNING_BALANCE: REPORTS_SET_BEGINNING_BALANCE,
    SET_PARAMETERS_KEYS: REPORTS_SET_PARAMETERS_KEYS,
    SET_REPORT_INFO: REPORTS_SET_REPORT_INFO,
    GET_ACTIVITY_DATE: REPORTS_GET_ACTIVITY_DATE,
    INITIALIZE_LOOKUPS: REPORTS_INITIALIZE_LOOKUPS,
};


export const CLAUSE_MANAGEMENT_ACTIONS = {
    SETUP_CLAUSE_GROUP: "setupClauseGroup",
    UPDATE_CLAUSE_GROUP: "updateClauseGroup",
    ADD_CLAUSES: "addClauses",
    UPDATE_CLAUSE_DATA: "updateClauseData",
    RESET_CLAUSE_GROUPS: "resetClauseGroups"
};