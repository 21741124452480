import { ref } from "vue";

/*  EXAMPLE
-----------------
    setup() {
        const {
            dataGridOne,
            dataGridTwo,
            invokeGridOneMethod,
            invokeGridOneComponentMethod,
            invokeGridTwoMethod,
            invokeGridTwoComponentMethod
        } = useGridInvokerMethods({
            gridOne: "dataGridOne",
            gridTwo: "dataGridTwo"
        });

        return {
            dataGridOne,
            dataGridTwo,
            invokeGridOneMethod,
            invokeGridOneComponentMethod,
            invokeGridTwoMethod,
            invokeGridTwoComponentMethod
        };
    },
***************/
export default function useGridInvokerMethods(gridInfo={ grid: "dataGrid" }) {
    const gridRefs = {};
    const methods = {};
    _.each(gridInfo, (val,key) => {
        gridRefs[val] = ref(null);
        let invokeGridFunctionName = `invoke${_.upperFirst(key)}Method`;
        let invokeComponentFunctionName = `invoke${_.upperFirst(key)}ComponentMethod`;
        methods[invokeGridFunctionName] = function(method, ...params) {
            return _.invoke(gridRefs[val].value, `gridInstance.${method}`, ...params) || null;
        }
        methods[invokeComponentFunctionName] = function(method, ...params) {
            return _.invoke(gridRefs[val].value, method, ...params) || null;
        }
    });
    return {
        ...gridRefs,
        ...methods
    };
}