<template>
    <div
        :class="classAttr"
        :style="{
            height: `${circleAttrs.diameter}px`,
            width: `${circleAttrs.diameter}px`
        }">
        <svg
            :height="circleAttrs.diameter"
            :width="circleAttrs.diameter">
            <circle
                cx="50%"
                cy="50%"
                fill="transparent"
                :r="circleAttrs.innerRadius"
                :stroke-width="circleAttrs.strokeWidth"
                :stroke-dasharray="circleAttrs.circumference"
                :stroke-dashoffset="circleAttrs.strokeDashOffset"
                :transform="circleAttrs.transform"
            />
        </svg>
        <div class="gauge-detail gauge-detail-value">{{ currentPercent }}%</div>
        <div class="gauge-detail gauge-detail-descriptor">{{ currentDescriptor }}</div>
    </div>
</template>

<script setup>
    import { ref, computed, watch, onMounted } from "vue";
    import { toUpper } from "lodash-es";

    const props = defineProps({
        value: { type: Number, default: 0 },
        max: { type: Number, default: 0 },
        radius: { type: Number, default: 50 },
        descriptor: { type: String, default: "USED" },
        variant: { type: String, default: "success" },
        initialTransitionDelay: { type: Number, default: 500 }
    });

    const waitForTransition = ref(true);

    const totalValue = computed(() => props.max < 0 ? 0 : props.max);
    const currentValue = computed(() =>
        !waitForTransition.value && props.value > 0 && totalValue.value > 0
            ? props.value > totalValue.value
                ? totalValue.value
                : props.value
            : 0);
    const currentPercent = computed(() => Math.round((currentValue.value / totalValue.value) * 100));
    const currentDescriptor = computed(() => toUpper(props.descriptor));
    const circleAttrs = computed(() => calcCircleAttrs(props.radius, currentPercent.value));
    const classAttr = computed(() => `rq-circle-gauge rq-circle-gauge-${props.variant}`);

    function calcCircleAttrs(r, percentVal) {
        let diameter = r * 2;
        let strokeWidth = r * 0.2;
        let innerRadius = r - (strokeWidth / 2);
        let innerDiameter = innerRadius * 2;
        let circumference = Math.round(innerDiameter * Math.PI);
        let strokeDashOffset = Math.round(circumference - ((percentVal / 100) * circumference));
        let transform = `rotate(-90, ${r}, ${r})`;
        return {
            diameter,
            strokeWidth,
            innerRadius,
            circumference,
            strokeDashOffset,
            transform
        };
    }

    onMounted(() => {
        if(props.initialTransitionDelay <= 0) {
            waitForTransition.value = false;
            return;
        }
        //delay setting value to trigger initial css transition
        _.delay(() => {
            waitForTransition.value = false;
        }, props.initialTransitionDelay);
    });
</script>