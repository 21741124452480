import { ORDER_ACTIONS, LOAN_ACTIONS } from '../../actions';
import { LOAN_MUTATIONS } from '../../mutations';
import { ACTION_WITH_COMMIT, updateStateArray, removeFromStateArrayByValue } from '../../helpers';
import ordersApi from '@/api/orders';

/*
    TG - 5/31/19: 
    This module is currently not being referenced as an orders sub-module to avoid app-wide refactor of the order loans reference ($store.state.orders.loans),
    so for now is simply being used for organization and merged in directly with the orders module (./index.js).  For now this is simply for organization/readability porposes.  
    This state object is left here mostly for reference and/or when/if updates are made to make this a proper sub-module.  The loan actions/mutatiosn are also merged with the order
    actions/mutations for backward compatibility.
*/

const state = {
    loans: []
};

const getters = {};

const actions = {
    
    [LOAN_ACTIONS.GET_LOANS](context, checkState=false) {
        if(checkState && !_.isEmpty(context.state.loans)) {
            return Promise.resolve(context.state.loans);
        }
        let orderId = context.state.orderId;
        let apiPromise = ordersApi.getLoans(orderId);
        return ACTION_WITH_COMMIT(context, apiPromise, LOAN_MUTATIONS.SET_ORDER_LOANS);
    },

    [LOAN_ACTIONS.SAVE_LOAN] (context, { loan, changes }) {  
        let apiPromise = ordersApi.saveLoan(loan, changes);
        if (loan.loanID == 0) {
            return ACTION_WITH_COMMIT(context, apiPromise, LOAN_MUTATIONS.ADD_ORDER_LOAN);
        } else {
            return ACTION_WITH_COMMIT(context, apiPromise, LOAN_MUTATIONS.UPDATE_ORDER_LOAN);
        }
    },
    
    [LOAN_ACTIONS.DELETE_LOAN](context, ids) {
        let orderId = context.state.orderId;
        let apiPromise = ordersApi.deleteLoan(orderId, ids);
        return ACTION_WITH_COMMIT(context, apiPromise, LOAN_MUTATIONS.SET_ORDER_LOANS);
    },
    
    [LOAN_ACTIONS.REORDER_LOANS] (context, changes) {
        let orderId = context.state.orderId;
        let apiPromise = ordersApi.reorderLoans(changes);

        return ACTION_WITH_COMMIT(context, apiPromise, LOAN_MUTATIONS.SET_ORDER_LOANS)
            .then(result => {
                return context.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId, refresh: true })
                    .then(() => context.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true)) // update the default loan assignment
                    .then(() => result);
            });
    }
};

const mutations = {
    [LOAN_MUTATIONS.SET_ORDER_LOANS] (state, value) { state.loans = _.sortBy(value, "loanOrder"); }, // sort by loan order here so that we do not depend on the server-side code to supply loans in the proper order
    [LOAN_MUTATIONS.ADD_ORDER_LOAN] (state, value) {  state.loans.push(value); },
    [LOAN_MUTATIONS.UPDATE_ORDER_LOAN] (state, value) { updateStateArray(state.loans, "loanID", value, true); },
    [LOAN_MUTATIONS.REMOVE_ORDER_LOAN] (state, value) {  removeFromStateArrayByValue(state.loans, "loanID", value); }
};

export default {
    state,
    getters,
    actions,
    mutations
};