<template>
    <div class="rq-msg-viewer">
        <rq-scroll-container
            ps-class-attr="rq-table-scroll-container"
            perfect-scrollbar
            update-on-resize>
            <div ref="msgContentElement" class="msg-content">
                <div class="msg-header">
                    <div class="msg-label">From:</div>
                    <div class="msg-value">{{ email.from }}</div>

                    <div class="msg-label">To:</div>
                    <div class="msg-value">{{ email.to }}</div>

                    <div class="msg-label">Cc:</div>
                    <div class="msg-value">{{ email.cc }}</div>

                    <div class="msg-label">Bcc:</div>
                    <div class="msg-value">{{ email.bcc }}</div>

                    <div class="msg-label">Subject:</div>
                    <div class="msg-value">{{ email.subject }}</div>
                </div>
                <div class="msg-body">
                    <rq-shadow-dom-element
                        class="msg-preview"
                        :html-content="email.body"
                    />
                </div>
                <div class="msg-attachments">
                    <a v-for="link in attachmentLinks"
                        :key="link.name"
                        :download="link.name"
                        :href="link.url">{{link.text}}</a>
                </div>
            </div>
        </rq-scroll-container>
    </div>
</template>
<script setup>
    import { computed, ref, watchEffect } from 'vue';
    const props = defineProps({
        email: Object
    });
    const attachments = computed(() => props.email.attachments);
    const attachmentLinks = ref([]);

    watchEffect(() => {
        if(!_.isEmpty(attachmentLinks.value)) {
            _.each(attachmentLinks.value, a => {
                URL.revokeObjectURL(a.url);
            });
        }
        attachmentLinks.value = _.map(attachments.value, a => ({
            name: a.name,
            url: _.base64ToObjectUrl(a.contentBase64, a.type),
            text: `${a.name} (${a.fileSize}kb)`
        }));
    });
</script>

<style lang="scss">
    /* These correspond with existing sass variables in case
       we wanted to move this to within it's own scss file */
    $gray:      #54535C;
    $gray-200:  #e9ecef;
    $gray-300:  #dee2e6;
    $gray-400:  #ced4da;
    $gray-500:  #adb5bd;
    /*******************************************************/

    .rq-msg-viewer {
        position: relative;
        height: 100%;
        border: 1px solid $gray-300;
        .msg-content {
            position: relative;
            padding: 2.5rem 2.25rem;
        }
        .msg-header {
            display: grid;
            grid-template-columns: 75px 1fr;
            gap: 0.5rem;
            margin-bottom: 30px;
            font-size: 0.8rem;
            .msg-label {
                color: $gray-500;
            }
        }
        .msg-body {
            position: relative;
            .rq-shadow-dom-element {
                padding: 0;
            }
        }
        .msg-attachments > a {
            display: inline-block;
            margin: 0.5rem 0;
            padding: 0 1rem;
            height: 40px;
            line-height: 40px;
            min-width: 400px;
            background-color: $gray-200;
            vertical-align: middle;
            &, &:hover {
                color: $gray;
                font-weight: bold;
                text-decoration: none;
            }
            &:hover {
                background-color: $gray-400;
            }
        }
    }
</style>