<template>
    <div class="content-wrapper rq-report-executor">
        <rq-banner
            variant="error"
            :title="errorTitle"
            :message="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="hasError"
        />
        <rq-page-section
            v-if="(reportPathValue || reportIdValue) && !immediate"
            :title="pageTitle"
            :collapsible="hasParameters"
            ref="filterSection"
            v-model:expanded="expandParameters"
        >
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_generate_report"
                            variant="theme"
                            :disabled="paramOperationInProgress"
                            @click="onGenerateReport">
                            Generate Report
                        </b-btn>
                    </li>

                    <li class="nav-item">
                        <div class="btn-group">
                            <button
                                automation_id="dd_parameters"
                                class="btn btn-md btn-theme dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">Parameters
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li><button automation_id="btn_load_defaults" type="button" class="dropdown-item" @click="resetCriteria">Load Defaults</button></li>
                                <li><button automation_id="btn_load_last_search" type="button" class="dropdown-item" v-if="hasLastSearch" @click="loadLastCriteria">Load Last Search</button></li>
                                <li><button automation_id="btn_run_last_search" type="button" class="dropdown-item" v-if="hasLastSearch" @click="runLastCriteria">Run Last Search</button></li>
                            </ul>
                        </div>
                    </li>
                    <li v-if="showCancel" class="nav-item">
                        <b-btn
                            automation_id="btn_cancel_report"
                            variant="theme"
                            @click="onCancelReport">
                            Cancel Report
                        </b-btn>
                    </li>
                </ul>

                <report-actions
                    v-if="hasDocExportOptions"
                    ref="reportActions"
                    class="doc-report-actions ms-auto"
                    :file-types="['DOCX', 'PDF']"
                    :export-options="docExportOptions"
                    :get-export-content="getDocExportContent"
                    right
                />
            </template>
            <div v-if="showParameters && parameterDefinitions.length > 0" class="content-wrapper exago-parameter-container">
                <dynamic-parameters
                    ref="parameterComponent"
                    :parameters="parameterDefinitions"
                    v-model:parameterValues="parameterValuesLocal"
                    :report-options="reportOptionsLocal"
                    :required-count="reportInfo.requiredCount"
                    @group-changed="onGroupChanged"
                    @on-validation="onParameterValidation"
                    :is-initializing="pauseUiRoutines"
                    v-model:is-busy="paramOperationInProgress"
                />
            </div>
        </rq-page-section>
        <rq-pdf-viewer
            v-if="isDocReport"
            v-show="showDocReport"
            ref="pdfViewer"
            read-only
        />
        <report-viewer
            v-else-if="showReport"
            ref="report"
            :key="reportViewerKey"
            :report-options="reportOptionsLocal"
            :report-path="reportPathValue"
            @loading="onLoading"
            @loaded="onLoaded"
        />
        <rq-no-data
            v-if="!showDocReport && !showReport && !hasReportError"
            :text="noDataMessage"
            size="lg"
            class="h-100"
        />
        <div v-if="hasReportError && !showReport && !showDocReport"
            class="report-error-container">
            <div class="report-error-content">
                <h1><FontAwesomeIcon icon="fas fa-exclamation-triangle" />Sorry...</h1>
                <p>{{reportErrorMessage}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed } from "vue";
    import { DateTime } from "luxon";
    import { mapState, createNamespacedHelpers, useStore } from "vuex";
    import { REPORT_ACTIONS, EXAGO_SESSION_ACTIONS } from "@/store/actions";
    import { BiReportDto } from "@reporting/exago-reports/report-models";
    import { DocumentFileType } from "@documents/models";
    import { SaveDocumentLocation } from "@documents/enums";

    import ReportViewer from "./ReportViewer";
    import ReportActions from "./ReportActions";
    import DynamicParameters from "@reporting/exago-reports/parameter-views/DynamicParameters";
    import { RqPdfViewer } from "@/shared/components/rq/";

    const reportStoreHelpers = createNamespacedHelpers("reports");

    export default {
        name: "ReportExecutor",
        components: {
            ReportActions,
            DynamicParameters,
            ReportViewer,
            RqPdfViewer
        },
        props: {
            reportPath: { type: String, default: null },
            reportOptions: { type: Object, default: () => ({}) },
            reportType: { type: String, default: null }
        },
        data() {
            return {
                reportViewerKey: 0,
                reportInfo: null,
                showReport: false,
                showDocReport: false,
                showParameters: false,
                reportOptionsLocal: _.cloneDeep(this.reportOptions),
                reportGroup: "",
                parameterValuesLocal: {},
                parameterDefinitions: [],
                showCancel: false,
                expandParameters: true,
                hasError: false,
                errorMessage: "",
                errorTitle: "",
                defaultErrorMessage: "Please correct the highlighted errors on screen to continue.",
                pauseUiRoutines: true,
                lastSearch: {},
                waitMs: 2500,
                hasReportError: false,
                reportErrorMessageVal: "",
                defaultReportErrorMessage: "An issue occurred while processing this report.  Please check the selected filter options and try again.",
                docExportOptions: [],
                paramOperationInProgress: false,
                parameterValuesLocal2: {},
            }
        },
        computed: {
            ...mapState({
                userId: state => state.authentication.session.userId
            }),
            reportPathValue() {
                let path = _.isNil(this.reportPath)
                    ? _.getFrom(this.reportOptions || {}, ["path", "reportPath"], null)
                    : this.reportPath;
                if(_.isEmpty(this.reportGroup)) return path;
                return `${path}_${this.reportGroup}`;
            },
            reportIdValue() { return _.getFrom(this.reportOptions || {}, ["reportId","reportID"], null); },
            reportExt() { return _.getFrom(this.reportOptions || {}, ["ext","ext"], null); },
            reportPhysicalPath() { return _.getFrom(this.reportOptions || {}, ["physicalPath","physicalPath"], null); },
            reportIsCustom() { return _.getFrom(this.reportOptions || {}, ["isCustomReport","isCustomReport"], false); },
            hasParameters() { return this.parameterDefinitions && this.parameterDefinitions.length > 0; },
            hasLastSearch() { return !_.isEmpty(this.lastSearch); },
            pageTitle() { return this.hasParameters ? "Filter" : "Report"; },
            noDataMessage() { return this.reportPath ? "Please generate report..." : "No report loaded..." },
            isDocReport() { return this.documentTemplateId > 0; },
            documentTemplateId() { return _.getFrom(this.reportOptions || {}, ["documentTemplateId","documentTemplateID"], 0); },
            definition() { return { reportPath: this.reportPath, reportOptions: this.reportOptions, }; },
            immediate() { return _.getBool(this, "reportOptions.immediate"); },
            parameterStorageKey() { return `report_execution_${this.reportPath}`; },
            hasReportIdentifier() { return !_.isNil(this.reportPathValue) || !_.isNil(this.reportIdValue); },
            reportErrorMessage() { return this.reportErrorMessageVal || this.defaultReportErrorMessage; },
            hasDocExportOptions() { return !_.isEmpty(this.docExportOptions); }
        },
        setup() {
            const vuexStore = useStore();
            const reportApiBaseUrl = computed({ get() { return vuexStore.state.exagoSession.reportAccess?.rsApi; }, });
            return {
                reportApiBaseUrl
            };
        },
        watch: {
            definition: {
                handler() {
                    this.resetCriteria();
                },
                deep: true,
            },
            reportApiBaseUrl: {
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    if(newValue && !_.isEmpty(newValue)) {
                        this.loadApiScript()
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        created() {
            const self = this;
            self.DOC_FILE_TYPES = DocumentFileType;
            self.SAVE_DOC_LOCATIONS = SaveDocumentLocation;
            self.docReportContent = null;

            self.expandParameters = !self.immediate;

            self.additionalLookupsInitialize()
                .then(() => {
                    if (self.immediate) return self.runImmediately();
                    return self.resetCriteria();
                });

            self.fetchReportsAccess();            
        },
        methods: {
            ...reportStoreHelpers.mapActions({
                clearParameterValues: REPORT_ACTIONS.CLEAR_PARAMETER_VALUES,
                additionalLookupsInitialize: REPORT_ACTIONS.INITIALIZE_LOOKUPS,
                setParametersKeys: REPORT_ACTIONS.SET_PARAMETERS_KEYS,
                setReportInfo: REPORT_ACTIONS.SET_REPORT_INFO,
            }),
            getLastSearch() {
                const self = this;
                let response = self.$rqStore.getItem(self.parameterStorageKey) || {}
                let lastSearch = typeof response !== 'object' ? JSON.parse(response) : response;
                _.set(self, "lastSearch", lastSearch);

                return self.lastSearch;
            },
            setLastSearch(values) {
                this.$rqStore.setItem(this.parameterStorageKey, values);
                this.getLastSearch();
            },
            clearLastCriteria() {
                const self = this;

                self.$rqStore.removeItem(self.parameterStorageKey);
                _.set(self, "lastSearch", {});

                // Reset back to defaults?
                return self.resetCriteria();
            },
            resetCriteria() {
                const self = this;
                self.pauseUiRoutines = false;
                return self.$rqBusy.wait(self.reset());
            },
            runImmediately() {
                const self = this;

                self.pauseUiRoutines = true;

                let promise = self.reset()
                    .then(() => _.wait(self.waitMs))
                    .then(() => {
                        self.pauseUiRoutines = false;

                        self.generateReport(self.immediate);
                    });

                return self.$rqBusy.wait(promise);
            },
            loadLastCriteria() {
                const self = this;

                self.pauseUiRoutines = true;

                let promise = self.reset(true)
                    .then(() => _.wait(self.waitMs))
                    .then(() => {
                        self.pauseUiRoutines = false;
                    })

                return self.$rqBusy.wait(promise);
            },
            runLastCriteria() {
                const self = this;

                self.loadLastCriteria()
                    .then(() => {
                        self.generateReport();
                    });
            },
            onGenerateReport() {
                const self = this;
                self.generateReport();
            },

            validate() {
                const self = this;
                let validParameters = _.invoke(self, "$refs.parameterComponent.isValid");
                return _.parseBool(validParameters, true)
            },
            generateReport(ignoreValidation=false) {
                const self = this;

                if (!ignoreValidation && !self.validate()) return;
                self.reportOptionsLocal.reportExt = self.reportExt;
                self.reportOptionsLocal.reportPhysicalPath = self.reportPhysicalPath;
                self.reportOptionsLocal.IsCustomReport = self.reportIsCustom;
                self.resetReportError();
                self.docExportOptions = [];

                self.reportOptionsLocal.parameters = self.generateParameterData();

                self.setLastSearch(self.parameterValuesLocal);

                if(self.isDocReport)
                    self.generateDocumentReport();
                else
                    self.forceReloadReportPane();
            },
            generateDocumentReport() {
                const self = this;
                let request = {
                    documentTemplateID: self.documentTemplateId,
                    parameters: {
                        ...self.reportOptionsLocal.parameters,
                        "userId": self.userId
                    }
                };

                self.resetReportError();
                self.showDocReport = true;

                let apiPromise = self.$api.DocumentReportsApi.generateReport(request);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let reportName = _.getFrom(self, ["reportOptions.title","reportOptions.name"], null) || "Report";
                        let reportDate = DateTime.now().toFormat("yyyy-MM-dd'T'HHmmss");
                        self.docReportContent = result.content;
                        _.invoke(self, "$refs.pdfViewer.load", { fileName: `${reportName}_${reportDate}.pdf`, content: result.content });
                        self.docExportOptions = result.reportExports;
                    })
                    .catch(error => {
                        self.setReportError(error);
                    });
            },
            getReport() {
                const self = this;
                if(!self.hasReportIdentifier) return Promise.resolve(null);
                if(!self.immediate
                    && ((self.isDocReport && self.reportIdValue === self.reportInfo.reportID)
                        || (!self.isDocReport && self.reportPathValue === self.reportInfo.path))) {
                    return Promise.resolve(self.reportInfo.toDataObject());
                }
                // return self.isDocReport
                //     ? self.$api.ExagoApi.getReportById(self.reportIdValue)
                //     : self.$api.ExagoApi.getReportByPath(self.reportPathValue);
                if(self.isDocReport) {
                    return self.$api.ExagoApi.getReportById(self.reportIdValue);
                }
                else {
                    return self.$api.ReportsApi.getReportByPath(self.reportPathValue);
                }
            },
            reset(useLastSearch=false) {
                const self = this;

                self.resetValidationError();
                self.resetReportError();
                self.showReport = false;
                self.showDocReport = false;
                self.docExportOptions = [];

                self.getLastSearch();

                let history = useLastSearch ? self.lastSearch : {};
                let promise = self.clearParameterValues()
                    .then(() => self.getReport())
                    .then(report => {
                        self.reportInfo = new BiReportDto(report);
                        if(!self.isDocReport) self.reportInfo.path = self.reportPathValue;
                        self.parameterDefinitions = report?.parameters || [];
                        let optionalParams = self.isDocReport ? {} : { p_Regions: null, p_Branches: null, };
                        _.set(self, "parameterValuesLocal", _.merge(
                            {},
                            optionalParams,
                            history,
                            _.get(self.reportOptions, "parameters") || {}));

                        var keys = self.parameterDefinitions.map(p => p.name);
                        self.setParametersKeys(keys);
                        self.setReportInfo(self.reportInfo);

                        return self.forceReloadParameterPane()
                    })
                    .then(() => self.$nextTick())
                    .then(() => {
                        _.invoke(self, "$refs.filterSection.expand");
                    })
                    .catch(() => {
                        self.parameterDefinitions = []
                        self.parameterValuesLocal = {};
                        _.invoke(self, "$refs.filterSection.collapse");
                    });

                return self.$rqBusy.wait(promise);
            },
            forceReloadReportPane() {
                return this.forceReloadChild("showReport");
            },
            forceReloadParameterPane() {
                return this.forceReloadChild("showParameters");
            },
            forceReloadChild(triggerField) {
                const self = this;
                _.set(self, triggerField, false);
                self.cancelToggle(false);
                return self.$nextTick()
                    .then(() => {
                        _.set(self, triggerField, true);
                    });
            },
            onGroupChanged(e) {
                const self = this;
                self.reportGroup = e.value || "";
                self.showReport = false;
            },
            onCancelReport() {
                const self = this;
                self.showReport = false;
                this.cancelToggle(false);
            },
            onLoading() {
                this.cancelToggle(true);
            },
            onLoaded() {
                this.cancelToggle(false);
            },
            cancelToggle(enable) {
                this.showCancel = enable;
            },
            onParameterValidation(e) {
                const self = this;

                self.hasError = _.getBool(e, "hasError", true);
                self.errorMessage = _.get(e, "message") || self.defaultErrorMessage;
            },
            resetValidationError() {
                this.hasError = false;
                this.errorMessage = "";
            },
            resetReportError() {
                this.setReportError();
            },
            setReportError(error=null) {
                if(_.isNil(error)) {
                    this.reportErrorMessageVal = "";
                    this.hasReportError = false;
                    return;
                }
                let responseStatus = _.get(error, "response.status", null);
                if(responseStatus === 504) {
                    this.reportErrorMessageVal = "The report process timed out while attempting to generate this report.  Try adjusting the report filters to reduce the amount of data being processed.";
                }
                console.error(error);

                this.hasReportError = true;
                this.showReport = false;
                this.showDocReport = false;
            },

            async getDocExportContent(fileType) {
                const self = this;
                let exportItem = _.find(self.docExportOptions, { fileType });
                if(_.isNil(exportItem)) return {};
                let fileInfo = {
                    fileName: exportItem.fileName,
                    description: exportItem.fileDescription,
                    content: null
                };

                if(exportItem.fileType === DocumentFileType.PDF) {
                    fileInfo.content = await _.invoke(self, "$refs.pdfViewer.getContent");
                }
                else {
                    let document = await self.$api.DocumentsApi.getCachedDocument(exportItem.cacheKey, exportItem.fileName);
                    fileInfo.content = document.content;
                }

                return fileInfo;
            },

            refreshReportViewer() {
                const self = this;
                self.reportViewerKey += 1;
            },
            fetchReportsAccess()
            {
                const self = this;
                self.$store.dispatch(EXAGO_SESSION_ACTIONS.GET_REPORT_API_URL);
            },
            loadApiScript() {
                const self = this;
                let scriptElement = document.getElementById("j$vm");                
                if(_.isNullOrEmpty(self.reportApiBaseUrl) || !_.isNil(scriptElement)) return;
 
                const RSApiRef = {
                    id: "j$vm",
                    type: "text/javascript",
                    src: self.reportApiBaseUrl,
                    crs: self.reportApiBaseUrl.replace('lib/jreportapi.js',''),
                };

                let scrTag = document.createElement('script');
                scrTag.setAttribute('id', RSApiRef.id);
                scrTag.setAttribute('src', RSApiRef.src);
                scrTag.setAttribute('crs', RSApiRef.crs);
                scrTag.setAttribute('type', RSApiRef.type);
                scrTag.onload = () => {
                        this.refreshReportViewer();
                    };
                document.head.appendChild(scrTag);
            },

            generateParameterData() {
                const self = this;
                let mappedParameters = {}
                
                _.forEach(self.parameterValuesLocal, (val, key) => {
                    let value = val;
                    /*RQO-29401: Pull the option Select All to DB to know there is 'Select All' option is being selected
                        If the input is null, the report will be show 'None'.
                        If the input is started with 0, the report will be showed as 'All Selected'
                        Otherwhile, the report will be listed the selected items
                    */
                    if (Array.isArray(value)){
                        value = _.join(value, ",");
                        let param = self.parameterDefinitions.find(data => data.name == key);
                        if (param && param.dataType.toUpperCase() == "MULTISELECT" && val.length > 0 && val.length == param.availableSelectionsCount){
                            value = "0," + value;
                        }
                    }
                    mappedParameters[key] = value;
                });
                return mappedParameters;
            },
        }
    }
</script>
