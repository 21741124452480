<template>
    <div class="email-field">
        <dx-tag-box
            ref="emailTagBox"
            :element-attr="{ automation_id }"
            class="form-control"
            :data-source="tagBoxDataSource"
            display-expr="displayText"
            value-expr="emailAddress"
            item-template="user-email-item"
            tag-template="user-email-tag"
            :accept-custom-value="true"
            :search-enabled="true"
            :search-expr="searchExpr"
            :show-selection-controls="true"
            :show-data-before-search="false"
            :open-on-field-click="false"
            apply-value-mode="useButtons"
            placeholder="Enter Email..."
            no-data-text="No matching emails found"
            custom-item-create-event=""
            v-model:opened="isOpened"
            v-model:value="selectedTagsValue"
            @input="onTagBoxInput"
            @keyDown="onTagBoxKeyDown"
            @keyUp="onTagBoxKeyUp"
            @focusIn="onTagBoxFocusIn"
            @focusOut="onTagBoxFocusOut"
            @customItemCreating="onCustomItemCreating"
            @selectionChanged="onTagBoxSelectionChanged"
            @valueChanged="onTagBoxValueChanged">
            <template #user-email-item="{ data }">
                <div class="d-flex flex-nowrap p-2">
                    <span :title="data.listItemTitle" class="text-truncate me-3" v-html="data.listItemHtml" />
                    <span :title="data.contactType" class="text-muted text-truncate font-italic ms-auto">{{data.contactType}}</span>
                </div>
            </template>
            <template #user-email-tag="{ data }">
                <div
                    :id="data.elementId"
                    :class="{ 'dx-tag-content':true, 'rq-tag-invalid':!data.isValid }"
                    @click="onTagClick($event, 'to', data)">
                    {{data.displayText}}
                    <div class="dx-tag-remove-button"></div>
                    <b-tooltip
                        v-if="!data.isValid"
                        :target="data.elementId"
                        placement="top"
                        triggers="hover"
                        container="body"
                        variant="danger"
                        boundary="window">Invalid format.</b-tooltip>
                    <b-tooltip
                        v-else-if="!data.isCustom"
                        :target="data.elementId"
                        placement="top"
                        triggers="hover"
                        container="body"
                        boundary="window">{{data.emailAddress}}</b-tooltip>
                </div>
            </template>
        </dx-tag-box>
        <div class="field-actions">
            <b-btn
                v-if="!noData"
                class="email-action"
                v-rq-tooltip.hover.top
                title="Select Contact Emails"
                tabindex="-1"
                @click="onOpenEmailField">
                <FontAwesomeIcon icon="fas fa-search" />
            </b-btn>
            <b-btn
                v-if="showHideIcon"
                class="email-action"
                v-rq-tooltip.hover.top
                :title="`Hide ${label} Field`"
                tabindex="-1"
                @click="$emit('hide')">
                <FontAwesomeIcon icon="fas fa-times-circle" />
            </b-btn>
        </div>
    </div>
</template>
<script>
    import DxTagBox from "devextreme-vue/tag-box";
    import RegEx from "../../../utilities/RegEx";

    class EmailTagBoxItem {
        constructor(options) {
            options = options || {};
            this.id = _.uniqueId();
            this.elementId = `rq-email-tag-${this.id}`;
            this.emailAddress = _.trim(options.emailAddress || "");
            this.displayText = _.trim(options.displayText || options.fullName || this.emailAddress);
            this.contactType = options.contactType || "";
            this.isCustom = _.parseBool(options.isCustom);
        }
        get listItemTitle() { return this.displayText === this.emailAddress ? this.emailAddress : `${this.displayText}, ${this.emailAddress}`; }
        get listItemHtml() { return this.displayText === this.emailAddress ? this.emailAddress : `${this.displayText},&nbsp;${this.emailAddress}`; }
        get isValid() { return !_.isEmpty(this.emailAddress) && RegEx.Email.test(this.emailAddress); }
    }

    export default {

        name: "EmailField",

        components: { DxTagBox },

        props: {
            automation_id: { type: String, default: "" },
            label: { type: String, default: "" },
            dataSource: { type: Array, default: () => [] },
            modelValue: { type: Array, default: () => [] },
            showHideIcon: { type: Boolean, default: false },
            hasError: { type: Boolean, default: false },
        },

        data () {
            const self = this;
            return {
                tagBoxDataSource: [],
                selectedTagsValue: [],
                searchExpr: ["displayText","emailAddress"],
                isOpened: false,
                tabDefaultPrevented: false,
                inputText: "",
                searchTextInUse: false,
                isFocused: false,
                regainFocus: false
            };
        },

        computed: {
            hasErrorValue: {
                get() { return this.hasError; },
                set(val) { this.$emit("update:hasError", _.parseBool(val)); }
            },
            noData() { return _.isEmpty(this.dataSource); },
            hasUserInput() { return !_.isEmpty(_.trim(this.inputText)); }
        },

        watch: {
            dataSource: {
                handler(newValue, oldValue) {
                    let customTags = _.filter(this.tagBoxDataSource, item => item.isCustom);
                    let contactData = _.map(newValue, item => new EmailTagBoxItem(item));
                    this.tagBoxDataSource = [...contactData];
                    if(_.isEmpty(customTags)) return;
                    this.tagBoxDataSource.push(...customTags);
                },
                immediate: true
            },
            modelValue: {
                handler(newValue, oldValue) {
                    if(this.compareListValues(newValue, this.selectedTagsValue)) return;
                    _.forEach(newValue, emailAddress => {
                        if(_.some(this.tagBoxDataSource, { emailAddress })) return;
                        this.tagBoxDataSource.push(new EmailTagBoxItem({ emailAddress, isCustom: true }));
                    });
                    this.selectedTagsValue = newValue;
                },
                immediate: true
            },
            selectedTagsValue(newValue, oldValue) {
                if(this.compareListValues(newValue, this.modelValue)) return;
                this.$emit("update:modelValue", newValue);
            }
        },

        methods: {

            onCustomItemCreating(e){
                let newItem = this.selectEmailAddress(e.text);
                if(_.isEmpty(newItem)) return;
                e.customItem = newItem;
            },

            onTagBoxInput(e) {
                this.inputText = e.component.option("text");
            },

            onTagBoxSelectionChanged(e){
                if(_.isEmpty(this.inputText)) return;
                let customVal = this.inputText;
                let customValIsValid = RegEx.Email.test(customVal);
                if(!_.isEmpty(e.addedItems) && !customValIsValid) return;
                this.selectEmailAddress(customVal);
            },

            onTagBoxValueChanged(e) {
                let emailData = this.tagBoxDataSource.slice();
                _.remove(emailData, item => item.isCustom && !_.includes(this.selectedTagsValue, item.emailAddress));
                this.tagBoxDataSource = emailData;
                if(this.searchTextInUse) return;
                this.setSearchText();
                this.validate();
                if(this.isFocused || !this.regainFocus) return;
                this.focus();
            },

            onTagClick(e, target, tagData) {
                if(tagData.isValid || _.invoke(e, "target.classList.contains", "dx-tag-remove-button")) {
                    this.focus();
                    return;
                }
                this.searchTextInUse = true;
                let customValueIndex = this.selectedTagsValue.indexOf(tagData.emailAddress);
                this.selectedTagsValue.splice(customValueIndex, 1);
                this.setSearchText(tagData.emailAddress);
                this.validate();
                this.$nextTick(() => {
                    this.searchTextInUse = false;
                });
            },

            onTagBoxKeyDown(e) {
                this.tabDefaultPrevented = false;
                if(e.event.key === "Enter") {
                    this.$nextTick(() => { this.setSearchText(); });
                    return;
                }
                if(e.event.key === "Tab") {
                    let textOptionVal = e.component.option("text");
                    this.regainFocus = !_.isEmpty(textOptionVal) && !this.regainFocus;

                    //if it was already cleared then the value's already been set
                    if(!this.hasUserInput) return;

                    this.tabDefaultPrevented = true;
                    this.regainFocus = true;
                    e.event.preventDefault();
                    e.event.stopImmediatePropagation();
                    this.selectEmailAddress();
                }
            },

            onTagBoxKeyUp(e) {
                if(e.event.key !== "Tab" || !this.tabDefaultPrevented) return;
                e.event.preventDefault();
                e.event.stopImmediatePropagation();
                this.tabDefaultPrevented = false;
                this.setSearchText();
            },

            onTagBoxFocusIn(e) {
                this.regainFocus = false;
                this.isFocused = true;
                this.inputText = "";
            },

            onTagBoxFocusOut(e) {
                if(!this.isFocused) return;

                this.isFocused = false;

                let clickedOk = _.invoke(e, "event.relatedTarget.classList.contains", "dx-popup-done") || false;
                let clickedCancel = _.invoke(e, "event.relatedTarget.classList.contains", "dx-popup-cancel") || false;

                if(clickedOk) return;
                if(!this.hasUserInput || clickedCancel) {
                    this.inputText = "";
                    return;
                }

                this.selectEmailAddress();

                if(!this.regainFocus) return;
                this.focus();
            },

            onOpenEmailField() {
                //this.reset(true);
                this.isOpened = true;
                this.focus();
            },

            selectEmailAddress(val=null) {
                const self = this;
                let emailAddress = val || this.inputText;
                let newItem = null;
                let existingItem = self.findMatchingItem(emailAddress);

                self.inputText = "";

                if(_.isNil(existingItem)) {
                    newItem = new EmailTagBoxItem({ emailAddress, isCustom: true });
                    self.tagBoxDataSource.push(newItem);
                }
                else {
                    emailAddress = existingItem.emailAddress;
                }

                if(!_.some(self.selectedTagsValue, emailVal => _.toLower(emailVal) === _.toLower(emailAddress))) {
                    self.selectedTagsValue = [...self.selectedTagsValue, emailAddress];
                }
                self.isOpened = false;
                return newItem;
            },

            getSearchInputElement() {
                this.setDxOption("text","");
                let $inputElement = this.invokeDxMethod("$element");
                if(!$inputElement) return null;
                return $inputElement.find(".dx-texteditor-input");
            },

            getSearchText() {
                let $inputElement = this.getSearchInputElement();
                return _.invoke($inputElement, "val");
            },

            setSearchText(val="") {
                let $inputElement = this.getSearchInputElement();
                _.invoke($inputElement, "val", val);
            },

            closeDropDown() {
                if(!this.getDxOption("opened")) return;
                this.invokeDxMethod("close");
                this.reset(true);
            },

            reset(preserveValue=false) {
                let currentValue = this.selectedTagsValue.slice();
                this.invokeDxMethod("reset");
                if(!preserveValue) return;
                this.$nextTick(() => {
                    this.selectedTagsValue = currentValue;
                });
            },

            focus() {
                this.regainFocus = false;
                this.$nextTick(() => {
                    this.invokeDxMethod("focus");
                });
            },

            validate() {
                let customItems = _.filter(this.tagBoxDataSource, "isCustom");
                this.hasErrorValue = _.some(customItems, item => !item.isValid);
                return this.hasErrorValue;
            },

            findMatchingItem(val) {
                let trimlow = txt => _.trim(_.toLower(txt));
                let isEq = (v1,v2) => trimlow(v1) === trimlow(v2);
                return _.find(this.tagBoxDataSource, item => isEq(item.displayText, val) || isEq(item.emailAddress, val));
            },

            invokeDxMethod(method, args=[]) {
                return _.invoke(this, `$refs.emailTagBox.$_instance.${method}`, ...args);
            },

            getDxOption(option) {
                return this.invokeDxMethod("option", [option]);
            },

            setDxOption(option, value) {
                return this.invokeDxMethod("option", [option, value]);
            },

            compareListValues(listA, listB) {
                let plainListA = _.isNil(listA) ? [] : listA.slice().sort();
                let plainListB = _.isNil(listB) ? [] : listB.slice().sort();
                return _.isEqual(plainListA, plainListB);
            }
        }
    };
</script>
