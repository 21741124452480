import { ADD_FILE_GROUP, ADD_NOTE, MANAGE_NOTES, APPLY_TEMPLATE, CANCEL, SAVE, SPACER, VIEW_DOCS,
    DIVIDER, READY2CLOSE, PUBLISH, R2C_SPACER } from "./global";

export const Actions = {
    SAVE_PROPERTY: {
        automation_id: 'btn_save',
        key: "save-property",
        text: "Save",
        tooltip: "Save Property",
        disableWhenReadOnly: true
    },
    VIEW_AUDIT_LOG: {
        automation_id: 'btn_view_audit_log',
        key: "app-audit-log",
        text: "Audit",
        tooltip: "View Audit Log"
    },
    DIVIDER,
    READY2CLOSE,
    PUBLISH,
    R2C_SPACER
}

const BASIC = [
    SPACER, SPACER, ADD_FILE_GROUP,
    SPACER, SPACER, VIEW_DOCS
];

export const ViewActions = {
    MAIN: [
        ADD_FILE_GROUP, ADD_NOTE(), VIEW_DOCS,
        SAVE, CANCEL, _.assign({}, APPLY_TEMPLATE, { text: "Apply File Template"}),
            _.assign({}, Actions.VIEW_AUDIT_LOG, { props: { categories: [] }}),
            DIVIDER, READY2CLOSE, _.assign({}, PUBLISH, { text: "Publish to ready2close" })
    ],
    NOTES: BASIC,
    CPL: [
        SPACER, ADD_FILE_GROUP, VIEW_DOCS,
        SPACER, SAVE, CANCEL
    ],
    EXT: [
        SPACER, ADD_FILE_GROUP, ADD_NOTE(),
        SPACER, SPACER, VIEW_DOCS
    ],
    CUSTOM: [
        ADD_FILE_GROUP, ADD_NOTE(), VIEW_DOCS,
        SAVE, CANCEL, APPLY_TEMPLATE
    ],
    BASIC
};

export const LoansViewActions = {
    LIST: [
        SPACER, ADD_FILE_GROUP, ADD_NOTE(),
        SPACER, VIEW_DOCS, _.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Loan Template" })
    ],
    FORM: [
        ADD_FILE_GROUP, SAVE, ADD_NOTE(),
        CANCEL, VIEW_DOCS, _.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Loan Template" })
    ]
};

export const ContactsViewActions = {
    LIST: [
        SPACER, ADD_FILE_GROUP, ADD_NOTE(),
        SPACER, R2C_SPACER, READY2CLOSE, PUBLISH, VIEW_DOCS
    ],
    BUYER: [
        ADD_FILE_GROUP, ADD_NOTE(), VIEW_DOCS,
        SAVE, CANCEL, _.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Buyer Template" })
    ],
    SELLER: [
        ADD_FILE_GROUP, ADD_NOTE(), VIEW_DOCS,
        SAVE, CANCEL, _.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Seller Template" })
    ],
    COMPANY: [
        ADD_FILE_GROUP, ADD_NOTE(), VIEW_DOCS,
        SPACER, SAVE, CANCEL
    ],
    CONTACT: [
        ADD_FILE_GROUP, ADD_NOTE(), VIEW_DOCS,
        SPACER, SAVE, CANCEL
    ]
};

export const PropertiesViewActions = {
    LIST: [
        R2C_SPACER, READY2CLOSE, PUBLISH, ADD_FILE_GROUP, ADD_NOTE(),
        SPACER, VIEW_DOCS, _.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Property Template" })
    ],
    FORM: [
        ADD_FILE_GROUP, ADD_NOTE(), VIEW_DOCS,
        SAVE, CANCEL, _.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Property Template" }),
        DIVIDER, READY2CLOSE, _.assign({}, PUBLISH, { text: "Publish to ready2close" })
    ]
};

export const InvoicesViewActions = {
    LIST: [
        SPACER, ADD_FILE_GROUP, MANAGE_NOTES("Invoice"),
        SPACER, VIEW_DOCS, _.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Invoice Template" })
    ],
    FORM: [
        ADD_FILE_GROUP, MANAGE_NOTES("Invoice"), VIEW_DOCS,
        SAVE, CANCEL, _.assign({}, APPLY_TEMPLATE, { tooltip: "Apply Invoice Template" })
    ]
};