import { REALTOR_COMMISSION_ACTIONS } from '../actions';
import { REALTOR_COMMISSION_MUTATIONS } from '../mutations';
import { RealtorCommissionApi } from '../../api/';

import { BASIC_ACTION, ACTION_WITH_COMMIT } from "../helpers";

const state = {
    alertTarget: "",
    alertMessage: "",
    commission: {}
};

const getters = { };

const actions = {
    
    [REALTOR_COMMISSION_ACTIONS.GET_REALTOR_COMMISSION](context, orderId) {
        let apiPromise = RealtorCommissionApi.getByOrderId(orderId);
        return ACTION_WITH_COMMIT(context, apiPromise, REALTOR_COMMISSION_MUTATIONS.SET_REALTOR_COMMISSION);
    },

    [REALTOR_COMMISSION_ACTIONS.SAVE_REALTOR_COMMISSION](context, commission) {  
        let saveRequest = { data: commission };
        let apiPromise = RealtorCommissionApi.save(saveRequest);
        return ACTION_WITH_COMMIT(context, apiPromise, REALTOR_COMMISSION_MUTATIONS.SET_REALTOR_COMMISSION);
    },

    [REALTOR_COMMISSION_ACTIONS.DELETE_REALTOR_COMMISSION_PAYEE](context, id) {  
        let apiPromise = RealtorCommissionApi.deletePayee(id);
        return BASIC_ACTION(context, apiPromise, () => { context.commit(REALTOR_COMMISSION_MUTATIONS.REMOVE_REALTOR_COMMISSION_PAYEE, id); });
    }

    /* *************************************************************************************** 
        If payees end up being added/updated individually; otherwise, saveRealtorCommission 
        should handle all adds/updates
      *************************************************************************************** */
    // addRealtorCommissionPayee(context, commissionPayee) {  
    //     //let apiPromise = RealtorCommissionApi.add(commissionPayee);
    //     let apiPromise = Promise.resolve(commissionPayee);
    //     return ACTION_WITH_COMMIT(context, apiPromise, "addRealtorCommissionPayee");
    // },

    // updateRealtorCommissionPayee(context, commissionPayee) {  
    //     //let apiPromise = RealtorCommissionApi.update(commissionPayee);
    //     let apiPromise = Promise.resolve(commissionPayee);
    //     return ACTION_WITH_COMMIT(context, apiPromise, "updateRealtorCommissionPayee");
    // },
    /* *************************************************************************************** */
};

const mutations = {
    [REALTOR_COMMISSION_MUTATIONS.SET_REALTOR_COMMISSION](state, value) { state.commission = value; },
    [REALTOR_COMMISSION_MUTATIONS.REMOVE_REALTOR_COMMISSION_PAYEE](state, value) {         
        let itemIndex = _.findIndex(state.commission.payees, item => item.realtorCommissionPayeeID === value);
        if(itemIndex < 0) return;
        state.commission.payees.splice(itemIndex, 1);
    }
};

export default { state, getters, actions, mutations };
