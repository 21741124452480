
export default {
    data: function() {
        return {
            rqtableMxn_selectedItems: [],
            rqtableMxn_options: {}
        }
    },
    methods: {
        rqtableMxn_ClearSelection() {
            this.rqtableMxn_selectedItems = [];
        },
        rqtableMxn_OnItemSelected(e) {
            if(!this.rqtableMxn_options || !this.rqtableMxn_options.itemKey || this.rqtableMxn_options.itemKey === "") {
                console.error("rq table options object and key is needed");
            }
            let isIdFound = _.some(this.rqtableMxn_selectedItems, x => x[this.rqtableMxn_options.itemKey] === e.data[this.rqtableMxn_options.itemKey]);
            if(isIdFound && !e.isSelected)
            {
                this.rqtableMxn_selectedItems = _.filter(this.rqtableMxn_selectedItems, x => x[this.rqtableMxn_options.itemKey] !== e.data[this.rqtableMxn_options.itemKey]);
            }
            else if(!isIdFound && e.isSelected){
                this.rqtableMxn_selectedItems = [...this.rqtableMxn_selectedItems, ...[e.data]];
            }
        }
    }
}