// globalDialogs.js
import { useRqDialog, useRqBusy, useRqToast, useRqAppUtils } from "../plugins/composables";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { ORDER_ACTIONS, SET_PAGE } from "@/store/actions";
import { ORDER_MUTATIONS } from '@/store/mutations';
import { GlobalEventManager, GLOBAL_EVENTS } from "@/app.events";
import NewOrder from "@order-entry/NewOrder";
import NewSecondaryFileDialog from "@order-entry/dialogs/NewSecondaryFileDialog";
import DocumentGeneration from "@documents/views/DocumentGeneration";
import DuplicateOrderSearch from "@search/components/DuplicateOrderSearch";
import SaveDocumentsDialog from "@documents/components/SaveDocumentsDialog";
import { EmailForm, FileNumberDialog, ContentDiffDisplay } from "../components";
import { CdfEditorWrapper } from "@/integrations";

export function useGlobalDialogs() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { openDialog } = useRqDialog();
    const { waitFor } = useRqBusy();
    const { toastError } = useRqToast();
    const { goToFile, saveCurrentRoute } = useRqAppUtils();

    function showEmail({ title="Send Email", subject="", to="", cc="", bcc="", attachments=[], onOk=()=>true, onCancel=()=>true }) {
        openDialog({
            title,
            width: 1100,
            height: 825,
            component: EmailForm,
            okTitle: "Send",
            props: { subject, to, cc, bcc, attachments },
            onOk(e) {
                let result = e.component.onOk();
                if(!result) return result;
                return onOk(e);
            },
            onCancel
        });
    }

    function openNewOrderDialog() {
        openDialog({
            title: "Create New File",
            width: 600,
            adaptive: true,
            resizable: false,
            component: NewOrder,
            onOk: async (e) => {
                let orderId = await e.component.commit();
                if(_.parseNumber(orderId, 0) === 0) return false;
                goToFile(orderId);
                return true;
            }
        });
    }

    function openNewSecondaryOrderDialog({ settings }) {
        openDialog({
            title: "Create New Secondary Files",
            width: 800,
            height: 610,
            adaptive: false,
            component: NewSecondaryFileDialog,
            props: {
                settings
            },
            onOk: async (e) => {
                return e.component.save()
                    .then(data => {
                        let order = _.cloneDeep(store.state.orders.order);
                        order.isPrimaryFile = true;
                        store.commit(ORDER_MUTATIONS.SET_ORDER, order);
                        store.commit(ORDER_MUTATIONS.SET_ORDER_SECONDARY_FILE_LIST, data);
                        return true;
                    })
                    .catch(error => {
                        console.log(error);
                        e.component.errorMessage = error.errorMessage;
                        return false;
                    });
            }
        });
    }

    function openDuplicateOrderSearchDialog() {
        openDialog({
             title: "Duplicate File Search",
             width: "90%",
             height: "90%",
             scrollable: true,
             customFooter: true,
             component: DuplicateOrderSearch,
             onComponentAction: e => {
                if (e.originalEvent.name === "create") {
                    openNewOrderDialog();
                }
                if (e.originalEvent.name === "open") {
                    let orderId = _.parseNumber(e.originalEvent.data, 0);
                    if (orderId <= 0) {
                        toastError("The selected order is invalid.  Please try again.");
                        return false;
                    }
                    router.push(`/order/${orderId}/oe`);
                }
                return true;
            }
         });
    }

    async function newOrder() {
        let storePromise = store.dispatch(ORDER_ACTIONS.GET_DUPLICATE_SEARCH_SETUP);
        let result = await waitFor(storePromise);

        if(result?.fields?.length > 0) {
            openDuplicateOrderSearchDialog();
        }
        else {
            openNewOrderDialog();
        }
    }

    async function newSecondaryOrder() {
        let storePromise = store.dispatch(ORDER_ACTIONS.GET_SECONDARY_FILE_CREATE);
        let settings = await waitFor(storePromise);
        if(settings?.ordersID > 0) {
            openNewSecondaryOrderDialog({settings});
        }
    }

    async function openDocumentGenerationDialog() {
        let success = await saveCurrentRoute();
        if(!success) return;
        openDialog({
            title: "Generate Documents",
            height: "95%",
            width: "95%",
            resizable: false,
            component: DocumentGeneration,
            buttons: [
                { title: "Close", automationId: "btn_modal_close", cssClass: "btn btn-secondary", closeOnComplete: true },
                { title: "View Saved Prompts", automationId: "btn_modal_view_saved_prompts", cssClass: "btn btn-primary", closeOnComplete: false, onClick: e => e.component.launchSavedPrompts() },
                { title: "Generate", automationId: "btn_modal_ok", cssClass: "btn btn-primary", closeOnComplete: false, onClick: e => e.component.generateDocuments() }
            ]
        });
    }

    function openFileNumberDialog({ title, allowLockedFiles=false, required=true, onOk=()=>true, onCancel=()=>true }) {
        openDialog({
            title,
            width: 500,
            height: "auto",
            adaptive: true,
            component: FileNumberDialog,
            props: {
                allowLockedFiles,
                required
            },
            onOk(e) {
                let dialogResult = e.component.dialogResult();
                return onOk(dialogResult);
            },
            onCancel
        });
    }

    function openSaveDocumentsDialog({ title, viewModel, documents, isDocumentGeneration, callback=()=>false }) {
        openDialog({
            title,
            component: SaveDocumentsDialog,
            width: 950,
            minHeight: 200,
            adaptive: true,
            props: {
                viewModel,
                documents,
                isDocumentGeneration,
            },
            onOk(e) {
                if (e.component.saveComplete) return true;
                e.component.save();
                e.component.saveComplete = true;
                return callback(e);
            },
        });
    }

    // function launchCdfEditor(id=0) {
    //     const self = this;
    //     self.$rq.saveCurrentRoute().then(success => {
    //         if (id == 0) {
    //             let storePromise = self.$store.dispatch(ORDER_ACTIONS.GET_LOANS, false);
    //             self.$rqBusy.wait(storePromise)
    //             .then(result => {
    //                 if (result) {
    //                     id = result[0].loanID;
    //                     self.openCdfWysiwygDialog(id);
    //                 }
    //             });
    //         }
    //         else {
    //             self.openCdfWysiwygDialog(id);
    //         }
    //     });
    // }

    async function openCdfEditorDialog(loanId=0) {
        let success = await saveCurrentRoute();
        if(!success) return;
        openDialog({
            title: "CDF WYSIWYG",
            height: "95%",
            width: "95%",
            component: CdfEditorWrapper,
            resizable: false,
            draggable: false,
            hideHeader: true,
            okOnly: true,
            okTitle: "Close",
            onOk: async e => {
                await e.component.saveOnCloseEditor();
            },
            onClosed: async e => {
                let ordersId = store.state.orders.orderId;
                await store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, { orderId: ordersId, refresh: true});
                if(route.fullPath?.endsWith("launchEditor=cdf")){
                    await this.$router.push({ name: route.name, path: route.path, meta: route.meta, query: {} });
                }
                const routeClone = _.pick(route, [ "name", "path", "meta" ]);
                store.dispatch(SET_PAGE, routeClone);
                GlobalEventManager.emit("cdf-editor:reload-route");
            },
            props: { loanId }
        });
    }

    function openContentDiff(leftValue, rightValue, title="Content Compare", leftLabel="Current Data", rightLabel="Pending Data") {
        openDialog({
            title,
            height: "60%",
            width: "60%",
            minWidth: 700,
            props: {
                leftLabel,
                leftValue,
                rightLabel,
                rightValue
            },
            component: ContentDiffDisplay,
            closeOnly: true,
            closeTitle: "Close",
        });
    }

    const GLOBAL_DIALOG_EVENT_SOURCE = "app-root::global-dialogs"
    function registerDialogEvents() {
        GlobalEventManager.onPrint(GLOBAL_DIALOG_EVENT_SOURCE, openDocumentGenerationDialog);
        GlobalEventManager.onEmail(GLOBAL_DIALOG_EVENT_SOURCE, showEmail);
        GlobalEventManager.onNewOrder(GLOBAL_DIALOG_EVENT_SOURCE, newOrder);
        GlobalEventManager.onNewSecondaryOrder(GLOBAL_DIALOG_EVENT_SOURCE, newSecondaryOrder);
        GlobalEventManager.register(GLOBAL_DIALOG_EVENT_SOURCE, GLOBAL_EVENTS.SAVE_DOCUMENTS, openSaveDocumentsDialog);
        GlobalEventManager.register(GLOBAL_DIALOG_EVENT_SOURCE, GLOBAL_EVENTS.FILE_NUMBER_PROMPT, openFileNumberDialog);
    }

    function unregisterDialogEvents() {
        GlobalEventManager.unregister(GLOBAL_DIALOG_EVENT_SOURCE);
    }

    return {
        showEmail,
        openNewOrderDialog,
        openDuplicateOrderSearchDialog,
        openDocumentGenerationDialog,
        openFileNumberDialog,
        openSaveDocumentsDialog,
        openCdfEditorDialog,
        registerDialogEvents,
        unregisterDialogEvents,
        openContentDiff
    };
}